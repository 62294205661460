import { useMemo, useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../../../../contexts/authContext';
import { Link, useParams, useNavigate } from "react-router-dom";

import { IconChevronRight, IconSearch } from '@tabler/icons-react';
import { Loader, Autocomplete  } from '@mantine/core';

import AppHdWithCloseRight from '../../../../../composents/Headers/AppHeaders/AppHdWithCloseRight/AppHdWithCloseRight';
import AppDesktopLogo from '../../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

const ListPrestaToRecommend = (props) => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [listPresta, setlistPresta] = useState(null);
    const [searchInput, setSearchInput] = useState('');

    let {id} = useParams()

    const navigate = useNavigate();

    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/p/login");
            return;
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
            };
            window.addEventListener('resize', handleResize);
            return () => {
            window.removeEventListener('resize', handleResize);
            };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);

    const getPrestas = async () => { 
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/p/matchs/p-list-reco/${id}`, {
                method: "GET",
                headers: {
                Authorization: `Bearer ${token}`
                }
            });
            const listPresta = await response.json();
            setlistPresta(listPresta)
        } catch (error) { console.error(error);
      }
    }  
    useMemo( async () => {
        await getPrestas()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
  

    const searchList = () => {
      // filter presta according to search values
      let filteredPrestas = listPresta.sort((a,b)=>a.name.localeCompare(b.name)).filter((presta) => {
         return presta.name.toLowerCase().includes(searchInput.toLowerCase())         
      });
      
    // create render
    const filtered = filteredPrestas?.map((presta) => (
        <>
            {isMobile ? (
                <div key = {presta} style = {{marginBottom : '20px'}}>
                    <div className="width95pCenter">
                        <Link to={`/p/matchs/p-info-reco/${presta.prestaUserAccount._id}`} 
                                state={{ prestaId: presta.prestaUserAccount._id , idOffer: id}}
                                className="aReset bT">
                            <div className="horizontal iCenter spaceBtw mrgL10">
                                <div className="horizontal iCenter">
                                    <div className="mrgR10"> 
                                        {presta.logo
                                            ?   <img className="roundImg70"
                                                    src={presta.logo}
                                                    alt='logo'
                                                    size={88}
                                                />
                                            :   <div className="noAvatarProfile">{presta.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                        }
                                    </div>
                                    <div className="vertical">
                                        <div><h1 className="txt15 noMrgTB">{presta.name}</h1></div>
                                        <div><h1 className="txt12 capitals thin noMrgTB">{presta.username}</h1></div>
                                        <div><p className="txt13 bold noMrgTB finalDots">{presta.prestaUserAccount.occupation.join(" - ")}</p></div>
                                        {presta.isItAmember 
                                            ?   ""
                                            :   <div><p className="txt13 bold noMrgTB finalDots">{presta.prospectOccupation.join(" - ")}</p></div>
                                        }
                                        <div><p className="txt13 noMrgTB">{presta.address.city}</p></div>
                                    </div>
                                </div>

                                <div ><IconChevronRight width={16} className="mrgLauto mrgR10"/>
                                </div>  
                            </div>
                        </Link>
                    </div>
                </div>
            ) : (
                <div key = {presta} style = {{marginBottom : '10px'}}>
                    <div className="width50pCenter">
                        <Link to={`/p/matchs/p-info-reco/${presta.prestaUserAccount._id}`} 
                                state={{ prestaId: presta.prestaUserAccount._id , idOffer: id}}
                                className="aReset bT">
                            <div className="horizontal iCenter spaceBtw mrgT20 mrgB20 mrgR20 mrgL20">
                                <div className="horizontal iCenter">
                                    <div className="mrgR20"> 
                                        {presta.logo
                                            ?   <img className="roundImg100"
                                                    src={presta.logo}
                                                    alt='logo'
                                                    size={88}
                                                />
                                            :   <div className="noAvatarProfile">{presta.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                        }
                                    </div>
                                    <div className="vertical">
                                        <div><h1 className="txt16 noMrgTB">{presta.name}</h1></div>
                                        <div><h1 className="txt15 dGreyT capitals thin noMrgT mrgB5">{presta.username}</h1></div>
                                        <div><p className="txt15 bold noMrgTB mrgR10">{presta.prestaUserAccount.occupation.join(" - ")}</p></div>
                                        {presta.isItAmember 
                                            ?   ""
                                            :   <div><p className="txt15 bold noMrgT mrgB5mrgR10">{presta.prospectOccupation.join(" - ")}</p></div>
                                        }
                                        <div><p className="txt15 noMrgTB">{presta.address.city}</p></div>
                                    </div>
                                </div>

                                <div ><IconChevronRight width={23} className="mrgLauto mrgR10"/>
                                </div>  
                            </div>
                        </Link>
                    </div>
                </div>
            )}
        </>
    ));
    return <div>{filtered} </div>;
   }

    return (
      listPresta ?
        <>
            {isMobile ? (
                <>
                    <AppHdWithCloseRight>Recommander</AppHdWithCloseRight>
                    <div className="width95pCenter">
                            <Autocomplete
                                icon = <IconSearch/>
                                placeholder= "Rechercher"
                                data={listPresta.map(presta => presta.name)}
                                value={searchInput}
                                onChange={setSearchInput}
                            />
                    </div>
                        <div className="mrgB20"></div>
                        {searchList()}
                    
                </>
            ) : (
                <>
                    <AppDesktopLogo />
                    <AppHdWithCloseRight>Recommander</AppHdWithCloseRight>
                    <div className="width50pCenter">
                            <Autocomplete
                                className='w100 mrgR5'
                                icon = <IconSearch/>
                                placeholder= "Rechercher"
                                data={listPresta.map(presta => presta.name)}
                                value={searchInput}
                                onChange={setSearchInput}
                                size='md'
                            />
                    </div>
                    <div className="mrgB30"></div>
                    {searchList()}
                    
                </>
            )}
        </>
      : 
      <Loader />
    )  
}

export default ListPrestaToRecommend;

