import { React, useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../../../../contexts/authContext';
import { useParams, useNavigate } from "react-router-dom";

import { IconHammer, IconLocation, IconCalendarEvent, IconPlus, IconPencil, IconEye } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { FileButton, Button, Loader, Badge, Modal } from '@mantine/core';
import { parseISO, format } from 'date-fns';

import AppHdWithCloseRight from '../../../../../composents/Headers/AppHeaders/AppHdWithCloseRight/AppHdWithCloseRight';
import Box from '../../../../../composents/Boxes/Standard/Box';
import PrestaRectangleButtonMain from '../../../../../composents/Buttons/Presta/RectangleButtonMain/RectangleButtonMain';
import AppDesktopLogo from '../../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';


const SendQuotation = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [offer, setOffer] = useState(null);
    const [opened, { open, close }] = useDisclosure(false);
    const [ openedModalTooBigPdf, { open: openModalTooBigPdf, close: closeModalTooBigPdf}] = useDisclosure(false);
    const [ openedModalWrongType, { open: openModalWrongType, close: closeModalWrongType}] = useDisclosure(false);

    const [quotationFile, setQuotationFile] = useState();
    const [quotationName, setQuotationName] = useState();
    const [downloadLink, setDownloadLink] = useState();

    let {id} = useParams()

    const navigate = useNavigate();

    useEffect(() => {
        getSingleOffer();
        const token = getToken();
        if (!token) {
            navigate("/p/login");
            return;
        }
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
            };
            window.addEventListener('resize', handleResize);
            return () => {
            window.removeEventListener('resize', handleResize);
            };
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
        [quotationFile]);
    
    const getSingleOffer = async() => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/p/matchs/send-quo/${id}`, {
                method: "GET",
                headers: {
                Authorization: `Bearer ${token}`
                }
            });
            const offer = await response.json();
            const rangeDate = offer.workRangeDates
            offer.startDate = format(parseISO(rangeDate[0]), "dd/MM/yyyy"); 
            offer.endDate = format(parseISO(rangeDate[1]), "dd/MM/yyyy");
            setOffer(offer);
            } catch (error) { 
                console.error(error);
        }
    }  

    const fileBrowseHandler = async (event) => {
        setQuotationName(event.name)
        const maxSizeMo = 10 * 1024 * 1024
        if(event.size > maxSizeMo) {
            setQuotationName('')
            setQuotationFile('')
            openModalTooBigPdf()
        } else if(event.type !== 'application/pdf' && event.type !== 'image/png' && event.type !== 'image/jpeg' && event.type !== 'image/jpg'){ 
            setQuotationName('')
            setQuotationFile('')
            openModalWrongType()
        } else if (event.size <= maxSizeMo) {
            let reader = new FileReader()
            reader.readAsDataURL(event)
            reader.onload = () => {
            setQuotationFile(reader.result)
            setDownloadLink(reader.result)          
            }    
        
            await getSingleOffer()
            reader.onerror = function (error) {
            }
        };
    }

    const openPDF = () => {
        let win = window.open()
        win.document.write('<iframe src="' + downloadLink  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');

    }

    const handlSendQuotation = async(values, _event) => {
        open();
        try {
            const token = localStorage.getItem('token');
            await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/p/matchs/send-quo/${id}`, {
                method: "POST",
                body: JSON.stringify({
                    quotationFile : quotationFile,
                    quotationName : quotationName,
                    downloadLink : downloadLink
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            setTimeout(() => {
                navigate('/p/quo/list');
            }, 500)

        } catch (error) {
        }
      ;
      }

    return(
        offer ?
            <>
                {isMobile ? (
                    <>
                        <AppHdWithCloseRight>Envoyer un devis</AppHdWithCloseRight>
                            <Box>
                                <div className="horizontal iCenter mrgR10">
                                    <div className="vertical mrgR10 jCenter">                        
                                        <div className="mrgL20">
                                            {offer.pictures.length > 0
                                                ?   <img className="roundImg70"
                                                        src={offer.pictures[0]}
                                                        alt='photo_chantier'
                                                        size={88}
                                                    />
                                                :   <>
                                                        {offer.author.company.logo
                                                            ?   <img className="roundImg70"
                                                                    src={offer.author.company.logo}
                                                                    alt='photo_chantier'
                                                                    size={88}/>
                                                            :   <div className="noAvatarProfile">{offer.author.company.name.charAt(0)}</div>
                                                        }
                                                    </>
                                            }
                                            
                                        </div>                        
                                    </div>
                                    <div className="vertical jCenter mrgL10">
                                        <div className='mrgB5'>
                                            {offer.urgent 
                                                ?   <Badge color="red">Urgent</Badge> 
                                                :   ""
                                            }
                                        </div>
                                        <div className='horizontal iBtm'>
                                            
                                            <div>
                                                <p className="txt11 dGreyT noMrgTB capitals">{offer.author.company.name}</p>
                                            </div>

                                            
                                        </div>
                                            <div>
                                                <p className="txt15 lineH15 mediumplus noMrgT mrgB5">{offer.title}</p>
                                            </div>
                                            <div className="horizontal iCenter">
                                                <IconHammer width={13} height={13} className="mrgR5"/>
                                                <p className="noMrgTB txt13 mrgL3">{offer.occupation}</p>
                                            </div>
                                            <div className="horizontal iCenter">
                                                <IconCalendarEvent width={13} height={13} className="mrgR5"/>
                                                <p className="noMrgTB txt13 mrgL3">Du {offer.startDate} au {offer.endDate}</p>
                                            </div>
                                            <div className="horizontal iCenter">
                                                <IconLocation width={13} height={13} className="mrgR5"/>

                                                <p className="noMrgTB txt13 mrgL3">{offer.cityOfIntervention}</p>
                                            </div>    
                                    </div>
                                </div>
                            </Box>

                        <div className="mrgB5"></div>

                            <div className="vertical width90pCenter">
                                <p className="txt15 bold mrgB5">Devis</p>
                                    <div className='vertical iStart'>
                                        {quotationFile && (
                                            <>
                                                <p className='txt15 noMrgTB'>Fichier choisi : </p>
                                                <div className='horizontal jCenter iCenter w100 spaceBtw mrgB10'>
                                                    <div><p className='bold mrgT5 mrgB5 txt15'>{quotationName}</p></div>
                                                    <div>
                                                        <div onClick={openPDF} className='aReset bT'>
                                                            <IconEye width={22} className='mrgL10'/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {quotationFile 
                                            ?   <FileButton leftIcon={<IconPencil size="1rem" />} className='mrgT5 useRoboto medium txt13 bT w100' variant="outline" color="gray" onChange={fileBrowseHandler} accept="image/png,image/jpeg,image/jpg,application/pdf">
                                                    {(props) => <Button {...props}>Modifier Devis</Button>}
                                                </FileButton>
                                            :   <FileButton leftIcon={<IconPlus size="1rem" />} className='mrgT5 useRoboto medium txt13 bT w100' variant="outline" color="gray" onChange={fileBrowseHandler} accept="image/png,image/jpeg,image/jpg,application/pdf">
                                                    {(props) => <Button {...props}>Ajouter Devis</Button>}
                                                </FileButton>
                                        }
                                    </div>
                                    {openModalTooBigPdf 
                                        ?   <Modal 
                                                centered
                                                size="50%"
                                                opened={openedModalTooBigPdf}
                                                onClose={closeModalTooBigPdf}
                                                withCloseButton={false}>
                                                <p className="useRoboto txt15 txtCenter noMrgTB">
                                                    10 Mo Maximum par fichier
                                                </p>
                                            </Modal> 
                                        :   ""
                                    }
                                    {openModalWrongType 
                                        ?   <Modal 
                                                centered
                                                size="50%"
                                                opened={openedModalWrongType}
                                                onClose={closeModalWrongType}
                                                withCloseButton={false}>
                                                <p className="useRoboto txt15 txtCenter noMrgTB">
                                                    Fichiers autorisés : .pdf, .png, .jpg, .jpeg
                                                </p>
                                            </Modal> 
                                        :   ""
                                    }
                                <p className="txtJustify txt13 dGreyT lineH15 mrgB30">
                                    Vérifier votre fichier et sa qualité avant de l'envoyer.
                                    Tout document illisible ou ne correspondant pas à un devis sera refusé.
                                </p>

                            </div>

                            <div className="width90pCenter">
                                {quotationFile 
                                    ?   <PrestaRectangleButtonMain onClick={handlSendQuotation}>
                                            Envoyer
                                        </PrestaRectangleButtonMain>
                                    :   ""
                                }
                                {open  
                                    ?   <Modal 
                                            centered
                                            size="50%"
                                            opened={opened}
                                            onClose={close}
                                            withCloseButton={false}
                                            className='useRoboto'>
                                            <p className="txtCenter noMrgTB txt15">
                                                Devis envoyé
                                            </p>
                                        </Modal> 
                                    :   ""
                                }
                            </div>
                    </>
                ) : (
                    <>
                        <AppDesktopLogo />
                        <AppHdWithCloseRight>Envoyer un devis</AppHdWithCloseRight>
                        <div className='width50pCenter'> 
                            <Box>
                                <div className="horizontal iCenter mrgR10 mrgT10 mrgB10">
                                    <div className="vertical mrgR10 jCenter">                        
                                        <div className="mrgL20">
                                            {offer.pictures.length > 0
                                                ?   <img className="roundImg100"
                                                        src={offer.pictures[0]}
                                                        alt='photo_chantier'
                                                        size={88}
                                                    />
                                                :   <>
                                                        {offer.author.company.logo
                                                            ?   <img className="roundImg100"
                                                                    src={offer.author.company.logo}
                                                                    alt='photo_chantier'
                                                                    size={88}/>
                                                            :   <div className="noAvatarProfile">{offer.author.company.name.charAt(0)}</div>
                                                        }
                                                    </>
                                            }
                                            
                                        </div>                        
                                    </div>
                                    <div className="vertical jCenter mrgL10">
                                        <div className='mrgB5'>
                                            {offer.urgent 
                                                ?   <Badge size='lg' color="red">Urgent</Badge> 
                                                :   ""
                                            }
                                        </div>
                                        <div className='horizontal iBtm'>
                                            
                                            <div>
                                                <p className="txt13 dGreyT noMrgT mrgB5 capitals">{offer.author.company.name}</p>
                                            </div>

                                            
                                        </div>
                                            <div>
                                                <p className="txt16 lineH15 mediumplus noMrgT mrgB5">{offer.title}</p>
                                            </div>
                                            <div className="horizontal iCenter">
                                                <IconHammer width={20} height={18} className="mrgR10 mrgB5"/>
                                                <p className="noMrgT mrgB5 txt13 mrgL3">{offer.occupation}</p>
                                            </div>
                                            <div className="horizontal iCenter">
                                                <IconCalendarEvent width={20} height={18} className="mrgR10 mrgB5"/>
                                                <p className="noMrgT mrgB5 txt13 mrgL3">Du {offer.startDate} au {offer.endDate}</p>
                                            </div>
                                            <div className="horizontal iCenter">
                                                <IconLocation width={20} height={18} className="mrgR10 mrgB5"/>

                                                <p className="noMrgT mrgB5 txt13 mrgL3">{offer.cityOfIntervention}</p>
                                            </div>    
                                    </div>
                                </div>
                            </Box>

                            <div className="mrgB5"></div>

                            <div className="vertical">
                                <p className="txt16 bold mrgB5">Devis</p>
                                    <div className='vertical iStart'>
                                        {quotationFile && (
                                            <>
                                                <p className='txt15 noMrgTB'>Fichier choisi : </p>
                                                <div className='horizontal jCenter iCenter w100 spaceBtw'>
                                                    <div><p className='bold mrgT5 mrgB5 txt16'>{quotationName}</p></div>
                                                    <div>
                                                        <button onClick={openPDF} className='btnReset bWhite aReset bT'>
                                                            <IconEye width={25} className='mrgL10'/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {quotationFile 
                                            ?   <FileButton size="lg" leftIcon={<IconPencil size="1.4rem" />} className='mrgT5 useRoboto medium txt16 bT w100' variant="outline" color="gray" onChange={fileBrowseHandler} accept="image/png,image/jpeg,image/jpg,application/pdf">
                                                    {(props) => <Button {...props}><p className='txt16'>Modifier Devis</p></Button>}
                                                </FileButton>
                                            :   <FileButton size="lg" leftIcon={<IconPlus size="1.4rem" />} className='mrgT5 useRoboto medium bT w100' variant="outline" color="gray" onChange={fileBrowseHandler} accept="image/png,image/jpeg,image/jpg,application/pdf">
                                                    {(props) => <Button {...props}><p className='txt16'>Ajouter Devis</p></Button>}
                                                </FileButton>
                                        }
                                    </div>
                                    {openModalTooBigPdf 
                                        ?   <Modal 
                                                centered
                                                size="auto"
                                                opened={openedModalTooBigPdf}
                                                onClose={closeModalTooBigPdf}
                                                withCloseButton={false}>
                                                <p className="useRoboto txt16 txtCenter noMrgTB">
                                                    10 Mo Maximum par fichier
                                                </p>
                                            </Modal> 
                                        :   ""
                                    }
                                    {openModalWrongType 
                                        ?   <Modal 
                                                centered
                                                size="auto"
                                                opened={openedModalWrongType}
                                                onClose={closeModalWrongType}
                                                withCloseButton={false}>
                                                <p className="useRoboto txt16 txtCenter noMrgTB">
                                                    Fichiers autorisés : .pdf, .png, .jpg, .jpeg
                                                </p>
                                            </Modal> 
                                        :   ""
                                    }
                                <p className="txtJustify txt16 dGreyT lineH18 mrgB30">
                                    Vérifier votre fichier et sa qualité avant de l'envoyer.
                                    Tout document illisible ou ne correspondant pas à un devis sera refusé.
                                </p>

                            </div>

                            <div>
                                {quotationFile 
                                    ?   <PrestaRectangleButtonMain onClick={handlSendQuotation}>
                                            <p className='txt16 noMrgTB'>Envoyer</p>
                                        </PrestaRectangleButtonMain>
                                    :   ""
                                }
                                {open  
                                    ?   <Modal 
                                            centered
                                            size="auto"
                                            opened={opened}
                                            onClose={close}
                                            withCloseButton={false}
                                            className='useRoboto'>
                                            <p className="txtCenter noMrgTB txt16">
                                                Devis envoyé
                                            </p>
                                        </Modal> 
                                    :   ""
                                }
                            </div>
                        </div>                          
                    </>
                )}
            </>
        : 
        <Loader />
    )
}

export default SendQuotation;