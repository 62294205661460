import { React, useState, useEffect, useContext } from 'react'; 
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';

import { useDisclosure } from '@mantine/hooks';
import { TextInput, MultiSelect, Select, FileInput, Modal, Badge, Textarea, Button, Group, Autocomplete } from '@mantine/core';
import { IconCalendar } from '@tabler/icons-react';
import { DatePickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';

import { subYears } from 'date-fns';

import SyndicRectangleButton from '../../composents/Buttons/Syndic/RectangleButton/RectangleButtonMain'
import AppHdWithReturnLeft from '../../composents/Headers/AppHeaders/AppHdWithReturnLeft/AppHdWithReturnLeft';

const AddCustomer = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const [typeOfCustomer, setTypeOfCustomer ] = useState('');
    const [openedPrestaModal, { open: openPrestaModal, close: closePrestaModal}] = useDisclosure(false)
    const [openedSyndicModal, { open: openSyndicModal, close: closeSyndicModal}] = useDisclosure(false)
    const [openedConfirmationModal, { open: openConfirmationModal, close: closeConfirmationModal}] = useDisclosure(false)
    const [queryPlaceOfIntervention, setQueryPlaceOfIntervention] = useState('');
    const [optionsPlaceOfIntervention, setOptionsPlaceOfIntervention] = useState([]);
    const [placesOfIntervention, setplacesOfIntervention] = useState([]);
    const [ openedModalAlreadyAdded, { open: openModalAlreadyAdded, close: closeModalAlreadyAdded}] = useDisclosure(false)

    const [queryStreet, setQueryStreet] = useState('');
    const [optionsStreet, setOptionsStreet] = useState([]);
    const [streetSubmit, setStreetSubmit] = useState('');

    const [queryCityCie, setQueryCityCie] = useState('');
    const [optionsCityCie, setOptionsCityCie] = useState([]);
    const [cityCieSubmit, setCityCieSubmit] = useState('');

    const [ logo, setLogo ] = useState();
    const [ websiteUrl, setWebsiteUrl ] = useState();
    const [ error, setError ] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/adm/login");
            return;
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
      
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);
  

    const formPresta = useForm({
        initialValues: {
            type: 'prestataire',
            title: '',
            lastname: '',
            firstname: '',
            username: '',
            email: '',
            phone: '',
            occupation: [],
            placesOfIntervention: [],
            namecie: '',
            siretnb : '',
            logo: '',
            no: '',
            street: '',
            postalcode: '',
            city: '',
            country: '',
            phonecie: '',
            emailcie: '',
            qualifications: [],
            creationDate: subYears(new Date(),1),
            description: '',
            website: '',
            nbConnexion : 0
        },

    validate: {
        title : (value) => (value.length < 1 ? 'Sélectionnez' : null),
        lastname : (value) => (
            value.length < 1
            ? 'Renseignez le nom de l\'utilisateur artisan - prestataire'
            : value.length > 30 
            ? '30 caractères max.' 
            : null
        ),
        firstname : (value) => (
            value.length < 1 
            ? 'Renseignez le prénom de l\'utilisateur artisan - prestataire'
            : value.length > 30 
            ? '30 caractères max.'
            : null
        ),
        username : (value) => (
            value.length < 1 
            ? 'Renseignez le nom d\'utilisateur de l\'artisan - prestataire'
            : value.length > 30 
            ? '30 caractères max.'
            : null
        ),
        email : (value) => (
            value.length < 1
            ? 'Renseignez l\'E-mail de l\'utilisateur artisan - prestataire'
            : /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) 
            ? null 
            : 'E-mail non valide'
        ),
        phone: (value) => (
            value && !/^[0-9]{10}$/.test(value) 
            ? 'Numéro de téléphone invalide' 
            : null),
        occupation : (value) => (
            value.length < 1 
            ? 'Sélectionnez le ou les métier.s de l\'utilisateur artisan - prestataire'
            : null
        ),
        namecie : (value) => (
            value.length < 1 
            ? 'Renseignez la désignation de la société'
            : value.length > 30
            ? '30" caractères maximum'
            : null
        ),
        siretnb : (value) => (
            value && !/^[0-9]{14}$/.test(value) 
            ? 'Numéro de siret invalide' 
            : null),
        no : (value) => (value.length < 1 ? 'Saisir le n° de la voirie' : null),
        postalcode : (value) => (
            value && !/^[0-9]{5}$/.test(value) 
            ? 'Code postal invalide' 
            : null),
        country : (value) => (
            value.length < 1 
            ? 'Renseignez la commune de la société'
            : value.length > 35 
            ? '35 caractères max.'
            : null
        ),
        phonecie: (value) => (
            value && !/^[0-9]{10}$/.test(value) 
            ? 'Numéro de téléphone invalide' 
            : null),
        emailcie : (value) => (
            value.length < 1
            ? 'Renseignez l\'E-mail de contact de la société'
            : /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) 
            ? null 
            : 'E-mail non valide'
        ),
        creationDate : (value) => (
            value.length < 1 
            ? 'Renseignez la date de création de la société'
            : null
        ),
        description : (value) => (
            value.length < 1 
            ? 'Renseignez la description de la société'
            : value.length > 300 
            ? '300 caractères max.'
            : null
        ),
        nbConnexion : (value) => (
            value.length < 1 
            ? 'Renseignez le nombre de connexion accordé'
            : null
        ),  
    }
        
    });

    const handlePlaceOfInterventionQueryChange = async (value) => {
        if(placesOfIntervention.includes(value)){
            setQueryPlaceOfIntervention('')
            openModalAlreadyAdded()
        } else {
            setQueryPlaceOfIntervention(value);
            try {
                const token = localStorage.getItem('token');
                const response = await fetch('https://boldee-97d8d36af3bd.herokuapp.com/adm/bu/search-places', {
                    method: 'POST',
                    body: JSON.stringify({ query: value }),
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }          
                })

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                    }
  
                const data = await response.json();
                const suggestionsMap = new Map();
                data.forEach((feature) => {
                    const nom = feature.nom || '';
                    const code = feature.code || '';
                    const suggestionKey = `${code} ${nom}`;

                    suggestionsMap.set(suggestionKey, {
                        value: `${code} ${nom}`,
                        label: `${code} ${nom}`,
                        nom: nom,
                        code: code
                    });
                    });

                    const uniqueSuggestions = Array.from(suggestionsMap.values());

                setOptionsPlaceOfIntervention(uniqueSuggestions);

            } catch (error) {
            }
        }
    };

    const handleAddCity = () => {
        if (queryPlaceOfIntervention) {
            setplacesOfIntervention((prevSelected) => [...prevSelected, queryPlaceOfIntervention]);
            setQueryPlaceOfIntervention("");
        }
    };
    
    const handleDeleteCity = (city) => {
        const updatedCities = [...placesOfIntervention];
        const index = updatedCities.indexOf(city);
        if (index > -1) {
          updatedCities.splice(index, 1);
          setplacesOfIntervention(updatedCities);
        }
      };

    const formSyndic = useForm({
        initialValues: {
            type: 'syndic',
            title: '',
            lastname: '',
            firstname: '',
            email: '',
            phone: '',
            occupation: '',
            namecie: '',
            siretnb : '',
            logo: '',
            no: '',
            street: '',
            postalcode: '',
            city: '',
            country: '',
            phonecie: '',
            emailcie: ''
        },

        validate: {
            title : (value) => (value.length < 1 ? 'Sélectionnez' : null),
            lastname : (value) => (
                value.length < 1
                ? 'Renseignez le nom de l\'utilisateur syndic'
                : value.length > 30 
                ? '30 caractères max.' 
                : null
            ),
            firstname : (value) => (
                value.length < 1 
                ? 'Renseignez le prénom de l\'utilisateur syndic'
                : value.length > 30 
                ? '30 caractères max.'
                : null
            ),
            email : (value) => (
                value.length < 1
                ? 'Renseignez l\'E-mail de l\'utilisateur syndic'
                : /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) 
                ? null 
                : 'E-mail non valide'
            ),
            phone: (value) => (
                value && !/^[0-9]{10}$/.test(value) 
                ? 'Numéro de téléphone invalide' 
                : null),
            occupation : (value) => (
                value.length < 1 
                ? 'Renseignez le métier de l\'utilisateur syndic'
                : value.length > 50
                ? '50 caractères maximum'
                : null
            ),
            namecie : (value) => (
                value.length < 1 
                ? 'Renseignez la désignation de la société'
                : value.length > 30
                ? '30" caractères maximum'
                : null
            ),
            siretnb : (value) => (
                value && !/^[0-9]{14}$/.test(value) 
                ? 'Numéro de siret invalide' 
                : null),
            no : (value) => (value.length < 1 ? 'Saisir le n° de la voirie' : null),
            postalcode : (value) => (
                value && !/^[0-9]{5}$/.test(value) 
                ? 'Code postal invalide' 
                : null),
            country : (value) => (
                value.length < 1 
                ? 'Renseignez la commune de la société'
                : value.length > 35 
                ? '35 caractères max.'
                : null
            ),
            phonecie: (value) => (
                value && !/^[0-9]{10}$/.test(value) 
                ? 'Numéro de téléphone invalide' 
                : null),
            emailcie : (value) => (
                value.length < 1
                ? 'Renseignez l\'E-mail de contact de la société'
                : /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) 
                ? null 
                : 'E-mail non valide'
            )   
        }
    });

    const handleStreetQueryChange = async (value) => {
        setQueryStreet(value);
    
        if (value) {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch('https://boldee-97d8d36af3bd.herokuapp.com/adm/bu/search-address', {
                    method: 'POST',
                    body: JSON.stringify({ query: value }),
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }          
                })

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                  }
                const data = await response.json();
                const suggestions = data.features
                    .map((feature) => ({
                        value: feature.properties.street || '',
                        label: feature.properties.street || '',
                    }))
                    .filter((item, index, self) =>
                        index === self.findIndex((t) => t.value === item.value)
                    );
                    

                setOptionsStreet(suggestions);
            } catch (error) {
            }
        }
    }

    const handleStreetQuerySubmit = (streetSelected) => {
        setStreetSubmit(streetSelected)
    };

    const handleCityCieQueryChange = async (value) => {
        setQueryCityCie(value);
    
        if (value) {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch('https://boldee-97d8d36af3bd.herokuapp.com/adm/bu/search-address', {
                    method: 'POST',
                    body: JSON.stringify({ query: value }),
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }          
                })

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                  }
                const data = await response.json();
                const suggestions = data.features
                    .map((feature) => ({
                        value: feature.properties.city || '',
                        label: feature.properties.city || '',
                    }))
                    .filter((item, index, self) =>
                        index === self.findIndex((t) => t.value === item.value)
                    );
                    
                setOptionsCityCie(suggestions);
            } catch (error) {
            }
        }
    }

    const handleCityCieQuerySubmit = (citySelected) => {
        setCityCieSubmit(citySelected)
    };
    

    const HandleOpenPrestaModal = () => {
        openPrestaModal();
    }

    const handleUrlChange = (event) => {
        const inputValue = event.currentTarget.value
        if(inputValue.startsWith('https://' || 'http://')) {
         setWebsiteUrl(inputValue)
        } else {
         setWebsiteUrl('https://' + inputValue)
        }
     } 

    const handlePrestaFormSubmit = async (values, _event) => {
        _event.preventDefault();
        if(logo === undefined) {
            setLogo('')
        }        
        

        try {
            const token = localStorage.getItem('token');
            const response = await fetch("https://boldee-97d8d36af3bd.herokuapp.com/adm/bu/addPcust", {          
                method: "POST",
                body: JSON.stringify({
                    type: 'prestataire',
                    title: values.title,
                    lastname: values.lastname,
                    firstname: values.firstname,
                    username: values.username,
                    email: values.email,
                    phone: values.phone,
                    occupation: values.occupation,
                    placesOfIntervention: placesOfIntervention,
                    name: values.namecie,
                    siret_nb: values.siretnb,
                    logo: logo,
                    no: values.no,
                    street: streetSubmit.target.value,
                    postalcode: values.postalcode,
                    city: cityCieSubmit.target.value,
                    country: values.country,
                    phonecie: values.phonecie,
                    emailcie: values.emailcie,
                    qualifications: values.qualifications,
                    creationDate: values.creationDate,
                    description: values.description,
                    website: websiteUrl,
                    nbConnexion: values.nbConnexion
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            if(response.ok) {
                setTimeout(() => {
                    closePrestaModal()
                    openConfirmationModal()
                    window.location.reload();
                }, 1000)
            } else {
                const errorResponse = await response.json();
                if (errorResponse.error === 'Cet email est déjà utilisé par un autre prestataire.') {
                    setError('Email déjà utilisé par un autre prestataire.');
                }  
            }
        } catch (error) {
        }
    }

    const HandleOpenSyndicModal = () => {
        openSyndicModal();
    }

    const handleSyndicFormSubmit = async (values, _event) => {
        if(logo === undefined) {
            setLogo('')
        }
        _event.preventDefault();

        try {
            const token = localStorage.getItem('token');
            const response = await fetch("https://boldee-97d8d36af3bd.herokuapp.com/adm/bu/addScust", {
                method: "POST",
                body: JSON.stringify({
                    type: 'syndic',
                    title: values.title,
                    lastname: values.lastname,
                    firstname: values.firstname,
                    email: values.email,
                    phone: values.phone,
                    occupation: values.occupation,
                    name: values.namecie,
                    siret_nb: values.siretnb,
                    logo: logo,
                    no: values.no,
                    street: streetSubmit.target.value,
                    postalcode: values.postalcode,
                    city: cityCieSubmit.target.value,
                    country: values.country,
                    phonecie: values.phonecie,
                    emailcie: values.emailcie,
                    website: websiteUrl
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            if(response.ok) {
                setTimeout(() => {
                    closeSyndicModal()
                    openConfirmationModal()
                    window.location.reload();
                }, 1000)
            } else {
                const errorResponse = await response.json();
                if (errorResponse.error === 'Cet email est déjà utilisé par un autre prestataire.') {
                    setError('Email déjà utilisé utilisé par un autre prestataire.');
                }  
            } 
        } catch (error) {
        }
    }

    const fileBrowseHandler = (event) => {
        let reader = new FileReader()
        reader.readAsDataURL(event)
        reader.onload = () => {
          setLogo(reader.result)
        };
        reader.onerror = function (error) {
        }
    }

    const handlecloseSyndicModal = () => {
        setError('');
        closeSyndicModal();
    }

    const handleclosePrestaModal = () => {
        setError('');
        closePrestaModal();
    }

    return(
        <>{isMobile ? (
            <>
                <AppHdWithReturnLeft><p className='terracotaT'>Ajouter un nouveau client</p></AppHdWithReturnLeft>
                <p className="txt15 width90pCenter">Le client doit avoir au préalable signé un contrat d'abonnement.</p>
                
                <div className='width90pCenter'>

                    <Select
                        label="Type de client"
                        placeholder="Sélectionner"
                        data={[
                            { value:"syndic", label: 'Syndic de copropriétés' },
                            { value:"prestataire", label: 'Artisan - Prestataire' }
                        ]}
                        value={typeOfCustomer} 
                        onChange={setTypeOfCustomer}
                    />

                    <div className="mrgB30"></div>


                    {typeOfCustomer === 'prestataire' &&
                        (   <form>
                            <>
                                <h3 className="noMrgB terracotaT">Utilisateur Artisan - Prestataire</h3>
                                <hr></hr>
                                <Select
                                    label="Civilité"
                                    placeholder="Sélectionnez"
                                    data={[
                                        { value: 'Mme', label: 'Madame' },
                                        { value: 'M', label: 'Monsieur' },
                                    ]}
                                    withAsterisk
                                    {...formPresta.getInputProps('title')}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    label="Nom"
                                    placeholder="Dupont"
                                    withAsterisk
                                    {...formPresta.getInputProps('lastname')}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    label="Prénom"
                                    placeholder="Henry"
                                    withAsterisk
                                    {...formPresta.getInputProps('firstname')}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    label="Nom d'utilisateur"
                                    placeholder="HenryD88"
                                    withAsterisk
                                    {...formPresta.getInputProps('username')}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    label="Email"
                                    placeholder="Email"
                                    withAsterisk
                                    {...formPresta.getInputProps('email')}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    label="N° de téléphone direct"
                                    placeholder="XXXXXXXXXX"
                                    withAsterisk
                                    {...formPresta.getInputProps('phone')}
                                />
                                <div className="mrgB10"></div>

                                <MultiSelect 
                                    label="Métier(s)"
                                    placeholder="Sélectionnez"
                                    data={[
                                        { value: 'Entreprise de travaux', label: 'Entreprise de travaux' },
                                        { value: 'Electricien', label: 'Electricien' },
                                        { value: 'Menuisier', label: 'Menuisier' },
                                        { value: 'Plombier', label: 'Plombier' },
                                        { value: 'Entreprise de nettoyage, Gardien, Concierge', label: 'Entreprise de nettoyage, Gardien, Concierge' },
                                        { value: 'Jardinier- Paysagiste', label: 'Jardinier- Paysagiste' },
                                        { value: 'Entreprise de maintenance et de dépannage', label: 'Entreprise de maintenance et de dépannage' },
                                        { value: 'Chauffagiste', label: 'Chauffagiste' },
                                        { value: 'Désinfection, Désinsectisation, Dératisation', label: 'Désinfection, Désinsectisation, Dératisation' },
                                        { value: 'Architecte, Bureaux d\'études', label: 'Architecte, Bureaux d\'études' },
                                        { value: 'Assureur', label: 'Assureur' },
                                        { value: 'Avocat spécialisé en droit immobilier', label: 'Avocat spécialisé en droit immobilier' },
                                        { value: 'Autre', label: 'Autre' }
                                    ]}
                                    withAsterisk
                                    {...formPresta.getInputProps('occupation')}
                                />

                                <div className="mrgB10"></div>
                                <div className='horizontal iEnd mrgB10'>
                                    <Autocomplete 
                                        label="Lieu(x) d'intervention"
                                        placeholder= "Saisir un département ..."
                                        withAsterisk
                                            value={queryPlaceOfIntervention}
                                            onChange={handlePlaceOfInterventionQueryChange}
                                            data={optionsPlaceOfIntervention}
                                            textFieldProps={{ required: true }}
                                            style={{ width: '100%' }}
                                        />
                                    <Button className='mrgL3'color='dark' onClick={handleAddCity} disabled={!queryPlaceOfIntervention}>
                                        Ajouter
                                    </Button>   
                                </div>
                                {openModalAlreadyAdded  
                                    ?   <Modal 
                                            centered
                                            size="55%"                                        
                                            opened={openedModalAlreadyAdded}
                                            onClose={closeModalAlreadyAdded}
                                            withCloseButton={false}>
                                            <p className="useRoboto txt15 txtCenter noMrgTB">
                                                Département déjà enregistré
                                            </p>
                                        </Modal> 
                                    :   ""
                                }     
                                <div className='horizontal wraped'>
                                    {placesOfIntervention.map((city) => (
                                    <div key={city} >
                                        <div className='mrgR10'  onClick={() => handleDeleteCity(city)}>
                                            <Badge color='gray'>
                                                <p className='useRoboto txt13 medium noMrgTB '>{city} x</p>
                                            </Badge>
                                        
                                        </div>
                                    </div>
                                    ))}
                                </div>
                                <div className="mrgB10"></div>

                                <h3 className="noMrgB terracotaT">Société</h3>
                                <hr></hr>

                                <TextInput
                                    label="Désignation sociale de la société"
                                    placeholder="Nom de l'entreprise"
                                    withAsterisk
                                    {...formPresta.getInputProps('namecie')}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    placeholder="N° Siret"
                                    label="N° Siret"
                                    withAsterisk
                                    {...formPresta.getInputProps('siretnb')}
                                />
                                <div className="mrgB10"></div>

                                <DatePickerInput
                                    icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                                    locale="fr"
                                    valueFormat="DD/MM/YYYY"
                                    label="Date de création de la société"
                                    placeholder="Sélectionner une date"
                                    maxDate={subYears(new Date(),1)}
                                    withAsterisk
                                    {...formPresta.getInputProps('creationDate')}
                                />
                                <div className="mrgB10"></div>

                                <Textarea
                                    placeholder="Description"
                                    label="Description de la société donnée par le client"
                                    withAsterisk
                                    {...formPresta.getInputProps('description')}
                                />
                                <div className="mrgB10"></div>

                                <FileInput
                                    placeholder="Télécharger"
                                    label="Logo"
                                    onChange={fileBrowseHandler}
                                    clearable
                                    accept="image/png,image/jpeg,image/jpg"
                                />

                                <p className="thin txt15 mrgT20 mrgB5 terracotaT">Adresse postale</p>
                                <TextInput
                                    placeholder="N°"
                                    label="Numéro de rue"
                                    withAsterisk
                                    {...formPresta.getInputProps('no')}
                                />
                                <div className="mrgB10"></div>

                                <Autocomplete
                                    label="Nom de rue"
                                    placeholder="Nom de rue"
                                    withAsterisk
                                    value={queryStreet}
                                    onChange={handleStreetQueryChange}
                                    onSelect={handleStreetQuerySubmit}
                                    data={optionsStreet}
                                    textFieldProps={{ required: true }}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    placeholder="Code postal"
                                    label="Code postal"
                                    withAsterisk
                                    {...formPresta.getInputProps('postalcode')}
                                />
                                <div className="mrgB10"></div>

                                <Autocomplete
                                    placeholder="Commune"
                                    label="Commune"
                                    withAsterisk
                                    value={queryCityCie}
                                    onChange={handleCityCieQueryChange}
                                    onSelect={handleCityCieQuerySubmit}
                                    data={optionsCityCie}
                                    textFieldProps={{ required: true }}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    placeholder="Pays"
                                    label="Pays"
                                    withAsterisk
                                    {...formPresta.getInputProps('country')}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    label="N° de téléphone siège / agence"
                                    placeholder="XXXXXXXXXX"
                                    withAsterisk
                                    {...formPresta.getInputProps('phonecie')}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    label="Email du siège"
                                    placeholder="Email"
                                    withAsterisk
                                    {...formPresta.getInputProps('emailcie')}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    label="Site Web"
                                    placeholder="http://www.societe.com"
                                    value={websiteUrl}
                                    onChange={handleUrlChange}
                                />
                                <div className="mrgB10"></div>
                
                                <MultiSelect
                                    label="Certificats et qualifications"
                                    placeholder= "Sélectionner"
                                    data={[
                                        { value: 'artisandeconfiance', label: 'Artisan de Confiance' },
                                        { value: 'certibat', label: 'Certibat' },
                                        { value: 'ecoartisan', label: 'Eco-Artisans' },
                                        { value: 'ecocert', label: 'Ecocert' },
                                        { value: 'handibat', label: 'Handibat' },
                                        { value: 'iso9001', label: 'ISO 9001' },
                                        { value: 'qualibat', label: 'Qualibat' },
                                        { value: 'qualieau', label: 'Quali\'eau' },
                                        { value: 'qualienr', label: 'Qualit\'Enr' },
                                        { value: 'qualifelec', label: 'Qualifelec' },
                                        { value: 'qualipropre', label: 'Qualipropre' },
                                        { value: 'rge', label: 'RGE - Reconnu Garant de l\'Environnement' },
                                        { value: 'silverbat', label: 'Silverbat' },
                                    ]}
                                    // onChange={handlePlacesChange}
                                    clearable
                                    {...formPresta.getInputProps('qualifications')}
                                />
                                <div className="mrgB10"></div>

                                <h3 className="noMrgB terracotaT">Forfait</h3>
                                <hr></hr>
                                <Select
                                    label="Nombre de connexion"
                                    placeholder="Sélectionnez"
                                    data={[
                                        { value: '3', label: '2 connexions max' },
                                        { value: '6', label: '5 connexions max' },
                                        { value: '11', label: '10 connexions max' },
                                    ]}
                                    withAsterisk
                                    {...formPresta.getInputProps('nbConnexion')}
                                />

                                <div className="mrgB30"></div>
                                
        
                                <SyndicRectangleButton onClick={HandleOpenPrestaModal}>
                                    Enregistrer
                                </SyndicRectangleButton>
                            </>
                                {openPrestaModal  
                                    ?   <Modal 
                                            centered
                                            withCloseButton={false}    
                                            opened={openedPrestaModal} 
                                            onClose={closePrestaModal}
                                            size="80%">
                                                <Group noWrap mt="md" className="useRoboto txtCenter mrgT5 txt15">
                                                    Valider la création du nouveau compte Prestataire
                                                </Group>
                                                {error && <p className='txt13 rT'>Erreur : {error}</p>}

                                                <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20 w80">
                                                <Button variant="outline" color="dark" onClick={handleclosePrestaModal} className='useRoboto'>
                                                    Annuler
                                                </Button>
                                                <Button variant="filled" color="dark"  onClick={formPresta.onSubmit(handlePrestaFormSubmit)} className='useRoboto medium'>
                                                    Valider
                                                </Button>
                                            </Group>       
                                        </Modal> 
                                    :   ""
                                }    
                            </form>
                    
                        )
                    }

                    {typeOfCustomer === 'syndic' &&
                        (   <form>
                                <>
                                <h3 className="noMrgB terracotaT">Utilisateur Syndic de Copropriétés</h3>
                                <hr></hr>
                                <Select
                                    label="Civilité"
                                    placeholder="Sélectionnez"
                                    data={[
                                        { value: 'Mme', label: 'Madame' },
                                        { value: 'M', label: 'Monsieur' },
                                    ]}
                                    withAsterisk
                                    {...formSyndic.getInputProps('title')}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    label="Nom"
                                    placeholder="Dupont"
                                    withAsterisk
                                    {...formSyndic.getInputProps('lastname')}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    label="Prénom"
                                    placeholder="Henry"
                                    withAsterisk
                                    {...formSyndic.getInputProps('firstname')}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    label="Email"
                                    placeholder="Email"
                                    withAsterisk
                                    {...formSyndic.getInputProps('email')}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    label="N° de téléphone direct"
                                    placeholder="XXXXXXXXXX"
                                    withAsterisk
                                    {...formSyndic.getInputProps('phone')}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    label="Titre du poste"
                                    placeholder="Gestionnaire de copropriétés"
                                    withAsterisk
                                    {...formSyndic.getInputProps('occupation')}
                                />
                                <div className="mrgB30"></div>

                                <h3 className="noMrgB terracotaT">Société</h3>
                                <hr></hr>

                                <TextInput
                                    label="Désignation sociale de la société"
                                    placeholder="Nom de l'entreprise"
                                    withAsterisk
                                    {...formSyndic.getInputProps('namecie')}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    placeholder="N° Siret"
                                    label="N° Siret"
                                    withAsterisk
                                    {...formSyndic.getInputProps('siretnb')}
                                />
                                <div className="mrgB10"></div>

                                <FileInput
                                    placeholder="Télécharger"
                                    label="Logo"
                                    onChange={fileBrowseHandler}
                                    clearable
                                    accept="image/png,image/jpeg,image/jpg"
                                />

                                <p className="thin txt15 mrgT20 mrgB5 terracotaT">Adresse postale</p>
                                <TextInput
                                    placeholder="N°"
                                    label="Numéro de rue"
                                    withAsterisk
                                    {...formSyndic.getInputProps('no')}
                                />
                                <div className="mrgB10"></div>

                                <Autocomplete
                                    label="Nom de rue"
                                    placeholder="Nom de rue"
                                    withAsterisk
                                    value={queryStreet}
                                    onChange={handleStreetQueryChange}
                                    onSelect={handleStreetQuerySubmit}
                                    data={optionsStreet}
                                    textFieldProps={{ required: true }}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    placeholder="Code postal"
                                    label="Code postal"
                                    withAsterisk
                                    {...formSyndic.getInputProps('postalcode')}
                                />
                                <div className="mrgB10"></div>

                                <Autocomplete
                                    placeholder="Commune"
                                    label="Commune"
                                    withAsterisk
                                    value={queryCityCie}
                                    onChange={handleCityCieQueryChange}
                                    onSelect={handleCityCieQuerySubmit}
                                    data={optionsCityCie}
                                    textFieldProps={{ required: true }}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    placeholder="Pays"
                                    label="Pays"
                                    withAsterisk
                                    {...formSyndic.getInputProps('country')}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    label="N° de téléphone siège / agence"
                                    placeholder="XXXXXXXXXX"
                                    withAsterisk
                                    {...formSyndic.getInputProps('phonecie')}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    label="Email général siège / agence"
                                    placeholder="Email"
                                    withAsterisk
                                    {...formSyndic.getInputProps('emailcie')}
                                />

                                <div className="mrgB30"></div>
        
                                <SyndicRectangleButton onClick={HandleOpenSyndicModal}>
                                    Enregistrer
                                </SyndicRectangleButton>
                                </>
                                {openSyndicModal  
                                    ?   <Modal 
                                            centered
                                            withCloseButton={false}    
                                            opened={openedSyndicModal} 
                                            onClose={closeSyndicModal}
                                            size="60%">
                                                <Group noWrap mt="md" className="useRoboto txtCenter mrgT5 txt15">
                                                    Valider la création du nouveau compte Syndic
                                                </Group>
                                                {error && <p className='txt13 rT'>Erreur : {error}</p>}
                                                <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20 w80">
                                                    <Button variant="outline" color="dark" onClick={handlecloseSyndicModal} className='useRoboto'>
                                                        Annuler
                                                    </Button>
                                                    <Button variant="filled" color="dark" onClick={formSyndic.onSubmit(handleSyndicFormSubmit)}  className='useRoboto medium'>
                                                        Valider
                                                    </Button>
                                            </Group>       
                                        </Modal> 
                                    :   ""
                                }  
                            </form>
                        )
                    }
                
                    <div className="mrgB30"></div>
                    {openConfirmationModal  
                            ?   <Modal 
                                    centered
                                    size="55%"
                                    
                                    opened={openedConfirmationModal}
                                    onClose={closeConfirmationModal}
                                    withCloseButton={false}>
                                    <p className="useRoboto txt15 txtCenter noMrgTB">
                                        Utilisateur enregistré avec succès
                                    </p>
                                </Modal> 
                            :   ""
                    }
                </div>
            </>
        ) : (
            <>
                <AppHdWithReturnLeft><p className='terracotaT'>Ajouter un nouveau client</p></AppHdWithReturnLeft>
                <p className="txt18 width50pCenter">Le client doit avoir au préalable signé un contrat d'abonnement.</p>
                
                <div className='width50pCenter'>
                    <Select
                        label="Type de client"
                        placeholder="Sélectionner"
                        data={[
                            { value:"syndic", label: 'Syndic de copropriétés' },
                            { value:"prestataire", label: 'Artisan - Prestataire' }
                        ]}
                        value={typeOfCustomer} 
                        onChange={setTypeOfCustomer}
                        size="md"
                    />

                    <div className="mrgB30"></div>


                    {typeOfCustomer === 'prestataire' &&
                        (   <form>
                            <>
                                <h3 className="noMrgB terracotaT">Utilisateur Artisan - Prestataire</h3>
                                <hr className="mrgB20"></hr>
                                <Select
                                    label="Civilité"
                                    placeholder="Sélectionnez"
                                    data={[
                                        { value: 'Mme', label: 'Madame' },
                                        { value: 'M', label: 'Monsieur' },
                                    ]}
                                    size="md"
                                    withAsterisk
                                    {...formPresta.getInputProps('title')}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    label="Nom"
                                    placeholder="Dupont"
                                    size="md"
                                    withAsterisk
                                    {...formPresta.getInputProps('lastname')}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    label="Prénom"
                                    placeholder="Henry"
                                    size="md"
                                    withAsterisk
                                    {...formPresta.getInputProps('firstname')}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    label="Nom d'utilisateur"
                                    placeholder="HenryD88"
                                    size="md"
                                    withAsterisk
                                    {...formPresta.getInputProps('username')}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    label="Email"
                                    placeholder="Email"
                                    size="md"
                                    withAsterisk
                                    {...formPresta.getInputProps('email')}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    label="N° de téléphone direct"
                                    placeholder="XXXXXXXXXX"
                                    size="md"
                                    withAsterisk
                                    {...formPresta.getInputProps('phone')}
                                />
                                <div className="mrgB20"></div>

                                <MultiSelect 
                                    label="Métier(s)"
                                    placeholder="Sélectionnez"
                                    data={[
                                        { value: 'Entreprise de travaux', label: 'Entreprise de travaux' },
                                        { value: 'Electricien', label: 'Electricien' },
                                        { value: 'Menuisier', label: 'Menuisier' },
                                        { value: 'Plombier', label: 'Plombier' },
                                        { value: 'Entreprise de nettoyage, Gardien, Concierge', label: 'Entreprise de nettoyage, Gardien, Concierge' },
                                        { value: 'Jardinier- Paysagiste', label: 'Jardinier- Paysagiste' },
                                        { value: 'Entreprise de maintenance et de dépannage', label: 'Entreprise de maintenance et de dépannage' },
                                        { value: 'Chauffagiste', label: 'Chauffagiste' },
                                        { value: 'Désinfection, Désinsectisation, Dératisation', label: 'Désinfection, Désinsectisation, Dératisation' },
                                        { value: 'Architecte, Bureaux d\'études', label: 'Architecte, Bureaux d\'études' },
                                        { value: 'Assureur', label: 'Assureur' },
                                        { value: 'Avocat spécialisé en droit immobilier', label: 'Avocat spécialisé en droit immobilier' },
                                        { value: 'Autre', label: 'Autre' }
                                    ]}
                                    size="md"
                                    withAsterisk
                                    {...formPresta.getInputProps('occupation')}
                                />

                                <div className="mrgB20"></div>
                                <div className='horizontal iEnd mrgB10'>
                                    <Autocomplete 
                                        label="Lieu(x) d'intervention"
                                        placeholder= "Saisir un département ..."
                                        size="md"
                                        withAsterisk
                                            value={queryPlaceOfIntervention}
                                            onChange={handlePlaceOfInterventionQueryChange}
                                            data={optionsPlaceOfIntervention}
                                            textFieldProps={{ required: true }}
                                            style={{ width: '100%' }}
                                        />
                                    <Button size="md" className='mrgL3'color='dark' onClick={handleAddCity} disabled={!queryPlaceOfIntervention}>
                                        Ajouter
                                    </Button>   
                                </div>
                                {openModalAlreadyAdded  
                                    ?   <Modal 
                                            centered
                                            size="auto"                                        
                                            opened={openedModalAlreadyAdded}
                                            onClose={closeModalAlreadyAdded}
                                            withCloseButton={false}>
                                            <p className="useRoboto txt18 txtCenter noMrgTB">
                                                Département déjà enregistré
                                            </p>
                                        </Modal> 
                                    :   ""
                                }     
                                <div className='horizontal wraped'>
                                    {placesOfIntervention.map((city) => (
                                    <div key={city} >
                                        <div className='mrgR10'  onClick={() => handleDeleteCity(city)}>
                                            <Badge color='gray'>
                                                <p className='useRoboto txt13 medium noMrgTB'>{city} x</p>
                                            </Badge>
                                        
                                        </div>
                                    </div>
                                    ))}
                                </div>
                                <div className="mrgB30"></div>

                                <h3 className="noMrgB terracotaT">Société</h3>
                                <hr className="mrgB20"></hr>

                                <TextInput
                                    label="Désignation sociale de la société"
                                    placeholder="Nom de l'entreprise"
                                    size="md"
                                    withAsterisk
                                    {...formPresta.getInputProps('namecie')}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    placeholder="N° Siret"
                                    label="N° Siret"
                                    size="md"
                                    withAsterisk
                                    {...formPresta.getInputProps('siretnb')}
                                />
                                <div className="mrgB20"></div>

                                <DatePickerInput
                                    icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                                    locale="fr"
                                    valueFormat="DD/MM/YYYY"
                                    label="Date de création de la société"
                                    placeholder="Sélectionner une date"
                                    maxDate={subYears(new Date(),1)}
                                    size="md"
                                    withAsterisk
                                    {...formPresta.getInputProps('creationDate')}
                                />
                                <div className="mrgB20"></div>

                                <Textarea
                                    placeholder="Description"
                                    label="Description de la société donnée par le client"
                                    size="md"
                                    withAsterisk
                                    {...formPresta.getInputProps('description')}
                                />
                                <div className="mrgB20"></div>

                                <FileInput
                                    placeholder="Télécharger"
                                    label="Logo"
                                    onChange={fileBrowseHandler}
                                    size="md"
                                    clearable
                                    accept="image/png,image/jpeg,image/jpg"
                                />

                                <p className="thin txt16 mrgT20 mrgB5 terracotaT">Adresse postale</p>
                                <TextInput
                                    placeholder="N°"
                                    label="Numéro de rue"
                                    size="md"
                                    withAsterisk
                                    {...formPresta.getInputProps('no')}
                                />
                                <div className="mrgB20"></div>

                                <Autocomplete
                                    label="Nom de rue"
                                    placeholder="Nom de rue"
                                    withAsterisk
                                    value={queryStreet}
                                    onChange={handleStreetQueryChange}
                                    onSelect={handleStreetQuerySubmit}
                                    data={optionsStreet}
                                    textFieldProps={{ required: true }}
                                    size="md"
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    placeholder="Code postal"
                                    label="Code postal"
                                    withAsterisk
                                    size="md"
                                    {...formPresta.getInputProps('postalcode')}
                                />
                                <div className="mrgB20"></div>

                                <Autocomplete
                                    placeholder="Commune"
                                    label="Commune"
                                    size="md"
                                    withAsterisk
                                    value={queryCityCie}
                                    onChange={handleCityCieQueryChange}
                                    onSelect={handleCityCieQuerySubmit}
                                    data={optionsCityCie}
                                    textFieldProps={{ required: true }}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    placeholder="Pays"
                                    label="Pays"
                                    size="md"
                                    withAsterisk
                                    {...formPresta.getInputProps('country')}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    label="N° de téléphone siège / agence"
                                    placeholder="XXXXXXXXXX"
                                    size="md"
                                    withAsterisk
                                    {...formPresta.getInputProps('phonecie')}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    label="Email du siège"
                                    placeholder="Email"
                                    size="md"
                                    withAsterisk
                                    {...formPresta.getInputProps('emailcie')}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    label="Site Web"
                                    placeholder="http://www.societe.com"
                                    value={websiteUrl}
                                    onChange={handleUrlChange}
                                    size="md"
                                />
                                <div className="mrgB20"></div>
                
                                <MultiSelect
                                    label="Certificats et qualifications"
                                    placeholder= "Sélectionner"
                                    data={[
                                        { value: 'artisandeconfiance', label: 'Artisan de Confiance' },
                                        { value: 'certibat', label: 'Certibat' },
                                        { value: 'ecoartisan', label: 'Eco-Artisans' },
                                        { value: 'ecocert', label: 'Ecocert' },
                                        { value: 'handibat', label: 'Handibat' },
                                        { value: 'iso9001', label: 'ISO 9001' },
                                        { value: 'qualibat', label: 'Qualibat' },
                                        { value: 'qualieau', label: 'Quali\'eau' },
                                        { value: 'qualienr', label: 'Qualit\'Enr' },
                                        { value: 'qualifelec', label: 'Qualifelec' },
                                        { value: 'qualipropre', label: 'Qualipropre' },
                                        { value: 'rge', label: 'RGE - Reconnu Garant de l\'Environnement' },
                                        { value: 'silverbat', label: 'Silverbat' },
                                    ]}
                                    size="md"
                                    clearable
                                    {...formPresta.getInputProps('qualifications')}
                                />
                                <div className="mrgB20"></div>

                                <h3 className="noMrgB terracotaT">Forfait</h3>
                                <hr className='mrgB20'></hr>
                                <Select
                                    label="Nombre de connexion"
                                    placeholder="Sélectionnez"
                                    data={[
                                        { value: '3', label: '2 connexions max' },
                                        { value: '6', label: '5 connexions max' },
                                        { value: '11', label: '10 connexions max' },
                                    ]}
                                    size="md"
                                    withAsterisk
                                    {...formPresta.getInputProps('nbConnexion')}
                                />

                                <div className="mrgB30"></div>
                                
        
                                <SyndicRectangleButton onClick={HandleOpenPrestaModal}>
                                    Enregistrer
                                </SyndicRectangleButton>
                            </>
                                {openPrestaModal  
                                    ?   <Modal 
                                            centered
                                            withCloseButton={false}    
                                            opened={openedPrestaModal} 
                                            onClose={closePrestaModal}
                                            size="auto">
                                                <Group noWrap mt="md" className="useRoboto txtCenter mrgT5 txt18">
                                                    Valider la création du nouveau compte Prestataire
                                                </Group>
                                                {error && <p className='txt15 rT'>Erreur : {error}</p>}

                                                <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20 w80">
                                                <Button variant="outline" color="dark" onClick={handleclosePrestaModal} className='useRoboto'>
                                                    Annuler
                                                </Button>
                                                <Button variant="filled" color="dark"  onClick={formPresta.onSubmit(handlePrestaFormSubmit)} className='useRoboto medium'>
                                                    Valider
                                                </Button>
                                            </Group>       
                                        </Modal> 
                                    :   ""
                                }    
                            </form>
                    
                        )
                    }

                    {typeOfCustomer === 'syndic' &&
                        (   <form>
                                <>
                                <h3 className="noMrgB terracotaT">Utilisateur Syndic de Copropriétés</h3>
                                <hr className='mrgB20'></hr>
                                <Select
                                    label="Civilité"
                                    placeholder="Sélectionnez"
                                    data={[
                                        { value: 'Mme', label: 'Madame' },
                                        { value: 'M', label: 'Monsieur' },
                                    ]}
                                    size="md"
                                    withAsterisk
                                    {...formSyndic.getInputProps('title')}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    label="Nom"
                                    placeholder="Dupont"
                                    size="md"
                                    withAsterisk
                                    {...formSyndic.getInputProps('lastname')}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    label="Prénom"
                                    placeholder="Henry"
                                    size="md"
                                    withAsterisk
                                    {...formSyndic.getInputProps('firstname')}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    label="Email"
                                    placeholder="Email"
                                    size="md"
                                    withAsterisk
                                    {...formSyndic.getInputProps('email')}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    label="N° de téléphone direct"
                                    placeholder="XXXXXXXXXX"
                                    size="md"
                                    withAsterisk
                                    {...formSyndic.getInputProps('phone')}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    label="Titre du poste"
                                    placeholder="Gestionnaire de copropriétés"
                                    size="md"
                                    withAsterisk
                                    {...formSyndic.getInputProps('occupation')}
                                />
                                <div className="mrgB30"></div>

                                <h3 className="noMrgB terracotaT">Société</h3>
                                <hr className='mrgB20'></hr>

                                <TextInput
                                    label="Désignation sociale de la société"
                                    placeholder="Nom de l'entreprise"
                                    size="md"
                                    withAsterisk
                                    {...formSyndic.getInputProps('namecie')}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    placeholder="N° Siret"
                                    label="N° Siret"
                                    size="md"
                                    withAsterisk
                                    {...formSyndic.getInputProps('siretnb')}
                                />
                                <div className="mrgB20"></div>

                                <FileInput
                                    placeholder="Télécharger"
                                    label="Logo"
                                    onChange={fileBrowseHandler}
                                    clearable
                                    accept="image/png,image/jpeg,image/jpg"
                                    size="md"
                                />

                                <p className="thin txt18 mrgT20 mrgB5 terracotaT">Adresse postale</p>
                                <TextInput
                                    placeholder="N°"
                                    label="Numéro de rue"
                                    size="md"
                                    withAsterisk
                                    {...formSyndic.getInputProps('no')}
                                />
                                <div className="mrgB20"></div>

                                <Autocomplete
                                    label="Nom de rue"
                                    placeholder="Nom de rue"
                                    size="md"
                                    withAsterisk
                                    value={queryStreet}
                                    onChange={handleStreetQueryChange}
                                    onSelect={handleStreetQuerySubmit}
                                    data={optionsStreet}
                                    textFieldProps={{ required: true }}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    placeholder="Code postal"
                                    label="Code postal"
                                    size="md"
                                    withAsterisk
                                    {...formSyndic.getInputProps('postalcode')}
                                />
                                <div className="mrgB20"></div>

                                <Autocomplete
                                    placeholder="Commune"
                                    label="Commune"
                                    size="md"
                                    withAsterisk
                                    value={queryCityCie}
                                    onChange={handleCityCieQueryChange}
                                    onSelect={handleCityCieQuerySubmit}
                                    data={optionsCityCie}
                                    textFieldProps={{ required: true }}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    placeholder="Pays"
                                    label="Pays"
                                    size="md"
                                    withAsterisk
                                    {...formSyndic.getInputProps('country')}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    label="N° de téléphone siège / agence"
                                    placeholder="XXXXXXXXXX"
                                    size="md"
                                    withAsterisk
                                    {...formSyndic.getInputProps('phonecie')}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    label="Email général siège / agence"
                                    placeholder="Email"
                                    size="md"
                                    withAsterisk
                                    {...formSyndic.getInputProps('emailcie')}
                                />

                                <div className="mrgB30"></div>
        
                                <SyndicRectangleButton onClick={HandleOpenSyndicModal}>
                                    Enregistrer
                                </SyndicRectangleButton>
                                </>
                                {openSyndicModal  
                                    ?   <Modal 
                                            centered
                                            withCloseButton={false}    
                                            opened={openedSyndicModal} 
                                            onClose={closeSyndicModal}
                                            size="60%">
                                                <Group noWrap mt="md" className="useRoboto txtCenter mrgT5 txt15">
                                                    Valider la création du nouveau compte Syndic
                                                </Group>
                                                {error && <p className='txt13 rT'>Erreur : {error}</p>}
                                                <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20 w80">
                                                    <Button variant="outline" color="dark" onClick={handlecloseSyndicModal} className='useRoboto'>
                                                        Annuler
                                                    </Button>
                                                    <Button variant="filled" color="dark" onClick={formSyndic.onSubmit(handleSyndicFormSubmit)}  className='useRoboto medium'>
                                                        Valider
                                                    </Button>
                                            </Group>       
                                        </Modal> 
                                    :   ""
                                }  
                            </form>
                        )
                    }
                
                    <div className="mrgB30"></div>
                    {openConfirmationModal  
                            ?   <Modal 
                                    centered
                                    size="55%"
                                    
                                    opened={openedConfirmationModal}
                                    onClose={closeConfirmationModal}
                                    withCloseButton={false}>
                                    <p className="useRoboto txt15 txtCenter noMrgTB">
                                        Utilisateur enregistré avec succès
                                    </p>
                                </Modal> 
                            :   ""
                    }
                </div>
            </>
        )}
        </>
    );
}
export default AddCustomer;