import { React, useEffect, useState } from 'react';
import  { NavLink } from "react-router-dom";
import { IconCalendarDue, IconInbox , IconFocus, IconBadge, IconUser } from '@tabler/icons-react';
import LogoBurgerMenuApp from '../../composents/Logo/BurgerMenuLogoApp/LogoBurgerMenuApp';

 
const AppNavbarPresta = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    
    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 768);
        };
    
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return(
        <div className="app-navbar">
            {isMobile ? (
                <>
                    <ul id="app-navbar-container" >
                        <NavLink to = "/p/rdv/list" activeClassName="active">
                            <IconCalendarDue/>
                            <p>RDV</p>
                        </NavLink>
                        <NavLink to = "/p/quo/list" activeClassName="active">
                            <IconInbox/>
                            <p>Devis</p>
                        </NavLink>
                        <NavLink to = "/p/matchs/list" activeClassName="active">
                            <IconFocus/>
                            <p>Matchs</p>
                        </NavLink>
                        <NavLink to = "/p/offers/list" activeClassName="active">
                            <IconBadge/>
                            <p>Offres</p>
                        </NavLink>
                        <NavLink to = "/p/profile/menu" activeClassName="active">
                            <IconUser/>
                            <p id="navProfilPresta">Profil</p>
                        </NavLink>
                        
                    </ul>
                </>
            ) : (
                <>
                    <div className='horizontal spaceBtw iCenter appMenuBorder'>
                        <LogoBurgerMenuApp />
                        <div className='horizontal mrgR100 wMenu spaceBtw txt18'>
                            <NavLink to = "/p/rdv/list" activeClassName="active" className='aReset bT medium'>
                                <p className='mrgT5 mrgB10 mediumplus'>RDV</p>
                            </NavLink>
                            <NavLink to = "/p/quo/list" activeClassName="active" className='aReset bT medium'>
                                <p className='mrgT5 mrgB10 mediumplus'>Devis</p>
                            </NavLink>
                            <NavLink to = "/p/matchs/list" activeClassName="active" className='aReset bT medium'>
                                <p className='mrgT5 mrgB10 mediumplus'>Matchs</p>
                            </NavLink>
                            <NavLink to = "/p/offers/list" activeClassName="active" className='aReset bT medium'>
                                <p className='mrgT5 mrgB10 mediumplus'>Offres</p>
                            </NavLink>
                            <NavLink to = "/p/profile/menu" activeClassName="active" className='aReset bT medium'>
                                <p id="navProfilPresta" className='mrgT5 mrgB10 mediumplus'>Profil</p>
                            </NavLink>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default AppNavbarPresta;

