import { React, useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../../../contexts/authContext';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { useDisclosure } from '@mantine/hooks';
import { Modal, Group, Button } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import SyndicRectangleButtonSnd from '../../../../composents/Buttons/Syndic/RectangleButton/RectangleButtonSecond';
import SyndicRectangleButton from '../../../../composents/Buttons/Syndic/RectangleButton/RectangleButtonMain';
import OfferCard from '../../../Offer/OfferCard';
import DeleteButton from '../../../../composents/Buttons/All/DeleteButton/DeleteButton';

const PublishedOffer = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [opened, { close, open }] = useDisclosure(false);

    let { id } = useParams()

    const navigate = useNavigate();

    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/s/login");
            return;
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const HandleOpenDeleteModal = () => {
        open()
    }

    const HandleDeleteOffer = async () => {
        try {
            const token = localStorage.getItem('token');
            await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/offers/delete/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ id }),
            });
            navigate('/s/offers/list')
        } catch (error) {
        }
    }

    return (
        <>
            {isMobile ? (
                <>
                    <OfferCard />
                    <div className="width90pCenter">
                        <Link to={`/s/offers/offer-edit/${id}`}>
                            <SyndicRectangleButtonSnd>
                                Modifier
                            </SyndicRectangleButtonSnd>
                        </Link>

                        <div className="mrgB10"></div>

                        <Link to={`/s/offers/offer-rdv&quo/${id}`}>
                            <SyndicRectangleButton>
                                RDV et Devis
                            </SyndicRectangleButton>
                        </Link>

                        <div className="mrgB20"></div>

                        <DeleteButton
                            onClick={HandleOpenDeleteModal}>
                            Supprimer l'offre
                        </DeleteButton>
                        {open ?
                            <Modal
                                centered
                                withCloseButton={false}
                                opened={opened}
                                onClose={close}
                                size="auto"
                                className='useRoboto'>
                                <Group noWrap mt="md" className="horizontal jCenter mrgT5">
                                    <p className='noMrgTB txt15'>Supprimer l'offre ?</p>
                                </Group>

                                <Group mt="xl" className="txtCenter horizontal jCenter mrgT20">
                                    <Button  variant="outline" color="dark" onClick={close} className='useRoboto'>
                                        Annuler
                                    </Button>
                                    <Button variant="filled" color="dark" onClick={HandleDeleteOffer} className='useRoboto medium'>
                                        Supprimer
                                    </Button>
                                </Group>
                            </Modal>
                            :
                            ""
                        }
                        <div className="mrgB20"></div>

                    </div>
                </>
            ) : (
                <>
                    <div className="menuDesktop width50pCenter horizontal">
                        <Link to={`/s/offers/offer-edit/${id}`}>
                            <IconEdit width={20} className='resetBtn bT mrgR10'/>
                        </Link>

                        <IconTrash width={20} className='resetBtn bT ' onClick={HandleOpenDeleteModal}/>
                        {open ?
                            <Modal
                                centered
                                withCloseButton={false}
                                opened={opened}
                                onClose={close}
                                size="auto"
                                className='useRoboto'>
                                <Group noWrap mt="md" className="horizontal jCenter mrgT5">
                                    <p className='noMrgTB txt15'>Supprimer l'offre ?</p>
                                </Group>

                                <Group mt="xl" className="txtCenter horizontal jCenter mrgT20">
                                    <Button  variant="outline" color="dark" onClick={close} className='useRoboto'>
                                        <div className='txt15'>Annuler</div>
                                    </Button>
                                    <Button variant="filled" color="dark" onClick={HandleDeleteOffer} className='useRoboto medium'>
                                        <div className='txt15'>Supprimer</div>
                                    </Button>
                                </Group>
                            </Modal>
                            :
                            ""
                        }
                    </div>

                    <OfferCard />
                    
                    <div className="width50pCenter">
                        <Link to={`/s/offers/offer-rdv&quo/${id}`}>
                            <SyndicRectangleButton>
                                <p className='txt16'>RDV et Devis</p>
                            </SyndicRectangleButton>
                        </Link>
                    </div>
                </>
            )}
        </>
    )

}

export default PublishedOffer;