
import { React, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import { IconChevronLeft, IconEye, IconStar } from '@tabler/icons-react';

import classes from './RdvAndQuotationHd.module.css';
const RdvAndQuotationHd = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    let {id} = useParams()

    useEffect(() => {       
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
      
          window.addEventListener('resize', handleResize);
      
          return () => {
            window.removeEventListener('resize', handleResize);
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);

    return(
        <>
            {isMobile ? (
                <div className={classes.appHeading}>
                    <div className={classes.navback}>   
                    <IconChevronLeft width={18}/>                
                        <button className={classes.btnResetNavback}>
                            <Link to={`/s/offers/offer/${id}`} className='aReset bT'>
                                Retour
                            </Link>
                        </button>
                    </div>
                    <div className={classes.navtitle}>
                        <h1>{props.children}</h1>
                    </div>
                    <div className={classes.navrightside}>
                        <button className={classes.btnReset}>
                            <Link to={`/s/offers/favorite-p-match/${id}`} className="aReset bT">
                                <IconStar width={30}/>
                            </Link>
                        </button>
                    </div>
                </div>
            ) : (
                <>
                    <div className={classes.appHeading}>
                        <div className={classes.navback}>   
                            <div><IconChevronLeft width={20}/></div>               
                            <div>
                                <Link to={`/s/offers/list`} className='aReset bT noMrgTB'>
                                    <button className={classes.btnResetNavback}>
                                        Retour liste
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className={classes.navtitle}>
                            <h1>{props.children}</h1>
                        </div>
                        <div className={classes.navrightside}>
                            <div className='horizontal iCenter'>
                                <Link to={`/s/offers/offer/${id}`} className='aReset bT noMrgTB'>
                                    <IconEye width={25} height={25} className='mrgR10'/>
                                </Link>
                                <Link to={`/s/offers/favorite-p-match/${id}`} className="aReset bT">
                                    <IconStar width={25} height={25}/>
                                </Link>                                
                            </div>                            
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default RdvAndQuotationHd;