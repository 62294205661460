import { React, useEffect, useMemo, useState, useContext } from 'react';
import { AuthContext } from '../../contexts/authContext';
import { useParams, useNavigate } from "react-router-dom";

import { Carousel } from '@mantine/carousel';
import { IconHammer, IconLocation, IconBuilding, IconCalendarEvent, IconCalendarDue, IconPhoto, IconFileCheck, IconChevronRight } from '@tabler/icons-react';
import { Badge, Loader } from '@mantine/core';
import { format, parseISO } from 'date-fns';

import ClosePageOverPictureButton from '../../composents/Buttons/All/ClosePageOverPictureButton/ClosePageOverPictureButton';
import ClosePageOverPictureButtonwithModal from '../../composents/Buttons/All/ClosePageOverPictureButtonwithModal/ClosePageOverPictureButtonwithModal';
import ClosePageOverPictureButtonModalSpeOffer from '../../composents/Buttons/All/ClosePageOverPictureButtonwithModalForSpeOffer/ClosePageOverPictureButtonwithModalForSpeOffer';
import Box from '../../composents/Boxes/Standard/Box';
import AppDesktopLogo from '../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

const OfferCard = (props) => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [offer, setOffer] = useState(null);
    const isEditOffer = props.isEditOffer
    const isEditSpeOffer = props.isEditSpeOffer

    let { id } = useParams()

    const navigate = useNavigate();

    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/s/login");
            return;
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const getSingleOffer = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/offers/offer/${id}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const offer = await response.json();
            const rangeDate = offer.workRangeDates
            offer.startDate = format(parseISO(rangeDate[0]), 'dd/MM/yyyy')
            offer.endDate = format(parseISO(rangeDate[1]), 'dd/MM/yyyy')
            const dDate = offer.deadlineToReceiveQuotationDate
            offer.deadline = format(parseISO(dDate), 'dd/MM/yyyy')
            setOffer(offer);
            
        } catch (error) {
        }
    }

    useMemo( async () => {
        await getSingleOffer()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const today = new Date().toISOString()

    const openLinkToDocs = (event) => {
        window.open(offer.linkToDocs, '_blank');
    }

    return (
        offer  ?
            <>
                {isMobile ? (
                    <>
                        <div>
                            <Carousel
                                height={350}
                                slideSize="33.333333%"
                                slideGap="md"
                                breakpoints={[
                                    { maxWidth: 'md', slideSize: '50%' },
                                    { maxWidth: 'sm', slideSize: '100%', slideGap: 0 },
                                ]}
                                loop
                                align="start">

                                {offer.pictures.length > 0
                                    ?   <>
                                            {offer.pictures.map((url) => (
                                                <Carousel.Slide key={url}>
                                                    <img className="imgCarroussel"
                                                        src={url}
                                                        alt='photo_chantier'/>
                                                </Carousel.Slide>
                                            ))}
                                        </>
                                    :   <>
                                            {offer.author.company.logo
                                                ?   <Carousel.Slide className='horizontal iCenter jCenter'>
                                                        <img    
                                                            className="bLiteGrey carrouselLogoImg"
                                                            src={offer.author.company.logo}
                                                            alt='logo'/>
                                                    </Carousel.Slide> 
                                                    :   <div className='horizontal iCenter jCenter w100 dGreyT'><IconPhoto width={48} height={48}/></div>
                                            }
                                        </>     
                                }

                            
                            </Carousel>

                            {offer.author.company.logo
                                ?   <div id="syndic-logo-OffreDeTravaux" >
                                        <img className='img68x68' src={offer.author.company.logo} alt='logo'/>
                                    </div>  
                                : <div className="noLogoOnPic">{offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                            }
                            
                            {isEditOffer
                                ?   <ClosePageOverPictureButtonwithModal />
                                :   <>
                                        {isEditSpeOffer
                                            ?   <ClosePageOverPictureButtonModalSpeOffer />
                                            :   <ClosePageOverPictureButton/>
                                            
                                        }
                                    </> 
                            }
                            
         
                        </div>

                        <div className="offer-first-lines-info">
                        {offer.deadlineToReceiveQuotationDate < today

                        ?   <div>
                                <p className='txt15 rT mediumplus txtCenter'>Votre délai de réception de devis est arrivé à échéance. Mettez à jour votre offre.</p>
                            </div>
                            :   ""
                        }
                            <div className="horizontal iCenter">
                                <p className="txt13 noMrgTB mrgR5 ">{format(parseISO(offer.createdAt), 'dd/MM/yy')}</p>
                                <div>
                                    {offer.urgent
                                        ? <Badge color="red">Urgent</Badge>
                                        : ""
                                    }
                                </div>

                            </div>
                            <div>
                                <h1 className="txt20 lineH20 cap">{offer.title}</h1>
                            </div>
                            <div>
                                <p className="txt15 lineH18 noMrgB">{offer.description}</p>
                            </div>
                            <div>
                                {offer.linkToDocs
                                        ? <div onClick={openLinkToDocs} className='horizontal iCenter'>
                                            <p className="underline underlineOffset3 mrgT5 mrgB7 mrgR2">Voir documents</p> <IconChevronRight size={15} className='mrgT8 mrgB7'/>
                                          </div>
                                        : ""
                                    }
                            </div>
                        </div>

                        <div id="positionUp">
                            <div className="redbox">
                                <div className="vertical wraped">
                                    <div className="horizontal iCenter mrgB5 mrgR20">
                                        <IconHammer width={13} className="mrgL10 mrgR10" />
                                        <span className="bold">{offer.occupation}</span></div>
                                    <div className="horizontal spaceBtw wraped">
                                        <div className="horizontal iCenter mrgB5 mrgR20">
                                            <IconLocation width={13} className="mrgL10 mrgR10" />
                                            <span className="bold ">{offer.cityOfIntervention}</span></div>
                                        {offer.nbOfLots
                                            ? <div className="horizontal iCenter mrgB5">
                                                <IconBuilding width={13} className="mrgL10 mrgR10" />
                                                <span className="bold mrgR20">{offer.nbOfLots} lots</span>
                                            </div>
                                            : ""
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="mrgB10"></div>

                            <Box>
                                <div className="mrgB10"></div>
                                <div>
                                    <div className="horizontal iCenter"><IconCalendarEvent width={18} className="mrgL10 mrgR10" />
                                        <span className="txt15">Du {offer.startDate} au {offer.endDate}</span></div>
                                    <div className="txt13 mrgL40 dGreyT">Dates prévisionnelles de travaux</div>
                                </div>

                                <div className="mrgB20"></div>

                                <div>
                                    <div className="horizontal iCenter"><IconCalendarDue width={18} className="mrgL10 mrgR10" />
                                        <span className="txt15">{offer.deadline}</span></div>
                                    <div className="txt13 mrgL40 dGreyT">Date limite de réception des devis</div>
                                </div>

                                <div className="mrgB20"></div>

                                <div>
                                    {offer.quotationReceptionAllowed
                                        ? <div className="horizontal iCenter">
                                            <IconFileCheck width={18} className="mrgL10 mrgR10" />
                                            <span className="txt15">Envoi de devis sans RDV de chiffrage possible</span>
                                        </div>
                                        : ""
                                    }
                                </div>

                                {/* <div className="mrgB5"></div> */}
                            </Box>

                        </div>
                    </>
                ) : (
                    <>
                        <AppDesktopLogo/>
                        <div className='mrgT20 width50pCenter'>
                            {isEditOffer
                                    ?   <ClosePageOverPictureButtonwithModal />
                                    :   <>
                                            {isEditSpeOffer
                                                ?   <ClosePageOverPictureButtonModalSpeOffer />
                                                :   <ClosePageOverPictureButton />
                                            }
                                        </> 
                                }
                        </div>
                        <div className='width50pCenter horizontal'>
                            <div className='w400'>
                                <div className='mrgB10'></div>
                                <Carousel
                                    style={{
                                        border:'1px solid #ebebeb',
                                    }}
                                    height={390}
                                    slideSize="98%"
                                    slideGap="sm"
                                    breakpoints={[
                                        { maxWidth: 'md', slideSize: '70%' },
                                        { maxWidth: 'sm', slideSize: '100%', slideGap: 0 },
                                    ]}
                                    loop
                                    align="start">

                                    {offer.pictures.length > 0
                                        ?   <>
                                                {offer.pictures.map((url) => (
                                                    <Carousel.Slide key={url}>
                                                        <img className="imgCarroussel"
                                                            src={url}
                                                            alt='photo_chantier'/>
                                                    </Carousel.Slide>
                                                ))}
                                            </>
                                        :   <>
                                                {offer.author.company.logo
                                                    ?   <Carousel.Slide className='horizontal iCenter jCenter'>
                                                            <img    
                                                                className="bLiteGrey carrouselLogoImg"
                                                                src={offer.author.company.logo}
                                                                alt='logo'/>
                                                        </Carousel.Slide> 
                                                        :   <div className='horizontal iCenter jCenter w100 dGreyT'><IconPhoto width={48} height={48}/></div>
                                                }
                                            </>     
                                    }

                                
                                </Carousel>

                                {offer.author.company.logo
                                    ?   <div id="syndic-logo-OffreDeTravaux" >
                                            <img className='img68x68' src={offer.author.company.logo} alt='logo'/>
                                        </div>  
                                    : <div className="noLogoOnPic">{offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                }
                            </div>
                            
                            <div className='vertical width50pCenter mrgL20 mrgT10'>
                                <div className="offer-first-lines-info">
                                    {offer.deadlineToReceiveQuotationDate < today
                                        ?   <div>
                                                <p className='txt18 rT mediumplus txtCenter'>Votre délai de réception de devis est arrivé à échéance. Mettez à jour votre offre.</p>
                                            </div>
                                            :   ""
                                        }
                                    <div className="horizontal iStart mrgT20">
                                        <div>
                                            <p className="txt16 noMrgTB mrgR10 ">{format(parseISO(offer.createdAt), 'dd/MM/yy')}</p>
                                        </div>
                                        <div>
                                            {offer.urgent
                                                ? <Badge size="lg" color="red">Urgent</Badge>
                                                : ""
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <h1 className="txt20 cap">{offer.title}</h1>
                                    </div>
                                    <div>
                                        <p className="txt16 lineH18 noMrgB">{offer.description}</p>
                                    </div>

                                    <div>
                                        {offer.linkToDocs
                                                ? <div onClick={openLinkToDocs} className='horizontal iEnd'>
                                                    <p className="underline underlineOffset3 mrgT10 mrgB5 mrgR2">Voir documents</p> <IconChevronRight size={17} className='mrgT8 mrgB7'/>
                                                </div>
                                                : ""
                                            }
                                    </div>
                                

                                    <div>
                                        <div className="redbox">
                                            <div className="vertical">
                                                <div className="horizontal iCenter mrgB5 mrgR20">
                                                    <IconHammer width={18} className="mrgL10 mrgR10" />
                                                    <span className="medium txt16">{offer.occupation}</span>
                                                </div>
                                                <div className="horizontal iCenter mrgB5 mrgR20">
                                                    <IconLocation width={18} className="mrgL10 mrgR10" />
                                                    <span className="medium txt16">{offer.cityOfIntervention}</span>
                                                </div>
                                                    {offer.nbOfLots
                                                        ? <div className="horizontal iCenter mrgB5">
                                                            <IconBuilding width={18} className="mrgL10 mrgR10" />
                                                            <span className="medium txt16 mrgR20">{offer.nbOfLots} lots</span>
                                                        </div>
                                                        : ""
                                                    }
                                            </div>
                                        </div>

                                        <div className="mrgB20"></div>
                                        
                                        <div className='vertical mrgL10'>
                                            
                                            <div className="horizontal iStart">
                                                <IconCalendarEvent width={18} className="mrgL5 mrgR10"/>
                                                <div className='vertical'>
                                                    <span className="txt16">Du {offer.startDate} au {offer.endDate}</span>
                                                    <div className="txt15 dGreyT">Dates prévisionnelles de travaux</div>    
                                                </div>
                                            </div>

                                            <div className="mrgB10"></div>

                                            <div>
                                                <div className="horizontal iStart">
                                                    <IconCalendarDue width={18} className="mrgL5 mrgR10"/>
                                                    <div className='vertical'>
                                                        <span className="txt16">{offer.deadline}</span>
                                                        <div className="txt15 dGreyT">Date limite de réception des devis</div>    
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mrgB10"></div>

                                            <div>
                                                {offer.quotationReceptionAllowed 
                                                    ?   <div className="horizontal iStart">
                                                            <IconFileCheck width={18} className="mrgL5 mrgR10"/>
                                                            <span className="txt16">Envoi de devis sans RDV de chiffrage possible</span>
                                                        </div>
                                                    :   ""
                                                }  
                                            </div>
                                            <div className='mrgB30'></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
            :
            <Loader />
    )

}

export default OfferCard;