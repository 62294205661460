import { React, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../../../contexts/authContext';
import { useParams, useNavigate } from 'react-router-dom';

import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import OfferCard from '../../../../Offer/OfferCard';
import SyndicRectangleButton from '../../../../../composents/Buttons/Syndic/RectangleButton/RectangleButtonMain'
import SyndicRectangleButtonSnd from '../../../../../composents/Buttons/Syndic/RectangleButton/RectangleButtonSecond';
import AppDesktopLogo from '../../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

const NewOfferRecap = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [opened, { open, close }] = useDisclosure(false);

    let {id} = useParams()

    const navigate = useNavigate();

    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/s/login");
            return;
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    const HandleOfferPublication  = async () => {
        open()

        try {
            const token = localStorage.getItem('token');
            await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/offers/add-offer/${id}`, {
                method: "POST",
                body: JSON.stringify({
                    id: id,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            setTimeout(() => {
                navigate('/s/offers/list');
            }, 1000)

        } catch (error) {
        }
    }

    const HandleModification = () => {
        navigate(`/s/offers/preoffer-edit/${id}`)

    }
        return(
            <>
                {isMobile ? (
                    <>
                        <div>
                            <OfferCard isEditOffer={true} isEditSpeOffer={false} />
                            

                            <div className="width90pCenter">
                                <SyndicRectangleButtonSnd onClick={HandleModification}>
                                    Retour
                                </SyndicRectangleButtonSnd>

                                <div className="mrgB10"></div>

                                <SyndicRectangleButton
                                    onClick={HandleOfferPublication}
                                    >
                                    Publier mon offre
                                </SyndicRectangleButton>
                                {open ?
                                    <Modal 
                                        centered
                                        size="auto"
                                        opened={opened}
                                        onClose={close}
                                        withCloseButton={false}>
                                        <p className="txtCenter noMrgTB useRoboto txt15">
                                            Votre offre a été publiée
                                        </p>
                                    </Modal> 
                                : 
                                    ""
                                }                    
                                <div className="mrgB20"></div>    
                            </div>

                        </div>
                    </>
                 ) : (
                    <>
                        <div>
                            <AppDesktopLogo/>
                            <OfferCard isEditOffer={true} isEditSpeOffer={false}/>
                            <div className="width50pCenter">
                                <SyndicRectangleButtonSnd onClick={HandleModification}>
                                    <p className='txt16 noMrgTB'>Retour</p>
                                </SyndicRectangleButtonSnd>

                                <div className="mrgB10"></div>

                                <SyndicRectangleButton
                                    onClick={HandleOfferPublication}>
                                    <p className='txt16 noMrgTB'>Publier mon offre</p>
                                </SyndicRectangleButton>
                                {open ?
                                    <Modal 
                                        centered
                                        size="auto"
                                        opened={opened}
                                        onClose={close}
                                        withCloseButton={false}>
                                        <p className="txtCenter noMrgTB useRoboto txt16">
                                            Votre offre a été publiée
                                        </p>
                                    </Modal> 
                                : 
                                    ""
                                }                    
                                <div className="mrgB20"></div>    
                            </div>

                        </div>
                    
                    </>
                 )}
            </>
        )
    
}

export default NewOfferRecap;