import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from '@mantine/form';
import { TextInput, Textarea, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import SyndicRectangleButton from '../../composents/Buttons/Syndic/RectangleButton/RectangleButtonMain';
import NavbarPublic from '../Navbar/NavbarPublic';
import Footer from '../Footer/Footer';
import LogoLogin from '../../composents/Logo/LoginLogo/LogoLogin';

const Contact = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const navigate = useNavigate(null);
    const [opened, { open, close }] = useDisclosure(false);


    useEffect(()=>{
        window.scrollTo(0,0);
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
      
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
      },[])

    const form = useForm({
        initialValues: {
            email: '',
            object:"",
            message:"",
        },

        validate: {
            email : (value) => (
                value.length < 1
                ? 'Renseignez votre E-mail'
                : /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) 
                ? null 
                : 'E-mail non valide'
            ),
            object : (value) => (
                value.length < 1 
                ? 'Renseignez l\'objet de votre message'
                : value.length > 50 
                ? '50 caractères maximum.'
                : null
            ),
            message : (value) => (
                value.length < 10 
                ? 'Saisissez votre message'
                : value.length > 500 
                ? 'Entre 10 et 500 caractères maximum.' 
                : null
            )
        }
    })

    const handleFormSubmit = async (values, _event) => {
        _event.preventDefault();
    
        try {
            await fetch("https://boldee-97d8d36af3bd.herokuapp.com/contact", {
                method: "POST",
                body: JSON.stringify({
                    email: values.email,
                    object: values.object,
                    message: values.message
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            });
            open();
            setTimeout(() => {
                navigate('/');
            }, 500)
            } catch (error) {
            }
        }
    return(
        <>
            <NavbarPublic/>
            {isMobile ? (
                <>
                    <form onSubmit={form.onSubmit(handleFormSubmit)}>
                        <div className="width90pCenter mrgT150 txtCenter">
                            <div><LogoLogin /></div>
                            <p id="signUpTitle">Contactez-nous</p>
                        </div>
                        <div className='width90pCenter'>
                                <p id="signUpSubtitle" className='mrgB5'>
                                    Découvrir tous les avantages de <span className='mediumplus'>Boldee Syndics</span> et de <span className='mediumplus'>Boldee Artisans, Prestataires</span>
                                </p>
                                <p id="signUpSubtitle" className='noMrgT mrgB20'>
                                    Demande de rdv et d'informations complémentaires
                                </p>
                        </div>

                        <div className="width90pCenter">
                        <TextInput
                            withAsterisk
                            label="Email"
                            placeholder="votre@email.com"
                            {...form.getInputProps('email')}
                        />

                        <TextInput
                            label="Objet"
                            placeholder="Objet"
                            withAsterisk
                            {...form.getInputProps('object')}
                        />

                        <div className="mrgB5"></div>

                        <Textarea 
                            label="Message"
                            placeholder="Votre message"
                            withAsterisk
                            {...form.getInputProps('message')}
                        />

                        <div className="mrgB5"></div>

                        </div>

                        <div className="mrgB20"></div>

                        <div className="width90pCenter">
                            <SyndicRectangleButton type="submit">
                                Envoyer
                            </SyndicRectangleButton>
                            {open  
                                ?   <Modal 
                                        centered
                                        size="50%"
                                        opened={opened}
                                        onClose={close}
                                        withCloseButton={false}>
                                        <p className="useRoboto txt15 txtCenter noMrgTB">
                                            Votre message a été envoyé
                                        </p>
                                    </Modal> 
                                :   ""
                            }
                        </div>
                    </form>
                </>
            ) : (
                <>
                    <form onSubmit={form.onSubmit(handleFormSubmit)}>
                        <div className='width50pCenter'>
                            <div className="width90pCenter mrgT200 txtCenter">
                                <p id="signUpTitle">Contactez-nous</p>
                            </div>
                            <div className='width50pCenter'>
                                <p id="signUpSubtitle" className='mrgB5'>
                                    Découvrir tous les avantages de <span className='mediumplus'>Boldee Syndics</span> et de <span className='mediumplus'>Boldee Artisans, Prestataires</span>
                                </p>
                                <p id="signUpSubtitle" className='noMrgTB'>
                                    Demande de rdv et d'informations complémentaires
                                </p>
                            </div>
                        </div>

                        <div className="width30pCenter mrgT50">
                        <TextInput
                            withAsterisk
                            label="Email"
                            placeholder="votre@email.com"
                            size='lg'
                            {...form.getInputProps('email')}
                        />

                            <TextInput
                                label="Objet"
                                placeholder="Objet"
                                withAsterisk
                                size='lg'
                                {...form.getInputProps('object')}
                            />

                            <div className="mrgB5"></div>

                            <Textarea 
                                label="Message"
                                placeholder="Votre message"
                                withAsterisk
                                size='lg'
                                {...form.getInputProps('message')}
                            />

                            <div className="mrgB5"></div>

                        </div>

                        <div className="mrgB20"></div>

                        <div className="width30pCenter">
                            <SyndicRectangleButton type="submit">
                                Envoyer
                            </SyndicRectangleButton>
                            {open  
                                ?   <Modal 
                                        centered
                                        size="auto"
                                        opened={opened}
                                        onClose={close}
                                        withCloseButton={false}>
                                        <p className="useRoboto txt20 txtCenter noMrgTB">
                                            Votre message a été envoyé
                                        </p>
                                    </Modal> 
                                :   ""
                            }
                        </div>
                    </form>

                </>
            )}
            <Footer/>
        </>
    )
};

export default Contact;