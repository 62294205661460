import { useNavigate } from "react-router-dom";
import classes from './AppHdWithCloseRight.module.css'
import { IconX } from '@tabler/icons-react';

const AppHdWithCloseRight = (props) => {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1)
    }

    return(
        <div className={classes.appHeading}>
            <div className={classes.navbackBlank}></div>
            <div className={classes.navtitle}>
                <h2>{props.children}</h2>
            </div>
    
            <div >
                <button className={classes.btn} 
                    onClick={goBack}>
                    <IconX />
               </button>
            </div>
        </div>

    )
}

export default AppHdWithCloseRight;