import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IconChevronLeft } from '@tabler/icons-react';

import classes from './InfoRDVSyndicHd.module.css';

// utilisation de props pour le cas de l'utilisation ou non d'un modal pour le bouton retour

const InfoRdvSyndicHd = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const id = props.id

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 768);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
              // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []); 

    return(    
        //retour sans action modal
        <>
        {isMobile ? (
            <div className={classes.appHeading}>
                
                <div className={classes.navback}>   
                    <IconChevronLeft width={18}/>      
                    <button className={classes.btnResetNavback}>
                        <Link to={`/s/offers/offer-rdv&quo/${id}`}>
                            Retour
                        </Link>
                    </button>
                </div>
                <div className={classes.navtitle}>
                    <h1>{props.children}</h1>
                </div>
                <div className={classes.navrightsideBlank}>
                </div>
            </div>
        ) : (
            <div className={classes.appHeading}>
                
                <div className={classes.navback}>   
                    <IconChevronLeft width={30}/>      
                    <button className={classes.btnResetNavback}>
                        <Link to={`/s/offers/offer-rdv&quo/${id}`}>
                            Retour
                        </Link>
                    </button>
                </div>
                <div className={classes.navtitle}>
                    <h1>{props.children}</h1>
                </div>
                <div className={classes.navrightsideBlank}>
                </div>
            </div>
        )}
        </>
        
)}

export default InfoRdvSyndicHd;