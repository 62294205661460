import classes from './RectangleButtonDisabled.module.css';
const RectangleButtonDisabled = (props) => {
    return(
        <button
            className={classes.sRoundButton}
            type={props.type || "button"}
            onClick={props.onClick}
            >{props.children}
        </button>
    )
}

export default RectangleButtonDisabled;