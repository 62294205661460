import { React, useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../../../../../contexts/authContext';
import { useParams, Link, useNavigate } from "react-router-dom";

import { useDisclosure } from '@mantine/hooks';
import { Badge, Loader, Modal } from '@mantine/core';
import { IconHammer, IconLocation, IconCalendarEvent, IconClock, IconHourglassEmpty, IconMapPin, IconInfoCircle, IconPhone } from '@tabler/icons-react';
import { parseISO, format } from 'date-fns';
import { fr } from 'date-fns/locale';

import InfoRdvSyndicHd from '../../../../../../composents/Headers/Customized/InfoRDVSyndicHd/InfoRDVSyndicHd';
import Box from '../../../../../../composents/Boxes/Standard/Box';
import SyndicRectangleButton from '../../../../../../composents/Buttons/Syndic/RectangleButton/RectangleButtonMain';
import SyndicRectangleButtonSnd from '../../../../../../composents/Buttons/Syndic/RectangleButton/RectangleButtonSecond';
import AppDesktopLogo from '../../../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

const RdvDetails = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [rdv, setRdv] = useState(null);
    const [openedContactInfo, { open : openContactInfo, close : closeContactInfo}] = useDisclosure(false);
    
    localStorage.setItem('page', 'rdv');

    let {id} = useParams()

    const navigate = useNavigate();

    useEffect(() => {
        getSingleRdv();
        const token = getToken();
        if (!token) {
            navigate("/s/login");
            return;
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
      
          window.addEventListener('resize', handleResize);
      
          return () => {
            window.removeEventListener('resize', handleResize);
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);
    
    const getSingleRdv = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/offers/offer/details-rdv/${id}`, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`
                }
              });
            const rdv = await response.json();
            setRdv(rdv);
            const rangeDate = rdv.offer.workRangeDates
            rdv.offer.startDate = format(parseISO(rangeDate[0]), 'dd/MM/yyyy')
            rdv.offer.endDate = format(parseISO(rangeDate[1]), 'dd/MM/yyyy')
        } catch (error) { 
                console.error(error);
        }
    };
    
    const handleCall = (event) => {
        const phoneNumber = event.currentTarget.id
        window.location.href = `tel:${phoneNumber}`
    };

    const showContact = () => {
        openContactInfo()
    };

    return(
        rdv  ?
            <>
                {isMobile ? (
                    <>
                        <InfoRdvSyndicHd id={rdv.offer._id}>Informations du RDV</InfoRdvSyndicHd>
                            <Box>
                                <div className='horizontal iStart spaceBtw'>
                                    <div className="vertical spaceBtw mrgL10">
                                        <div className="horizontal iCenter mrgL10">
                                            <IconCalendarEvent  width={15}/>
                                            <p className="txt16 bold mrgL5 mrgT5 mrgB5 cap">{format(parseISO(rdv.dateOfAppointment), 'eeee dd MMMM', {locale: fr})}</p>
                                        </div>
                                        <div className="horizontal iCenter mrgL10">
                                            <IconHourglassEmpty width={15} className="mrgR5"/>
                                            <p className="txt15 bold mrgR10 noMrgTB">{rdv.duration}</p>
                                        </div>
                                    </div>
                                    <div className="horizontal iCenter mrgR10">
                                        <IconClock width={15} className="mrgR5"/>
                                        <p className="txt15 bold mrgR10 mrgT5 mrgB5">{format(parseISO(rdv.timeOfAppointment), 'HH:mm')}</p>
                                    </div>
                                    </div>
                            </Box>

                            <div className="mrgB10"></div>

                            <Link to={`/s/offers/offer/company-page/${rdv.applicant.company._id}`} className="aReset bT">                        
                                <Box>
                                    <div className="horizontal iCenter mrgL20">
                                        {rdv.applicant.avatar
                                            ?   <div>
                                                    <img 
                                                        className="roundImg70"
                                                        src={rdv.applicant.avatar}
                                                        alt='avatar'/>
                                                </div>
                                            :   <div className="vertical mrgR10">
                                                    <div className="noAvatarProfile">{rdv.applicant.firstname.charAt(0)}{rdv.applicant.lastname.charAt(0)}</div>
                                                </div>
                                        }
                                        
                                            <div className="vertical">
                                                <h1 className="txt15 medium noMrgTB mrgL10">{rdv.applicant.title} {rdv.applicant.firstname} {rdv.applicant.lastname}</h1>
                                                <h1 className="txt13 noMrgTB mrgL10 medium capitals">{rdv.applicant.company.name}</h1>
                                                <p className="txt13 noMrgTB mrgL10">{rdv.applicant.occupation.join(' - ')}</p>  
                                            </div>
                                        
                                    </div>
                                </Box>
                            </Link>

                            <div className="mrgB10"></div>

                            <Link to={`/s/offers/offer-info/${rdv.offer._id}`} className="aReset bT">
                                <Box>
                                    <div className="horizontal iCenter mrgL20">
                                        <div className="vertical mrgR10">
                                        {rdv.offer.pictures.length > 0 
                                            ?   <div>
                                                    <img 
                                                        className="roundImg70 noMrgTB"
                                                        src={rdv.offer.pictures[0]}
                                                        alt='photo_chantier'/>
                                                </div>
                                            :   <>
                                                    {rdv.offer.author.company.logo
                                                        ?   <img    
                                                                className="roundImg70 noMrgTB"
                                                                src={rdv.offer.author.company.logo}
                                                                alt='logo'/>
                                                        :   <div className="noAvatarProfile">{rdv.offer.author.company.name.charAt(0)}</div>
                                                    }
                                                </>     
                                        }
                                        </div>
                                        <div className="vertical jCenter mrgL10">
                                        <div className="horizontal spaceBtw mrgB5">
                                            {rdv.offer.urgent 
                                                ?   <Badge color="red">Urgent</Badge>
                                                :   ""
                                            }
                                        </div>

                                        <div>
                                            <p className="txt15 medium noMrgTB">{rdv.offer.title}</p>
                                        </div>
                                        <div className="horizontal iCenter">
                                            <IconHammer width={13} height={13} className="noMrgTB mrgR10"/>
                                            <p className="noMrgTB txt13">{rdv.offer.occupation}</p>
                                        </div>
                                        <div className="horizontal iCenter">
                                            <IconLocation  width={13} height={13} className="noMrgTB mrgR10"/>
                                            <p className="noMrgTB txt13">{rdv.offer.cityOfIntervention}</p>
                                        </div>          
                                    </div>
                                </div>
                            </Box>
                            </Link>
                            
                            <div className="mrgB20"></div>
                                    <div className="width95pCenter">
                                        <p className="txt15 mrgB5 mrgL20 mrgR20 bold">ADRESSE DU RDV</p>
                                    </div>
                                    <Box>
                                    <div className="mrgL10">
                                            <div className="horizontal iCenter mrgL10">
                                                <IconMapPin width={15}/>
                                                <p className="txt15 mrgL10 noMrgTB cap">{rdv.address}</p>
                                            </div>
                                            
                                        {rdv.instructions.length > 0
                                                ?   <> 
                                                        <hr className='width95pCenter mrgL10'></hr>
                                                        <div className="horizontal iStart mrgL10">
                                                            <IconInfoCircle width={15}/>
                                                            <p className="txt15 mrgL10 noMrgTB mrgR5 cap">{rdv.instructions}</p>
                                                        </div>
                                                    </>
                                                :   ""
                                            }  
                                    </div>
                                </Box>

                                <div className="mrgB30"></div>

                                <div className="width90pCenter">
                                    <Link to={`/s/offers/edit-rdv/${rdv._id}`}>
                                        <SyndicRectangleButtonSnd className ="btnReset wT bBlack" >
                                            Modifier                                                                           
                                        </SyndicRectangleButtonSnd>
                                    </Link>
                                    <div className="mrgB10"></div>
                                    <SyndicRectangleButton className ="btnReset wT bBlack" id={rdv.applicant.phone} onClick={handleCall}>
                                        Contacter                                                                           
                                    </SyndicRectangleButton>
                                </div>

                                <div className="mrgB30"></div> 
                    </>
                ) : (
                    <>
                        <AppDesktopLogo />
                        <div className='width50pCenter'>
                            <InfoRdvSyndicHd id={rdv.offer._id}>
                                Informations du RDV
                            </InfoRdvSyndicHd>
                            <div className='mrgT20'></div>
                            <div>
                                <p className="txt18 mrgB10 medium mrgL20 dGreyT">RDV</p>
                            </div>
                            <Box>
                                <div className='horizontal iStart spaceBtw mrgT10 mrgB10'>
                                    <div className="vertical spaceBtw mrgL10">
                                        <div className="horizontal iCenter mrgL10 mrgB5">
                                            <IconCalendarEvent  width={20}/>
                                            <p className="txt18 bold mrgL5 mrgT5 mrgB5 cap">{format(parseISO(rdv.dateOfAppointment), 'eeee dd MMMM', {locale: fr})}</p>
                                        </div>
                                        <div className="horizontal iCenter mrgL10">
                                            <IconHourglassEmpty width={20} className="mrgR5"/>
                                            <p className="txt18 bold mrgR10 noMrgTB">{rdv.duration}</p>
                                        </div>
                                    </div>
                                    <div className="horizontal iCenter mrgR10">
                                        <IconClock width={20} className="mrgR5"/>
                                        <p className="txt18 bold mrgR10 mrgT5 mrgB5">{format(parseISO(rdv.timeOfAppointment), 'HH:mm')}</p>
                                    </div>
                                </div>
                            </Box>

                            <div className="mrgB20"></div>

                            <Link to={`/s/offers/offer/company-page/${rdv.applicant.company._id}`} className="aReset bT">                        
                                <Box>
                                    <div className="horizontal iCenter mrgL20 mrgT20 mrgB10">
                                        {rdv.applicant.avatar
                                            ?   <div>
                                                    <img 
                                                        className="roundImg100"
                                                        src={rdv.applicant.avatar}
                                                        alt='avatar'/>
                                                </div>
                                            :   <div className="vertical mrgR10">
                                                    <div className="noAvatarProfile">{rdv.applicant.firstname.charAt(0)}{rdv.applicant.lastname.charAt(0)}</div>
                                                </div>
                                        }
                                        
                                            <div className="vertical mrgL10">
                                                <h1 className="txt18 mediumplus noMrgTB">{rdv.applicant.title} {rdv.applicant.firstname} {rdv.applicant.lastname}</h1>
                                                <p className="txt18 noMrgB mrgT5 capitals">{rdv.applicant.company.name}</p>
                                                <div className='horizontal iStart mrgT5'>
                                                    <div><IconHammer width={18} height={18}/></div>    
                                                    <p className="txt16 noMrgTB mrgL10 mrgR20">
                                                        {rdv.applicant.occupation.join(' - ')}
                                                    </p>
                                                </div>  
                                            </div>
                                        
                                    </div>
                                </Box>
                            </Link>

                            <div className="mrgB20"></div>

                            <Link to={`/s/offers/offer-info/${rdv.offer._id}`} className="aReset bT">
                                <Box>
                                    <div className="horizontal iCenter mrgL20">
                                        <div className="vertical mrgR10">
                                        {rdv.offer.pictures.length > 0 
                                            ?   <div>
                                                    <img 
                                                        className="roundImg100 noMrgTB"
                                                        src={rdv.offer.pictures[0]}
                                                        alt='photo_chantier'/>
                                                </div>
                                            :   <>
                                                    {rdv.offer.author.company.logo
                                                        ?   <img    
                                                                className="roundImg100 noMrgTB"
                                                                src={rdv.offer.author.company.logo}
                                                                alt='logo'/>
                                                        :   <div className="noAvatarProfile">{rdv.offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                    }
                                                </>     
                                        }
                                        </div>
                                        <div className="vertical jCenter mrgL10 mrgT20 mrgB20">
                                            <div className="horizontal spaceBtw mrgB10">
                                                {rdv.offer.urgent 
                                                    ?   <Badge size="lg" color="red">Urgent</Badge>
                                                    :   ""
                                                }
                                            </div>

                                            <div>
                                                <h2 className="txt18 lineH15 noMrgT mrgB5">{rdv.offer.title}</h2>
                                            </div>
                                            <div className="horizontal iCenter mrgT5">
                                                <IconHammer width={18} className="mrgR10"/>
                                                <p className="noMrgTB txt16">{rdv.offer.occupation}</p>
                                            </div>
                                            <div className="horizontal iCenter">
                                                <IconLocation  width={18} className="mrgR10"/>
                                                <p className="noMrgTB txt16">{rdv.offer.cityOfIntervention}</p>
                                            </div>          
                                        </div>
                                </div>
                            </Box>
                            </Link>
                                
                            <div className="mrgB30"></div>

                            <div>
                                <p className="txt18 mrgB10 medium mrgL20 dGreyT">ADRESSE DU RDV</p>
                            </div>

                            <Box>
                                <div className="mrgL10 mrgT10 mrgB10">
                                        <div className="horizontal iCenter mrgL10 mrgT20 mrgB20">
                                            <IconMapPin width={18}/>
                                            <p className="txt18 mrgL10 noMrgTB cap">{rdv.address}</p>
                                        </div>
                                        
                                    {rdv.instructions.length > 0
                                            ?   <> 
                                                    <hr className='width95pCenter mrgL10 mrgB20'></hr>
                                                    <div className="horizontal iStart mrgL10">
                                                        <IconInfoCircle width={26}/>
                                                        <p className="txt18 mrgL10 mrgR20 noMrgT mrgB10 cap">{rdv.instructions}</p>
                                                    </div>
                                                </>
                                            :   ""
                                        }  
                                </div>
                            </Box>

                            <div className="mrgB30"></div>

                            <div>
                                <Link to={`/s/offers/edit-rdv/${rdv._id}`}>
                                    <SyndicRectangleButtonSnd className ="btnReset wT bBlack" >
                                        Modifier                                                                           
                                    </SyndicRectangleButtonSnd>
                                </Link>
                                <div className="mrgB10"></div>

                                <SyndicRectangleButton id={rdv.applicant.phone} onClick={showContact}>
                                    Contacter
                                </SyndicRectangleButton>
                            </div>

                            { openContactInfo 
                                        ?   <Modal 
                                                centered
                                                size="auto"
                                                opened={openedContactInfo}
                                                onClose={closeContactInfo}
                                                title="Contacter">
                                                <div className='mrgR40 mrgL40'>
                                                    <p className="useRoboto txtCenter bold txt18 noMrgT mrgB10">
                                                        {rdv.applicant.company.name} 
                                                    </p>
                                                    <p className="horizontal txtCenter iCenter useRoboto txt16 noMrgT mrgB10">
                                                        <div className='noMrgTB mrgR5'><IconPhone width={20} height={20}/></div>
                                                        <p className='noMrgT mrgB5 txt18'>{rdv.applicant.company.address.phonecie}</p>
                                                    </p>
                                                </div>
                                            </Modal> 
                                        :   ""
                                    }

                            <div className="mrgB100"></div> 
                        </div>
                    </>
                )}
            </>
       :
       <Loader />
    )

}

export default RdvDetails;