import { React, useMemo, useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../../../contexts/authContext';
import { Link, useNavigate } from 'react-router-dom';

import { Modal, Loader, Autocomplete, Select } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus, IconSearch, IconChevronDown, IconHammer, IconLocation } from '@tabler/icons-react';

import AppHdWithCloseRight from '../../../../composents/Headers/AppHeaders/AppHdWithCloseRight/AppHdWithCloseRight'
import AppDesktopLogo from '../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

const AddPrestaToFavorite = () => {
  const { getToken } = useContext(AuthContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
  const [companyListinDb, setCompanyListInDb] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [opened, { open, close }] = useDisclosure(false);
  const [prestaId, setPrestaId] = useState('');
  const [showBox, setShowBox] = useState({});
  const [filterValue, setFilterValue] =  useState('nom');


  const navigate = useNavigate();

  useEffect(() => {
      const token = getToken();
      if (!token) {
          navigate("/s/login");
          return;
      }

      const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  
  const getAllPrestas = async () => { 
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/favPresta/add-list`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const companiesArr = await response.json();
        setCompanyListInDb(companiesArr)
          
      } catch (error) { console.error(error);
    }
  }  
  
  useMemo( async () => {
      await getAllPrestas()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddPrestaToFavClick = async (event) => {
      open()
      const idPresta = event.currentTarget.id
      setPrestaId(idPresta)

      try {
        const token = localStorage.getItem('token');
        await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/favPresta/p-page-to-add/${idPresta}`, {
            method: "POST",
            body: JSON.stringify({
                id: idPresta,
            }),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        setTimeout(() => {
          setShowBox(prevShowBox => ({
            ...prevShowBox,
            [idPresta]: !prevShowBox[idPresta]
          }));
        }, 800)
        } catch (error) {
      }
}

  const searchList = () => {
    //filter presta according to search values
    let filteredCompanies = companyListinDb.sort((a,b)=>a.name.localeCompare(b.name)).filter((company) => {
      if (filterValue === "metier") { 
        return (company.prestaUserAccount.occupation.toString()).toLowerCase().includes(searchInput.toLowerCase())

      }
        return company.name.toLowerCase().includes(searchInput.toLowerCase())

    });

    const filtered = filteredCompanies?.map((company, index) => (
        <>
            {isMobile ? (
              <div key = {index} style = {{margin : '10px'}}>
                {!showBox[company.prestaUserAccount._id] ?  
                  <div>
                        <div className="horizontal iCenter spaceBtw mrgL10 mrgT20 mrgB20">
                            <Link to={`/s/favPresta/p-page-to-add/${company._id}`} className="aReset bT">
                              <div className="horizontal iCenter">
                                  <div className="mrgR10"> 
                                      {company.logo
                                        ?   <img className="roundImg70"
                                              src={company.logo}
                                              alt='logo'/>
                                        :   <div className="noAvatarProfile">{company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                        }
                                  </div>
                                  <div className="vertical">
                                      <div><h1 className="txt15 noMrgTB capitals">{company.name}</h1></div>
                                      <div><p className="txt12 capitals thin noMrgTB">{company.prestaUserAccount.username}</p></div>
                                      {company.isItAmember 
                                        ?  <div><p className="txt13 bold noMrgTB finalDotsMinus">{company.prestaUserAccount.occupation.join(' - ')}</p></div>
                                        : <div><p className="txt13 medium noMrgTB finalDotsMinus">{company.prospectOccupation.join(' - ')}</p></div>
                                      }
                                      <div><p className="txt13 noMrgTB">{company.address.city} - {company.address.postalcode}</p></div>
                                      {company.isItAmember 
                                        ?   <div className="horizontal">
                                                <p className="txt13 noMrgTB"><span className="bold mrgR5">{(company.prestaUserAccount.addedToFavBy).length}</span></p>
                                                <p className="txt13 noMrgTB">ajouté en favoris</p>
                                            </div>
                                        :   ""
                                      }
                                      
                                  </div>
                              </div>
                            </Link>
                          
                            <div> 
                              {company.isItAmember 
                                  ? <>  
                                        <IconPlus 
                                            id={company.prestaUserAccount._id}
                                            onClick={handleAddPrestaToFavClick}
                                            width={18} className="mrgLauto mrgR10"
                                        />
                                        {open && prestaId === company.prestaUserAccount._id 
                                            ?   <Modal 
                                                    centered
                                                    size="auto"
                                                    opened={opened}
                                                    onClose={close}
                                                    withCloseButton={false}>
                                                    <div className="txtCenter noMrgTB vertical useRoboto">
                                                        <p className='noMrgTB medium txt15'>{company.name}</p> 
                                                        <p className="mrgT5 noMrgB txt15">a été ajouté à vos favoris</p>
                                                    </div>
                                                </Modal> 
                                            :   ""
                                        } 
                                    </>
                                  :   <Link to={`/s/favPresta/p-page-to-add/${company._id}`} className="aReset bT">
                                          <IconPlus width={18} className="mrgLauto mrgR10"/>
                                      </Link>
                              }

                            </div>
                                    
                        </div> 
                    
                    <div className="mrgB5"></div>
                    
                  </div>
                  :
                  ""
                }
              </div>
            ) : (
              <div key = {index} style = {{margin : '10px'}}>
                {!showBox[company.prestaUserAccount._id] ?  
                  <div className='width50pCenter'>
                        <div className="horizontal iCenter spaceBtw mrgL10 mrgT20 mrgB20">
                            <Link to={`/s/favPresta/p-page-to-add/${company._id}`} className="aReset bT">
                              <div className="horizontal iCenter mrgT20 mrgB20 mrgR20 mrgL20">
                                  <div className="mrgR20"> 
                                      {company.logo
                                        ?   <img className="roundImg100"
                                              src={company.logo}
                                              alt='logo'/>
                                        :   <div className="noAvatarProfile">{company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                        }
                                  </div>
                                  <div className="vertical">
                                      <div><h1 className="txt16 noMrgTB capitals">{company.name}</h1></div>
                                      <div><p className="txt16 capitals thin noMrgT mrgB5">{company.prestaUserAccount.username}</p></div>
                                      <div className='horizontal iCenter'>
                                        <div className='mrgR10'><IconHammer width={20} height={18}/></div>
                                            {company.isItAmember 
                                              ?  <div><p className="txt15 medium noMrgTB mrgR10">{company.prestaUserAccount.occupation.join(' - ')}</p></div>
                                              : <div><p className="txt15 medium noMrgTB mrgR10">{company.prospectOccupation.join(' - ')}</p></div>
                                            }
                                      </div>   
                                        
                                        <div className='horizontal iCenter'>
                                        <div className='mrgR10'><IconLocation width={20} height={18}/></div>
                                            <div className='mrgR10'>
                                              <p className="txt16 noMrgTB">{company.address.city} - {company.address.postalcode}</p>
                                            </div>
                                        </div>
                                      {company.isItAmember 
                                        ?   <div className="horizontal mrgT5">
                                                <p className="txt16 noMrgTB"><span className="bold mrgR5">{(company.prestaUserAccount.addedToFavBy).length}</span></p>
                                                <p className="txt16 noMrgTB">ajouté en favoris</p>
                                            </div>
                                        :   ""
                                      }
                                      
                                  </div>
                              </div>
                            </Link>
                          
                            <div> 
                              {company.isItAmember 
                                  ? <>  
                                      <div className='noMrgTB'>
                                          <IconPlus 
                                            id={company.prestaUserAccount._id}
                                            onClick={handleAddPrestaToFavClick}
                                            width={25} className="mrgLauto mrgR10"
                                        />
                                      </div>
                                        {open && prestaId === company.prestaUserAccount._id 
                                            ?   <Modal 
                                                    centered
                                                    size="auto"
                                                    opened={opened}
                                                    onClose={close}
                                                    withCloseButton={false}>
                                                    <div className="txtCenter noMrgTB vertical useRoboto">
                                                        <p className='noMrgTB medium txt16'>{company.name}</p> 
                                                        <p className="mrgT5 noMrgB txt16">a été ajouté à vos favoris</p>
                                                    </div>
                                                </Modal> 
                                            :   ""
                                        } 
                                    </>
                                  :   <Link to={`/s/favPresta/p-page-to-add/${company._id}`} className="aReset bT">
                                          <IconPlus width={25} className="mrgLauto mrgR10"/>
                                      </Link>
                              }
                            </div>    
                        </div>
                  </div>
                  :
                  ""
                }
              </div>
            )}
        </>
    ));

    return  <>
                {isMobile ? (
                  <div> 
                    {filtered}
                  </div>
                ) : (
                  <div className='mrgB100'> 
                    {filtered}
                  </div>
                )}
            </> 
    
 }

  
    
  return (
    companyListinDb ?
      <>
          {isMobile ? (
            <>
              <AppHdWithCloseRight>Ajouter un prestataire</AppHdWithCloseRight>
              <div className="width95pCenter horizontal spaceBtw">
                  <Autocomplete
                      className='w100 mrgR5'
                      icon = <IconSearch/>
                      placeholder= "Rechercher un nouveau prestataire"
                      data={companyListinDb.map(company => company.name)}
                      value={searchInput}
                      onChange={setSearchInput}
                  />

                  <Select
                    variant="filled"
                    className='w25 useRoboto'
                    data={[
                        { value: 'nom', label: 'Nom' },
                        { value: 'metier', label: 'Métier' }
                    ]}
                    defaultValue='nom'
                    value={filterValue}
                    onChange={setFilterValue}
                    radius="sm"
                    rightSection={<IconChevronDown size="1rem" />}
                    rightSectionWidth={30}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    placeholder="Filtrer"
                    />
              </div>
              <div>
                <div className="mrgB10"></div>
                {searchList()}

              </div>
            </>
          ) : (
            <>
              <AppDesktopLogo />
              <AppHdWithCloseRight>Ajouter un prestataire</AppHdWithCloseRight>
              <div className="width50pCenter horizontal spaceBtw">
                  <Autocomplete
                      className='w100 mrgR5'
                      icon = <IconSearch/>
                      placeholder= "Rechercher un nouveau prestataire"
                      data={companyListinDb.map(company => company.name)}
                      value={searchInput}
                      onChange={setSearchInput}
                      size='md'
                  />

                  <Select
                    variant="filled"
                    className='w25 useRoboto'
                    data={[
                        { value: 'nom', label: 'Nom' },
                        { value: 'metier', label: 'Métier' }
                    ]}
                    defaultValue='nom'
                    value={filterValue}
                    onChange={setFilterValue}
                    radius="sm"
                    rightSection={<IconChevronDown size="1rem" />}
                    rightSectionWidth={30}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    placeholder="Filtrer"
                    size='md'
                    />
              </div>
              <div>
                <div className="mrgB10"></div>
                {searchList()}

              </div>
            </>
          )}
      </>
    : 
    <Loader />
    ) 
}

  export default AddPrestaToFavorite;