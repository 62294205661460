import { React, useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../../../../../contexts/authContext';
import { Link, useParams, useNavigate } from "react-router-dom";

import { IconClock, IconHammer, IconLocation, IconCalendar, IconPhone } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { DatePickerInput, TimeInput } from '@mantine/dates';
import { formatISO, set, addYears } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import 'dayjs/locale/fr';
import { useForm } from '@mantine/form';
import { Textarea, Badge, Select, Autocomplete, Loader, Modal, Group, Button } from '@mantine/core';

import AppHdWithReturnLeft from '../../../../../../composents/Headers/AppHeaders/AppHdWithReturnLeft/AppHdWithReturnLeft';
import Box from '../../../../../../composents/Boxes/Standard/Box';
import SyndicRectangleButton from '../../../../../../composents/Buttons/Syndic/RectangleButton/RectangleButtonMain';
import RectangleButtonDisabled from '../../../../../../composents/Buttons/All/RectangleButtonDisabled/RectangleButtonDisabled';
import AppDesktopLogo from '../../../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

const SendRdvInfoForm = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [userData, setUserData] = useState();
    const [rdv, setRdvInfo] = useState(null)
    const [ openedValidationModal, { open: openValidationModal, close: closeValidationModal}] = useDisclosure(false)
    const [ openedAddressModal, { open: openAddressModal, close: closeAddressModal}] = useDisclosure(false)
    const [ openedLongMsgModal, { open: openLongMsgModal, close: closeLongMsgModal}] = useDisclosure(false)

    const [query, setQuery] = useState('');
    const [options, setOptions] = useState([]);
    const [addressSubmit, setAddressSubmit] = useState('');
    const [instructions, setInstructions] = useState('');

    const [openedContactInfo, { open : openContactInfo, close : closeContactInfo}] = useDisclosure(false);
    localStorage.setItem('page', 'rdv');

    let {id} = useParams()
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const token = getToken();
            if (!token) {
                navigate("/s/login");
                return;
            };
            getInfoRdv();
            getUser();
        }
        fetchData();
        
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
      
          window.addEventListener('resize', handleResize);
      
          return () => {
            window.removeEventListener('resize', handleResize);
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);
    
    const getUser = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch('https://boldee-97d8d36af3bd.herokuapp.com/s/getUser', {
                method: "GET",    
                headers: {
                    Authorization: `Bearer ${token}`
                    }
        });
            const data = await response.json()
            setUserData(data)
        }   catch (error) {
            return null;
        }
    }

    const getInfoRdv = async () => {       
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/offers/offer/rdv-info-send-form/${id}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const rdv = await response.json();
            setRdvInfo(rdv);
        } catch (error) {
        } 
    }

    const form = useForm({
        initialValues: {
            _id:id,
            dateOfAppointment : new Date(),
            timeOfAppointment : new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
            duration : '',
            address : '',
            instructions : ''
        },

        validate: {
            timeOfAppointment : (value) => (value.length < 1 ? 'Saisissez l\'heure du RDV' : null),
            duration : (value) => (value.length < 1 ? 'Sélectionnez la durée du RDV' : null),
            address : (value) => (value.length < 1 ? 'Saisissez l\'adresse du lieu RDV' : null),
            instructions : (value) => (value.length > 200 ? '200 caractères maximum' : null),
        }
    })
    
    const handleQueryChange = async (value) => {
        setQuery(value);    
        if (value) {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch('https://boldee-97d8d36af3bd.herokuapp.com/s/offers/offer/rdv-info-send-form/search-address', {
                method: 'POST',
                body: JSON.stringify({ query: value }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }          
                })

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                  }
                const data = await response.json();
                const suggestions = data.features.map((feature) => ({
                    value: feature.properties.label,
                    label: feature.properties.label,
                }));
                setOptions(suggestions);
            } catch (error) {
            }
        }
    
    };

    const handleQuerySubmit = (addressSelected) => {
        setAddressSubmit(addressSelected)
    };
    
    const handleChangeInstructions = (event) => {
        const value = event.target.value; // Obtenez la valeur du Textarea
        setInstructions(value); // Mettez à jour l'état avec la nouvelle valeur
      };

    const handleFormCheck = () => {
        if(addressSubmit === ''){
            openAddressModal()
        } else
        if(addressSubmit.target.value === '') {
            openAddressModal()
        } else 
        if(instructions.length > 200) {
            openLongMsgModal()
        } else {
            openValidationModal()
        }
    }

    const handleFormSubmit = async (event) => {
       
        const values = form.values;
        const idRdv = event.currentTarget.id
        try {
            const token = localStorage.getItem('token');
            const timezone = 'Europe/Paris';
            const currentDate = new Date();
            const timeParts = values.timeOfAppointment.split(':');
            const hour = parseInt(timeParts[0], 10);
            const minute = parseInt(timeParts[1], 10);
            const dateTime = set(currentDate, { hours: hour, minutes: minute });
            await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/offers/offer/rdv-info-send-form/${idRdv}`, {
                method: "POST",
                body: JSON.stringify({
                    idRdv : idRdv,
                    dateOfAppointment : values.dateOfAppointment, 
                    timeOfAppointment : formatISO(utcToZonedTime(dateTime, timezone)),  
                    duration : values.duration,
                    address : addressSubmit.target.value,
                    instructions : instructions
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            navigate(`/s/offers/offer/details-rdv/${idRdv}`)
        } catch (error) {
        }
    };

    const handleCall = (event) => {
        const phoneNumber = event.currentTarget.id
        window.location.href = `tel:${phoneNumber}`
    };

    const showContact = () => {
        openContactInfo()
    };

    return (
        
        rdv  ?
            <>
                {isMobile ? (
                    <>
                    
                        <AppHdWithReturnLeft isEditOffer={false} prestaId={rdv.applicant._id}>Envoyer les informations du RDV</AppHdWithReturnLeft>
                            
                                <div className="width90pCenter">
                                    <p className="send-RDV-info-titles">Destinataire</p>
                                </div>
                                
                                <Box>
                                    <div className="horizontal iCenter mrgR10">
                                        <div className="mrgR10 mrgL20">
                                            {rdv.applicant.avatar
                                                ?   <img 
                                                        className="roundImg70 w70p"
                                                        src={rdv.applicant.avatar}
                                                        alt='avatar'/>
                                                :   <div className="noAvatarProfile">{rdv.applicant.company.name.charAt(0)}</div>
                                            }    
                                        </div>
                                            
                                        <div className="vertical jCenter mrgL10">
                                            <Link to={`/s/offers/offer/company-page/${rdv.applicant.company._id}`} className="aReset bT">                        
                                                <div className="horizontal">
                                                    <h1 className="txt15 noMrgTB">{rdv.applicant.title} {rdv.applicant.firstname} {rdv.applicant.lastname}</h1>
                                                </div>
                                                <div className="horizontal">
                                                    <h1 className="txt15 noMrgTB">{rdv.applicant.company.name}</h1>
                                                </div>
                                            </Link>
                                            
                                            <p className="txt13 noMrgT mrgB5">{rdv.applicant.occupation.join(' - ')}</p>
                                            
                                            <button className="btn-action-contact-rdv bBlack" id={rdv.applicant.phone} onClick={handleCall}>
                                                Contacter                                               
                                            </button>
                                        </div>
                                    </div>
                                </Box>

                                <div className="mrgB20"></div>

                                <div className="width90pCenter">
                                    <p className=" send-RDV-info-titles">Offre</p>
                                </div>

                                
                                <Box>
                                    <Link to={`/s/offers/offer-info/${rdv.offer._id}`} className="aReset bT">
                                        <div className="horizontal iCenter mrgR10">
                                            <div className="mrgR10 mrgL20">
                                                {rdv.offer.pictures.length > 0
                                                    ?   <img 
                                                            className="roundImg70 txt12 w70p"
                                                            src={rdv.offer.pictures[0]}
                                                            alt='photo_chantier'/>
                                                    :   <>
                                                            
                                                            {userData.company.logo
                                                                ?   <img    
                                                                        className="roundImg70 noMrgTB"
                                                                        src={userData.company.logo}
                                                                        alt='logo'/>
                                                                :   <div className="noAvatarProfile">{userData.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                            }
                                                        </>
                                                        
                                                }
                                            </div>

                                            <div className="vertical jCenter mrgL10">
                                                {rdv.offer.urgent ?
                                                    <div className="horizontal iCenter mrgB5">
                                                        <Badge color="red">Urgent</Badge>    
                                                    </div>
                                                : ""
                                                }    
                                            <div>
                                                <h2 className="txt15 lineH15 noMrgT mrgB5">{rdv.offer.title}</h2>
                                            </div>
                                            <div className="horizontal iCenter lineHmin">
                                                <IconHammer width={13} className="mrgR5"/>
                                                <p className="noMrg txt13">{rdv.offer.occupation}</p>
                                            </div>
                                            <div className="horizontal iCenter lineHmin">
                                                <IconLocation  width={13} className="mrgR5"/>
                                                <p className="noMrg txt13">{rdv.offer.cityOfIntervention}</p>
                                            </div>
                                            
                                                
                                                    
                                            </div>
                                        </div>
                                    </Link>
                                </Box>
                                            
                                <div className="mrgB20"></div>

                                <div className="width90pCenter">
                                    <p className="send-RDV-info-titles">RDV</p>
                                </div>
                                <form>
                                    <div className="width90pCenter">
                                        
                                        <DatePickerInput
                                            icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                                            defaultDate={new Date()}
                                            valueFormat="DD MMM YYYY"
                                            locale="fr"
                                            label="Date du RDV"
                                            minDate={new Date()}
                                            maxDate = {addYears(new Date(),3)} 
                                            withAsterisk
                                            required = {true}
                                            error="required"
                                            {...form.getInputProps('dateOfAppointment')}    
                                        />

                                        <TimeInput
                                            icon={<IconClock size="1rem" stroke={1.5}/>}
                                            label="Heure du RDV"
                                            required = {true}
                                            clearable
                                            withAsterisk
                                            {...form.getInputProps('timeOfAppointment')} 
                                        />

                                        <Select
                                            label="Durée du RDV"
                                            placeholder="Sélectionnez"
                                            data={[
                                                { value: 'De 30 min à 1 heure', label: 'De 30 min à 1 heure' },
                                                { value: 'De 1 à 2 heures', label: 'De 1 à 2 heures' },
                                                { value: 'Une demie journée', label: 'Une demie journée' },
                                                { value: 'Autre', label: 'Autre' }
                                            ]}
                                            required = {true}
                                            withAsterisk
                                            {...form.getInputProps('duration')}
                                        />

                                        <Autocomplete
                                            label="Adresse"
                                            placeholder="Entrez une adresse"
                                            withAsterisk
                                            required = {true}
                                            value={query}
                                            onChange={handleQueryChange}
                                            onSelect={handleQuerySubmit}
                                            data={options}
                                            textFieldProps={{ required: true }}
                                        />

                                        <Textarea
                                            placeholder="Message"
                                            label="Message (facultatif)"
                                            value={instructions}
                                            onChange={handleChangeInstructions}
                                            />
                                    
                                    </div>

                                    <div className="mrgB20"></div>

                                    <div className="width90pCenter">
                                        {form.isDirty() 
                                            ?   <SyndicRectangleButton onClick={handleFormCheck}>
                                                    Envoyer
                                                </SyndicRectangleButton>
                                            :   <RectangleButtonDisabled>
                                                    Envoyer
                                                </RectangleButtonDisabled>
                                        }
                                        {openAddressModal 
                                        ?   <Modal 
                                                centered
                                                size="50%"
                                                opened={openedAddressModal}
                                                onClose={closeAddressModal}
                                                withCloseButton={false}>
                                                <p className="useRoboto txt15 txtCenter noMrgTB">
                                                    Entrer une adresse de rendez-vous
                                                </p>
                                            </Modal> 
                                        :   ""
                                        }
                                        {openLongMsgModal 
                                        ?   <Modal 
                                                centered
                                                size="50%"
                                                opened={openedLongMsgModal}
                                                onClose={closeLongMsgModal}
                                                withCloseButton={false}>
                                                <p className="useRoboto txt15 txtCenter noMrgTB">
                                                    Le message doit comporter 200 caractères maximum
                                                </p>
                                            </Modal> 
                                        :   ""
                                        }       
                                        {openValidationModal ?
                                            <Modal 
                                                centered
                                                withCloseButton={false}    
                                                opened={openedValidationModal} 
                                                onClose={closeValidationModal}
                                                size="70%">
                                                <Group noWrap mt="md" className="useRoboto txtCenter mrgT10 noMrgB vertical lineH10">
                                                    <p className='noMrgTB txt15'>Envoyer les informations du RDV à</p> 
                                                    <p className='noMrgTB txt15 medium'>{rdv.applicant.company.name} ?</p>
                                                </Group>

                                                <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20 w80">
                                                    <Button variant="outline" color="dark" onClick={closeValidationModal} className='useRoboto'>
                                                        Annuler
                                                    </Button>
                                                    <Button 
                                                        type="submit"
                                                        variant="filled" 
                                                        color="dark"
                                                        className='useRoboto medium'
                                                        id={rdv._id}
                                                        onClick={handleFormSubmit} >
                                                            Envoyer
                                                    </Button>
                                                </Group>       
                                            </Modal>
                                        :
                                        ""
                                        }
                                    </div>

                                    <div className="mrgB30"></div>
            
                                </form>
                    </>
                ) : (
                    <>
                        <AppDesktopLogo/>
                        <AppHdWithReturnLeft isEditOffer={false} prestaId={rdv.applicant._id}>Envoyer les informations du RDV</AppHdWithReturnLeft>
                            
                            <div className="width50pCenter">
                                <p className="send-RDV-info-titles">Destinataire</p>
                                <Box>
                                    <div className="horizontal iCenter mrgR10 mrgB10 mrgT10">
                                        <div className="mrgR10 mrgL20">
                                        {rdv.applicant.avatar
                                            ?   <img 
                                                    className="roundImg100"
                                                    src={rdv.applicant.avatar}
                                                    alt='avatar'/>
                                            :   <div className="noAvatarProfile">{rdv.applicant.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                        }    
                                        </div>
                                            
                                        <div className="vertical jCenter mrgL10">
                                            <Link to={`/s/offers/offer/company-page/${rdv.applicant.company._id}`} className="aReset bT">                        
                                                <div className="horizontal">
                                                    <h1 className="txt18 mediumplus noMrgTB">{rdv.applicant.title} {rdv.applicant.firstname} {rdv.applicant.lastname}</h1>
                                                </div>
                                                <div className="horizontal">
                                                    <p className="txt18 noMrgB mrgT5">{rdv.applicant.company.name}</p>
                                                </div>
                                            </Link>
                                            
                                            <p className="txt15 mrgT5 mrgB5">{rdv.applicant.occupation.join(' - ')}</p>
                                            
                                             <button className="btn-action-contact-rdv bBlack" onClick={showContact}>
                                                Contacter
                                            </button>
                                            { openContactInfo 
                                                ?   <Modal 
                                                        centered
                                                        size="auto"
                                                        opened={openedContactInfo}
                                                        onClose={closeContactInfo}
                                                        title="Contacter">
                                                        <div className='mrgR40 mrgL40'>
                                                            <p className="useRoboto txtCenter bold txt18 noMrgT mrgB10">
                                                                {rdv.applicant.company.name} 
                                                            </p>
                                                            <p className="horizontal txtCenter iCenter useRoboto txt16 noMrgT mrgB10">
                                                                <div className='noMrgTB mrgR5'><IconPhone width={20} height={20}/></div>
                                                                <p className='noMrgT mrgB5 txt18'>{rdv.applicant.company.address.phonecie}</p>
                                                            </p>
                                                        </div>
                                                    </Modal> 
                                                :   ""
                                            }
                                        </div>
                                    </div>
                                </Box>

                                <div className="mrgB20"></div>

                                <p className="send-RDV-info-titles">Offre</p>

                                
                                <Box>
                                    <Link to={`/s/offers/offer-info/${rdv.offer._id}`} className="aReset bT">
                                        <div className="horizontal iCenter mrgR10 mrgB10 mrgT10">
                                            <div className="mrgR10 mrgL20">
                                                {rdv.offer.pictures.length > 0
                                                    ?   <>
                                                        <img 
                                                            className="roundImg100 txt12"
                                                            src={rdv.offer.pictures[0]}
                                                            alt='photo_chantier'/>
                                                        </>
                                                    :   <>
                                                            
                                                            {userData.company.logo
                                                                ?   <img    
                                                                        className="roundImg100 noMrgTB"
                                                                        src={userData.company.logo}
                                                                        alt='logo'/>
                                                                :   <div className="noAvatarProfile">{userData.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                            }
                                                        </>
                                                        
                                                }
                                            </div>

                                            <div className="vertical jCenter mrgL10">
                                                {rdv.offer.urgent ?
                                                    <div className="horizontal iCenter mrgB10">
                                                        <Badge color="red" size='lg'>Urgent</Badge>    
                                                    </div>
                                                : ""
                                                }    
                                            <div>
                                                <h2 className="txt18 lineH15 noMrgT mrgB5">{rdv.offer.title}</h2>
                                            </div>
                                            <div className="horizontal iCenter mrgT5">
                                                <IconHammer width={18} className="mrgR10"/>
                                                <p className="noMrg txt15">{rdv.offer.occupation}</p>
                                            </div>
                                            <div className="horizontal iCenter">
                                                <IconLocation  width={18} className="mrgR10"/>
                                                <p className="noMrg txt15">{rdv.offer.cityOfIntervention}</p>
                                            </div>
                                            
                                                
                                                    
                                            </div>
                                        </div>
                                    </Link>
                                </Box>
                                            
                                <div className="mrgB20"></div>

                                <p className="send-RDV-info-titles">RDV</p>
                                <form>            
                                    <div>
                                        <DatePickerInput
                                            icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                                            defaultDate={new Date()}
                                            valueFormat="DD MMM YYYY"
                                            locale="fr"
                                            label="Date du RDV"
                                            minDate={new Date()}
                                            maxDate = {addYears(new Date(),3)} 
                                            withAsterisk
                                            required = {true}
                                            error="required"
                                            size='md'
                                            {...form.getInputProps('dateOfAppointment')}    
                                        />
                                        
                                        <div className='mrgB20'></div>
                                        
                                        <TimeInput
                                            icon={<IconClock size="1rem" stroke={1.5}/>}
                                            label="Heure du RDV"
                                            required = {true}
                                            clearable
                                            withAsterisk
                                            size='md'
                                            {...form.getInputProps('timeOfAppointment')} 
                                        />

                                        <div className='mrgB20'></div>

                                        <Select
                                            label="Durée du RDV"
                                            placeholder="Sélectionnez"
                                            data={[
                                                { value: 'De 30 min à 1 heure', label: 'De 30 min à 1 heure' },
                                                { value: 'De 1 à 2 heures', label: 'De 1 à 2 heures' },
                                                { value: 'Une demie journée', label: 'Une demie journée' },
                                                { value: 'Autre', label: 'Autre' }
                                            ]}
                                            required = {true}
                                            withAsterisk
                                            size='md'
                                            {...form.getInputProps('duration')}
                                        />

                                        <div className='mrgB20'></div>

                                        <Autocomplete
                                            label="Adresse"
                                            placeholder="Entrez une adresse"
                                            withAsterisk
                                            required = {true}
                                            value={query}
                                            onChange={handleQueryChange}
                                            onSelect={handleQuerySubmit}
                                            data={options}
                                            textFieldProps={{ required: true }}
                                            size='md'
                                        />
                                        
                                        <div className='mrgB20'></div>

                                        <Textarea
                                            placeholder="Message"
                                            label="Message (facultatif)"
                                            value={instructions}
                                            onChange={handleChangeInstructions}
                                            size='md'
                                        />                                    
                                    </div>

                                    <div className="mrgB30"></div>

                                    <div className='mrgB100'>
                                        {form.isDirty() 
                                            ?   <SyndicRectangleButton onClick={handleFormCheck}>
                                                    <p className='txt16'>Envoyer</p>
                                                </SyndicRectangleButton>
                                            :   <RectangleButtonDisabled>
                                                    <p className='txt16'>Envoyer</p>
                                                </RectangleButtonDisabled>
                                        }
                                        {openAddressModal 
                                        ?   <Modal 
                                                centered
                                                size="auto"
                                                opened={openedAddressModal}
                                                onClose={closeAddressModal}
                                                withCloseButton={false}>
                                                <p className="useRoboto txt16 txtCenter noMrgTB">
                                                    Entrer une adresse de rendez-vous
                                                </p>
                                            </Modal> 
                                        :   ""
                                        }
                                         {openLongMsgModal 
                                        ?   <Modal 
                                                centered
                                                size="auto"
                                                opened={openedLongMsgModal}
                                                onClose={closeLongMsgModal}
                                                withCloseButton={false}>
                                                <p className="useRoboto txt16 txtCenter noMrgTB">
                                                    Le message doit comporter 200 caractères maximum
                                                </p>
                                            </Modal> 
                                        :   ""
                                        }    
                                        {openValidationModal ?
                                            <Modal 
                                                centered
                                                withCloseButton={false}    
                                                opened={openedValidationModal} 
                                                onClose={closeValidationModal}
                                                size="auto">
                                                <Group noWrap mt="md" className="useRoboto txtCenter mrgT10 noMrgB vertical lineH10">
                                                    <p className='noMrgTB txt16'>Envoyer les informations du RDV à</p> 
                                                    <p className='noMrgTB txt16 medium'>{rdv.applicant.company.name} ?</p>
                                                </Group>

                                                <Group mt="xl" className="txtCenter horizontal spaceArd mrgT30">
                                                    <Button variant="outline" color="dark" onClick={closeValidationModal} className='useRoboto'>
                                                        <p className='txt16'>Annuler</p>
                                                    </Button>
                                                    <Button 
                                                        type="submit"
                                                        variant="filled" 
                                                        color="dark"
                                                        className='useRoboto medium'
                                                        id={rdv._id}
                                                        onClick={handleFormSubmit} >
                                                            <p className='txt16'>Envoyer</p>
                                                    </Button>
                                                </Group>       
                                            </Modal>
                                        :
                                        ""
                                        }
                                    </div>

                                    <div className="mrgB30"></div>
            
                                </form>
                                </div>
                    </>
                )}
            </>
        :
        <Loader />
    )
}

export default SendRdvInfoForm;

