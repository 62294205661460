import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconX, IconChevronLeft } from '@tabler/icons-react';

import classes from './GoBackClosePageOverPictureButton.module.css'

const GoBackClosePageOverPictureButton = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return(
        <>
        {isMobile ? (
            <>
                <button
                    className={classes.closeBtn}
                    type={props.type || "button"}
                    onClick={props.onClick}
                    ><IconX className={classes.iconX}
                            onClick={goBack}/>
                </button>
            </>
        ) : (
            <>
                <button className={classes.closeBtn}
                    type={props.type || "button"}
                    onClick={props.onClick}>
                    <div className='horizontal iCenter' onClick={goBack}>
                        <div>
                            <IconChevronLeft
                            width={20}  
                            className={classes.iconX}
                            />
                        </div>
                        <div>
                            <p className='txt16 medium noMrgTB mrgL5'>
                                Retour
                            </p>
                        </div>
                    </div>
                </button>
            </>
        )}
     </>
    )
}
export default GoBackClosePageOverPictureButton;