import { React, useEffect, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import { Modal, Group, Button } from '@mantine/core';
import { IconX } from '@tabler/icons-react';

import classes from './AddOfferHd.module.css';

const AppHdWithCloseRight = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [opened, { open, close }] = useDisclosure(false);
    const navigate = useNavigate()

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const HandleOpenModal = () => {
        open();
    }

    const goToOffers = () => {
        navigate('/s/offers/list')
    }

    return(
        <>
            <div className={classes.appHeading}>
                <div className={classes.navbackBlank}></div>
                <div className={classes.navtitle}>
                    <h2>{props.children}</h2>
                </div>
        
                <div >
                    <IconX className={classes.btnReset}
                            onClick={HandleOpenModal}/>
                            {isMobile ? (
                                <>
                                { open 
                                    ?   <Modal 
                                            centered
                                            withCloseButton={false}    
                                            opened={opened} 
                                            onClose={close}
                                            size="auto">
                                                <Group noWrap mt="md" className="useRoboto txtCenter mrgT5 txt15">
                                                    Abandonner la publication de votre offre ?
                                                </Group>

                                                <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20 w80">
                                                <Button variant="outline" color="dark" onClick={close} className='useRoboto'>
                                                    Annuler
                                                </Button>
                                                <Button variant="filled" color="dark"  onClick={goToOffers} className='useRoboto medium'>
                                                    Abandonner
                                                </Button>
                                            </Group>       
                                        </Modal> 
                                    :   ""
                                }
                                </>
                            ) : (
                                <>
                                 { open 
                                    ?   <Modal 
                                            centered
                                            withCloseButton={false}    
                                            opened={opened} 
                                            onClose={close}
                                            size="auto">
                                                <Group noWrap mt="md" className="useRoboto txtCenter mrgT5 txt16">
                                                    Abandonner la publication de votre offre ?
                                                </Group>

                                                <Group mt="xl" className="txtCenter horizontal jCenter mrgT20">
                                                <Button variant="outline" color="dark" onClick={close} className='useRoboto'>
                                                    <p className='txt16 medium'>Annuler</p>
                                                </Button>
                                                <Button variant="filled" color="dark"  onClick={goToOffers} className='useRoboto medium'>
                                                    <p className='txt16'>Abandonner</p>
                                                </Button>
                                            </Group>       
                                        </Modal> 
                                    :   ""
                                }
                                </>
                            )}
                </div>
            </div>
        </>
    )
}

export default AppHdWithCloseRight;