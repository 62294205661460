import { React, useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../../../../contexts/authContext';
import { useNavigate } from 'react-router-dom';

import { useForm } from '@mantine/form';
import { TextInput, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import AppHdWithReturnLeft from '../../../../../composents/Headers/AppHeaders/AppHdWithReturnLeft/AppHdWithReturnLeft';
import PrestaRectangleButtonMain from '../../../../../composents/Buttons/Presta/RectangleButtonMain/RectangleButtonMain';
import RectangleButtonDisabled from '../../../../../composents/Buttons/All/RectangleButtonDisabled/RectangleButtonDisabled';
import AppDesktopLogo from '../../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

function SendSyndicContact() {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [opened, { open, close }] = useDisclosure(false);
    
    const navigate = useNavigate();

    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/p/login");
            return;
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
            };
            window.addEventListener('resize', handleResize);
            return () => {
            window.removeEventListener('resize', handleResize);
            };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const form = useForm({
        initialValues: { email : "" },
        validate: {
            email : (value) => (
                value.length < 1
                ? 'Renseignez votre E-mail'
                : /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) 
                ? null 
                : 'E-mail non valide'
            ),   
        }
    })

    const handleFormSubmit = async (value, _event) => {
        _event.preventDefault();
        try {
            const token = localStorage.getItem('token');
            await fetch("https://boldee-97d8d36af3bd.herokuapp.com/p/profile/reco", {
                method: "POST",
                body: JSON.stringify({
                    email: value.email
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            open();
        } catch (error) {
        }
    }

    return(
        <>
            {isMobile ? (
                <form onSubmit={form.onSubmit(handleFormSubmit)}>
                    <AppHdWithReturnLeft>Recommandez Boldee</AppHdWithReturnLeft>
                    <div className="width95pCenter">
                        <TextInput
                            label="Email du Syndic"
                            placeholder="Email"
                            {...form.getInputProps('email')}
                        />
                    </div>

                    <div className='mrgB10'></div>

                    <div className="width95pCenter">
                    {form.isDirty() 
                        ?   <PrestaRectangleButtonMain type="submit">
                                Recommander
                            </PrestaRectangleButtonMain>
                        :   <RectangleButtonDisabled>
                                Recommander
                            </RectangleButtonDisabled>
                    }
                        {open
                            ? <Modal
                                centered
                                size="50%"
                                opened={opened}
                                onClose={close}
                                withCloseButton={false}
                                className='useRoboto'>
                                <p className="txtCenter noMrgTB txt15">
                                    Recommandation envoyée
                                </p>
                            </Modal>
                            : ""
                        }
                        </div>
                </form>
            ) : (
                <>
                    <AppDesktopLogo/>
                    <form onSubmit={form.onSubmit(handleFormSubmit)}>
                    <AppHdWithReturnLeft>Recommandez Boldee</AppHdWithReturnLeft>
                    <div className='mrgB100'></div>

                    <div className="width50pCenter">
                        <TextInput
                            label="Email du Syndic"
                            placeholder="Email"
                            size='md'
                            {...form.getInputProps('email')}
                        />

                    <div className='mrgB20'></div>

                    {form.isDirty() 
                        ?   <PrestaRectangleButtonMain type="submit">
                                <p className='txt16'>Recommander</p>
                            </PrestaRectangleButtonMain>
                        :   <RectangleButtonDisabled>
                                <p className='txt16'>Recommander</p>
                            </RectangleButtonDisabled>
                    }
                        {open
                            ? <Modal
                                centered
                                size="auto"
                                opened={opened}
                                onClose={close}
                                withCloseButton={false}>
                                <p className="useRoboto txtCenter noMrgTB txt16">
                                    Recommandation envoyée
                                </p>
                            </Modal>
                            : ""
                        }
                        </div>
                </form>
                </>
            )}
        </>   
    )
}

export default SendSyndicContact;

