import { React, useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../../../../contexts/authContext';
import { useNavigate } from 'react-router-dom';

import { IconChevronRight } from '@tabler/icons-react';
import AppHdWithReturnLeft from '../../../../../composents/Headers/AppHeaders/AppHdWithReturnLeft/AppHdWithReturnLeft';
import Box from '../../../../../composents/Boxes/Standard/Box'
import AppDesktopLogo from '../../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

const SyndicDisclaimer = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const navigate = useNavigate();

    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/s/login");
            return;
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return(
        <>
            {isMobile ? (
                <>
                    <AppHdWithReturnLeft>Mentions légales</AppHdWithReturnLeft>
                    <a href="https://res.cloudinary.com/do9t6jjdy/image/upload/v1702889743/LG-P-001_-_Mentions_l%C3%A9gales_v.3_1223_zlu3kf.pdf" target="_blank" rel="noreferrer" className="aReset bT">
                        <Box>
                            <div className="horizontal iCenter spaceBtw">
                                <p id="syndicOpenedMLGa4" className="noMrgTB mrgL10 txt15">Mentions légales</p>
                                <IconChevronRight width={16} className='mrgR10'/>
                            </div> 
                        </Box>
                    </a>
                    <div className="mrgB10"></div>

                    <a href="https://res.cloudinary.com/do9t6jjdy/image/upload/v1693765220/LPG002_Conditions_G%C3%A9n%C3%A9rales_d_Utilisation_v.2_0923_idmckv.pdf" target="_blank" rel="noreferrer" className="aReset bT">
                        <Box>
                            <div className="horizontal iCenter spaceBtw">
                                <p id="syndicOpenedCGUGa4" className="noMrgTB mrgL10 txt15">Conditions Générales d'Utilisation</p>
                                <IconChevronRight width={16} className='mrgR10'/>
                            </div> 
                        </Box>
                    </a>

                    <div className="mrgB10"></div>
                    
                    <a href="https://res.cloudinary.com/do9t6jjdy/image/upload/v1702889743/RGPD-P-001_-_Politique_de_confidentialit%C3%A9_v.3_1223_gcrwbv.pdf" target="_blank" rel="noreferrer"className="aReset bT">   
                        <Box>
                            <div className="horizontal iCenter spaceBtw">
                                <p id="syndicOpenedRGPDGa4" className="noMrgTB mrgL10 txt15">Protection des données</p>
                                <IconChevronRight width={16} className='mrgR10'/>
                            </div> 
                        </Box>
                    </a>
                </>
            ) : (
                <>
                    <AppDesktopLogo/>
                    <AppHdWithReturnLeft>Mentions légales</AppHdWithReturnLeft>
                    <div className='mrgB30'></div>
                    <div className='width50pCenter'>
                        <a href="https://res.cloudinary.com/do9t6jjdy/image/upload/v1702889743/LG-P-001_-_Mentions_l%C3%A9gales_v.3_1223_zlu3kf.pdf" target="_blank" rel="noreferrer" className="aReset bT">
                            <Box>
                                <div className="horizontal iCenter spaceBtw">
                                    <p id="syndicOpenedMLGa4" className="mrgL20 txt18 mrgT10 mrgB10">Mentions légales</p>
                                    <IconChevronRight width={30} className='mrgR20'/>
                                </div> 
                            </Box>
                        </a>
                        <div className="mrgB10"></div>

                        <a href="https://res.cloudinary.com/do9t6jjdy/image/upload/v1693765220/LPG002_Conditions_G%C3%A9n%C3%A9rales_d_Utilisation_v.2_0923_idmckv.pdf" target="_blank" rel="noreferrer" className="aReset bT">
                            <Box>
                                <div className="horizontal iCenter spaceBtw">
                                    <p id="syndicOpenedCGUGa4" className="mrgL20 txt18 mrgT10 mrgB10">Conditions Générales d'Utilisation</p>
                                    <IconChevronRight width={30} className='mrgR20'/>
                                </div> 
                            </Box>
                        </a>

                        <div className="mrgB10"></div>
                        
                        <a href="https://res.cloudinary.com/do9t6jjdy/image/upload/v1702889743/RGPD-P-001_-_Politique_de_confidentialit%C3%A9_v.3_1223_gcrwbv.pdf" target="_blank" rel="noreferrer" className="aReset bT">
                            <Box>
                                <div className="horizontal iCenter spaceBtw">
                                    <p id="syndicOpenedRGPDGa4" className="mrgL20 txt18 mrgT10 mrgB10">Protection des données</p>
                                    <IconChevronRight width={30} className='mrgR20'/>
                                </div> 
                            </Box>
                        </a>
                    </div>
                </>
            )}
        </>
    )
}

export default SyndicDisclaimer;

