import {React, useState, useEffect } from 'react';
import classes from './MatchHd.module.css'
import { IconZoomInFilled  } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

const MatchHd = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };      
          window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return(
        <>
            {isMobile ? (
                <>
                    <div className={classes.appHeading}>
                        <div className={classes.navbackBlank}></div>
                        <div className={classes.navtitle}>
                            <h2>{props.children}</h2>
                        </div>
                
                        <div >
                            <Link to={`/p/search`}>
                                <IconZoomInFilled id="searchOfferFromMatchga4" className={classes.iconColor} />
                            </Link>
                        </div>
                    </div>
                </>
            ) : (
                <>
                <div className={classes.appHeading}>
                    <div className={classes.navbackBlank}></div>
                    <div className={classes.navtitle}>
                        <h2>{props.children}</h2>
                    </div>
            
                    <div className='mrgR20'>
                        <Link to={`/p/search`}>
                            <IconZoomInFilled id="searchOfferFromMatchga4" className={classes.iconColor} />
                        </Link>
                    </div>
                </div>
            </> 
            )}
        </>
    )
}

export default MatchHd;