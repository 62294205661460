import React from "react";
import rgeLogo from '../../../../assets/images/rge.jpg'
import classes from './LogoRGE.module.css';

const LogoRGE = (props) => {
    return(
        <div className={classes.Logo}>
            <img src={rgeLogo} alt="logo"/>
        </div>
    )
}
export default LogoRGE;