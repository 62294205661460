import { React, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';

import { IconSearch, IconX } from '@tabler/icons-react';
import { Loader, Autocomplete, Modal } from '@mantine/core';

import AppHdWithReturnLeft from '../../../../../composents/Headers/AppHeaders/AppHdWithReturnLeft/AppHdWithReturnLeft';
import PrestaRectangleButtonMain from '../../../../../composents/Buttons/Presta/RectangleButtonMain/RectangleButtonMain';
import Box from '../../../../../composents/Boxes/Standard/Box';
import AppDesktopLogo from '../../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

const AddPlaceOfIntervention = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [ userData, setUserData ] = useState({ placesOfIntervention: [] })

    const [query, setQuery] = useState('');
    const [options, setOptions] = useState([]);
    const [ openedModalAlreadyAdded, { open: openModalAlreadyAdded, close: closeModalAlreadyAdded}] = useDisclosure(false)
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        fetchData();

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const fetchData = async () => {
        const token = getToken();
        if (!token) {
            navigate("/p/login");
            return;
        }
        
        try {
            await getUser()
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };

    const getUser = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch('https://boldee-97d8d36af3bd.herokuapp.com/p/getUser', {
                method: "GET",    
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const data = await response.json()
            if (data) {
                setUserData(prevState => ({
                    ...prevState,
                    placesOfIntervention: data.placesOfIntervention
                  }));
                setIsLoading(false);
              } 
        }   catch (error) {
            return null;
        }
    }  
    
    const handleAddCity = async () => {
        if (userData.placesOfIntervention.includes(query)){
            setQuery('')
            openModalAlreadyAdded()
        } else
         if (query) {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch('https://boldee-97d8d36af3bd.herokuapp.com/p/profile/intervention-places/add', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ 
                        placesOfIntervention : [...userData.placesOfIntervention, query] 
                    }),
                });
    
                const data = await response.json();
                sessionStorage.setItem("user", JSON.stringify(data));
                setUserData(prevState => ({
                    ...prevState,
                    placesOfIntervention: data.placesOfIntervention
                  }));
                
            } catch (error) {
            }
        };
    }
 
    const handleDeletePlace = async (event) => {
        const placeToDelete = event.currentTarget.id;

        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://boldee-97d8d36af3bd.herokuapp.com/p/profile/intervention-places/delete', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ placeToDelete }),
            });

            const data = await response.json();
            setUserData(prevState => ({
                ...prevState,
                placesOfIntervention: data.placesOfIntervention
              }));
              sessionStorage.setItem("user", JSON.stringify(data));
        } catch (error) {
        }
    };
    const handleQueryChange = async (value) => {
        setQuery(value);
        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://boldee-97d8d36af3bd.herokuapp.com/p/profile/search-intervention-places', {
                method: 'POST',
                body: JSON.stringify({ query: value }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }          
            })

            if (!response.ok) {
                throw new Error('Network response was not ok');
              }
            const data = await response.json();
            const suggestionsMap = new Map();
            data.forEach((feature) => {
                const nom = feature.nom || '';
                const code = feature.code || '';
                const suggestionKey = `${code} ${nom}`;

                suggestionsMap.set(suggestionKey, {
                    value: `${code} ${nom}`,
                    label: `${code} ${nom}`,
                    nom: nom,
                    code: code
                });
                });
                const uniqueSuggestions = Array.from(suggestionsMap.values());
                setOptions(uniqueSuggestions);
        } catch (error) {
        }
    
    };

    if (isLoading) {
        return <Loader />;
      }
      
    return (
        <>
            {isMobile ? (
                <>
                    <AppHdWithReturnLeft>Mes lieux d'intervention</AppHdWithReturnLeft>

                    <p className="width90pCenter txt13 dGreyT">Personnalisez vos lieux d'intervention pour recevoir les meilleurs matchs</p>

                    <div className="width90pCenter">
                    <Autocomplete
                        icon= {<IconSearch />}
                        placeholder= "N° de département, ville ..."
                        withAsterisk
                        value={query}
                        onChange={handleQueryChange}
                        data={options}
                    />

                    </div>
                    <div className="mrgB10"></div>

                    <div className="width90pCenter">
                        <PrestaRectangleButtonMain onClick={handleAddCity} disabled={!query}>
                            Ajouter
                        </PrestaRectangleButtonMain>  
                    </div>
                    {openModalAlreadyAdded  
                        ?   <Modal 
                                centered
                                size="55%"
                                
                                opened={openedModalAlreadyAdded}
                                onClose={closeModalAlreadyAdded}
                                withCloseButton={false}>
                                <p className="useRoboto txt15 txtCenter noMrgTB">
                                    Département déjà enregistré
                                </p>
                            </Modal> 
                        :   ""
                    }        

                <div className="mrgB30"></div>
                {userData.placesOfIntervention.length > 0 
                    ? (
                        <>
                        
                        <p className="mrgL30 txt13 dGreyT">Mes lieux d'intervention enregistrés</p>
                            {userData.placesOfIntervention.map((place, index) => (
                            <div key={index} style={{ margin: '5px' }}>
                                <Box>
                                <div className="horizontal spaceBtw iCenter">
                                    <div>
                                    <p className="mrgL20 noMrgTB">{place}</p>
                                    </div>
                                    <div className="mrgR20">
                                    <IconX width={15} id={place} onClick={handleDeletePlace} />
                                    </div>
                                </div>
                                </Box>
                            </div>
                            ))}
                        </>
                    ) : (
                        <p className="width90pCenter txt13 dGreyT">
                            Vous n'avez pas enregistré de lieu d'intervention
                        </p>
                    )}
                            <div className="mrgB20"></div>
                </>
            ) : (
                <>
                    <AppDesktopLogo/>
                    <AppHdWithReturnLeft>Mes lieux d'intervention</AppHdWithReturnLeft>
                    <div className='width50pCenter'>
                        <p className="txt18 dGreyT">Personnalisez vos lieux d'intervention pour recevoir les meilleurs matchs</p>

                        <div>
                            <Autocomplete
                                icon= {<IconSearch />}
                                placeholder= "N° de département, ville ..."
                                withAsterisk
                                value={query}
                                onChange={handleQueryChange}
                                data={options}
                                size='md'
                            />

                        </div>
                        <div className="mrgB10"></div>

                        <div>
                            <PrestaRectangleButtonMain onClick={handleAddCity} disabled={!query}>
                                Ajouter
                            </PrestaRectangleButtonMain>  
                        </div>
                        
                        {openModalAlreadyAdded  
                            ?   <Modal 
                                    centered
                                    size="auto"
                                    
                                    opened={openedModalAlreadyAdded}
                                    onClose={closeModalAlreadyAdded}
                                    withCloseButton={false}>
                                    <p className="useRoboto txt16 txtCenter noMrgTB">
                                        Département déjà enregistré
                                    </p>
                                </Modal> 
                            :   ""
                        }        

                        <div className="mrgB30"></div>
                        {userData.placesOfIntervention.length > 0 
                            ? (
                                <>
                                    <div>
                                        <p className="txt18 dGreyT">Mes lieux d'intervention enregistrés</p>
                                    
                                    {userData.placesOfIntervention.map((place, index) => (
                                    <div key={index} style={{ marginBottom: '10px' }}>
                                        <Box>
                                            <div className="horizontal spaceBtw iCenter">
                                                <div>
                                                <p className="mrgL20 mrgT5 mrgB5 txt18">{place}</p>
                                                </div>
                                                <div className="mrgR20">
                                                <IconX width={20} id={place} onClick={handleDeletePlace} />
                                                </div>
                                            </div>
                                        </Box>
                                    </div>
                                    ))}
                                    </div>
                                </>
                            ) : (
                                <p className="txt18 dGreyT">
                                    Vous n'avez pas enregistré de lieu d'intervention
                                </p>
                            )}
                            <div className="mrgB100"></div>
                    </div>            
                </>
            )}
        </>
    )
}

export default AddPlaceOfIntervention;


