import { React, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../../../contexts/authContext';
import { useNavigate } from 'react-router-dom';

import { useForm } from '@mantine/form';
import { PasswordInput, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import AppHdWithReturnLeft from '../../../../../composents/Headers/AppHeaders/AppHdWithReturnLeft/AppHdWithReturnLeft';
import PrestaRectangleButtonMain from '../../../../../composents/Buttons/Presta/RectangleButtonMain/RectangleButtonMain';
import RectangleButtonDisabled from '../../../../../composents/Buttons/All/RectangleButtonDisabled/RectangleButtonDisabled';
import AppDesktopLogo from '../../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

function PrestaChangePsw () {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [opened, { open, close }] = useDisclosure(false);
    const [error, setError] = useState()

    const navigate = useNavigate();

    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/p/login");
            return;
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const form = useForm({
        initialValues: {
            password:"",
            newPassword:"",
            newPasswordConfirmation:""
        },
        
        validate: {
            password: (value) => (
                value.length < 1
                  ? 'Renseignez votre mot de passe.'
                  : null
              ),
            newPassword: (value) => (
                value && /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/.test(value)
                    ? null
                    : 'Le mot de passe doit comporter au moins 8 caractères avec une majuscule, une minuscule et un caractère spécial.'
                ),
            newPasswordConfirmation: (value, values) => (
                value.length < 1
                    ? 'Le mot de passe doit être identique.'
                    :  value !== values.newPassword
                    ? 'Le mot de passe doit être identique.'
                    : null
            )   
        }
    })

    const handleFormSubmit = async (values, _event) => {
        _event.preventDefault();
        try {
            const token = localStorage.getItem('token');
            const response = await fetch("https://boldee-97d8d36af3bd.herokuapp.com/p/profile/param/psw-edit", {
                method: "POST",
                body: JSON.stringify({
                    password: values.password,
                    newPassword: values.newPassword
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            if(response.ok) {
                open();
                setTimeout(() => {
                    window.location.reload();
                }, 700)
            } else {
                const errorResponse = await response.json();
                if (errorResponse.error === "Mot de passe erroné.") {
                    setError("Mot de passe erroné.");
                }
            }
        } catch (error) {
    }
    }

    return(
        <>
            {isMobile ? (
                <>
                    <form onSubmit={form.onSubmit(handleFormSubmit)}>
                    
                        <AppHdWithReturnLeft>Modifier mon mot de passe</AppHdWithReturnLeft>

                        <div className="width90pCenter" sx={{ maxWidth: 300 }} mx="auto">
                            <PasswordInput
                                label="Mot de passe"
                                placeholder="********"
                                {...form.getInputProps('password')}
                            />
                            {error && <p className='txt13 rT mrgT5'>{error}</p>}

                            <div className="mrgB5"></div>

                            <PasswordInput
                                label="Nouveau mot de passe"
                                placeholder="********"
                                {...form.getInputProps('newPassword')}
                            />

                            <div className="mrgB5"></div>

                            <PasswordInput
                                label="Confirmation du nouveau mot de passe"
                                placeholder="********"
                                {...form.getInputProps('newPasswordConfirmation')}
                            />
                        </div>

                        <div className="mrgB20"></div>

                        <div className="width90pCenter">
                            {form.isDirty() 
                                ?   <PrestaRectangleButtonMain type="submit">
                                        <p id="prestaChangesPswga4" className='noMrgTB'>Enregistrer</p>
                                    </PrestaRectangleButtonMain>
                                :   <RectangleButtonDisabled>
                                        Enregistrer
                                    </RectangleButtonDisabled>
                            }
                            
                            {open  
                                ?   <Modal 
                                        centered
                                        size="50%"
                                        opened={opened}
                                        onClose={close}
                                        withCloseButton={false}
                                        className='useRoboto'>
                                        <p className="txtCenter noMrgTB txt15">
                                            Nouveau mot de passe enregistré
                                        </p>
                                    </Modal> 
                                :   ""
                            }
                        </div>
                    </form>
                </>
            ) : (
                <>
                    <AppDesktopLogo/>
                    <form onSubmit={form.onSubmit(handleFormSubmit)}>
                    
                        <AppHdWithReturnLeft>Modifier mon mot de passe</AppHdWithReturnLeft>
                        <div className='mrgB30'></div>
                        <div className="width50pCenter" sx={{ maxWidth: 300 }} mx="auto">
                            <PasswordInput
                                label="Mot de passe"
                                placeholder="********"
                                size='md'
                                {...form.getInputProps('password')}
                            />
                            {error && <p className='txt13 rT mrgT5'>{error}</p>}

                            <div className="mrgB10"></div>

                            <PasswordInput
                                label="Nouveau mot de passe"
                                placeholder="********"
                                size='md'
                                {...form.getInputProps('newPassword')}
                            />

                            <div className="mrgB10"></div>

                            <PasswordInput
                                label="Confirmation du nouveau mot de passe"
                                placeholder="********"
                                size='md'
                                {...form.getInputProps('newPasswordConfirmation')}
                            />
                        </div>

                        <div className="mrgB30"></div>

                        <div className="width50pCenter">
                            {form.isDirty() 
                                ?   <PrestaRectangleButtonMain type="submit">
                                        <p id="prestaChangesPswga4" className='txt16 noMrgTB'>Enregistrer</p>
                                    </PrestaRectangleButtonMain>
                                :   <RectangleButtonDisabled>
                                        <p className='txt16'>Enregistrer</p>
                                    </RectangleButtonDisabled>
                            }
                            
                            {open  
                                ?   <Modal 
                                        centered
                                        size="auto"
                                        opened={opened}
                                        onClose={close}
                                        withCloseButton={false}>
                                        <p className="useRoboto txtCenter noMrgTB txt16">
                                            Nouveau mot de passe enregistré
                                        </p>
                                    </Modal> 
                                :   ""
                            }
                        </div>
                    </form>
                </>
            )}
        </>
    )
}

export default PrestaChangePsw;

