import { React, useMemo, useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { AuthContext } from '../../../../../contexts/authContext';
import { useDisclosure } from '@mantine/hooks';
import { Loader, Modal } from '@mantine/core';
import { IconPhone } from '@tabler/icons-react';
import AppHdWithCloseRight from '../../../../../composents/Headers/AppHeaders/AppHdWithCloseRight/AppHdWithCloseRight';
import PrestaRectangleButtonMain from '../../../../../composents/Buttons/Presta/RectangleButtonMain/RectangleButtonMain';
import Box from '../../../../../composents/Boxes/Standard/Box';
import AppDesktopLogo from '../../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

const SyndicInfoById = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [syndicInfo, setsyndicInfo] = useState(null);
    const [openedContactInfo, { open : openContactInfo, close : closeContactInfo}] = useDisclosure(false);

    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/p/login");
            return;
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSyndicInfo = async() => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/p/profile/s-info/${id}`, {
                method: "GET",
                headers: {
                Authorization: `Bearer ${token}`
                }
            });
            const infos = await response.json();
            setsyndicInfo(infos)
        } catch (error) { console.error(error);
      }
    }  

    useMemo(async() => {
        await getSyndicInfo()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const handleCall = (event) => {
        const phoneNumber = event.currentTarget.id
        window.location.href = `tel:${phoneNumber}`
    };

    const showContact = () => {
        openContactInfo()
    };

    return(
        syndicInfo ?
            <>
                {isMobile ? (
                    <>
                        <div>
                            <AppHdWithCloseRight>{syndicInfo.company.name}</AppHdWithCloseRight>
                                <Box>
                                    <div className="horizontal iCenter mrgL20">
                                        <div className="mrgR10 mrgL10">
                                            {syndicInfo.company.logo
                                                ?   <img 
                                                        src={syndicInfo.company.logo}
                                                        alt='logo'
                                                        className='roundImg70'/>
                                                :   <div className="noAvatarProfile">{syndicInfo.company.name.charAt(0)}</div>
                                            }
                                            
                                        </div>
                                        <div className="vertical jCenter mrgL10">
                                            <div><p className="txt15 noMrgT mrgB5 bold capitals lineH15">{syndicInfo.company.name}</p></div>
                                            <div><p className="txt13 noMrgTB">{syndicInfo.company.address.no}, {syndicInfo.company.address.street}</p></div>
                                            <div><p className="txt13 noMrgT mrgB5">{syndicInfo.company.address.postalcode} {syndicInfo.company.address.city}</p></div>
                                            <div><p className="txt13 noMrgTB">{syndicInfo.company.emailcie}</p></div>
                                        </div>
                                    </div>
                                </Box>       
                            

                            <div className="mrgB20"></div>
                            <div className="width90pCenter">
                                <PrestaRectangleButtonMain id={syndicInfo.company.address.phonecie} onClick={handleCall} className='btnReset'>
                                    Contacter
                                </PrestaRectangleButtonMain>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <AppDesktopLogo/>
                        <AppHdWithCloseRight>{syndicInfo.company.name}</AppHdWithCloseRight>
                            <div className='width50pCenter'>
                                <Box>
                                    <div className="horizontal iCenter mrgL20 mrgT20 mrgB20">
                                        <div className="mrgR20 mrgL20">
                                            {syndicInfo.company.logo
                                                ?   <img 
                                                        src={syndicInfo.company.logo}
                                                        alt='logo'
                                                        className='roundImg100'/>
                                                :   <div className="noAvatarProfile">{syndicInfo.company.name.charAt(0)}</div>
                                            }
                                            
                                        </div>
                                        <div className="vertical jCenter mrgL10">
                                            <div><p className="txt18 noMrgT mrgB10 mediumplus capitals lineH15">{syndicInfo.company.name}</p></div>
                                            <div><p className="txt16 noMrgTB">{syndicInfo.company.address.no}, {syndicInfo.company.address.street}</p></div>
                                            <div><p className="txt16 noMrgT mrgB5">{syndicInfo.company.address.postalcode} {syndicInfo.company.address.city}</p></div>
                                            <div><p className="txt16 noMrgTB">{syndicInfo.company.emailcie}</p></div>
                                        </div>
                                    </div>
                                </Box>      
                            
                                <div className="mrgB20"></div>
                                <div >
                                    <PrestaRectangleButtonMain onClick={showContact} className='btnReset'>
                                        <p className='txt16'>Contacter</p>
                                    </PrestaRectangleButtonMain>
                                </div>
                                { openContactInfo 
                                    ?   <Modal 
                                            centered
                                            size="auto"
                                            opened={openedContactInfo}
                                            onClose={closeContactInfo}
                                            title="Contacter">
                                            <div className='mrgR40 mrgL40'>
                                                <p className="useRoboto txtCenter bold txt18 noMrgT mrgB10">
                                                    {syndicInfo.company.name} 
                                                </p>
                                                <p className="horizontal txtCenter iCenter useRoboto txt16 noMrgT mrgB10">
                                                    <div className='noMrgTB mrgR5'><IconPhone width={20} height={20}/></div>
                                                    <p className='noMrgT mrgB5 txt18'>{syndicInfo.company.address.phonecie}</p>
                                                </p>
                                            </div>
                                        </Modal>
                                    :   ""
                                } 
                            </div>
                    </>
                )}
            </>
        : 
        <Loader />
    )  
}

export default SyndicInfoById;

