import React from "react";
import qualipropreLogo from '../../../../assets/images/qualipropre.jpg'
import classes from './LogoQualipropre.module.css';

const LogoQualipropre = (props) => {
    return(
        <div className={classes.Logo}>
            <img src={qualipropreLogo} alt="logo"/>
        </div>
    )
}
export default LogoQualipropre;