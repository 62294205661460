import React from "react";
import qualibatLogo from '../../../../assets/images/qualibat.jpg'
import classes from './LogoQualibat.module.css';

const LogoQualibat = (props) => {
    return(
        <div className={classes.Logo}>
            <img src={qualibatLogo} alt="logo"/>
        </div>
    )
}
export default LogoQualibat;