import {React, useEffect, useContext, useState } from 'react';
import { AuthContext } from "../../../contexts/authContext";
import { TextInput, Box, PasswordInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';

import SyndicRectangleButton from '../../../composents/Buttons/Syndic/RectangleButton/RectangleButtonMain';
import LogoLogin from '../../../composents/Logo/LoginLogo/LogoLogin';


function LoginAdmin() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { handleLoginAdmin, userData, getToken, error } = useContext(AuthContext);
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      email: '',
      password: ''
    },

    validate: {
      email : (value) => (
        value.length < 1
        ? 'Renseignez votre E-mail'
        : /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) 
        ? null 
        : 'E-mail non valide'
      ),      
      password: (value) => (
        value.length < 1
          ? 'Renseignez votre mot de passe.'
          : null
      ),
    }
  });

  useEffect(
    () => {
      window.scrollTo(0, 0);
      const token = getToken();
      if (token) {
        navigate("/adm/menu");
        return;
      };
      const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
      };
    
      window.addEventListener('resize', handleResize);
      return () => {
      window.removeEventListener('resize', handleResize);
      };
      
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userData]
  );

  return (
    <>{isMobile ? (
        <div>
          <div className="width90pCenter txtCenter">
            <div className="mrgT150"></div>
            <div><LogoLogin /></div>
            <p className="txt18 medium mrgT5 terracotaT">admin</p>
          </div>
            <Box className="width90pCenter" sx={{ maxWidth: 300 }} mx="auto">
              
                <form onSubmit={form.onSubmit(handleLoginAdmin)}>
                  <TextInput
                    withAsterisk
                    placeholder="Email"
                    {...form.getInputProps('email')}
                  />
                  <div className="mrgB10"></div>

                  <PasswordInput
                    placeholder='Mot de passe'
                    withAsterisk
                    {...form.getInputProps('password')}
                  />
                  {error && <p className='txt13 rT mrgT5 mrgB5'> {error}</p>}
                  <div className="mrgB10"></div>

                  <SyndicRectangleButton
                    type={"submit"}
                    onClick={() => { }}>
                    Se connecter</SyndicRectangleButton>

                </form>

            </Box>
          <div className="mrgT150"></div>
        </div>
      ) : (
        <div>
          <div className="width90pCenter txtCenter">
            <div className="mrgT150"></div>
            <div><LogoLogin /></div>
            <p className="txt20 medium mrgT5 terracotaT">admin</p>
          </div>
            <Box className="width90pCenter" sx={{ maxWidth: 300 }} mx="auto">
              
                <form onSubmit={form.onSubmit(handleLoginAdmin)}>
                  <TextInput
                    withAsterisk
                    placeholder="Email"
                    size='md'
                    {...form.getInputProps('email')}
                  />
                  <div className="mrgB10"></div>

                  <PasswordInput
                    placeholder='Mot de passe'
                    size='md'
                    withAsterisk
                    {...form.getInputProps('password')}
                  />
                  {error && <p className='txt13 rT mrgT5 mrgB5'> {error}</p>}
                  <div className="mrgB10"></div>

                  <SyndicRectangleButton
                    type={"submit"}
                    onClick={() => { }}>
                    Se connecter</SyndicRectangleButton>

                </form>

            </Box>
          <div className="mrgT150"></div>
        </div>
      )}
    </>
  )

}

export default LoginAdmin;