import classes from './Logout.module.css'

const LogoutButton = (props) => {
    return(
        <button
            className={classes.logout}
            type={props.type || "button"}
            onClick={props.onClick}
            >{props.children}
        </button>
    )
}

export default LogoutButton;