import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDisclosure } from '@mantine/hooks';
import { Modal, Group, Button } from '@mantine/core';

import { IconX, IconChevronLeft } from '@tabler/icons-react';

import classes from './ClosePageOverPictureButtonwithModalForSpeOffer.module.css';

const ClosePageOverPictureButtonModalSpeOffer = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [opened, { close, open }] = useDisclosure(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const HandleOpenModal = () => {
        open();
    }

    const goToOffers = () => {
        navigate('/s/favPresta/fav-list')
    }

    return(
        <>
            {isMobile ? (
                <>
                    <button
                        className={classes.closeBtn}
                        type={props.type || "button"}
                        onClick={props.onClick}
                        >
                            <IconX className={classes.iconX}
                                onClick={HandleOpenModal}/>
                                { open 
                                    ?   <Modal 
                                            centered
                                            withCloseButton={false}    
                                            opened={opened} 
                                            onClose={close}
                                            size="auto">
                                                <Group noWrap mt="md" className="useRoboto txtCenter mrgT5 txt15">
                                                    Abandonner la publication de votre offre ?
                                                </Group>

                                                <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20 w80">
                                                    <Button variant="outline" color="dark" onClick={close} className='useRoboto'>
                                                        Annuler
                                                    </Button>
                                                    <Button variant="filled" color="dark"  onClick={goToOffers} className='useRoboto medium'>
                                                        Abandonner
                                                    </Button>
                                            </Group>       
                                        </Modal> 
                                    :   ""
                                }
                    </button>
                </>
            ) : (
                <>
                    <button
                        className={classes.closeBtn}
                        type={props.type || "button"}
                        onClick={props.onClick}
                        >
                            <div className='horizontal iCenter' onClick={HandleOpenModal}>
                                <div><IconChevronLeft  width={20} className={classes.iconX} /> </div>
                                <div><p className='txt16 medium noMrgTB mrgL5 bT'>Retour</p></div>
                            </div>
                                { open 
                                    ?   <Modal 
                                            centered
                                            withCloseButton={false}    
                                            opened={opened} 
                                            onClose={close}
                                            size="auto">
                                                <Group noWrap mt="md" className="useRoboto txtCenter mrgT5 txt16">
                                                    Abandonner la publication de votre offre ?
                                                </Group>

                                                <Group mt="xl" className="txtCenter horizontal jCenter mrgT20">
                                                    <Button variant="outline" color="dark" onClick={close} className='useRoboto'>
                                                        <p className='txt16'>Annuler</p>
                                                    </Button>
                                                    <Button variant="filled" color="dark"  onClick={goToOffers} className='useRoboto medium'>
                                                    <p className='txt16'>Abandonner</p>
                                                    </Button>
                                            </Group>       
                                        </Modal> 
                                    :   ""
                                }
                    </button>
                </>
            )}
        </>
    )
}
export default ClosePageOverPictureButtonModalSpeOffer;