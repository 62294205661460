import { React, useMemo, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../../contexts/authContext';
import { Link, useNavigate } from 'react-router-dom';

import { IconHammer, IconLocation, IconClock, IconCircleCheck, IconCircleX, IconCircleMinus, IconChevronRight } from '@tabler/icons-react';
import { Accordion, Badge, Loader } from '@mantine/core';
import { parseISO, format } from 'date-fns';
import { subDays } from 'date-fns'

import Box from '../../../../composents/Boxes/Standard/Box'
import AppHdSingleTitle from '../../../../composents/Headers/AppHeaders/AppHdSingleTitle/AppHdSingleTitle';
import AppNavbarPresta from '../../../Navbar/NavbarPresta';

const QuotationDashboard = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [listQuotation, setlistQuotation] = useState();
    const [nbAcceptedQuot, setNbAcceptedQuot] = useState(0);
    const [nbPendingQuot, setNbPendingQuot] = useState(0); 

    const navigate = useNavigate();
    
    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/p/login");
            return;
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getQuotations = async () => { 
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/p/quo/list`, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`
                }
              });
            let list = await response.json();
            setlistQuotation(list)
            
            let filterPendingQuot = list.filter(quotation => quotation.status === 'pending' && quotation.offer.workRangeDates[0] > yesterday)
            let nbfilterPendingQuot = filterPendingQuot.length
            setNbPendingQuot(nbfilterPendingQuot)  
       
            let filterAcceptedQuot = list.filter(quotation => quotation.status === 'acceptedBySyndic')
            let nbfilterAcceptedQuot = filterAcceptedQuot.length
            setNbAcceptedQuot(nbfilterAcceptedQuot)  
        } catch (error) { 
            console.error(error);
      }
    }

    useMemo( async () => {
        await getQuotations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log(`nbA: ${nbAcceptedQuot}`)
        console.log(`nbP: ${nbPendingQuot}`)
      }, [nbAcceptedQuot, nbPendingQuot]);
    
    const openPDF = (event) => {
        let win = window.open()
        win.document.write('<iframe src="' + event.currentTarget.id  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    }

   const subOneDayOfToday = subDays(new Date(),1);
   const yesterday = subOneDayOfToday.toISOString();

    return(
        listQuotation ?
            <>
                {isMobile ? (
                    <>
                        <AppNavbarPresta></AppNavbarPresta>
                        <AppHdSingleTitle>Mes devis</AppHdSingleTitle>
                            <div className='mrgB10'></div>

                            {/* pending */}
                            <Accordion variant="default" radius="xs" defaultValue="awaiting">
                                <Accordion.Item value="awaiting">
                                <Accordion.Control icon={<IconClock  size={20} className="terracotaT" />}>
                                    <p className="useRoboto noMrgT terracotaT bold mrgB7">Devis en attente ({nbPendingQuot})</p></Accordion.Control>
                                    {listQuotation.map((quotation, index) => (
                                        <div key = {index} style = {{margin : '10px'}}>
                                            {quotation.status === 'pending' && quotation.offer.workRangeDates[0] > yesterday                                     
                                                ?   <Accordion.Panel>
                                                        <Link to={`/p/quo/edit/${quotation._id}`} className="aReset bT">
                                                            <Box>
                                                                <div className="horizontal iCenter useRoboto">
                                                                    <div className="vertical mrgL20 mrgR20">
                                                                        {quotation.offer.pictures.length > 0
                                                                            ?    <img 
                                                                                    className="roundImg70"
                                                                                    src={quotation.offer.pictures[0]}
                                                                                    alt='photo_chantier'/> 
                                                                            :   <>
                                                                                    {quotation.offer.author.company.logo
                                                                                        ?   <img 
                                                                                                className="roundImg70"
                                                                                                src={quotation.offer.author.company.logo}
                                                                                                alt='photo_chantier'/>
                                                                                        :   <div className="noAvatarProfile">{quotation.offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                                                    }
                                                                                </>
                                                                        } 
                                                                    </div>
                                                                    
                                                                    <div className="vertical jCenter">
                                                                        <div className='horizontal iBtm'>
                                                                                <div>
                                                                                    <p className="txt11 noMrgTB lineH10">{format(parseISO(quotation.sendDate), 'dd/MM/yy')}</p>
                                                                                </div>
                                                                                <div className='mrgR5'></div>
                                                                                <div>
                                                                                    {quotation.offer.urgent 
                                                                                        ?   <Badge color="red">Urgent</Badge> 
                                                                                        :   ""
                                                                                    }
                                                                                </div>

                                                                                <div className='mrgR5'></div>
                                                                                
                                                                                <div>
                                                                                    {quotation.viewedBySyndic 
                                                                                        ?   <Badge color="green">Vu</Badge>
                                                                                        :   ""
                                                                                    }
                                                                                </div>
                                                                        </div>
                                                                            <div>
                                                                                <p className="txt15 mediumplus noMrgTB capitals">{quotation.offer.author.company.name}</p>
                                                                            </div>

                                                                            <div className='lineH15'>
                                                                                <div className="vertical iStart">
                                                                                    <div className='horizontal'>
                                                                                        <IconHammer width={13} height={13} className="mrgR5"/>
                                                                                        <p className="noMrgTB txt13 mrgR5 finalDotsMinus">{quotation.offer.occupation}</p>
                                                                                    </div>
                                                                                    <div className='horizontal'>
                                                                                        <IconLocation width={13} height={13} className="mrgR5"/>
                                                                                    <p className="noMrgTB txt13 mrgR5 finalDotsMinus">{quotation.offer.cityOfIntervention}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mrgB5"></div>
                                                                            
                                                                    </div>
                                                                    <div className="mrgLauto mrgR10 horizontal iCenter">
                                                                        <IconChevronRight width={16}/>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </Box>
                                                        </Link>    
                                                    </Accordion.Panel>
                                                :   ""
                                            }
                                        </div>
                                    ))}
                                </Accordion.Item>
                            </Accordion>

                            {/* accepted */}
                            <Accordion variant="default" radius="xs" defaultValue="accepted">
                                <Accordion.Item value="accepted">
                                <Accordion.Control icon={<IconCircleCheck  size={20} className="mrgT5 greenT" />}>
                                    <p className="roboto mrgT10 greenT bold ">Devis acceptés ({nbAcceptedQuot})</p></Accordion.Control>
                                    {listQuotation.map((quotation, index) => (
                                        <div key = {index} style = {{margin : '15px'}}>
                                        {quotation.status === 'acceptedBySyndic' 
                                            ?   <Accordion.Panel>
                                                    <Link to={`/p/quo/${quotation._id}`} className="aReset bT">
                                                        <Box>
                                                            <div className="horizontal iCenter useRoboto">
                                                                <div className="vertical iCenter mrgL20 mrgR20">
                                                                    {quotation.offer.pictures.length > 0
                                                                        ?    <img 
                                                                                className="roundImg70"
                                                                                src={quotation.offer.pictures[0]}
                                                                                alt='photo_chantier'/> 
                                                                        :   <>
                                                                                {quotation.offer.author.company.logo
                                                                                    ?   <img 
                                                                                            className="roundImg70"
                                                                                            src={quotation.offer.author.company.logo}
                                                                                            alt='photo_chantier'/>
                                                                                    :   <div className="noAvatarProfile">{quotation.offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                                                }
                                                                            </>
                                                                    }  
                                                                </div>
                                                            
                                                                <div className="vertical jCenter">
                                                                    <div className='horizontal iBtm'>
                                                                        <p className="txt11 noMrgTB lineH10">{format(parseISO(quotation.sendDate), 'dd/MM/yy')}</p>
                                                                    </div> 
                                                                    <div>
                                                                        <p className="txt15 mediumplus noMrgTB capitals">{quotation.offer.author.company.name}</p>
                                                                    </div>

                                                                    <div className="lineH15">
                                                                        <div className="vertical iStart">
                                                                            <div className='horizontal'>
                                                                                <IconHammer width={13} height={13} className="mrgR5 "/>
                                                                                <p className="noMrgTB txt13 mrgR5 finalDotsMinus">{quotation.offer.occupation}</p>
                                                                            </div>
                                                                            <div className='horizontal'>
                                                                                <IconLocation width={13} height={13} className="mrgR5 dGreyT"/>
                                                                                <p className="noMrgTB txt13 mrgR5 finalDotsMinus">{quotation.offer.cityOfIntervention}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mrgB5"></div>
                                                                </div>

                                                                <div className="mrgLauto mrgR10 horizontal iCenter">
                                                                    <IconChevronRight width={16}/>
                                                                </div>
                                                            </div>
                                                        </Box>
                                                    </Link>    
                                                </Accordion.Panel>
                                            :   ""
                                        }
                                        </div>
                                    ))}
                                </Accordion.Item>
                            </Accordion>

                            {/* refused */}               
                            <Accordion variant="default" radius="xs">
                                <Accordion.Item value="refused">
                                    <Accordion.Control icon={<IconCircleX  size={20} className="bT mrgT10" />}>
                                        <p className="roboto mrgT10 bT bold mrgB7">Devis refusés</p></Accordion.Control>
                                        {listQuotation.map((quotation, index) => (
                                        <div key = {index} style = {{margin : '15px'}}>
                                            {quotation.status === 'refusedBySyndic' && quotation.offer.workRangeDates[0] > yesterday 
                                                ?   <Accordion.Panel>
                                                    {/* {quotation._id} */}
                                                        <div id={quotation.downloadLink} onClick={openPDF} className='aReset bT'>
                                                            <Box>
                                                                <div className="horizontal iCenter useRoboto">
                                                                    <div className="vertical iCenter mrgL20 mrgR20">                         
                                                                        <div>
                                                                            {quotation.offer.pictures.length > 0
                                                                                ?    <img 
                                                                                        className="roundImg70"
                                                                                        src={quotation.offer.pictures[0]}
                                                                                        alt='photo_chantier'/> 
                                                                                :   <>
                                                                                        {quotation.offer.author.company.logo
                                                                                            ?   <img 
                                                                                                    className="roundImg70"
                                                                                                    src={quotation.offer.author.company.logo}
                                                                                                    alt='photo_chantier'/>
                                                                                            :   <div className="noAvatarProfile">{quotation.offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                                                        }
                                                                                    </>
                                                                            }   
                                                                        </div>
                                                                    </div>
                                                                    <div>

                                                                    <div>
                                                                        <p className="txt11 noMrgTB lineH10">{format(parseISO(quotation.sendDate), 'dd/MM/yy')}</p>
                                                                    </div>
                                                                    <div>
                                                                        <p className="txt15 mediumplus noMrgTB capitals">{quotation.offer.author.company.name}</p>
                                                                    </div>
                                                                    <div className="lineH15">
                                                                        <div className="vertical iStart">
                                                                            <div className='horizontal'>
                                                                                <IconHammer width={13} height={13} className="mrgR5"/>
                                                                                <p className="noMrgTB txt13 mrgR5 finalDotsMinus">{quotation.offer.occupation}</p>
                                                                            </div>
                                                                            <div className='horizontal'>                
                                                                                <IconLocation width={13} height={13} className="mrgR5"/>
                                                                                <p className="noMrgTB txt13 finalDotsMinus mrgR5">{quotation.offer.cityOfIntervention}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mrgB5"></div>
                                                                            
                                                                    </div>
                                                                    <div className="mrgLauto mrgR10 horizontal iCenter">
                                                                        <IconChevronRight width={16}/>
                                                                    </div>
                                                                </div>
                                                            </Box>
                                                        </div>    
                                                    </Accordion.Panel>
                                                :   ""
                                            }
                                        </div>
                                        ))} 
                                </Accordion.Item>      
                            </Accordion>

                            {/* archived */}                     
                            <Accordion variant="default" radius="xs">
                                <Accordion.Item value="deleted">
                                    <Accordion.Control icon={<IconCircleMinus  size={20} className="dGreyT mrgT10" />}>
                                        <p className="roboto mrgT10 dGreyT bold mrgB7">Devis archivés</p></Accordion.Control>
                                        {listQuotation.map((quotation, index) => (
                                        <div key = {index} style = {{margin : '15px'}}>
                                            {quotation.status === 'archivedByPresta' 
                                                ?   <Accordion.Panel>
                                                        <div id={quotation.downloadLink} onClick={openPDF} className='aReset bT'>
                                                            <Box>
                                                                <div className="horizontal iCenter useRoboto">
                                                                    <div className="vertical iCenter mrgL20 mrgR20">                         
                                                                        <div>
                                                                            {quotation.offer.pictures.length > 0
                                                                                ?    <img 
                                                                                        className="roundImg70"
                                                                                        src={quotation.offer.pictures[0]}
                                                                                        alt='photo_chantier'/> 
                                                                                :   <>
                                                                                        {quotation.offer.author.company.logo
                                                                                            ?   <img 
                                                                                                    className="roundImg70"
                                                                                                    src={quotation.offer.author.company.logo}
                                                                                                    alt='photo_chantier'/>
                                                                                            :   <div className="noAvatarProfile">{quotation.offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                                                        }
                                                                                    </>
                                                                            }   
                                                                        </div>
                                                                    </div>
                                                                <div>    
                                                                    <div>
                                                                        <p className="txt11 dGreyT noMrgTB lineH10">{format(parseISO(quotation.sendDate), 'dd/MM/yy')}</p>
                                                                    </div>
                                                                    <div>
                                                                        <p className="txt15 dGreyT mediumplus noMrgTB capitals">{quotation.offer.author.company.name}</p>
                                                                    </div>
                                                                    <div className="lineH15">
                                                                        <div className="vertical iStart">
                                                                            <div className='horizontal'>
                                                                                <IconHammer width={13} height={13} className="mrgR5 dGreyT"/>
                                                                                <p className="noMrgTB dGreyT txt13 mrgR5 finalDotsMinus">{quotation.offer.occupation}</p>
                                                                            </div>
                                                                            
                                                                            <div className='horizontal'>
                                                                                <IconLocation width={13} height={13} className="mrgR5 dGreyT"/>
                                                                                <p className="noMrgTB dGreyT txt13 finalDotsMinus mrgR5">{quotation.offer.cityOfIntervention}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                    <div className="mrgLauto mrgR10 horizontal iCenter">
                                                                        <IconChevronRight width={16}/>
                                                                    </div>
                                                            </div>
                                                            </Box>
                                                        </div>    
                                                    </Accordion.Panel>
                                                :   ""
                                            }
                                        </div>
                                        ))} 
                                </Accordion.Item>      
                            </Accordion>

                        <div id="mrgB100"></div>
                    </>
                ) : (
                    <>
                        <AppNavbarPresta></AppNavbarPresta>
                        <AppHdSingleTitle>Mes devis</AppHdSingleTitle>
                            <div className='mrgB30'></div>
                            <div className='width50pCenter'>
                                {/* pending */}
                                <Accordion variant="default" radius="xs" defaultValue="awaiting">
                                    <Accordion.Item value="awaiting">
                                    <Accordion.Control icon={<IconClock  size={23} className="mrgT5 terracotaT" />}>
                                        <p className="useRoboto mrgT20 terracotaT bold mrgB20 txt18">Devis en attente ({nbPendingQuot})</p></Accordion.Control>
                                        {listQuotation.map((quotation, index) => (
                                            <div key = {index} style = {{margin : '10px'}}>
                                                {quotation.status === 'pending' && quotation.offer.workRangeDates[0] > yesterday                                     
                                                    ?   <Accordion.Panel>
                                                            <Link to={`/p/quo/edit/${quotation._id}`} className="aReset bT">
                                                                <Box>
                                                                    <div className="horizontal iCenter useRoboto mrgT10 mrgB10">
                                                                        <div className="vertical mrgL20 mrgR20">
                                                                            {quotation.offer.pictures.length > 0
                                                                                ?    <img 
                                                                                        className="roundImg100"
                                                                                        src={quotation.offer.pictures[0]}
                                                                                        alt='photo_chantier'/> 
                                                                                :   <>
                                                                                        {quotation.offer.author.company.logo
                                                                                            ?   <img 
                                                                                                    className="roundImg100"
                                                                                                    src={quotation.offer.author.company.logo}
                                                                                                    alt='photo_chantier'/>
                                                                                            :   <div className="noAvatarProfile">{quotation.offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                                                        }
                                                                                    </>
                                                                            } 
                                                                        </div>
                                                                        
                                                                        <div className="vertical jCenter">
                                                                            <div className='horizontal iBtm'>
                                                                                    <div>
                                                                                        <p className="txt15 noMrgTB">{format(parseISO(quotation.sendDate), 'dd/MM/yy')}</p>
                                                                                    </div>
                                                                                    <div className='mrgR10'></div>
                                                                                    <div>
                                                                                        {quotation.offer.urgent 
                                                                                            ?   <Badge size="lg" color="red">Urgent</Badge> 
                                                                                            :   ""
                                                                                        }
                                                                                    </div>

                                                                                    <div className='mrgR10'></div>
                                                                                    
                                                                                    <div>
                                                                                        {quotation.viewedBySyndic 
                                                                                            ?   <Badge size="lg" color="green">Vu</Badge>
                                                                                            :   ""
                                                                                        }
                                                                                    </div>
                                                                            </div>
                                                                                <div>
                                                                                    <p className="txt18 medium noMrgTB capitals">{quotation.offer.author.company.name}</p>
                                                                                </div>

                                                                                <div className='lineH15'>
                                                                                    <div className="vertical iStart">
                                                                                        <div className='horizontal iCenter mrgB5'>
                                                                                            <IconHammer width={20} height={20} className="mrgR10"/>
                                                                                            <p className="noMrgTB txt16 mrgR5">{quotation.offer.occupation}</p>
                                                                                        </div>
                                                                                        <div className='horizontal iCenter mrgB5'>
                                                                                            <IconLocation width={20} height={20} className="mrgR10"/>
                                                                                        <p className="noMrgTB txt16 mrgR5">{quotation.offer.cityOfIntervention}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                        </div>
                                                                        <div className="mrgLauto mrgR20 horizontal iCenter">
                                                                            <IconChevronRight width={23}/>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </Box>
                                                            </Link>    
                                                        </Accordion.Panel>
                                                    :   ""
                                                }
                                            </div>
                                        ))}
                                    </Accordion.Item>
                                </Accordion>

                                {/* accepted */}
                                <Accordion variant="default" radius="xs" defaultValue="accepted">
                                    <Accordion.Item value="accepted">
                                    <Accordion.Control icon={<IconCircleCheck  size={23} className="mrgT10 greenT" />}>
                                        <p className="useRoboto mrgT20 greenT bold txt18">Devis acceptés ({nbAcceptedQuot})</p></Accordion.Control>
                                        {listQuotation.map((quotation, index) => (
                                            <div key = {index} style = {{margin : '10px'}}>
                                            {quotation.status === 'acceptedBySyndic' 
                                                ?   <Accordion.Panel>
                                                        <Link to={`/p/quo/${quotation._id}`} className="aReset bT">
                                                            <Box>
                                                                <div className="horizontal iCenter useRoboto mrgT10 mrgB10">
                                                                    <div className="vertical iCenter mrgL20 mrgR20">
                                                                        {quotation.offer.pictures.length > 0
                                                                            ?    <img 
                                                                                    className="roundImg100"
                                                                                    src={quotation.offer.pictures[0]}
                                                                                    alt='photo_chantier'/> 
                                                                            :   <>
                                                                                    {quotation.offer.author.company.logo
                                                                                        ?   <img 
                                                                                                className="roundImg100"
                                                                                                src={quotation.offer.author.company.logo}
                                                                                                alt='photo_chantier'/>
                                                                                        :   <div className="noAvatarProfile">{quotation.offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                                                    }
                                                                                </>
                                                                        }  
                                                                    </div>
                                                                
                                                                    <div className="vertical jCenter">
                                                                        <div className='horizontal iBtm'>
                                                                            <p className="txt15 noMrgTB">{format(parseISO(quotation.sendDate), 'dd/MM/yy')}</p>
                                                                        </div>
                                                                        <div>
                                                                            <p className="txt18 medium noMrgTB capitals">{quotation.offer.author.company.name}</p>
                                                                        </div>

                                                                        <div className="lineH15">
                                                                            <div className="vertical iStart">
                                                                                <div className='horizontal iCenter mrgB5'>
                                                                                    <IconHammer width={20} height={20} className="mrgR10 "/>
                                                                                    <p className="noMrgTB txt16 mrgR5">{quotation.offer.occupation}</p>
                                                                                </div>
                                                                                <div className='horizontal iCenter mrgB5'>
                                                                                    <IconLocation width={20} height={20} className="mrgR10"/>
                                                                                    <p className="noMrgTB txt16 mrgR5">{quotation.offer.cityOfIntervention}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mrgLauto mrgR20 horizontal iCenter">
                                                                        <IconChevronRight width={23}/>
                                                                    </div>
                                                                </div>
                                                            </Box>
                                                        </Link>    
                                                    </Accordion.Panel>
                                                :   ""
                                            }
                                            </div>
                                        ))}
                                    </Accordion.Item>
                                </Accordion>

                                {/* refused */}               
                                <Accordion variant="default" radius="xs">
                                    <Accordion.Item value="refused">
                                        <Accordion.Control icon={<IconCircleX  size={23} className="bT mrgT10" />}>
                                            <p className="useRoboto mrgT20 bT bold txt18">Devis refusés</p></Accordion.Control>
                                            {listQuotation.map((quotation, index) => (
                                            <div key = {index} style = {{margin : '10px'}}>
                                                {quotation.status === 'refusedBySyndic' && quotation.offer.workRangeDates[0] > yesterday 
                                                    ?   <Accordion.Panel>
                                                            <div id={quotation.downloadLink} onClick={openPDF} className='aReset bT'>
                                                                <Box>
                                                                    <div className="horizontal iCenter useRoboto mrgT10 mrgB10">
                                                                        <div className="vertical iCenter mrgL20 mrgR20">                         
                                                                            <div>
                                                                                {quotation.offer.pictures.length > 0
                                                                                    ?    <img 
                                                                                            className="roundImg100"
                                                                                            src={quotation.offer.pictures[0]}
                                                                                            alt='photo_chantier'/> 
                                                                                    :   <>
                                                                                            {quotation.offer.author.company.logo
                                                                                                ?   <img 
                                                                                                        className="roundImg100"
                                                                                                        src={quotation.offer.author.company.logo}
                                                                                                        alt='photo_chantier'/>
                                                                                                :   <div className="noAvatarProfile">{quotation.offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                                                            }
                                                                                        </>
                                                                                }   
                                                                            </div>
                                                                        </div>
                                                                        <div>

                                                                        <div>
                                                                            <p className="txt15 noMrgTB">{format(parseISO(quotation.sendDate), 'dd/MM/yy')}</p>
                                                                        </div>
                                                                        <div>
                                                                            <p className="txt18 medium noMrgTB capitals">{quotation.offer.author.company.name}</p>
                                                                        </div>
                                                                        <div className="lineH15">
                                                                            <div className="vertical iStart">
                                                                                <div className='horizontal iCenter mrgB5'>
                                                                                    <IconHammer width={20} height={20} className="mrgR10"/>
                                                                                    <p className="noMrgTB txt16 mrgR5">{quotation.offer.occupation}</p>
                                                                                </div>
                                                                                <div className='horizontal iCenter mrgB5'>                
                                                                                    <IconLocation width={20} height={20} className="mrgR10"/>
                                                                                    <p className="noMrgTB txt16 mrgR5">{quotation.offer.cityOfIntervention}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>                                                                                
                                                                        </div>
                                                                        <div className="mrgLauto mrgR20 horizontal iCenter">
                                                                            <IconChevronRight width={23}/>
                                                                        </div>
                                                                    </div>
                                                                </Box>
                                                            </div>    
                                                        </Accordion.Panel>
                                                    :   ""
                                                }
                                            </div>
                                            ))} 
                                    </Accordion.Item>      
                                </Accordion>

                                {/* archived */}                     
                                <Accordion variant="default" radius="xs">
                                    <Accordion.Item value="deleted">
                                        <Accordion.Control icon={<IconCircleMinus  size={23} className="dGreyT mrgT10" />}>
                                            <p className="useRoboto mrgT20 dGreyT bold txt18">Devis archivés</p></Accordion.Control>
                                            {listQuotation.map((quotation, index) => (
                                            <div key = {index} style = {{margin : '10px'}}>
                                                {quotation.status === 'archivedByPresta' 
                                                    ?   <Accordion.Panel>
                                                            <div id={quotation.downloadLink} onClick={openPDF} className='aReset bT'>
                                                                <Box>
                                                                    <div className="horizontal iCenter useRoboto mrgT10 mrgB10">
                                                                        <div className="vertical iCenter mrgL20 mrgR20">                         
                                                                                {quotation.offer.pictures.length > 0
                                                                                    ?    <img 
                                                                                            className="roundImg100"
                                                                                            src={quotation.offer.pictures[0]}
                                                                                            alt='photo_chantier'/> 
                                                                                    :   <>
                                                                                            {quotation.offer.author.company.logo
                                                                                                ?   <img 
                                                                                                        className="roundImg100"
                                                                                                        src={quotation.offer.author.company.logo}
                                                                                                        alt='photo_chantier'/>
                                                                                                :   <div className="noAvatarProfile">{quotation.offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                                                            }
                                                                                        </>
                                                                                }   
                                                                        </div>
                                                                    <div className="vertical jCenter">    
                                                                        <div className='horizontal iBtm'>
                                                                            <p className="txt15 dGreyTB">{format(parseISO(quotation.sendDate), 'dd/MM/yy')}</p>
                                                                        </div>
                                                                        <div>
                                                                            <p className="txt18 medium noMrgTB dGreyT capitals">{quotation.offer.author.company.name}</p>
                                                                        </div>
                                                                        <div className="lineH15">
                                                                            <div className="vertical iStart">
                                                                                <div className='horizontal iCenter mrgB5'>
                                                                                    <IconHammer width={20} height={20} className="mrgR10 dGreyT"/>
                                                                                    <p className="dGreyT noMrgTB txt16 mrgR5">{quotation.offer.occupation}</p>
                                                                                </div>
                                                                                
                                                                                <div className='horizontal iCenter mrgB5'>
                                                                                    <IconLocation width={20} height={20} className="mrgR10 dGreyT"/>
                                                                                    <p className="dGreyT noMrgTB txt16 mrgR5">{quotation.offer.cityOfIntervention}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                        <div className="mrgLauto mrgR20 horizontal iCenter">
                                                                            <IconChevronRight width={23}/>
                                                                        </div>
                                                                </div>
                                                                </Box>
                                                            </div>    
                                                        </Accordion.Panel>
                                                    :   ""
                                                }
                                            </div>
                                            ))} 
                                    </Accordion.Item>      
                                </Accordion>
                            </div>
                        <div id="mrgB100"></div>
                    </>
                )}
            </>
        : 
        <Loader />
    )
}

export default QuotationDashboard;