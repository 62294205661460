import { React, useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../../../../../contexts/authContext';
import { Link, useParams, useNavigate } from "react-router-dom";

import { format, parseISO } from 'date-fns';
import { subDays } from 'date-fns';
import { useDisclosure } from '@mantine/hooks';
import { Accordion, Loader, Modal } from '@mantine/core';
import { IconX, IconClock, IconChevronRight, IconCalendarEvent, IconPhone } from '@tabler/icons-react';

const ReceivedRDVDemandList = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [listOpenRdv, setlistOpenRdv] = useState(null);
    const [listRdv, setlistRdv ] = useState(null);
    const [offerStatus, setOfferStatus] = useState(null);
    const [openedContactInfo, { open : openContactInfo, close : closeContactInfo}] = useDisclosure(false);

    let {id} = useParams()

    const navigate = useNavigate();

    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/s/login");
            return;
        }
        getFetchData();
        getOfferStatus();

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
            };
            window.addEventListener('resize', handleResize);
            return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const getOfferStatus = async () => {
        try { 
            const token = localStorage.getItem('token');
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/offers/offer/status/${id}`, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`
                }
              });
            const data = response.json();
            data.then((result) => {
                setOfferStatus(result)
              });            
        } catch (error) { 
            console.error(error);
            return null;
        }
    }

    async function getFetchData() {
        try{
            const [listOfOpenRdv, listOfRdv ] = await Promise.all([getOpenRdvs(), getRdvs()]);
            setlistOpenRdv(listOfOpenRdv);
            setlistRdv(listOfRdv);
        } catch (error) {
        }
    }

    const getOpenRdvs = async () => {
        try { 
            const token = localStorage.getItem('token');
            const list = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/offers/offer/rdvO/${id}`, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`
                }
              });
            return list.json();
        } catch (error) { 
            console.error(error);
            return null;
        }
    }

    const getRdvs = async () => {
        try { 
            const token = localStorage.getItem('token');
            const list = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/offers/offer/rdv/${id}`, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`
                }
              });
            return list.json();
        } catch (error) { 
            console.error(error);
            return null;
        }
    }

    const handleDeleteRdv = async (event) => {
        const rdvDemandId = event.currentTarget.id;
        
        try {
            const token = localStorage.getItem('token');
            await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/offers/offer/delete-rdv/${rdvDemandId}`, {
                method: "POST",
                body: JSON.stringify({
                    id: id,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            await getFetchData()
         } catch (error) {
        }
   }

   const subOneDayOfToday = subDays(new Date(),1)
   const yesterday = subOneDayOfToday.toISOString()

   const handleCall = (event) => {
        const phoneNumber = event.currentTarget.id
        window.location.href = `tel:${phoneNumber}`
    }

    const showContact = () => {
        openContactInfo()
    }
   
   return (
    listOpenRdv && listRdv ?
        <>
            {offerStatus
                ?   <>
                        {isMobile ? (
                            <>
                                <div className="width95pCenter">                    
                                    <Accordion defaultValue="rdvDemand">
                                        <Accordion.Item value="rdvDemand">
                                            <Accordion.Control>
                                                <p className="useRoboto bold mrgB20">Demandes de RDV</p>
                                            </Accordion.Control>
                                            
                                                {listOpenRdv.length > 0 
                                                    ?   <>
                                                            <Accordion.Panel>
                                                                {listOpenRdv.map((rdv, index) => (
                                                                    <div key = {index} style = {{margin : '7px'}}>
                                                                            {rdv.offer === id
                                                                                ? <>
                                                                                    {rdv.status === 'open' 
                                                                                        ?   <div className ="bLiteGrey">
                                                                                                <div className="vertical mrgL5">
                                                                                                    <div className="horizontal mrgR10 spaceBtw iCenter">
                                                                                                        <p className="txt13 mrgL15 mrgT10 mrgB5">{format(parseISO(rdv.sendDate), 'dd/MM/yy')}</p>
                                                                                                        <IconX 
                                                                                                            className='mrgT10 mrgR5' 
                                                                                                            width={15}
                                                                                                            id={rdv._id}
                                                                                                            onClick={handleDeleteRdv}    
                                                                                                            />
                                                                                                    </div>

                                                                                                    <div className="horizontal iCenter mrgL10">                           
                                                                                                        <div className='mrgB5'>
                                                                                                            {rdv.applicant.company.logo
                                                                                                                ?   <img 
                                                                                                                        className='roundImg70'
                                                                                                                        src={rdv.applicant.company.logo}
                                                                                                                        alt='logo'/>
                                                                                                                :   <div className="noAvatarProfile">{rdv.applicant.company.name.charAt(0)}</div>
                                                                                                            }  
                                                                                                        </div>
                                                                                                        
                                                                                                        <div className='vertical mrgL10'>
                                                                                                            <Link to={`/s/offers/offer/rdv/company-page/${rdv.applicant.company._id}`} className="aReset bT">
                                                                                                                <div className="horizontal">
                                                                                                                    <h1 className="useRoboto txt15 noMrgTB lineH15">{rdv.applicant.title} {rdv.applicant.firstname} {rdv.applicant.lastname}</h1>
                                                                                                                </div>
                                                                                                                <div className="horizontal">
                                                                                                                    <h1 className="useRoboto txt13 noMrgTB capitals medium">{rdv.applicant.company.name}</h1>
                                                                                                                </div>
                                                                                                            </Link>
                                                                                                            <p className="useRoboto txt13 noMrgTB finalDotsMinus">{rdv.applicant.occupation.join(' - ')}</p>
                                                                                                        </div>                        
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="mrgB5"></div>

                                                                                                <div className="horizontal jCenter iCenter">
                                                                                                
                                                                                                        <button className="btn-action-for-quot bDarkGrey mrgR10" id={rdv.applicant.phone} onClick={handleCall}>
                                                                                                            Contacter
                                                                                                        </button>
                                                                                                    
                                                                                                    <div className="mrgB5"></div>  
                                                                                                        <button className="btn-action-for-quot bBlack">
                                                                                                        <Link   to={`/s/offers/offer/rdv-info-send-form/${rdv._id}`}
                                                                                                                // state={{ rdvId: rdv._id }}
                                                                                                                className='wT aReset'>
                                                                                                            Envoyer infos RDV
                                                                                                            </Link>
                                                                                                        </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        :   ""
                                                                                        
                                                                                    }
                                                                                </>
                                                                                : ""
                                                                            }
                                                                                
                                                                    </div>
                                                                ))}
                                                            </Accordion.Panel> 
                                                        </>
                                                    :   <p className='txt13 dGreyT mrgL20 mrgT5'>Vous n'avez pas de demande de RDV</p>
                                                }
                                            
                                        </Accordion.Item>
                                    </Accordion>

                                    <div className="mrgB5"></div>
                                                
                                    <Accordion defaultValue="rdvToCome">
                                        <Accordion.Item value="rdvToCome">
                                            <Accordion.Control>
                                                <p className="useRoboto bold mrgB20">RDV à venir</p>
                                            </Accordion.Control>
                                            {listRdv.length > 0 && listRdv.some(rdv => rdv.status === 'taken')
                                                ?   <>
                                                        <Accordion.Panel>
                                                            {listRdv.map((rdv, index) => (
                                                                <div key = {index} style = {{margin : '4px'}}>
                                                                    {rdv.offer === id
                                                                        ?   <>
                                                                                {rdv.dateOfAppointment && rdv.status === 'taken' && rdv.dateOfAppointment > yesterday
                                                                                    ?   <div className='bLiteGrey'>
                                                                                            <div className ="vertical mrgL5">
                                                                                                <Link to={`/s/offers/offer/details-rdv/${rdv._id}`} className="aReset bT">
                                                                                                    <div className="horizontal iCenter spaceBtw mrgL5">
                                                                                                        <div className="horizontal iCenter mrgL10">
                                                                                                            <IconCalendarEvent  width={15} className="mrgT5" />
                                                                                                            <h2 className="txt15 mrgL5 mrgT10 mrgB5">{format(new Date(rdv.dateOfAppointment), 'dd/MM/yy')}</h2>
                                                                                                        </div>

                                                                                                        <div className="horizontal iCenter mrgR5">
                                                                                                            <IconClock width={15} className="mrgR5 mrgT5"/>
                                                                                                            <h2 className="txt15 mrgR10 mrgT10 mrgB5">{format(new Date(rdv.timeOfAppointment), 'HH:mm')}</h2>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="horizontal iCenter spaceBtw">
                                                                                                            <div className="horizontal iCenter mrgL10 mrgB5">
                                                                                                                <div className='mrgB5'>
                                                                                                                    {rdv.applicant.company.logo
                                                                                                                        ?   <img 
                                                                                                                                className='roundImg70'
                                                                                                                                src={rdv.applicant.company.logo}
                                                                                                                                alt='logo'/>
                                                                                                                        :   <div className="noAvatarProfile">{rdv.applicant.company.name.charAt(0)}</div>
                                                                                                                    }  
                                                                                                                </div>
                                                                                                                
                                                                                                                <div className="mrgL10" >
                                                                                                                    <p className="txt15 bold noMrgTB lineH15">{rdv.applicant.title} {rdv.applicant.firstname} {rdv.applicant.lastname}</p>
                                                                                                                    <p className="txt13 bold noMrgTB capitals medium">{rdv.applicant.company.name}</p>
                                                                                                                    <p className="txt13 noMrgTB finalDotsMinus">{rdv.applicant.occupation.join(' - ')}</p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        <div>
                                                                                                            <IconChevronRight width={16} className='mrgR10'/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    :   ""
                                                                                }
                                                                            </>
                                                                        :   ""
                                                                    }
                                                                
                                                                </div>
                                                            ))}
                                                        </Accordion.Panel>
                                                    </>
                                                
                                                :   <p className='txt13 dGreyT mrgL20 mrgT5'>Vous n'avez pas de RDV à venir</p>
                                                }
                                        </Accordion.Item>
                                    </Accordion>
                                
                                    <div className="mrgB5"></div>
                                    
                                    <Accordion>
                                        <Accordion.Item value="deletedRDV">
                                            <Accordion.Control>
                                                <p className="useRoboto bold mrgB20">RDV supprimés</p>
                                            </Accordion.Control>
                                            {listRdv.length > 0  && listRdv.some(rdv => rdv.status === 'close')
                                                ?   <>
                                                        <Accordion.Panel>
                                                            {listRdv.map((rdv, index) => (
                                                                <div key = {index} style = {{margin : '5px'}}>
                                                                    {rdv.offer === id
                                                                        ?   <>
                                                                                {rdv.status === 'close' 
                                                                                    ?   
                                                                                        <div className='bLiteGrey horizontal iCenter spaceBtw'>
                                                                                            <div><p className="txt13 mrgT5 mrgB5 mrgL10">{rdv.applicant.company.name}</p></div>
                                                                                            <div><p className="txt13 mrgT5 mrgB5 mrgR10">Supprimé le {format(parseISO(rdv.sendDate), 'dd/MM/yy')}</p></div>
                                                                                        </div>
                                                                                            
                                                                                    :   ""
                                                                                }
                                                                            </>
                                                                        :   ""
                                                                    } 
                                                                </div>
                                                            ))}
                                                        </Accordion.Panel>
                                                    </>
                                                : <p className='txt13 dGreyT mrgL20 mrgT5'>Aucun RDV supprimé</p>
                                                }
                                        </Accordion.Item>
                                    </Accordion>
                                    <div id='mrgB50'></div>
                                </div>    
                            </>
                        ) : (
                            <>
                                <div className="width50pCenter">                    
                                    <Accordion defaultValue="rdvDemand">
                                        <Accordion.Item value="rdvDemand">
                                            <Accordion.Control>
                                                <p className="useRoboto bold mrgB20 txt18">Demandes de RDV</p>
                                            </Accordion.Control>
                                            
                                            {listOpenRdv.length > 0 
                                                ?   <>
                                                        <Accordion.Panel>
                                                            {listOpenRdv.map((rdv, index) => (
                                                                <div key = {index} style = {{margin : '10px'}}>
                                                                        {rdv.offer === id
                                                                            ? <>
                                                                                {rdv.status === 'open' 
                                                                                    ?   <div className ="bLiteGrey">
                                                                                            <div className="vertical mrgL20 mrgT10">
                                                                                                <div className="horizontal mrgR10 spaceBtw iCenter">
                                                                                                    <p className="useRoboto txt16 mrgL30 mrgT20 mrgB5">
                                                                                                        {format(parseISO(rdv.sendDate), 'dd/MM/yy')}
                                                                                                    </p>
                                                                                                    <IconX 
                                                                                                        className='mrgT20 mrgR10' 
                                                                                                        width={30}
                                                                                                        id={rdv._id}
                                                                                                        onClick={handleDeleteRdv}    
                                                                                                        />
                                                                                                </div>

                                                                                                <div className="horizontal iCenter mrgL10">                           
                                                                                                    <div className='mrgB20'>
                                                                                                        {rdv.applicant.company.logo
                                                                                                            ?   <img 
                                                                                                                    className='roundImg100'
                                                                                                                    src={rdv.applicant.company.logo}
                                                                                                                    alt='logo'/>
                                                                                                            :   <div className="noAvatarProfile">
                                                                                                                    {rdv.applicant.company.name.split(" ").map((word) => word.charAt(0)).join("")}
                                                                                                                </div>
                                                                                                        }  
                                                                                                    </div>
                                                                                                    
                                                                                                    <div className='vertical mrgL30'>
                                                                                                        <Link to={`/s/offers/offer/rdv/company-page/${rdv.applicant.company._id}`} className="aReset bT">
                                                                                                            <div className="horizontal">
                                                                                                                <h1 className="txt18 useRoboto noMrgT mrgB5 lineH20">{rdv.applicant.title} {rdv.applicant.firstname} {rdv.applicant.lastname}</h1>
                                                                                                            </div>
                                                                                                            <div className="horizontal">
                                                                                                                <p className="txt18 useRoboto noMrgT mrgB5 capitals medium">{rdv.applicant.company.name}</p>
                                                                                                            </div>
                                                                                                        </Link>
                                                                                                        <p className="txt16 lineH20 useRoboto noMrgTB mrgR20">{rdv.applicant.occupation.join(' - ')}</p>
                                                                                                    
                                                                                                        <div className="mrgB10"></div>

                                                                                                        <div className="horizontal">
                                                                                                            <button className="btn-action-for-quot bDarkGrey mrgR10" id={rdv.applicant.phone} onClick={showContact}>
                                                                                                                <p className='txt16 noMrgTB'>Contacter</p>
                                                                                                            </button>
                                                                                                        
                                                                                                            <div className="mrgB5"></div>  
                                                                                                                <button className="btn-action-for-quot bBlack">
                                                                                                                <Link   to={`/s/offers/offer/rdv-info-send-form/${rdv._id}`}
                                                                                                                        className='wT aReset'>
                                                                                                                     <p className='txt16 noMrgTB'>Envoyer infos RDV</p>
                                                                                                                </Link>
                                                                                                                </button>
                                                                                                            </div>
                                                                                                            
                                                                                                    </div>
                                                                                                      
                   
                                                                                                </div>
                                                                                                { openContactInfo 
                                                                                                    ?   <Modal 
                                                                                                            centered
                                                                                                            size="auto"
                                                                                                            opened={openedContactInfo}
                                                                                                            onClose={closeContactInfo}
                                                                                                            title="Contacter">
                                                                                                            <div className='mrgR40 mrgL40'>
                                                                                                                <p className="useRoboto txtCenter bold txt18 noMrgT mrgB10">
                                                                                                                    {rdv.applicant.company.name} 
                                                                                                                </p>
                                                                                                                <p className="horizontal txtCenter iCenter useRoboto txt16 noMrgT mrgB10">
                                                                                                                    <div className='noMrgTB mrgR5'><IconPhone width={20} height={20}/></div>
                                                                                                                    <p className='noMrgT mrgB5 txt18'>{rdv.applicant.company.address.phonecie}</p>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </Modal> 
                                                                                                    :   ""
                                                                                                }     
                                                                                            </div>
                                                                                        </div>
                                                                                    :   ""
                                                                                    
                                                                                }
                                                                            </>
                                                                            : ""
                                                                        }
                                                                            
                                                                </div>
                                                            ))}
                                                        </Accordion.Panel> 
                                                    </>
                                                :   <p className='txt16 dGreyT mrgL20 mrgT5'>Vous n'avez pas de demande de RDV</p>
                                            }
                                            
                                        </Accordion.Item>
                                    </Accordion>

                                    <div className="mrgB5"></div>
                                                
                                    <Accordion defaultValue="rdvToCome">
                                        <Accordion.Item value="rdvToCome">
                                            <Accordion.Control>
                                                <p className="useRoboto bold txt18">RDV à venir</p>
                                            </Accordion.Control>
                                            {listRdv.length > 0 && listRdv.some(rdv => rdv.status === 'taken')
                                                ?   <>
                                                        <Accordion.Panel>
                                                            {listRdv.map((rdv, index) => (
                                                                <div key = {index} style = {{margin : '10px'}}>
                                                                    {rdv.offer === id
                                                                        ?   <>
                                                                                {rdv.dateOfAppointment && rdv.status === 'taken' && rdv.dateOfAppointment > yesterday
                                                                                    ?   <div className='bLiteGrey'>
                                                                                            <div className ="vertical mrgL5">
                                                                                                <Link to={`/s/offers/offer/details-rdv/${rdv._id}`} className="aReset bT">
                                                                                                    <div className="horizontal spaceBtw mrgL10 mrgT20 mrgB10">
                                                                                                        <div className="horizontal jCenter mrgL10">
                                                                                                            <div><IconCalendarEvent  width={25}/></div>
                                                                                                            <div><h2 className="useRoboto txt18 mrgL5 noMrgTB">{format(new Date(rdv.dateOfAppointment), 'dd/MM/yy')}</h2></div>
                                                                                                        </div>

                                                                                                        <div className="horizontal jCenter mrgR10">
                                                                                                            <div><IconClock width={25} className="mrgR5"/></div>
                                                                                                            <div><h2 className="useRoboto txt18 mrgR20 noMrgTB">{format(new Date(rdv.timeOfAppointment), 'HH:mm')}</h2></div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="horizontal iCenter spaceBtw mrgB20">
                                                                                                        <div className="horizontal iCenter mrgL20 mrgB5">
                                                                                                            <div>
                                                                                                                {rdv.applicant.company.logo
                                                                                                                    ?   <img 
                                                                                                                            className='roundImg100'
                                                                                                                            src={rdv.applicant.company.logo}
                                                                                                                            alt='logo'/>
                                                                                                                    :   <div className="noAvatarProfile">{rdv.applicant.company.name.charAt(0)}</div>
                                                                                                                }  
                                                                                                            </div>
                                                                                                            
                                                                                                            <div className="mrgL30" >
                                                                                                                <p className="txt18 useRoboto bold noMrgT mrgB5 lineH20">{rdv.applicant.title} {rdv.applicant.firstname} {rdv.applicant.lastname}</p>
                                                                                                                <p className="txt18 useRoboto bold noMrgT mrgB5 capitals medium">{rdv.applicant.company.name}</p>
                                                                                                                <p className="txt16 lineH20 useRoboto noMrgTB mrgR20">{rdv.applicant.occupation.join(' - ')}</p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <IconChevronRight width={30} className='mrgR20'/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    :   ""
                                                                                }
                                                                            </>
                                                                        :   ""
                                                                    }
                                                                
                                                                </div>
                                                            ))}
                                                        </Accordion.Panel>
                                                    </>
                                                :   <p className='txt16 dGreyT mrgL20 mrgT5'>Vous n'avez pas de RDV à venir</p>
                                                }
                                        </Accordion.Item>
                                    </Accordion>
                                
                                    <div className="mrgB5"></div>
                                    
                                    <Accordion>
                                        <Accordion.Item value="deletedRDV">
                                            <Accordion.Control>
                                                <p className="useRoboto bold mrgB20 txt18">RDV supprimés</p>
                                            </Accordion.Control>
                                            {listRdv.length > 0  && listRdv.some(rdv => rdv.status === 'close')
                                                ?   <>
                                                        <Accordion.Panel>
                                                            {listRdv.map((rdv, index) => (
                                                                <div key = {index} style = {{margin : '10px'}}>
                                                                    {rdv.offer === id
                                                                        ?   <>
                                                                                {rdv.status === 'close' 
                                                                                    ?   
                                                                                        <div className='bLiteGrey horizontal iCenter spaceBtw'>
                                                                                            <div><p className="txt16 useRoboto mrgT10 mrgB10 mrgL20 medium">{rdv.applicant.company.name}</p></div>
                                                                                            <div><p className="txt16 useRoboto mrgT10 mrgB10 mrgR20">Supprimé le {format(parseISO(rdv.sendDate), 'dd/MM/yy')}</p></div>
                                                                                        </div>
                                                                                            
                                                                                    :   ""
                                                                                }
                                                                            </>
                                                                        :   ""
                                                                    } 
                                                                </div>
                                                            ))}
                                                        </Accordion.Panel>
                                                    </>
                                                : <p className='txt16 dGreyT mrgL20 mrgT5'>Aucun RDV supprimé</p>
                                                }
                                        </Accordion.Item>
                                    </Accordion>
                                    <div id='mrgB100'></div>
                                </div>    
                            </>
                        )}
                    </>
                : <p className='width90pCenter dGreyT txt18 txtCenter'>RDV non requis pour cette offre</p>
            }
        
        </>
        : 
        <Loader />
    )
}

export default ReceivedRDVDemandList;

