import { React, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../contexts/authContext';
import { Link, useNavigate } from "react-router-dom";

import { UnstyledButton, Group, Loader} from '@mantine/core';
import { IconUser, IconLock, IconFileCertificate, IconBrandHipchat, IconChevronRight, IconBrowserCheck, IconMapPin, IconShare } from '@tabler/icons-react';

import AppNavbarPresta from '../../../Navbar/NavbarPresta';
import AppHdSingleTitle from '../../../../composents/Headers/AppHeaders/AppHdSingleTitle/AppHdSingleTitle';
import Box from '../../../../composents/Boxes/Standard/Box';

const PrestaProfileMenu = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [ menuProfile , setMenuProfile ] = useState(null)

    const navigate = useNavigate();
    
    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/p/login");
            return;
        }
        getUser();

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
      
          window.addEventListener('resize', handleResize);
      
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUser = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch('https://boldee-97d8d36af3bd.herokuapp.com/p/getUser', {
                method: "GET",    
                headers: {
                    Authorization: `Bearer ${token}`
                    }
        });
            const data = await response.json()
            setMenuProfile(data)
        }   catch (error) {
            return null;
        }
    }
    
    return(
        menuProfile ?
            <>
            {isMobile ? (
                <>
                    <AppNavbarPresta></AppNavbarPresta>
                    <>
                        <AppHdSingleTitle>Profil</AppHdSingleTitle>
                        <div className="mrgB20"></div>
                        <div className="txtCenter">
                            <UnstyledButton>
                                <Group>
                                    <div className="horizontal jCenter">
                                        <div>
                                            {menuProfile.company.logo     
                                                ?   <img src={menuProfile.company.logo} alt="logo" className='roundImg70' size={100} />
                                                :   <div className="noAvatarProfile">{menuProfile.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                            }
                                        </div>
                                        <div className="vertical mrgL20">
                                            <p className='txt18 medium noMrgB'>{menuProfile.firstname} {menuProfile.lastname}</p>
                                            <p className='txt15 capitals dGreyT noMrgT noMrgB'>{menuProfile.company.name}</p>
                                        </div>
                                    </div>
                                </Group>
                            </UnstyledButton>
                        
                            <div className="mrgB20"></div>

                            <Box>
                                <div  className="profil-menu vertical mrgL10 mrgR20">
                                    <Link to={`/p/profile/account`} className="aReset bT">
                                            <div className="horizontal iCenter spaceBtw">
                                                <div className="horizontal iCenter paddingMenuProfil">
                                                    <IconUser width={25} className="mrgR10"/>
                                                    <p>Mon compte</p>
                                                </div>
                                                <IconChevronRight width={16}/>

                                            </div>
                                            <hr></hr>
                                    </Link>
                        
                                    <Link to={`/p/profile/company-page`} className="aReset bT">
                                            <div className="horizontal iCenter spaceBtw">
                                                <div className="horizontal iCenter paddingMenuProfil">
                                                    <IconBrowserCheck width={25} className="mrgR10"/>
                                                    <p>Ma page vitrine</p>
                                                </div>
                                                <IconChevronRight width={16}/>

                                            </div>
                                            <hr></hr>
                                    </Link>

                                    <Link to={`/p/profile/intervention-places`} className="aReset bT">
                                            <div className="horizontal iCenter spaceBtw">
                                                <div className="horizontal iCenter paddingMenuProfil">
                                                    <IconMapPin width={25} className="mrgR10"/>
                                                    <p>Lieux d'intervention</p>
                                                </div>
                                                <IconChevronRight width={16}/>

                                            </div>
                                            <hr></hr>
                                    </Link>

                                    <Link to={`/p/profile/param/psw-edit`} className="aReset bT">
                                        <div className="horizontal iCenter spaceBtw">
                                            <div className="horizontal iCenter paddingMenuProfil">
                                                <IconLock width={25} className="mrgR10"/>
                                                <p>Modifier mon mot de passe</p>
                                            </div>
                                            <IconChevronRight width={16}/>
                                        </div>
                                        <hr></hr>
                                    </Link>

                                    <Link to="/p/profile/disclaimer" className="aReset bT">
                                            <div className="horizontal iCenter spaceBtw">
                                                <div className="horizontal iCenter paddingMenuProfil">
                                                    <IconFileCertificate width={25} className="mrgR10"/>
                                                    <p>Mentions légales</p>
                                                </div>
                                                <IconChevronRight width={16}/>
                                            </div>
                                            <hr></hr>
                                    </Link>

                                    <Link to="/p/profile/contact" className="aReset bT">
                                            <div className="horizontal iCenter spaceBtw">
                                                <div className="horizontal iCenter paddingMenuProfil">
                                                    <IconBrandHipchat width={25} className="mrgR10"/>
                                                    <p>Nous contacter</p>
                                                </div>
                                                <IconChevronRight width={16}/>
                                            </div>
                                            <hr></hr>
                                            
                                    </Link>

                                    <Link to="/p/profile/reco" className="aReset bT">
                                        <div className="horizontal iCenter spaceBtw">
                                            <div className="horizontal iCenter paddingMenuProfil">
                                                <IconShare  width={25} className="mrgR10"/>
                                                <p>Recommander Boldee à un Syndic</p>
                                            </div>
                                            <IconChevronRight width={16}/>
                                        </div>
                                    </Link>            
                                    
                                </div>
                            </Box>
                            
                            <div className='mrgB10'></div>

                            <div>
                                <p id="versionApp">Boldee V.beta 1</p>
                            </div>

                            <div className='mrgB150'></div>

                        </div>
                    </>
                </>
            ) : (
<>
                    <AppNavbarPresta></AppNavbarPresta>
                    <div className='width50pCenter'>
                        <AppHdSingleTitle>Profil</AppHdSingleTitle>
                        <div className="mrgB20"></div>
                        <div className="txtCenter">
                            <UnstyledButton>
                                <Group>
                                    <div className="horizontal jCenter">
                                        <div>
                                            {menuProfile.company.logo     
                                                ?   <img src={menuProfile.company.logo} alt="logo" className='roundImg70' size={100} />
                                                :   <div className="noAvatarProfile">{menuProfile.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                            }
                                        </div>
                                        <div className="vertical mrgL20">
                                            <p className='txt18 medium noMrgB'>{menuProfile.firstname} {menuProfile.lastname}</p>
                                            <p className='txt15 capitals dGreyT noMrgT noMrgB'>{menuProfile.company.name}</p>
                                        </div>
                                    </div>
                                </Group>
                            </UnstyledButton>
                        
                            <div className="mrgB20"></div>

                            <Box>
                                <div  className="profil-menu vertical mrgL10 mrgR20">
                                    <Link to={`/p/profile/account`} className="aReset bT">
                                            <div className="horizontal iCenter spaceBtw">
                                                <div className="horizontal iCenter paddingMenuProfil">
                                                    <IconUser width={25} className="mrgR10"/>
                                                    <p>Mon compte</p>
                                                </div>
                                                <IconChevronRight width={16}/>

                                            </div>
                                            <hr></hr>
                                    </Link>
                        
                                    <Link to={`/p/profile/company-page`} className="aReset bT">
                                            <div className="horizontal iCenter spaceBtw">
                                                <div className="horizontal iCenter paddingMenuProfil">
                                                    <IconBrowserCheck width={25} className="mrgR10"/>
                                                    <p>Ma page vitrine</p>
                                                </div>
                                                <IconChevronRight width={16}/>

                                            </div>
                                            <hr></hr>
                                    </Link>

                                    <Link to={`/p/profile/intervention-places`} className="aReset bT">
                                            <div className="horizontal iCenter spaceBtw">
                                                <div className="horizontal iCenter paddingMenuProfil">
                                                    <IconMapPin width={25} className="mrgR10"/>
                                                    <p>Lieux d'intervention</p>
                                                </div>
                                                <IconChevronRight width={16}/>

                                            </div>
                                            <hr></hr>
                                    </Link>

                                    <Link to={`/p/profile/param/psw-edit`} className="aReset bT">
                                        <div className="horizontal iCenter spaceBtw">
                                            <div className="horizontal iCenter paddingMenuProfil">
                                                <IconLock width={25} className="mrgR10"/>
                                                <p>Modifier mon mot de passe</p>
                                            </div>
                                            <IconChevronRight width={16}/>
                                        </div>
                                        <hr></hr>
                                    </Link>

                                    <Link to="/p/profile/disclaimer" className="aReset bT">
                                            <div className="horizontal iCenter spaceBtw">
                                                <div className="horizontal iCenter paddingMenuProfil">
                                                    <IconFileCertificate width={25} className="mrgR10"/>
                                                    <p>Mentions légales</p>
                                                </div>
                                                <IconChevronRight width={16}/>
                                            </div>
                                            <hr></hr>
                                    </Link>

                                    <Link to="/p/profile/contact" className="aReset bT">
                                            <div className="horizontal iCenter spaceBtw">
                                                <div className="horizontal iCenter paddingMenuProfil">
                                                    <IconBrandHipchat width={25} className="mrgR10"/>
                                                    <p>Nous contacter</p>
                                                </div>
                                                <IconChevronRight width={16}/>
                                            </div>
                                            <hr></hr>
                                            
                                    </Link>

                                    <Link to="/p/profile/reco" className="aReset bT">
                                        <div className="horizontal iCenter spaceBtw">
                                            <div className="horizontal iCenter paddingMenuProfil">
                                                <IconShare  width={25} className="mrgR10"/>
                                                <p>Recommander Boldee à un Syndic</p>
                                            </div>
                                            <IconChevronRight width={16}/>
                                        </div>
                                    </Link>            
                                    
                                </div>
                            </Box>
                            
                            <div className='mrgB10'></div>

                            <div>
                                <p id="versionApp">Boldee V.beta 1</p>
                            </div>

                            <div className='mrgB150'></div>

                        </div>
                    </div>
                </>
            )}
            </>
        : 
        <Loader />
    )
}

export default PrestaProfileMenu;