import { React, useMemo, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../../contexts/authContext';
import { useParams, useNavigate } from "react-router-dom";

import { Loader, Modal } from '@mantine/core';
import { IconHammer, IconLocation } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';

import AppHdWithCloseRight from '../../../../composents/Headers/AppHeaders/AppHdWithCloseRight/AppHdWithCloseRight';
import Box from '../../../../composents/Boxes/Standard/Box';
import AppDesktopLogo from '../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

const OfferListToSend = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [listOffer, setlistOffer] = useState(null);
    const [offerId, setOfferId] = useState(null);
    const [opened, { open, close }] = useDisclosure(false);

    let {id} = useParams()

    const navigate = useNavigate();

    useEffect(() => {
      const token = getToken();
      if (!token) {
          navigate("/s/login");
          return;
      };

      const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const getOffers = async () => { 
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/favPresta/offers-list/${id}`, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`
              }
            });
            let listOfData = await response.json();
            setlistOffer(listOfData)
        } catch (error) { console.error(error);
      }
    }

    useMemo( async () => {
      await getOffers()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSend = async (event) => {
      open()
      const _id = event.currentTarget.id
      setOfferId(_id)

      try {
        const token = localStorage.getItem('token');
        await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/favPresta/sendOffer-fm-Offer-list/${id}`, {
          method: "POST",
          body: JSON.stringify({
              id: id,
              _id: _id,
          }),
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`
          }
        })
        await getOffers()
      } catch (error) {
  }
    }

    const handleSendingCancel = async (event) => {
      const _id = event.currentTarget.id
      setOfferId(_id)

      try {
          const token = localStorage.getItem('token');
          await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/favPresta/cancelSending-fm-Offer-list/${id}`, {
              method: "POST",
              body: JSON.stringify({
                  id: id,
                  _id: _id,
              }),
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`
              }
          });
          await getOffers()
      } catch (error) {
      }
  }

  const today = new Date().toISOString()  
  
  return(
    listOffer ?
        <>
          {isMobile ? (
              <>
                  <AppHdWithCloseRight>Transmettre offre(s) de travaux à vos prestataires favoris</AppHdWithCloseRight>
                    {listOffer.length <= 0
                        ?   <p className="txt15 dGreyT noMrgT txtCenter">Aucune offre ne correspond aux métiers de vos prestataires favoris.</p>
                        :  <>  
                            
                            {listOffer.map((offer, index) => (
                                      <div key = {index} style = {{margin : '10px'}}>
                                        {offer.deadlineToReceiveQuotationDate >= today
                                          ? <Box>
                                            <div className='horizontal iCenter mrgL20'>
                                              <div>
                                                {offer.pictures.length > 0 
                                                    ?   <div>
                                                            <img className="roundImg70 noMrgTB"
                                                                src={offer.pictures[0]}
                                                                alt='photo_chantier'/>
                                                        </div>
                                                    :   <>
                                                          {offer.author.company.logo
                                                            ?   <img className="roundImg70"
                                                                    src ={offer.author.company.logo}
                                                                    alt='logo'/>
                                                            :   <div className="noAvatarProfile">{offer.author.company.name.charAt(0)}</div>
                                                          }
                                                        </>
                                                }
                                              </div>
                                              <div className='mrgL10'>
                                                <p className="txt15 medium noMrgTB">{offer.title}</p>
                                                  <div className='horizontal iCenter'>
                                                    <IconHammer width={13} height={13}/>
                                                    <p className="txt13 noMrgTB mrgL5">{offer.occupation}</p>
                                                  </div>
                                                  <div className='horizontal iCenter'>
                                                    <IconLocation width={13} height={13}/>
                                                    <p className="txt13 noMrgTB mrgL5">{offer.cityOfIntervention}</p>
                                                  </div>
                                              </div>
                                              
                                              {!offer.recommendTo.includes(id) ?
                                                  <button 
                                                      className="btn-offerTransfer mrgLauto mrgR20"
                                                      id={offer._id}
                                                      onClick={handleSend}
                                                  >
                                                      Envoyer
                                                  </button>
                                                  :
                                                  <button 
                                                      className=" btn-offerTransfered mrgLauto mrgR20"
                                                      id={offer._id}
                                                      onClick={handleSendingCancel}
                                                      >
                                                      Envoyé
                                                  </button>
                                                  }
                                                  {open && offerId === offer._id ?
                                                      <Modal 
                                                          centered
                                                          size="auto"
                                                          opened={opened}
                                                          onClose={close}
                                                          withCloseButton={false}>
                                                          <div className="txtCenter noMrgTB vertical useRoboto">
                                                              <p className="noMrgTB txt15">Votre offre a été envoyée</p> 
                                                          </div>
                                                      </Modal> 
                                                  : 
                                                      ""
                                                  }
                                                  </div> 
                                            </Box>
                                          : <p className="txt15 dGreyT noMrgT txtCenter">Aucune offre ne correspond aux métiers de vos prestataires favoris.</p>
                                          }
                                      </div>
                                ))}
                          </>
                          
                    }

                  <div className="mrgB30"></div>
              </>
          ) : (
            <>
                <AppDesktopLogo/>
                <AppHdWithCloseRight>Transmettre offre(s) de travaux à vos prestataires favoris</AppHdWithCloseRight>
                {listOffer.length < 0
                    ?   <p className="txt18 dGreyT noMrgT txtCenter">Aucune offre ne correspond aux métiers de vos prestataires favoris.</p>
                    :  <div className='width50pCenter'>  
                          {listOffer.map((offer, index) => (
                                    <div key = {index} style = {{margin : '10px'}}>
                                      {offer.deadlineToReceiveQuotationDate >= today
                                        ? 
                                          <div className='horizontal iCenter bLiteGrey mrgL30'>
                                            <div className='mrgL30 mrgT20 mrgB20'>
                                              {offer.pictures.length > 0 
                                                  ?   <div>
                                                          <img className="roundImg100 noMrgTB"
                                                              src={offer.pictures[0]}
                                                              alt='photo_chantier'/>
                                                      </div>
                                                  :   <>
                                                        {offer.author.company.logo
                                                          ?   <img className="roundImg100"
                                                                  src ={offer.author.company.logo}
                                                                  alt='logo'/>
                                                          :   <div className="noAvatarProfile">{offer.author.company.name.charAt(0)}</div>
                                                        }
                                                      </>
                                              }
                                            </div>
                                            <div className='mrgL20'>
                                              <p className="txt18 bold noMrgT mrgB5">{offer.title}</p>
                                                <div className='horizontal iCenter'>
                                                  <IconHammer width={20} height={20}/>
                                                  <p className="txt16 noMrgT mrgB5 mrgL10">{offer.occupation}</p>
                                                </div>
                                                <div className='horizontal iCenter'>
                                                  <IconLocation width={20} height={20}/>
                                                  <p className="txt16 noMrgTB mrgL10">{offer.cityOfIntervention}</p>
                                                </div>
                                            </div>
                                            
                                            {!offer.recommendTo.includes(id) ?
                                                <button 
                                                    className="btn-offerTransfer mrgLauto"
                                                    id={offer._id}
                                                    onClick={handleSend}
                                                >
                                                    Envoyer
                                                </button>
                                                :
                                                <button 
                                                    className=" btn-offerTransfered mrgLauto"
                                                    id={offer._id}
                                                    onClick={handleSendingCancel}
                                                    >
                                                    Envoyé
                                                </button>
                                                }
                                                {open && offerId === offer._id ?
                                                    <Modal 
                                                        centered
                                                        size="auto"
                                                        opened={opened}
                                                        onClose={close}
                                                        withCloseButton={false}>
                                                        <div className="txtCenter noMrgTB vertical useRoboto">
                                                            <p className="noMrgTB txt16">Votre offre a été envoyée</p> 
                                                        </div>
                                                    </Modal> 
                                                : 
                                                    ""
                                                }
                                          </div> 
                                        : ""
                                        }
                                    </div>
                              ))}
                        </div>
                      
                }

            <div className="mrgB100"></div>
        </>
          )}
        </>
      : 
      <Loader />
  )
    
}

export default OfferListToSend;