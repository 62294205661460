import { React, useMemo, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../../contexts/authContext';
import { Link, useNavigate } from 'react-router-dom';

import { Badge, Loader } from '@mantine/core';
import { IconClock, IconCalendarEvent, IconChevronRight, IconMessage } from '@tabler/icons-react';
import { parseISO, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { subDays } from 'date-fns'

import AppHdSingleTitle from '../../../../composents/Headers/AppHeaders/AppHdSingleTitle/AppHdSingleTitle';
import AppNavbarPresta from '../../../Navbar/NavbarPresta';
import Box from '../../../../composents/Boxes/Standard/Box';

const PrestaMyRdv = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [listRdv, setlistRdv] = useState(null);

    const navigate = useNavigate();
    
    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/p/login");
            return;
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRdvs = async () => { 
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/p/rdv/list`, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`
                }
              });
            let list = await response.json();
            list = list.filter(rdv => rdv.dateOfAppointment > yesterday);
            setlistRdv(list)
            
        } catch (error) { console.error(error);
      }
    }  
      useMemo( async () => {
        await getRdvs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

   const subOneDayOfToday = subDays(new Date(),1)
   const yesterday = subOneDayOfToday.toISOString()
   
    return(
        listRdv ?
            <>
                {isMobile ? ( 
                    <>
                        <AppNavbarPresta></AppNavbarPresta>
                        <AppHdSingleTitle>Mes Rendez-vous</AppHdSingleTitle>
                        {listRdv.length > 0
                            ?   <>
                                    {listRdv.map((rdv, index) => (
                                        <div key = {index} >
                                            
                                            {rdv.dateOfAppointment > yesterday 
                                                ?   <>
                                                    <Link to={`/p/rdv/info/${rdv._id}`} className="aReset bT">
                                                        <Box>
                                                            <div className="horizontal iCenter spaceBtw mrgL10">
                                                                <div className="horizontal iCenter mrgL10">
                                                                    <IconCalendarEvent  width={15} />
                                                                    <p className="txt16 bold mrgL5 mrgT5 mrgB5 cap">{format(parseISO(rdv.dateOfAppointment), 'eeee dd MMMM', {locale: fr})}</p>
                                                                </div>

                                                                <div className="horizontal iCenter mrgR10">
                                                                    <IconClock width={15} className="mrgR5"/>
                                                                    <h2 className="txt16 mrgR10 mrgT5 mrgB5">{format(parseISO(rdv.timeOfAppointment), 'HH:mm')}</h2>
                                                                </div>
                                                            </div>

                                                            <div className="horizontal iCenter spaceBtw mrgL10">
                                                                    <div className="horizontal iCenter mrgL10">
                                                                        <div>
                                                                            {rdv.offer.author.company.logo
                                                                                ?   <img className="roundImg70"
                                                                                        src={rdv.offer.author.company.logo}
                                                                                        alt='logo'/>
                                                                                :   <div className="noAvatarProfile">{rdv.offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                                            }
                                                                            
                                                                        </div>
                                                                        <div className="mrgL10" >
                                                                            <p className="txt15 medium noMrgTB">{rdv.offer.author.title} {rdv.offer.author.firstname} {rdv.offer.author.lastname}</p>
                                                                            <p className="txt13 thin mrgT5 noMrgB capitals">{rdv.offer.author.company.name}</p>
                                                                            {rdv.lastModification
                                                                                ? <>
                                                                                  <Badge color='green' size='s'>
                                                                                    modifié le : {new Date(rdv.lastModification).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: '2-digit' })}
                                                                                  </Badge>
                                                                                  </>
                                                                                : ""
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                <div className='mrgR20'>
                                                                    <IconChevronRight width={16}/>
                                                                </div>
                                                            </div>
                                                        </Box>
                                                    </Link>
                                                    </>
                                                :   ""
                                            }
                                            <div className="mrgB5"></div>
                                        </div>
                                    ))}
                                </>
                            :   <p className="txtCenter txt15 dGreyT">Pas de Rendez-Vous à venir</p>
                        }
                    </>
                ) : (
                    <>
                        <AppNavbarPresta></AppNavbarPresta>
                        <AppHdSingleTitle>Mes Rendez-vous</AppHdSingleTitle>
                        <div className='width50pCenter'>    
                            {listRdv.length > 0
                                ?   <>
                                        {listRdv.map((rdv, index) => (
                                            <div key = {index} style = {{margin : '15px'}}>
                                                {rdv.dateOfAppointment > yesterday 
                                                    ?   <>
                                                    <Link to={`/p/rdv/info/${rdv._id}`} className="aReset bT">
                                                        <Box>
                                                            <div className="horizontal iCenter spaceBtw mrgL20 mrgR20 mrgT10 mrgB10">
                                                                <div className="horizontal iCenter mrgL10">
                                                                    <IconCalendarEvent  width={20} className='mrgB5'/>
                                                                    <p className="txt18 bold mrgL5 mrgT5 mrgB5 cap">{format(parseISO(rdv.dateOfAppointment), 'eeee dd MMMM', {locale: fr})}</p>
                                                                </div>

                                                                <div className="horizontal iCenter mrgR10">
                                                                    <IconClock width={20} className="mrgR5 mrgB5"/>
                                                                    <h2 className="txt18 mrgR10 mrgT5 mrgB5">{format(parseISO(rdv.timeOfAppointment), 'HH:mm')}</h2>
                                                                </div>
                                                            </div>

                                                            <div className="horizontal iCenter spaceBtw mrgL20 mrgB10">
                                                                    <div className="horizontal iCenter mrgL10">
                                                                        <div>
                                                                            {rdv.offer.author.company.logo
                                                                                ?   <img className="roundImg100"
                                                                                        src={rdv.offer.author.company.logo}
                                                                                        alt='logo'/>
                                                                                :   <div className="noAvatarProfile">{rdv.offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                                            }
                                                                            
                                                                        </div>
                                                                        <div className="mrgL20" >
                                                                            <p className="txt18 mediumplus noMrgTB">{rdv.offer.author.title} {rdv.offer.author.firstname} {rdv.offer.author.lastname}</p>
                                                                            <p className="txt18 mrgT5 mrgB5 capitals">{rdv.offer.author.company.name}</p>
                                                                            {rdv.lastModification
                                                                                ? <>
                                                                                  <Badge color='green' size='lg'>
                                                                                    modifié le : {new Date(rdv.lastModification).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: '2-digit' })}
                                                                                  </Badge>
                                                                                  </>
                                                                                : ""
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                <div className='mrgR40'>
                                                                    <IconChevronRight width={23}/>
                                                                </div>
                                                            </div>
                                                        </Box>
                                                    </Link>
                                                        </>
                                                    :   ""
                                                }
                                                <div className="mrgB5"></div>
                                            </div>
                                        ))}
                                    </>
                                :   <>
                                        <div className="vertical">
                                            <div className='emptyListNotification'>
                                                <div className= 'horizontal iCenter mrgT10'>
                                                    <IconMessage width={100} height={45}/>
                                                    <p className='txt20 bold'>
                                                        Vous n'avez pas de RDV à venir
                                                    </p>
                                                </div>
                                                
                                            <div className='horizontal iCenter'>
                                                <IconMessage width={100} height={45} color='F8F8F8'/>
                                                <Link to="/p/matchs/list" className='aReset'><p className='txt18 terracotaT mrgT10 mrgB30 mrgR50'>Consulter mes offres personnalisées</p></Link>
                                                <Link to="/p/search" className='aReset'><p className='txt18 terracotaT mrgT10 mrgB30'>Accéder à toutes les offres</p></Link>
                                            </div> 
                                            </div>                                                        
                                        </div>
                                    </>
                            }
                        </div>
                    </>
                )}
            </>
            : 
            <Loader />
    )
}

export default PrestaMyRdv;