import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoNavbar from '../../composents/Logo/NavbarLogo/Logo';
import Navbar from '../Navbar/NavbarPublic';
import Footer from '../Footer/Footer';
import PrestaRectangleButtonMain from '../../composents/Buttons/Presta/RectangleButtonMain/RectangleButtonMain';
import { IconPlugConnectedX } from '@tabler/icons-react';

const PageNotFound = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0,0);
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const goBack = () => {
        navigate(-1)
    }

    const goHome = () => {
        navigate('/')
    }

    return (
        <>
        <Navbar/>
        <div>
        {isMobile ? (
            <>
             <div className='txtCenter mrgL30 mrgT10 mrgB100'><LogoNavbar /></div>
             <div className="bLiteGrey width90pCenter useRoboto vertical txtCenter">                    
                 <div className='mrgT10'></div>
                 <h1 className='mrgT20 mrgB5'>404 Page non trouvée</h1>
                 <div className='width90pCenter'><IconPlugConnectedX width={48} height={48}/></div>
                 <h3 className='mrgB30 mrgL10 mrgR10'>La page que vous tentez d'afficher n'existe pas.</h3>
                 <div className='w80'>
                    <PrestaRectangleButtonMain onClick={goBack}>
                        Revenir à la page précédente
                    </PrestaRectangleButtonMain>
                    <div className='mrgB10'></div>
                    <PrestaRectangleButtonMain onClick={goHome}>
                       Allez à la page d'accueil
                    </PrestaRectangleButtonMain>
                </div>
                 <div className="mrgB30"></div>           

             </div>
             </>
        ) : (
            <>
                <div className='txtCenter mrgL30 mrgT10 mrgB150'><LogoNavbar /></div>
                <div className="bLiteGrey width40pCenter useRoboto vertical txtCenter">                    
                    <h1 className='mrgT50 mrgB5'>404 Page non trouvée</h1>
                    <div className='width90pCenter'><IconPlugConnectedX width={55} height={55}/></div>                    
                   <h2 className='mrgB30'>La page que vous tentez d'afficher n'existe pas.</h2>
                   <div className='w40 width90pCenter '>
                        <PrestaRectangleButtonMain onClick={goBack}>
                            Revenir à la page précédente
                        </PrestaRectangleButtonMain>
                        <div className='mrgB10'></div>
                        <PrestaRectangleButtonMain onClick={goHome}>
                        Allez à la page d'accueil
                        </PrestaRectangleButtonMain>
                    </div>
                    <div id="mrgB50"></div>           

                </div>
            </>
        )}
        </div>
        <Footer/>
        </>
    );
  }

  export default PageNotFound
  