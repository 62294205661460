import { React, useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../../../contexts/authContext';
import { useNavigate, useParams, Link } from "react-router-dom";

import { Loader, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPhoto, IconPhone } from '@tabler/icons-react';

import { parseISO, formatDistanceToNowStrict } from 'date-fns';
import { fr } from "date-fns/locale";

import SyndicRectangleButton from '../../../../composents/Buttons/Syndic/RectangleButton/RectangleButtonMain'
import AppHdWithCloseRight from '../../../../composents/Headers/AppHeaders/AppHdWithCloseRight/AppHdWithCloseRight';
import Box from '../../../../composents/Boxes/Standard/Box';
import LogoQualibat from '../../../../composents/Logo/QualificationLogos/Qualibat/LogoQualibat'; 
import LogoArtisansDeConfiance from '../../../../composents/Logo/QualificationLogos/ArtisanDeConfiance/LogoArtisansDeConfiance'; 
import LogoCertibat from '../../../../composents/Logo/QualificationLogos/Certibat/LogoCertibat';
import LogoEcoArtisan from '../../../../composents/Logo/QualificationLogos/EcoArtisan/LogoEcoArtisan';
import LogoEcocert from '../../../../composents/Logo/QualificationLogos/EcoCert/LogoEcocert';
import LogoHandibat from '../../../../composents/Logo/QualificationLogos/Handibat/LogoHandibat';
import LogoIso9001 from '../../../../composents/Logo/QualificationLogos/Iso9001/LogoIso9001';
import LogoQualieau from '../../../../composents/Logo/QualificationLogos/Qualieau/LogoQualieau';
import LogoQualienr from '../../../../composents/Logo/QualificationLogos/Qualienr/LogoQualienr';
import LogoQualifelec from '../../../../composents/Logo/QualificationLogos/Qualifelec/LogoQualifelec';
import LogoQualipropre from '../../../../composents/Logo/QualificationLogos/Qualipropre/LogoQualipropre';
import LogoRGE from '../../../../composents/Logo/QualificationLogos/RGE/LogoRGE';
import LogoSilverbat from '../../../../composents/Logo/QualificationLogos/Silverbat/LogoSilverbat';
import AppDesktopLogo from '../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

const PrestaPageInfo = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [presta, setPresta] = useState(null);
    const [prestaId, setPrestaId] = useState(null);
    const [opened, { open, close }] = useDisclosure(false);
    const [openedContactInfo, { open : openContactInfo, close : closeContactInfo}] = useDisclosure(false);
    const [creationDate, setCreationDate] = useState(null);

    const navigate = useNavigate();

    let {id} = useParams()

    useEffect(() => {
        getSinglePresta();
        const token = getToken();
        if (!token) {
            navigate("/s/login");
            return;
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);
    
    const getSinglePresta = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/favPresta/p-page-info/${id}`, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`
                }
              });
            const presta = await response.json();
            setPresta(presta);

            const creationDate = presta.company.creationDate
            const date = parseISO(creationDate, 'dd/mm/yyyy', new Date())
            let result = formatDistanceToNowStrict(
                date, {locale: fr}
              )
            setCreationDate(result)
        } catch (error) { 
                console.error(error);
        }
    }

    const handleDeleteFav = async (event) => {
        open()
        const idPresta = event.currentTarget.id
        setPrestaId(idPresta)
        
        try {
            const token = localStorage.getItem('token');
            await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/favPresta/delete/${idPresta}`, {
                method: "POST",
                body: JSON.stringify({
                    id: id,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            setTimeout(() => {
                navigate('/s/favPresta/fav-list');
            }, 500)
        } catch (error) {
        }
    }

    const handleCall = (event) => {
        const phoneNumber = event.currentTarget.id
        window.location.href = `tel:${phoneNumber}`
    }

    const showContact = () => {
        openContactInfo()
    }

    return(
        presta || creationDate ?
        <>
            {isMobile ? (
                <>
                    <AppHdWithCloseRight>{presta.company.name}</AppHdWithCloseRight>

                    <div className="prestapage-container ">
                        <div className="prestapage-logo-container">
                            {presta.company.logo
                                ?   <img className="roundImg70"
                                        src={presta.company.logo}
                                        alt='logo'/>
                                :   <div className='horizontal iCenter jCenter w100 dGreyT'><IconPhoto width={48} height={48}/></div>
                            }
                        </div>
                        <hr></hr>
                        
                        
                        <div className="vertical mrgB10 width90pCenter">
                            <div className=" horizontal spaceBtw">    
                                <div><p className="txt18 bold noMrgTB">{presta.company.name}</p></div>
                                <div><button className='callBtn' id={presta.company.address.phonecie} onClick={handleCall}>Contacter</button></div>
                            </div>
                            <div><p className="txt12 capitals thin noMrgTB">{presta.username}</p></div>
                            <div><p className="txt13 noMrgTB bold">{presta.occupation.join(' - ')}</p></div>
                            <div className="vertical">
                                <div><p className="txt13 noMrgTB">{presta.company.address.no}, {presta.company.address.street}</p></div>
                                <div><p className="txt13 noMrgT mrgB5">{presta.company.address.postalcode} {presta.company.address.city}</p></div>
                                <div><p className="txt13 noMrgTB">{presta.company.emailcie}</p> </div>
                            </div>
                        </div>

                        <Box>
                            <div className="indicateurs bLiteGrey horizontal spaceArd mrgB10">
                                <div className="vertical">
                                    <div><h1>{creationDate}</h1></div>
                                    <div><p>d'expérience</p></div>
                                </div>
                                    <div className="vertical">
                                        <div><h1>{(presta.addedToFavBy).length}</h1></div>
                                        <div><p>ajouté en favoris</p></div>
                                    </div>
                                <div className="vertical">
                                    {presta.recommendedBySyndicOffers.length > 0
                                        ? <div><h1>{(presta.recommendedBySyndicOffers).length}</h1></div>
                                        : <div><h1>0</h1></div>
                                    }
                                    <div><p>recommandation(s)</p></div>
                                </div>
                            </div>
                        </Box>


                        <div className=" width90pCenter mrgB10">
                                <p className="txt13 txtJustify">{presta.company.description}</p>
                        </div>

                        {presta.company.qualifications.length > 0
                            ?
                                <div className='horizontal width90pCenter iCenter jCenter wraped'>
                                    {presta.company.qualifications.map((certification, index) => (
                                        <div key={index}>
                                            {certification === 'artisandeconfiance'
                                                ?   <div className='mrgR20'><LogoArtisansDeConfiance/></div>
                                                :   ''
                                            }
                                            {certification === 'certibat'
                                                ?   <div className='mrgR20'><LogoCertibat/></div>
                                                :   ''
                                            }
                                            {certification === 'ecoartisan'
                                                ?   <div className='mrgR20'><LogoEcoArtisan/></div>
                                                :   ''
                                            }
                                            {certification === 'ecocert'
                                                ?   <div className='mrgR20'><LogoEcocert/></div>
                                                :   ''
                                            }
                                            {certification === 'handibat'
                                                ?   <div className='mrgR20'><LogoHandibat/></div>
                                                :   ''
                                            }
                                            {certification === 'iso9001'
                                                ?   <div className='mrgR20'><LogoIso9001/></div>
                                                :   ''
                                            }
                                            {certification === 'qualibat'
                                                ?   <div className='mrgR20'><LogoQualibat/></div>
                                                :   ''
                                            }
                                            {certification === 'qualieau'
                                                ?   <div className='mrgR20'><LogoQualieau/></div>
                                                :   ''
                                            }
                                            {certification === 'qualienr'
                                                ?   <div className='mrgR20'><LogoQualienr/></div>
                                                :   ''
                                            }
                                            {certification === 'qualifelec'
                                                ?   <div className='mrgR20'><LogoQualifelec/></div>
                                                :   ''
                                            }
                                            {certification === 'qualipropre'
                                                ?   <div className='mrgR20'><LogoQualipropre/></div>
                                                :   ''
                                            }
                                            {certification === 'rge'
                                                ?   <div className='mrgR20'><LogoRGE/></div>
                                                :   ''
                                            }
                                            {certification === 'silverbat'
                                                ?   <div className='mrgR20'><LogoSilverbat/></div>
                                                :   ''
                                            }
                                        </div>
                                    ))}
                                </div>
                            :   ""
                        }            

                        <a href={presta.company.website} target="_blank" rel="noopener noreferrer" className="aReset bT">
                            <div className="txtCenter">
                                <p className="txt15">{presta.company.website}</p>
                            </div>
                        </a>              

                        <div className="mrgB30"></div>
                        <div className="width90pCenter">
                            <Link to={`/s/favPresta/offers-list/${presta._id}`}>
                                <SyndicRectangleButton>
                                    Transmettre une offre
                                </SyndicRectangleButton>
                            </Link>

                            <div className="mrgB10"></div>

                            <Link to={`/s/favPresta/add/${presta._id}`}>
                                <SyndicRectangleButton>
                                    Créer et envoyer une offre à {presta.company.name}
                                </SyndicRectangleButton>
                            </Link>
                            
                            <div className="mrgB30"></div>
                            
                    
                            <button
                                className="deleteBtn"
                                id={presta._id}
                                onClick={handleDeleteFav}>
                                Supprimer de mes favoris
                            </button>
                            {open && prestaId === presta._id 
                                ?   <Modal 
                                        centered
                                        size="30%"
                                        opened={opened}
                                        onClose={close}
                                        withCloseButton={false}>
                                        <p className="useRoboto txtCenter noMrgTB txt15">
                                            Supprimé
                                        </p>
                                    </Modal> 
                                :   ""
                            }
                            <div className="mrgB30"></div>
                        </div>
                    </div>
                </>
            ):(
                <>
                    <AppDesktopLogo/>
                    <AppHdWithCloseRight>
                        <p className='txt20'>{presta.company.name}</p>
                    </AppHdWithCloseRight>

                    <div className="prestapage-container">
                        <div className="prestapage-logo-container">
                            {presta.company.logo
                                ?   <img
                                        src={presta.company.logo}
                                        alt='logo'/>
                                :   <div className='horizontal iCenter jCenter w100 dGreyT mrgT20'>
                                        <IconPhoto width={48} height={48}/>
                                    </div>
                            }
                        </div>                       
                        
                        <div className="vertical mrgB10">
                            <div className="horizontal spaceBtw">    
                                <div><h1 className="txt25 noMrgT mrgB10">{presta.company.name}</h1></div>
                                <div><button className='callBtn' id={presta.company.address.phonecie} onClick={showContact}>Contacter</button></div>
                            </div>
                            <div>
                                <p className="txt18 capitals thin noMrgTB">{presta.username}</p>
                            </div>
                            <div><p className="txt18 lineH20 mrgT5 mrgB10 medium">{presta.occupation.join(' - ')}</p></div>
                            <div className="vertical mrgT5 mrgB20">
                                <div><p className="txt16 noMrgTB">{presta.company.address.no}, {presta.company.address.street}</p></div>
                                <div><p className="txt16 noMrgT mrgB10">{presta.company.address.postalcode} {presta.company.address.city}</p></div>
                                <div><p className="txt16 noMrgTB">{presta.company.emailcie}</p> </div>
                            </div>
                        </div>
                        { openContactInfo 
                                        ?   <Modal 
                                                centered
                                                size="auto"
                                                opened={openedContactInfo}
                                                onClose={closeContactInfo}
                                                title="Contacter">
                                                <div className='mrgR40 mrgL40'>
                                                    <p className="useRoboto txtCenter bold txt18 noMrgT mrgB10">
                                                        {presta.company.name} 
                                                    </p>
                                                    <p className="horizontal txtCenter iCenter useRoboto txt16 noMrgT mrgB10">
                                                        <div className='noMrgTB mrgR5'><IconPhone width={20} height={20}/></div>
                                                        <p className='noMrgT mrgB5 txt18'>{presta.company.address.phonecie}</p>
                                                    </p>
                                                </div>
                                            </Modal> 
                                        :   ""
                                    }    
                        <Box>
                            <div className="indicateurs bLiteGrey horizontal spaceArd mrgB10 mrgT10">
                                <div className="vertical">
                                    <div><h1>{creationDate}</h1></div>
                                    <div className='noMrgT'><p>d'expérience</p></div>
                                </div>
                                    <div className="vertical">
                                        <div><h1>{(presta.addedToFavBy).length}</h1></div>
                                        <div className='noMrgT'><p>ajouté en favoris</p></div>
                                    </div>
                                <div className="vertical">
                                    <div><h1>{presta.recommendedBySyndicOffers.length}</h1></div>
                                    <div className='noMrgT'><p>recommandation(s)</p></div>
                                </div>
                            </div>
                        </Box>


                        <div className="mrgT10 mrgB20">
                            <p className="txt16 txtJustify">{presta.company.description}</p>
                        </div>

                        {presta.company.qualifications.length > 0
                            ?
                                <div className='horizontal width90pCenter iCenter jCenter wraped'>
                                    {presta.company.qualifications.map((certification, index) => (
                                        <div key={index}>
                                            {certification === 'artisandeconfiance'
                                                ?   <div className='mrgR20'><LogoArtisansDeConfiance/></div>
                                                :   ''
                                            }
                                            {certification === 'certibat'
                                                ?   <div className='mrgR20'><LogoCertibat/></div>
                                                :   ''
                                            }
                                            {certification === 'ecoartisan'
                                                ?   <div className='mrgR20'><LogoEcoArtisan/></div>
                                                :   ''
                                            }
                                            {certification === 'ecocert'
                                                ?   <div className='mrgR20'><LogoEcocert/></div>
                                                :   ''
                                            }
                                            {certification === 'handibat'
                                                ?   <div className='mrgR20'><LogoHandibat/></div>
                                                :   ''
                                            }
                                            {certification === 'iso9001'
                                                ?   <div className='mrgR20'><LogoIso9001/></div>
                                                :   ''
                                            }
                                            {certification === 'qualibat'
                                                ?   <div className='mrgR20'><LogoQualibat/></div>
                                                :   ''
                                            }
                                            {certification === 'qualieau'
                                                ?   <div className='mrgR20'><LogoQualieau/></div>
                                                :   ''
                                            }
                                            {certification === 'qualienr'
                                                ?   <div className='mrgR20'><LogoQualienr/></div>
                                                :   ''
                                            }
                                            {certification === 'qualifelec'
                                                ?   <div className='mrgR20'><LogoQualifelec/></div>
                                                :   ''
                                            }
                                            {certification === 'qualipropre'
                                                ?   <div className='mrgR20'><LogoQualipropre/></div>
                                                :   ''
                                            }
                                            {certification === 'rge'
                                                ?   <div className='mrgR20'><LogoRGE/></div>
                                                :   ''
                                            }
                                            {certification === 'silverbat'
                                                ?   <div className='mrgR20'><LogoSilverbat/></div>
                                                :   ''
                                            }
                                        </div>
                                    ))}
                                </div>
                            :   ""
                        }            

                        <a href={presta.company.website} target="_blank" rel="noopener noreferrer" className="aReset bT">
                            <div className="txtCenter">
                                <p className="txt16">{presta.company.website}</p>
                            </div>
                        </a>              

                        <div className="mrgB30"></div>

                        <div>
                            <Link to={`/s/favPresta/offers-list/${presta._id}`}>
                                <SyndicRectangleButton>
                                    <p className='txt16'>Transmettre une offre</p>
                                </SyndicRectangleButton>
                            </Link>

                            <div className="mrgB10"></div>

                            <Link to={`/s/favPresta/add/${presta._id}`}>
                                <SyndicRectangleButton>
                                    <p className='txt16'>Créer et envoyer une offre à {presta.company.name}</p>
                                </SyndicRectangleButton>
                            </Link>
                            

                            <div className="mrgB30"></div>
                            
                    
                            <button
                                className="deleteBtn"
                                id={presta._id}
                                onClick={handleDeleteFav}>
                                <p className='txt16'>Supprimer de mes favoris</p>
                            </button>
                            {open && prestaId === presta._id 
                                ?   <Modal 
                                        centered
                                        size="auto"
                                        opened={opened}
                                        onClose={close}
                                        withCloseButton={false}>
                                        <p className="useRoboto txtCenter noMrgTB txt16">
                                            Supprimé
                                        </p>
                                    </Modal> 
                                :   ""
                            }
                            <div className="mrgB30"></div>
                        </div>
                    </div>
                </>
            )}
        </>
        : 
        <Loader />
    )
    
}

export default PrestaPageInfo;