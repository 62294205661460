import { React, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PasswordInput, Modal } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';

import PrestaRectangleButtonMain from '../../../composents/Buttons/Presta/RectangleButtonMain/RectangleButtonMain';
import RectangleButtonDisabled from '../../../composents/Buttons/All/RectangleButtonDisabled/RectangleButtonDisabled';
import LogoLogin from '../../../composents/Logo/LoginLogo/LogoLogin';

const PrestaResetPassword = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [openedConfirmationModal, {open: openConfirmationModal, close: closeConfirmationModal }] = useDisclosure(false);
    const [error, setError] = useState(null);

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const token = query.get('token');

    const navigate = useNavigate();

    useEffect(()=>{
        window.scrollTo(0,0);
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
         // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])


      
    const form = useForm({
        initialValues: {
            newPassword:"",
            newPasswordConfirmation:""
        },

        validate: {
            newPassword: (value) => (
                value && /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/.test(value)
                    ? null
                    : 'Le mot de passe doit comporter au moins 8 caractères avec une majuscule, une minuscule et un caractère spécial.'
                ),
            newPasswordConfirmation: (value, values) => (
                value.length < 1
                    ? 'Le mot de passe doit être identique.'
                    :  value !== values.newPassword
                    ? 'Le mot de passe doit être identique.'
                    : null
            )   
        }
    })

    const handleFormSubmit = async (values, _event) => {
        _event.preventDefault();

        try {
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/p/reset-password?token=${token}`, {
                method: "POST",
                body: JSON.stringify({
                    newPassword: values.newPassword,
                    newPasswordConfirmation: values.newPasswordConfirmation
                }),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            if(response.ok) {
                openConfirmationModal()
                setTimeout(() => {
                    navigate('/p/login')
                }, 500)
            } else {
                const errorResponse = await response.json();
                if (errorResponse.error === 'Votre lien de génération d\'un nouveau mot de passe a expiré ou a déjà été utilisé.') {
                    setError('Votre lien de génération d\'un nouveau mot de passe a expiré ou a déjà été utilisé.');
                }  else if (errorResponse.error === 'Lien non valide.') {
                    setError('Lien non valide.');  
                }  else if (errorResponse.error === 'Echec de mise à jour de votre nouveau mot de passe.') {
                    setError('Echec de mise à jour de votre nouveau mot de passe.');
                }
            }
        } catch (error) {
    }
}
    

    return(
        <>  
            {isMobile ? (
                <>
                    <div className="width90pCenter mrgT100 txtCenter">
                        <LogoLogin/>
                        <p className='txt20 bold'>Enregistrez votre nouveau mot de passe</p>
                    </div>
                    
                    <div className="width90pCenter mrgT20 w300p">
                        <form onSubmit={form.onSubmit(handleFormSubmit)}>
                        <PasswordInput
                            label="Nouveau mot de passe"
                            placeholder="********"
                            {...form.getInputProps('newPassword')}
                        />

                        <div className="mrgB5"></div>

                        <PasswordInput
                            label="Confirmation du nouveau mot de passe"
                            placeholder="********"
                            {...form.getInputProps('newPasswordConfirmation')}
                        />
                        <div className="mrgB30"></div>

                            {error && <p className='txt13 rT bold'>Erreur : {error}</p>}

                            {form.isDirty() 
                                ?   <PrestaRectangleButtonMain type="submit">
                                        Enregistrer
                                    </PrestaRectangleButtonMain>
                                :   <RectangleButtonDisabled>
                                        Enregistrer
                                    </RectangleButtonDisabled>
                            }
                            

                            { openConfirmationModal  
                                ?   <Modal 
                                        centered
                                        size="55%"
                                        opened={openedConfirmationModal}
                                        onClose={closeConfirmationModal}
                                        withCloseButton={false}>
                                        <p className="useRoboto txt15 txtCenter noMrgTB">
                                            Nouveau mot de passe enregistré
                                        </p>
                                    </Modal> 
                                :   ""
                            }
                        </form>
                    </div>
                </>
            ) : (
                <>
                    <div className="width90pCenter mrgT100 txtCenter">
                        <LogoLogin/>
                        <p className='txt30 bold'>Enregistrez votre nouveau mot de passe</p>
                    </div>
                    <div className="width30pCenter mrgT20 w300p">
                        <form onSubmit={form.onSubmit(handleFormSubmit)}>
                        <PasswordInput
                                label="Nouveau mot de passe"
                                placeholder="********"
                                size='lg'
                                {...form.getInputProps('newPassword')}
                            />

                            <div className="mrgB5"></div>

                            <PasswordInput
                                label="Confirmation du nouveau mot de passe"
                                placeholder="********"
                                size='lg'
                                {...form.getInputProps('newPasswordConfirmation')}
                            />
                            <div className="mrgB30"></div>

                            {error && <p className='txt13 rT bold'>Erreur : {error}</p>}

                            {form.isDirty() 
                                ?   <PrestaRectangleButtonMain type="submit">
                                        Enregistrer
                                    </PrestaRectangleButtonMain>
                                :   <RectangleButtonDisabled>
                                        Enregistrer
                                    </RectangleButtonDisabled>
                            }
                            

                            { openConfirmationModal  
                                ?   <Modal 
                                        centered
                                        size="auto"
                                        opened={openedConfirmationModal}
                                        onClose={closeConfirmationModal}
                                        withCloseButton={false}>
                                        <p className="useRoboto txt15 txtCenter noMrgTB">
                                            Nouveau mot de passe enregistré
                                        </p>
                                    </Modal> 
                                :   ""
                            }
                        </form>
                    </div>
                </>
            )}
        </>
    )
}

export default PrestaResetPassword;

