import { React, useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../../../contexts/authContext';
import { Link, useNavigate } from 'react-router-dom';

import { IconChevronRight, IconSearch, IconChevronDown, IconHammer, IconLocation, IconMessage } from '@tabler/icons-react';
import { Loader, Autocomplete, Select } from '@mantine/core';

import AppNavbarSyndic from '../../../Navbar/NavbarSyndic';
import FavoritePrestaHd from '../../../../composents/Headers/Customized/FavoritePrestaHd/FavoritePrestaHd';

const MyFavoritePresta = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [favPresta, setfavPresta] = useState(null);
    const [searchInput, setSearchInput] = useState('');
    const [filterValue, setFilterValue] =  useState('nom');

    const navigate = useNavigate();

    useEffect(() => {
        getFavPrestas();
        const token = getToken();
        if (!token) {
            navigate("/s/login");
            return;
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);
    
    const getFavPrestas = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/favPresta/fav-list`, {    
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`
                }
              });
            const favPresta = await response.json();
            setfavPresta(favPresta);
        } catch (error) { 
                console.error(error);
        }
    }

     const searchList = () => {
        let filteredPrestas = favPresta.favoritepresta.sort((a,b)=>a.company.name.localeCompare(b.company.name)).filter((favpresta) => {
            if (filterValue === "metier") {
                return (favpresta.occupation.toString()).toLowerCase().includes(searchInput.toLowerCase())
            }   
                return favpresta.company.name.toLowerCase().includes(searchInput.toLowerCase())
        });
        
    const filtered = filteredPrestas?.map((presta, index) => (
        <>
            {isMobile ? (
                <div key = {index} style = {{margin : '10px'}}>
                    <div>
                        <Link to={`/s/favPresta/p-page-info/${presta._id}`} className="aReset bT ">
                            <div className="horizontal iCenter spaceBtw mrgL10 mrgT20 mrgB20">
                                <div className="horizontal iCenter">
                                    <div className="mrgR10"> 
                                        {presta.company.logo
                                            ?   <img className="roundImg70"
                                                    src={presta.company.logo}
                                                    alt='logo'/>
                                            :   <div className="noAvatarProfile">{presta.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                        }
                                    </div>
                                    <div className="vertical">
                                        <div><h1 className="txt15 noMrgTB">{presta.company.name}</h1></div>
                                        <div><h1 className="txt12 capitals thin noMrgTB">{presta.username}</h1></div>
                                        <div><p className="txt13 bold noMrgTB finalDots">{presta.occupation.join(" - ")}</p></div>
                                        <div><p className="txt13 noMrgTB">{presta.company.address.city} - {presta.company.address.postalcode}</p></div>
                                        <div className="horizontal">
                                            <p className="txt13 noMrgTB"><span className="bold mrgR5">{presta.addedToFavBy.length}</span></p> 
                                            <p className="txt13 noMrgTB">ajouté en favoris</p>
                                        </div>
                                    </div>
                                </div>

                                <div ><IconChevronRight width={16} className="mrgLauto"/>
                                </div>  
                            </div>
                        </Link>
                    </div>
                </div>
            ) : (
                <div key = {index} style = {{marginBottom : '10px'}}>
                    <div className='width50pCenter'>
                        <Link to={`/s/favPresta/p-page-info/${presta._id}`} className="aReset bT">
                            <div className="horizontal bLiteGrey iCenter spaceBtw mrgT20 mrgB20">
                                <div className="horizontal iCenter mrgT20 mrgB20 mrgR20 mrgL20">
                                    <div className="mrgR20"> 
                                        {presta.company.logo
                                            ?   <img className="roundImg100"
                                                    src={presta.company.logo}
                                                    alt='logo'/>
                                            :   <div className="noAvatarProfile">{presta.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                        }
                                    </div>
                                    <div className="vertical">
                                        <div><h1 className="txt18 noMrgTB">{presta.company.name}</h1></div>
                                        <div><p className="txt16 capitals thin noMrgT mrgB5">{presta.username}</p></div>
                                        <div className="horizontal iStart">
                                            <div><IconHammer width={18} height={18}/></div>
                                            <p className="txt16 noMrgTB lineH18 mrgL10 mrgR10">{presta.occupation.join(" - ")}</p>
                                        </div>
                                        <div className="horizontal iCenter mrgB5">
                                            <div><IconLocation width={18} height={18}/></div>
                                            <p className="txt16 noMrgTB lineH18 mrgL10">{presta.company.address.city} - {presta.company.address.postalcode}
                                        </p></div>
                                        <div className="horizontal">
                                            <p className="txt16 noMrgTB"><span className="bold mrgR5">{presta.addedToFavBy.length}</span></p> 
                                            <p className="txt16 noMrgTB">ajouté en favoris</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='mrgR20'>
                                    <IconChevronRight width={25} className="mrgLauto"/>
                                </div>  
                            </div>
                        </Link>
                    </div>
                </div>
            )}
        </>
    ));

    return  <>
                {isMobile ? (
                    <div> 
                        {filteredPrestas.length > 0 
                            ?   <div className='mrgB100'>{filtered}</div>
                            :   <p className='txt15 dGreyT txtCenter mrgT50 width90pCenter'>Aucun résultat trouvé, cliquer sur l'icône + pour ajouter un nouveau prestataire à vos favoris</p>
                        }
                    </div>
                ) : (
                    <div> 
                        {filteredPrestas.length > 0 
                            ?   <div className='mrgB100'>{filtered}</div>
                            : <div className="width50pCenter vertical mrgT20">
                                <div className='emptyListNotification'>
                                    <div className= 'horizontal iCenter mrgT10'>
                                        <IconMessage width={100} height={45}/>
                                        <p className='txt20 bold'>
                                            Aucun résultat trouvé
                                        </p>
                                    </div>
                                
                                <div className='horizontal iCenter'>
                                    <IconMessage width={100} height={45} color='F8F8F8'/>
                                    <Link to="/s/favPresta/add-list" className='aReset'><p className='txt18 terracotaT mrgT10 mrgB30 mrgR50'>Ajouter un nouveau prestataire à mes favoris</p></Link>
                                </div> 
                            </div>

                            
                        </div>
                        }
                    </div>
                )}
            </>
    }
    
    return(
        favPresta ?
            <>
                {isMobile ? (
                    <div className="my-favorite-presta-list">
                        <AppNavbarSyndic/>
                        <FavoritePrestaHd >Mes prestataires favoris</FavoritePrestaHd>
                        <div className="width95pCenter horizontal spaceBtw">
                            <Autocomplete
                                className='w100 mrgR5'
                                icon = <IconSearch/>
                                placeholder= "Rechercher"
                                data={favPresta.favoritepresta.map(presta => presta.company.name)}
                                value={searchInput}
                                onChange={setSearchInput}
                            />

                            <Select
                                variant="filled"
                                className='w25 useRoboto'
                                data={[
                                    { value: 'nom', label: 'Nom' },
                                    { value: 'metier', label: 'Métier' }
                                ]}
                                defaultValue='nom'
                                value={filterValue}
                                onChange={setFilterValue}
                                radius="sm"
                                rightSection={<IconChevronDown size="1rem" />}
                                rightSectionWidth={30}
                                styles={{ rightSection: { pointerEvents: 'none' } }}
                                placeholder="Filtrer"
                                />
                        </div>
                        <div className="mrgB10"></div>
                        {searchList()}
                    </div>
                ) : (
                    <div className="my-favorite-presta-list">
                        <AppNavbarSyndic/>
                        <FavoritePrestaHd >Mes prestataires favoris</FavoritePrestaHd>
                        <div className='mrgB10'></div>

                        
                        <div className="width50pCenter horizontal spaceBtw">
                            <Autocomplete
                                className='w100 mrgR5'
                                icon = <IconSearch/>
                                placeholder= "Rechercher"
                                data={favPresta.favoritepresta.map(presta => presta.company.name)}
                                value={searchInput}
                                onChange={setSearchInput}
                                size='md'
                            />

                            <Select
                                variant="filled"
                                className='w25 useRoboto'
                                data={[
                                    { value: 'nom', label: 'Nom' },
                                    { value: 'metier', label: 'Métier' }
                                ]}
                                defaultValue='nom'
                                value={filterValue}
                                onChange={setFilterValue}
                                radius="sm"
                                rightSection={<IconChevronDown size="1rem" />}
                                rightSectionWidth={30}
                                styles={{ rightSection: { pointerEvents: 'none' } }}
                                placeholder="Filtrer"
                                size='md'
                                />
                        </div>
                        <div className="mrgB10"></div>
                        {searchList()}
                    </div>
                )}
            </>
        : 
        <Loader />
    )
    
}

export default MyFavoritePresta;