import { React, useEffect, useState, useContext } from 'react';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from '../../../../../contexts/authContext';

import { TextInput, Modal, Loader } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconCheck } from '@tabler/icons-react';
import { useForm } from '@mantine/form';

import AppHdWithCloseRight from '../../../../../composents/Headers/AppHeaders/AppHdWithCloseRight/AppHdWithCloseRight';
import Box from '../../../../../composents/Boxes/Standard/Box'
import PrestaRectangleButtonMain from '../../../../../composents/Buttons/Presta/RectangleButtonMain/RectangleButtonMain';
import PrestaRectangleButtonSnd from '../../../../../composents/Buttons/Presta/RectangleButtonSnd/RectangleButtonSnd';
import RectangleButtonDisabled from '../../../../../composents/Buttons/All/RectangleButtonDisabled/RectangleButtonDisabled';
import AppDesktopLogo from '../../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

const PrestaInfo = (props) => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [prestaInfo, setprestaInfo] = useState(null);
    const [prestaCieName, setprestaCieName] = useState(null);
    const [opened, { open, close }] = useDisclosure(false);
    const [ openedConfirmationModal, { open: openConfirmationModal, close: closeConfirmationModal}] = useDisclosure(false);

    let {id} = useParams()
    const navigate = useNavigate()

    const location = useLocation()
    const {idOffer} = location.state

    const getSinglePrestaInfo = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/p/matchs/p-info-reco/${id}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`
                }
              });
              const prestaInfo = await response.json();
              setprestaInfo(prestaInfo);
              let prestaCieName = prestaInfo.company.name
              setprestaCieName(prestaCieName)
            } catch (error) {
          }
    };

    useEffect(() => {
        getSinglePrestaInfo();
        const token = getToken();
        if (!token) {
            navigate("/p/login");
            return;
        };
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
            };
            window.addEventListener('resize', handleResize);
            return () => {
            window.removeEventListener('resize', handleResize);
            };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);
    
   

    
    const form = useForm({
        initialValues: {
            email: '',
        },
        validate: {
            email : (value) => (
                value.length < 1
                ? 'Renseignez votre E-mail'
                : /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) 
                ? null 
                : 'E-mail non valide'
            ),   
        }
    })

    const handleFormSubmit = async (values, _event) => {
        _event.preventDefault();
        try {
            const token = localStorage.getItem('token');
            await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/p/matchs/p-ext-reco/${id}`, {
                    method: "POST",
                body: JSON.stringify({
                    email: values.email,
                    recipientCieName : prestaCieName
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            openConfirmationModal()
            } catch (error) {
            }
    }
    
    const handleSend = async () => {
        try{
            const token = localStorage.getItem('token');
            await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/p/matchs/p-info-reco/${id}`, {
                method: "POST",
                body: JSON.stringify({
                    _id: idOffer
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            await getSinglePrestaInfo()
            open()
        } catch (error) {
        }
    }

    const handleCancelSending = async () => {
        try{
            const token = localStorage.getItem('token');
            await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/p/matchs/cancel-conf-reco/${id}`, {
                method: "POST",
                body: JSON.stringify({
                    _id: idOffer
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            await getSinglePrestaInfo()
        } catch (error) {
        }
    }

    const prestaOffers = prestaInfo && prestaInfo.recommendByConfrereOffers;
    const isAnyOfferMatched = prestaOffers && prestaOffers.some(offer => offer.includes(idOffer))

    return(
        prestaInfo ?
            <>
                {isMobile ? (
                    <>
                        <div className="width95pCenter">
                            <AppHdWithCloseRight>{prestaInfo.company.name}</AppHdWithCloseRight>
                            <Box>
                                <div className="horizontal iCenter">
                                    <div className="mrgL10">
                                        {prestaInfo.company.logo
                                            ?   <img className="roundImg70"
                                                    src={prestaInfo.company.logo}
                                                    alt='logo'
                                                    size={88}
                                                />
                                            :   <>
                                                    {prestaInfo.company.isItAmember
                                                        ?   <div className="noAvatarProfile">{prestaInfo.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                        :   ""
                                                    }    
                                                </>        
                                        }
                                    </div>
                                    <div className="mrgR10"></div>
                                    <div className="vertical iStart jCenter">
                                        <div><p className="txt15 noMrgTB bold capitals">{prestaInfo.company.name}</p></div>
                                        <div>
                                        {prestaInfo.company.isItAmember 
                                            ?   <h1 className="txt12 capitals thin noMrgTB">{prestaInfo.username}</h1>
                                            :   ""
                                        } 
                                        </div>
                                        {prestaInfo.company.isItAmember 
                                            ?    <div><p className="txt13 noMrgT mrgB10 medium">{prestaInfo.occupation.join(' - ')}</p></div>
                                            :   <div><p className="txt13 noMrgTB mrgB10 medium">{prestaInfo.company.prospectOccupation.join(" - ")}</p></div>
                                        }
                                        <div><p className="txt13 noMrgTB">{prestaInfo.company.address.no}, {prestaInfo.company.address.street}</p></div>
                                        <div><p className="txt13 noMrgTB">{prestaInfo.company.address.postalcode} {prestaInfo.company.address.city}</p></div>
                                        </div>     
                                </div>
                            </Box>  
                            <div className="mrgB20"></div>

                            <div className='width95pCenter'>
                                {prestaInfo.company.isItAmember 
                                    ?   
                                        <>  {prestaInfo.recommendByConfrereOffers.length > 0
                                                ?
                                                    <>  
                                                        <>
                                                            {prestaInfo.recommendByConfrereOffers.map((offer, index) => 
                                                                offer.includes(idOffer) 
                                                                ? 
                                                                    <PrestaRectangleButtonMain key = {index} onClick={handleCancelSending} >
                                                                        <div className='horizontal iCenter jCenter'>
                                                                            <p className="noMrgTB mrgR5">Recommandé</p>
                                                                            <IconCheck className="noMrgTB" width={15} />
                                                                        </div>
                                                                    </PrestaRectangleButtonMain>
                                                                : null
                                                            )}
                                                            
                                                            {!isAnyOfferMatched && (
                                                            <PrestaRectangleButtonSnd onClick={handleSend}>
                                                                Recommander
                                                            </PrestaRectangleButtonSnd>
                                                            )}
                                                        </>
                        
                                                        {open  
                                                            ?   <Modal 
                                                                    centered
                                                                    size="auto"
                                                                    opened={opened}
                                                                    onClose={close}
                                                                    withCloseButton={false}
                                                                    className='useRoboto'>
                                                                    <div className="vertical txtCenter noMrgTB">
                                                                        <p className="noMrgTB txt15">Recommandation envoyée à</p> 
                                                                        <p className="mrgT5 noMrgB txt15 medium capitals">{prestaInfo.company.name}</p>
                                                                    </div>
                                                                </Modal> 
                                                            :   ""
                                                        }
                                                    </>
                                                :   <PrestaRectangleButtonSnd onClick={handleSend}>
                                                        Recommander
                                                    </PrestaRectangleButtonSnd>
                                            }
                                        </>
                                    :   <>
                                            <p className='txt15 dGreyT'>Votre confrère n'est pas référencé chez Boldee, envoyez lui votre recommandation d'offre de travaux par email.</p>
                                            <form onSubmit={form.onSubmit(handleFormSubmit)}>
                                                <TextInput
                                                    withAsterisk
                                                    label="Recommander l'offre à :"
                                                    placeholder="Email"
                                                    {...form.getInputProps('email')}
                                                />
                                                <div className="mrgB10"></div>

                                                
                                                {form.isDirty() 
                                                    ?   <PrestaRectangleButtonMain type="submit">
                                                            Recommander
                                                        </PrestaRectangleButtonMain>
                                                    :   <RectangleButtonDisabled>
                                                            Recommander
                                                        </RectangleButtonDisabled>
                                                }
                                                { openConfirmationModal  
                                                    ?   <Modal 
                                                            centered
                                                            size="55%"
                                                            opened={openedConfirmationModal}
                                                            onClose={closeConfirmationModal}
                                                            withCloseButton={false}>
                                                            <p className="useRoboto txt15 txtCenter noMrgTB">
                                                                Recommandation envoyée
                                                            </p>
                                                        </Modal> 
                                                    :   ""
                                                }
                                            </form>
                                        </>
                                }
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <AppDesktopLogo />
                        <AppHdWithCloseRight>{prestaInfo.company.name}</AppHdWithCloseRight>
                        <div className="width50pCenter">
                            <Box>
                                <div className="horizontal iCenter mrgT10 mrgB10 mrgL10">
                                    <div className="mrgL10">
                                        {prestaInfo.company.logo
                                            ?   <img className="roundImg100"
                                                    src={prestaInfo.company.logo}
                                                    alt='logo'
                                                    size={88}
                                                />
                                            :   <div className="noAvatarProfile">{prestaInfo.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                        }
                                    </div>
                                    <div className="mrgR20"></div>
                                    <div className="vertical iStart jCenter">
                                        <div><p className="txt16 noMrgTB bold capitals">{prestaInfo.company.name}</p></div>
                                        <div><h1 className="txt15 capitals thin noMrgT mrgB5">{prestaInfo.username}</h1></div>
                                        {prestaInfo.company.isItAmember
                                            ?    <div><p className="txt16 noMrgTB medium mrgR10">{prestaInfo.occupation.join(' - ')}</p></div>
                                            :   <div><p className="txt16 noMrgTB medium mrgR10">{prestaInfo.company.prospectOccupation.join(" - ")}</p></div>
                                        }
                                        <div><p className="txt15 noMrgTB">{prestaInfo.company.address.no}, {prestaInfo.company.address.street}</p></div>
                                        <div><p className="txt15 noMrgTB">{prestaInfo.company.address.postalcode} {prestaInfo.company.address.city}</p></div>
                                        </div>     
                                </div>
                            </Box>  
                            <div className="mrgB20"></div>

                            <div>
                                {prestaInfo.company.isItAmember 
                                    ?   
                                        <>  {prestaInfo.recommendByConfrereOffers.length > 0
                                                ?
                                                    <>  
                                                        <>
                                                            {prestaInfo.recommendByConfrereOffers.map((offer, index) => 
                                                                offer.includes(idOffer) 
                                                                ? 
                                                                    <PrestaRectangleButtonMain key= {index} onClick={handleCancelSending} >
                                                                    <div className='horizontal iCenter jCenter'>
                                                                        <p className="noMrgTB mrgR5 txt16">Recommandé</p>
                                                                        <IconCheck className="noMrgTB" width={15} />
                                                                    </div>
                                                                    </PrestaRectangleButtonMain>
                                                                : null
                                                            )}
                                                            
                                                            {!isAnyOfferMatched && (
                                                            <PrestaRectangleButtonSnd onClick={handleSend}>
                                                                <p className='txt16 noMrgTB'>Recommander</p>
                                                            </PrestaRectangleButtonSnd>
                                                            )}
                                                        </>
                        
                                                        {open  
                                                            ?   <Modal 
                                                                    centered
                                                                    size="auto"
                                                                    opened={opened}
                                                                    onClose={close}
                                                                    withCloseButton={false}
                                                                    className='useRoboto'>
                                                                    <div className="vertical txtCenter noMrgTB">
                                                                        <p className="noMrgTB txt16">Recommandation envoyée à</p> 
                                                                        <p className="mrgT5 noMrgB txt16 medium capitals">{prestaInfo.company.name}</p>
                                                                    </div>
                                                                </Modal> 
                                                            :   ""
                                                        }
                                                    </>
                                                :   <PrestaRectangleButtonSnd onClick={handleSend}>
                                                        <p className='txt16 noMrgTB'>Recommander</p>
                                                    </PrestaRectangleButtonSnd>
                                            }
                                        </>
                                    :   <div>
                                            <p className='txt16 dGreyT txtCenter w80'>Votre confrère n'est pas référencé chez Boldee, envoyez lui votre recommandation d'offre de travaux par email.</p>
                                            <form onSubmit={form.onSubmit(handleFormSubmit)}>
                                                <TextInput
                                                    withAsterisk
                                                    label="Recommander l'offre à :"
                                                    placeholder="Email"
                                                    size="md"
                                                    {...form.getInputProps('email')}
                                                />
                                                <div className="mrgB20"></div>

                                                
                                                {form.isDirty() 
                                                    ?   <PrestaRectangleButtonMain type="submit">
                                                            <p className='txt16 noMrgTB'>Recommander</p>
                                                        </PrestaRectangleButtonMain>
                                                    :   <RectangleButtonDisabled>
                                                            <p className='txt16 noMrgTB'>Recommander</p>
                                                        </RectangleButtonDisabled>
                                                }
                                                { openConfirmationModal  
                                                    ?   <Modal 
                                                            centered
                                                            size="auto"
                                                            opened={openedConfirmationModal}
                                                            onClose={closeConfirmationModal}
                                                            withCloseButton={false}>
                                                            <p className="useRoboto txt16 txtCenter noMrgTB">
                                                                Recommandation envoyée
                                                            </p>
                                                        </Modal> 
                                                    :   ""
                                                }
                                            </form>
                                        </div>
                                }
                            </div>
                        </div>
                    </>
                )}
            </>
        : 
        <Loader />
    )
}

export default PrestaInfo;