import { React, useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../../../contexts/authContext';
import { Link, useParams, useNavigate } from "react-router-dom";

import { IconHammer, IconLocation, IconCalendarEvent, IconEye, IconPhone } from '@tabler/icons-react';
import { Badge, Loader, Modal, Group, Button } from '@mantine/core';
import { parseISO, format } from 'date-fns';
import { useDisclosure } from '@mantine/hooks';

import AppHdWithCloseRight from '../../../../composents/Headers/AppHeaders/AppHdWithCloseRight/AppHdWithCloseRight';
import Box from '../../../../composents/Boxes/Standard/Box';
import PrestaRectangleButtonMain from '../../../../composents/Buttons/Presta/RectangleButtonMain/RectangleButtonMain';
import SyndicRectangleButtonSnd from '../../../../composents/Buttons/Syndic/RectangleButton/RectangleButtonSecond';
import AppDesktopLogo from '../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

const QuotationInfo = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [quotation, setQuotation] = useState(null);
    const [deleteQuotation, setDeleteQuotation] = useState(false);
    const [opened, { open, close }] = useDisclosure(false);
    const [openedContactInfo, { open : openContactInfo, close : closeContactInfo}] = useDisclosure(false);

    const navigate = useNavigate()
    let {id} = useParams()

    useEffect(() => {
        getSingleQuotation();
        const token = getToken();
        if (!token) {
            navigate("/p/login");
            return;
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);
    
    const getSingleQuotation = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/p/quo/${id}`, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`
                }
              });
              const quotation = await response.json();
              const rangeDate = quotation.offer.workRangeDates
              quotation.startDate = format(parseISO(rangeDate[0]), 'dd/MM/yyyy');
              quotation.endDate = format(parseISO(rangeDate[1]), 'dd/MM/yyyy');
              setQuotation(quotation);
        } catch (error) {
        }
    }  

    const handleOpenDeleteModal = async () => {
        setDeleteQuotation(true);
        open()
    }

    const handleArchiveSubmit = async event => {
        const id = event.currentTarget.id;
        try {
            const token = localStorage.getItem('token');
            await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/p/quo/archive/${id}`, {
                method: "POST",
                body: JSON.stringify({
                    _id : id
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            setTimeout(() => {
                navigate('/p/quo/list');
            }, 500)
        } catch (error) {
        }
    }

    const openPDF = (event) => {
        let win = window.open()
        win.document.write('<iframe src="' + event.currentTarget.id  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    };

    const handleCall = (event) => {
        const phoneNumber = event.currentTarget.id
        window.location.href = `tel:${phoneNumber}`
    };

    const showContact = () => {
        openContactInfo()
    };

    return (
        quotation ?
            <>
                {isMobile ? (
                    <>
                        <AppHdWithCloseRight>Information devis</AppHdWithCloseRight>

                        <Link to={`/p/quo/info-offer/${quotation.offer._id}`} className="aReset bT">               
                            <div>
                                {quotation.status === "acceptedBySyndic" 
                                    ?   <p className="txtCenter bold greenT">Accepté</p>
                                    :   ""
                                }
                            </div>
                            <Box>
                                <div className="horizontal iCenter mrgR10">
                                    <div className="vertical mrgR10 jCenter">                         
                                        <div className="mrgL20">
                                            {quotation.offer.pictures.length > 0
                                                ?    <img 
                                                        className="roundImg70"
                                                        src={quotation.offer.pictures[0]}
                                                        alt='photo_chantier'/> 
                                                :   <>
                                                        {quotation.offer.author.company.logo
                                                            ?   <img 
                                                                    className="roundImg70"
                                                                    src={quotation.offer.author.company.logo}
                                                                    alt='photo_chantier'/>
                                                            :   <div className="noAvatarProfile">{quotation.offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                        }
                                                    </>
                                            }   
                                        </div>                            
                                    </div>

                                    <div className="vertical jCenter mrgL10">
                                        <div className='horizontal iBtm'>
                                            <p className="txt11 thin noMrgTB capitals">{quotation.offer.author.company.name}</p> 

                                            <div className='mrgR5'></div>

                                            <div>
                                                {quotation.offer.urgent 
                                                    ?   <Badge color="red">Urgent</Badge> 
                                                    :   ""
                                                }
                                            </div>

                                            <div className='mrgR5'></div>
                                                                                

                                        </div>

                                        <div>
                                            <p className="txt15 lineH15 mediumplus noMrgT mrgB5">{quotation.offer.title}</p>
                                        </div>
                                        
                                        <div className="horizontal iCenter">
                                            <IconHammer width={13} height={13} className="mrgR5"/>
                                            <p className="noMrgTB txt13 mrgL3">{quotation.offer.occupation}</p>
                                        </div>
                                        <div className="horizontal iCenter">
                                            <IconCalendarEvent width={13} height={13} className="mrgR5"/>
                                            <p className="noMrgTB txt13 mrgL3">Du {quotation.startDate} au {quotation.endDate}</p>
                                        </div>
                                        <div className="horizontal iCenter">
                                            <IconLocation width={13} height={13} className="mrgR5"/>

                                            <p className="noMrgTB txt13 mrgL3">{quotation.offer.cityOfIntervention}</p>
                                        </div>     
                                    </div>
                                </div>
                            </Box>
                        </Link>

                        <div className="mrgB20"></div>

                        <div className="width90pCenter">
                        <div><p className="txt15 mediumplus noMrgT mrgB5">Devis envoyé le {format(parseISO(quotation.sendDate), 'dd/MM/yyyy')}</p></div>
                        </div>
                        <Box>
                            <div className="horizontal iCenter spaceBtw">
                                <p className="txtJustify bold txt15 noMrgTB mrgL20">
                                    {quotation.quotationName}
                                </p>
                                <div id={quotation.downloadLink} onClick={openPDF} className='aReset bT'>
                                    <IconEye width={20} className="mrgR10 mrgT5 noMrgTB"/>
                                </div>
                            </div>
                        </Box>
                        <div className="mrgB20"></div>

                        <div className="width90pCenter">
                            {quotation.status === "acceptedBySyndic" 
                                ?   <>
                                        <PrestaRectangleButtonMain id={quotation.offer.author.phone} onClick={handleCall}>
                                            Contacter
                                        </PrestaRectangleButtonMain>
                                        <div className='mrgB10'></div>   
                                                            
                                            <SyndicRectangleButtonSnd onClick={handleOpenDeleteModal}>Archiver devis</SyndicRectangleButtonSnd>
                                            { open && deleteQuotation === true
                                                ?   <Modal 
                                                        centered
                                                        withCloseButton={false}    
                                                        opened={opened} 
                                                        onClose={close}
                                                        size="auto"
                                                        className='useRoboto'>
                                                            <p className="txtCenter mrgT5 txt15 noMrgB"> Archiver votre devis ?</p>
                                                            <p className="txtCenter mrgT5 txt13 noMrgT"> Archivage automatique au bout d'un an.</p>
                                                            <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20">
                                                                <Button variant="outline" color="dark" onClick={close} className='useRoboto'>
                                                                    Annuler
                                                                </Button>
                                                                <Button variant="filled" color="dark" id={quotation._id} className='useRoboto medium' onClick={handleArchiveSubmit}>
                                                                    Archiver
                                                                </Button>
                                                            </Group>       
                                                    </Modal>
                                                :   ""
                                            }
                                        
                                    </>
                                :   ""
                            }                
                        </div>
                        
                    </>
                ) : (
                    <>
                        <AppDesktopLogo/>
                        <AppHdWithCloseRight>Information devis</AppHdWithCloseRight>
                        <div className="width50pCenter">
                            <Link to={`/p/quo/info-offer/${quotation.offer._id}`} className="aReset bT">               
                                <div>
                                    {quotation.status === "acceptedBySyndic" 
                                        ?   <p className="txtCenter bold greenT txt20">Accepté</p>
                                        :   ""
                                    }
                                </div>
                                <Box>
                                    <div className="horizontal iCenter useRoboto mrgT20 mrgB20">
                                        <div className="vertical iCenter mrgL20 mrgR20">                         
                                            <div>
                                                {quotation.offer.pictures.length > 0
                                                    ?    <img 
                                                            className="roundImg100"
                                                            src={quotation.offer.pictures[0]}
                                                            alt='photo_chantier'/> 
                                                    :   <>
                                                            {quotation.offer.author.company.logo
                                                                ?   <img 
                                                                        className="roundImg100"
                                                                        src={quotation.offer.author.company.logo}
                                                                        alt='photo_chantier'/>
                                                                :   <div className="noAvatarProfile">{quotation.offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                            }
                                                        </>
                                                }   
                                            </div>                            
                                        </div>

                                        <div className="vertical jCenter">
                                            <div className='horizontal iBtm'>
                                                <p className="txt18 capitals noMrgT mrgB5">{quotation.offer.author.company.name}</p> 
                                                <div>
                                                    {quotation.offer.urgent 
                                                        ?   <Badge size='lg' color="red">Urgent</Badge> 
                                                        :   ""
                                                    }
                                                </div>

                                                <div className='mrgR10'></div>
                                            </div>

                                            <div>
                                                <p className="txt18 mediumplus noMrgT mrgB5">{quotation.offer.title}</p>
                                            </div>
                                            
                                            <div className="horizontal iCenter mrgB5">
                                                <IconHammer width={20} height={20} className="mrgR10"/>
                                                <p className="noMrgTB txt16 mrgR5">{quotation.offer.occupation}</p>
                                            </div>
                                            <div className="horizontal iCenter mrgB5">
                                                <IconCalendarEvent width={20} height={20} className="mrgR10"/>
                                                <p className="noMrgTB txt16 mrgR5">Du {quotation.startDate} au {quotation.endDate}</p>
                                            </div>
                                            <div className="horizontal iCenter mrgB5">
                                                <IconLocation width={20} height={20} className="mrgR10"/>

                                                <p className="noMrgTB txt16 mrgR5">{quotation.offer.cityOfIntervention}</p>
                                            </div>     
                                        </div>
                                    </div>
                                </Box>
                            </Link>

                            <div className="mrgB20"></div>

                        
                            <div><p className="txt18 mediumplus noMrgT mrgB10">Devis envoyé le {format(parseISO(quotation.sendDate), 'dd/MM/yyyy')}</p></div>
                            
                            <Box>
                                <div className="horizontal iCenter spaceBtw">
                                    <p className="txtJustify bold txt18 mrgT10 mrgB10 mrgL20">
                                        {quotation.quotationName}
                                    </p>
                                    <div id={quotation.downloadLink} onClick={openPDF} className='aReset bT'>
                                        <IconEye width={23} className="mrgR20 mrgT5 noMrgTB"/>
                                    </div>
                                </div>
                            </Box>
                            
                            <div className="mrgB30"></div>

                            {quotation.status === "acceptedBySyndic" 
                                ?   <> 
                                        <PrestaRectangleButtonMain id={quotation.offer.author.phone} onClick={showContact}>
                                            Contacter
                                        </PrestaRectangleButtonMain>
                                        { openContactInfo 
                                            ?   <Modal 
                                                    centered
                                                    size="auto"
                                                    opened={openedContactInfo}
                                                    onClose={closeContactInfo}
                                                    title="Contacter">
                                                    <div className='mrgR40 mrgL40'>
                                                        <p className="useRoboto txtCenter bold txt18 noMrgT mrgB10">
                                                            {quotation.offer.author.company.name} 
                                                        </p>
                                                        <p className="horizontal txtCenter iCenter useRoboto txt16 noMrgT mrgB10">
                                                            <div className='noMrgTB mrgR5'><IconPhone width={20} height={20}/></div>
                                                            <p className='noMrgT mrgB5 txt18'>{quotation.offer.author.phone}</p>
                                                        </p>
                                                    </div>
                                                </Modal>
                                            :   ""
                                        } 
                                        <div className='mrgB10'></div>   
                                                            
                                            <SyndicRectangleButtonSnd onClick={handleOpenDeleteModal}>Archiver devis</SyndicRectangleButtonSnd>
                                            { open && deleteQuotation === true
                                                ?   <Modal 
                                                        centered
                                                        withCloseButton={false}    
                                                        opened={opened} 
                                                        onClose={close}
                                                        size="auto"
                                                        className='useRoboto'>
                                                            <p className="txtCenter mrgT5 txt16 noMrgB"> Archiver votre devis ?</p>
                                                            <p className="txtCenter mrgT5 txt15 noMrgT"> Archivage automatique au bout d'un an.</p>
                                                            <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20">
                                                                <Button variant="outline" color="dark" onClick={close} className='useRoboto'>
                                                                    <p className='txt16'>Annuler</p>
                                                                </Button>
                                                                <Button variant="filled" color="dark" id={quotation._id} className='useRoboto medium' onClick={handleArchiveSubmit}>
                                                                    <p className='txt16'>Archiver</p>
                                                                </Button>
                                                            </Group>       
                                                    </Modal>
                                                :   ""
                                            }
                                        
                                    </>
                                :   ""
                            }                
                        </div>
                        
                    </>
                )}
            </>
        : 
        <Loader />
    )
}

export default QuotationInfo; 