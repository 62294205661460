import React from "react";
import boldeeLogo from '../../../assets/images/logo_boldee_BW.png'
import classes from './LogoFooter.module.css';

const LogoFooter = (props) => {
    return(
        <div className={classes.Logo}>
            <img src={boldeeLogo} alt="logo"/>
        </div>
    )
}
export default LogoFooter;