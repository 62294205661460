import { React, useMemo, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../../../contexts/authContext';
import { Link, useNavigate } from 'react-router-dom';

import { Loader} from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import Box from '../../../../../composents/Boxes/Standard/Box';
import AppHdWithCloseRight from '../../../../../composents/Headers/AppHeaders/AppHdWithCloseRight/AppHdWithCloseRight';
import AppDesktopLogo from '../../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

const WhoAddedMeInFavorite = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [listSyndic, setlistSyndic] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/p/login");
            return;
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSyndics = async () => { 
        try {
            const token = localStorage.getItem("token");
            const response = await fetch("https://boldee-97d8d36af3bd.herokuapp.com/p/profile/favoriteof", {
                method: "GET",
                headers: {
                Authorization: `Bearer ${token}`
                }
            });
            const list = await response.json();
            setlistSyndic(list)
        } catch (error) { console.error(error);
      }
    }  

    useMemo( async () => {
        await getSyndics()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        listSyndic ?
            <>
                {isMobile ? (
                    <>
                        <AppHdWithCloseRight>Ils vous ont ajouté en favoris</AppHdWithCloseRight>
                        {listSyndic.map((syndic, index) => (
                        <div key = {index} style = {{margin : '5px'}}>
                            <Box>
                                <Link to={{
                                        pathname: `/p/profile/s-info/${syndic._id}`,
                                    }}
                                    className="aReset bT">
                                    <div className="horizontal iCenter spaceBtw mrgL10 mrgR20">
                                        <Box>
                                            <div className="horizontal iCenter mrgL10">
                                                {syndic.company.logo
                                                    ?   <img src={syndic.company.logo}
                                                            className="roundImg70"
                                                            alt='logo'/> 
                                                    :   <div className="noAvatarProfile">{syndic.company.name.charAt(0)}</div>
                                                }
                                                
                                                <div>
                                                    <p className='mrgL10 noMrgTB'>{syndic.firstname} <span className=" medium capitals">{syndic.lastname}</span></p>
                                                    <p className="mrgL10 mrgT5 noMrgB capitals txt13 dGreyT">{syndic.company.name}</p>

                                                </div>
                                            </div>
                                        </Box>

                                        <IconChevronRight width={16} />
                                    </div>
                                </Link>
                            </Box>
                            <div className="mrgB10"></div>
                        </div>
                        ))}
                    </>
                ) : (
                    <>
                        <AppDesktopLogo/>

                        <AppHdWithCloseRight>Ils vous ont ajouté en favoris</AppHdWithCloseRight>
                        <div className='width50pCenter'>  
                            {listSyndic.map((syndic, index) => (
                            <div key = {index} style = {{margin : '10px'}}>
                                <Box>
                                    <Link to={{
                                            pathname: `/p/profile/s-info/${syndic._id}`,
                                        }}
                                        className="aReset bT">
                                        <div className="horizontal iCenter spaceBtw mrgL10 mrgR20">
                                            <Box>
                                                <div className="horizontal iCenter mrgL20">
                                                    {syndic.company.logo
                                                        ?   <img src={syndic.company.logo}
                                                                className="roundImg100"
                                                                alt='logo'/> 
                                                        :   <div className="noAvatarProfile">{syndic.company.name.charAt(0)}</div>
                                                    }
                                                    
                                                    <div className='mrgL30'>
                                                        <p className='noMrgTB txt18 medium'>{syndic.firstname} <span className="capitals">{syndic.lastname}</span></p>
                                                        <p className="mrgT5 noMrgB capitals txt18 dGreyT">{syndic.company.name}</p>

                                                    </div>
                                                </div>
                                            </Box>

                                            <IconChevronRight width={25} className='mrgR20'/>
                                        </div>
                                    </Link>
                                </Box>
                                <div className="mrgB10"></div>
                            </div>
                            ))}
                        </div>  
                    </>
                )}
            </>
        : 
        <Loader />

    )
}

export default WhoAddedMeInFavorite;