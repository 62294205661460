import classes from './MatchActionButton.module.css'

const MatchActionButton = (props) => {
    return(
        <button
            className={classes.actionBtn}
            type={props.type || "button"}
            onClick={props.onClick}
            >{props.children}
        </button>
    )
}

export default MatchActionButton;