import { React, useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../../../contexts/authContext';
import { useParams, useNavigate } from "react-router-dom";
import { useDisclosure } from '@mantine/hooks';
import AppHdWithCloseRight from '../../../../composents/Headers/AppHeaders/AppHdWithCloseRight/AppHdWithCloseRight';
import { Loader, Modal } from '@mantine/core';
import { IconPhone } from '@tabler/icons-react';
import PrestaRectangleButtonMain from '../../../../composents/Buttons/Presta/RectangleButtonMain/RectangleButtonMain';
import Box from '../../../../composents/Boxes/Standard/Box'
import AppDesktopLogo from '../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

const SyndicInfo = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [syndicInfo, setsyndicInfo] = useState(null);
    const [openedContactInfo, { open : openContactInfo, close : closeContactInfo}] = useDisclosure(false);
    
    let {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getSyndicInfo();
        const token = getToken();
        if (!token) {
            navigate("/p/login");
            return;
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);
    
    const getSyndicInfo = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/p/matchs/s-info/${id}`, {
                method: "GET",
                headers: {
                Authorization: `Bearer ${token}`
                }
            });
            let syndicInfo = await response.json();
            setsyndicInfo(syndicInfo);
        } catch (error) {
        }
    }  

    const handleCall = (event) => {
        const phoneNumber = event.currentTarget.id
        window.location.href = `tel:${phoneNumber}`
    }

    const showContact = () => {
        openContactInfo()
    };
    
    return(
        syndicInfo ?
            <>
                {isMobile ? (
                    <>
                        <div>
                            <AppHdWithCloseRight>{syndicInfo.author.company.name}</AppHdWithCloseRight>
                            <Box>
                                <div className="horizontal iCenter mrgL20 mrgT10 mrgB10">
                                    <div className="mrgR10 mrgL10">
                                        {syndicInfo.author.company.logo
                                            ?   <img src={syndicInfo.author.company.logo}
                                                    className='roundImg70'
                                                    alt='logo'/>
                                            :   <div className="noAvatarProfile">{syndicInfo.author.company.name.charAt(0)}</div>
                                        }
                                    
                                    </div>
                                    <div className="vertical jCenter mrgL10">
                                        <div><p className="txt15 noMrgT mrgB5 bold capitals lineH15">{syndicInfo.author.company.name}</p></div>
                                        <div><p className="txt13 noMrgTB">{syndicInfo.author.company.address.no}, {syndicInfo.author.company.address.street}</p></div>
                                        <div><p className="txt13 noMrgT mrgB5">{syndicInfo.author.company.address.postalcode} {syndicInfo.author.company.address.city}</p></div>
                                        <div><p className="txt13 noMrgTB">{syndicInfo.author.company.emailcie}</p></div>
                                    </div>
                                </div>
                            </Box>

                            <div className="mrgB20"></div>

                            <div className="width90pCenter">
                                <PrestaRectangleButtonMain id={syndicInfo.author.company.address.phonecie} onClick={handleCall} className='btnReset'>
                                    Contacter
                                </PrestaRectangleButtonMain>
                            </div>            
                        </div>
                    </>
                ) : (
                    <>
                        <AppDesktopLogo />
                        <AppHdWithCloseRight>{syndicInfo.author.company.name}</AppHdWithCloseRight>
                        <div className='width50pCenter'>    
                            <Box>
                                <div className="horizontal iCenter mrgL20 mrgT20 mrgB20">
                                    <div className="mrgR20 mrgL20">
                                        {syndicInfo.author.company.logo
                                            ?   <img src={syndicInfo.author.company.logo}
                                                    className='roundImg100'
                                                    alt='logo'/>
                                            :   <div className="noAvatarProfile">{syndicInfo.author.company.name.charAt(0)}</div>
                                        }
                                    
                                    </div>
                                    <div className="vertical jCenter mrgL10">
                                        <div><p className="txt18 noMrgT mrgB10 mediumplus capitals lineH15">{syndicInfo.author.company.name}</p></div>
                                        <div><p className="txt16 noMrgTB">{syndicInfo.author.company.address.no}, {syndicInfo.author.company.address.street}</p></div>
                                        <div><p className="txt16 noMrgT mrgB5">{syndicInfo.author.company.address.postalcode} {syndicInfo.author.company.address.city}</p></div>
                                        <div><p className="txt16 noMrgTB">{syndicInfo.author.company.emailcie}</p></div>
                                    </div>
                                </div>
                            </Box>

                            <div className="mrgB20"></div>

                            <div>
                                <PrestaRectangleButtonMain onClick={showContact} className='btnReset'>
                                    <p className='txt16'>Contacter</p>
                                </PrestaRectangleButtonMain>
                            </div>
                            { openContactInfo 
                                ?   <Modal 
                                        centered
                                        size="auto"
                                        opened={openedContactInfo}
                                        onClose={closeContactInfo}
                                        title="Contacter">
                                        <div className='mrgR40 mrgL40'>
                                            <p className="useRoboto txtCenter bold txt18 noMrgT mrgB10">
                                                {syndicInfo.author.company.name} 
                                            </p>
                                            <p className="horizontal txtCenter iCenter useRoboto txt16 noMrgT mrgB10">
                                                <div className='noMrgTB mrgR5'><IconPhone width={20} height={20}/></div>
                                                <p className='noMrgT mrgB5 txt18'>{syndicInfo.author.company.address.phonecie}</p>
                                            </p>
                                        </div>
                                    </Modal>
                                :   ""
                            } 
                        </div>            
                    </>
                )}
            </>
        : 
        <Loader />
    )  
}

export default SyndicInfo;