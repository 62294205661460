import { React, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../../../contexts/authContext';
import { useParams, useNavigate } from 'react-router-dom';

import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import OfferCard from '../../../../Offer/OfferCard';
import SyndicRectangleButton from '../../../../../composents/Buttons/Syndic/RectangleButton/RectangleButtonMain'
import SyndicRectangleButtonSnd from '../../../../../composents/Buttons/Syndic/RectangleButton/RectangleButtonSecond';
import AppDesktopLogo from '../../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

const NewSpeOfferRecap = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [opened, { open, close }] = useDisclosure(false);

    const [favPrestaName, setFavPrestaName] = useState('');
    const favUserId = localStorage.getItem('favUserId')
    let {id} = useParams()
    const navigate = useNavigate();

    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/s/login");
            return;
        }
        getFavUser();
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const getFavUser = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/getPUser/${favUserId}`, {
                method: "GET",    
                headers: {
                    Authorization: `Bearer ${token}`
                    }
        });
            const data = await response.json()
            setFavPrestaName(data.company.name)
        }   catch (error) {
            return null;
        }
    }
    
    const HandleOfferPublication  = async () => {
        open()

        try {
            const token = localStorage.getItem('token');
            await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/favPresta/add-offer/${id}`, {
                method: "POST",
                body: JSON.stringify({
                    id: id,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            setTimeout(() => {
                navigate('/s/offers/list');
            }, 1000)

        } catch (error) {
        }
    }

    const HandleModification = () => {
        navigate(`/s/favPresta/preoffer-edit/${id}`)

    }
        return(
            <>
                {isMobile ? (
                    <>
                        <div>
                            <OfferCard isEditOffer={false} isEditSpeOffer={true}/>
                            

                            <div className="width90pCenter">
                                <SyndicRectangleButtonSnd onClick={HandleModification}>
                                    Retour
                                </SyndicRectangleButtonSnd>

                                <div className="mrgB10"></div>

                                <SyndicRectangleButton
                                    onClick={HandleOfferPublication}
                                >
                                    Envoyer mon offre à {favPrestaName}
                                </SyndicRectangleButton>
                                {open ?
                                    <Modal 
                                        centered
                                        size="auto"
                                        opened={opened}
                                        onClose={close}
                                        withCloseButton={false}>
                                        <p className="txtCenter noMrgTB useRoboto txt15">
                                            Votre offre a été envoyée
                                        </p>
                                    </Modal> 
                                : 
                                    ""
                                }                    
                                <div className="mrgB20"></div>    
                            </div>

                        </div>
                    </>
                 ) : (
                    <>
                        <div>
                            <AppDesktopLogo/>
                            <OfferCard isEditOffer={false} isEditSpeOffer={true} />
                            <div className="width50pCenter">
                                <SyndicRectangleButtonSnd onClick={HandleModification}>
                                    <p className='txt16 noMrgTB'>Retour</p>
                                </SyndicRectangleButtonSnd>

                                <div className="mrgB10"></div>

                                <SyndicRectangleButton
                                    onClick={HandleOfferPublication}>
                                    <p className='txt16 noMrgTB'>Envoyer mon offre à {favPrestaName}</p>
                                </SyndicRectangleButton>
                                {open ?
                                    <Modal 
                                        centered
                                        size="auto"
                                        opened={opened}
                                        onClose={close}
                                        withCloseButton={false}>
                                        <p className="txtCenter noMrgTB useRoboto txt16">
                                            Votre offre a été envoyée
                                        </p>
                                    </Modal> 
                                : 
                                    ""
                                }                    
                                <div className="mrgB20"></div>    
                            </div>

                        </div>
                    
                    </>
                 )}
            </>
        )
    
}

export default NewSpeOfferRecap;