import { React, createContext, useState } from "react";
import { parseISO, format, formatDistanceToNowStrict } from "date-fns";
import { fr } from "date-fns/locale";

export const PrestaFncContext = createContext();

export const PrestaFncProvider = ({ children }) => {
  const [listOffer, setlistOffer] = useState();
  const [listSavedOffer, setSavedOffer] = useState([]);

  const getOffers = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/p/matchs/list`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        }
      );

      let list = await response.json();

      list.forEach(offer => {
        const rangeDate = offer.workRangeDates;
        offer.startDate = format(parseISO(rangeDate[0]), "dd/MM/yyyy");
        offer.endDate = format(parseISO(rangeDate[1]), "dd/MM/yyyy");
        const deadline = new Date(offer.deadlineToReceiveQuotationDate);
        let result = formatDistanceToNowStrict(deadline, {
          unit: "day",
          locale: fr
        });
        result = result.split(" ")[0];
        offer.deadline = result;
      });
      setlistOffer(list);
    } catch (error) {
    }
  };

  const getSavedOffers = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `https://boldee-97d8d36af3bd.herokuapp.com/p/offers/list`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        }
      );
      let presta = await response.json();
      let list = presta.savedOffers;
      list.forEach(offer => {
        const rangeDate = offer.workRangeDates;
        offer.startDate = format(parseISO(rangeDate[0]), "dd/MM/yyyy");
        offer.endDate = format(parseISO(rangeDate[1]), "dd/MM/yyyy");

        const deadline = new Date(offer.deadlineToReceiveQuotationDate);
        let result = formatDistanceToNowStrict(deadline, {
          unit: "day",
          locale: fr
        });
        result = result.split(" ")[0];
        offer.deadline = result;
      });
      setSavedOffer(list);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveSubmit = async event => {
    const idSave = event.currentTarget.id;
    try {
      const token = localStorage.getItem("token");
      await fetch(
        "https://boldee-97d8d36af3bd.herokuapp.com/p/offers/save",
        {
          method: "POST",
          body: JSON.stringify({
            id: idSave
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        }
      );
      await getOffers();
    } catch (error) {
    }
  };

  const handleUnsave = async event => {
    const id = event.currentTarget.id;

    try {
      const token = localStorage.getItem("token");
      await fetch(
        `https://boldee-97d8d36af3bd.herokuapp.com/p/offers/delete/${id}`,
        {
          method: "POST",
          body: JSON.stringify({
            _id: id
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        }
      );
      await getSavedOffers();
      await getOffers();
    } catch (error) {
    }
  };

  const handleNewRdvDemandSubmit = async event => {
    event.preventDefault();
    const id = event.currentTarget.id;
    try {
      const token = localStorage.getItem("token");
      await fetch(
        `https://boldee-97d8d36af3bd.herokuapp.com/p/matchs/rdv-demand/${id}`,
        {
          method: "POST",
          body: JSON.stringify({
            id: id
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        }
      );
      await getOffers();
    } catch (error) {
    }
  };

  return (
    <PrestaFncContext.Provider
      value={{
        listOffer,
        listSavedOffer,
        handleSaveSubmit,
        handleUnsave,
        handleNewRdvDemandSubmit,
        getOffers,
        getSavedOffers
      }}
    >
      {children}
    </PrestaFncContext.Provider>
  );
};
