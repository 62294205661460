import { React, useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import LogoFooter from '../../composents/Logo/FooterLogo/LogoFooter';

const Footer = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        window.scrollTo(0,0);
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <div className="footer">
            {isMobile ? (
                <>
                    <div className="footer-align">
                        <div>
                            <LogoFooter />
                        </div> 
                        <div>
                            <h3>Connexion</h3>
                            <li>
                                <Link to="/s/login">
                                    Syndic de copropriété
                                </Link>
                            </li>
                            <li>
                                <Link to="/p/login">
                                    Artisans - Prestataires
                                </Link>
                            </li>
                        </div>

                        <div>
                            <Link to="/signup">
                                <h3>S'inscrire</h3>
                            </Link>
                        </div>

                        <div>
                        <h3>Informations</h3>
                            <li>
                                <a href="https://res.cloudinary.com/do9t6jjdy/image/upload/v1702889743/LG-P-001_-_Mentions_l%C3%A9gales_v.3_1223_zlu3kf.pdf" target="_blank" rel="noreferrer">
                                    <div id="publicOpenedMLGa4" className='noMrgTB'>
                                        Mentions légales
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="https://res.cloudinary.com/do9t6jjdy/image/upload/v1693765220/LPG002_Conditions_G%C3%A9n%C3%A9rales_d_Utilisation_v.2_0923_idmckv.pdf" target="_blank" rel="noreferrer">
                                    <div id="publicOpenedCGUGa4" className='noMrgTB'>
                                        Conditions générales d'utilisation
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="https://res.cloudinary.com/do9t6jjdy/image/upload/v1702889743/RGPD-P-001_-_Politique_de_confidentialit%C3%A9_v.3_1223_gcrwbv.pdf" target="_blank" rel="noreferrer">
                                    <div id="publicOpenedRGPDGa4" className='noMrgTB'>
                                        Confidentialité
                                    </div>
                                </a>
                            </li>
                        </div>

                        <div>
                            <Link to="/contact">
                                <h3>Contact</h3>
                            </Link>
                        </div>
                    </div>
                <div><p>© Copyright 2024 Boldee</p></div>

                </>
            ) : (
                <>
                    <div className="footer-align">
                        <LogoFooter />
                    <div>
                        <h3>Connexion</h3>
                        <li>
                            <Link to="/s/login">
                                Syndic de copropriété
                            </Link>
                        </li>
                        <li>
                            <Link to="/p/login">
                                Artisans - Prestataires
                            </Link>
                        </li>
                    </div>

                    <div className='leftBorder padL40'>
                        <Link to="/signup">
                            <h3>S'inscrire</h3>
                        </Link>
                    </div>

                    <div className='leftBorder padL40'>
                        <h3>Informations</h3>
                            <li>
                                <a href="https://res.cloudinary.com/do9t6jjdy/image/upload/v1702889743/LG-P-001_-_Mentions_l%C3%A9gales_v.3_1223_zlu3kf.pdf" target="_blank" rel="noreferrer">
                                    <div id="publicOpenedMLGa4" className='noMrgTB'>
                                        Mentions légales
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="https://res.cloudinary.com/do9t6jjdy/image/upload/v1693765220/LPG002_Conditions_G%C3%A9n%C3%A9rales_d_Utilisation_v.2_0923_idmckv.pdf" target="_blank" rel="noreferrer">
                                    <div id="publicOpenedCGUGa4" className='noMrgTB'>
                                        Conditions générales d'utilisation
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="https://res.cloudinary.com/do9t6jjdy/image/upload/v1702889743/RGPD-P-001_-_Politique_de_confidentialit%C3%A9_v.3_1223_gcrwbv.pdf" target="_blank" rel="noreferrer">
                                    <div id="publicOpenedRGPDGa4" className='noMrgTB'>
                                        Confidentialité
                                    </div>
                                </a>
                            </li>
                        </div>

                        <div className='leftBorder padL40'>
                            <Link to="/contact">
                                <h3>Contact</h3>
                            </Link>
                        </div>
                    </div>
                    <div><p>© Copyright 2024 Boldee</p></div>
                </>
            )}
        </div>  
        );
}


export default Footer;

