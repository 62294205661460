import { React, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../../contexts/authContext';
import { Link, useNavigate } from 'react-router-dom';

import { useDisclosure } from '@mantine/hooks';
import { MultiSelect, Badge, Autocomplete, Button, Modal } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconHammer, IconLocation, IconCalendarEvent } from '@tabler/icons-react';
import { parseISO, format } from 'date-fns';
import { subDays } from 'date-fns';

import AppHdWithCloseRight from '../../../../composents/Headers/AppHeaders/AppHdWithCloseRight/AppHdWithCloseRight';
import PrestaRectangleButtonMain from '../../../../composents/Buttons/Presta/RectangleButtonMain/RectangleButtonMain';
import Box from '../../../../composents/Boxes/Standard/Box';
import AppDesktopLogo from '../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

const OfferSearch = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [listResults, setlistResults] = useState(JSON.parse(window.localStorage.getItem('offerResult')));
    const [queryPlaceOfIntervention, setQueryPlaceOfIntervention] = useState('');
    const [optionsPlaceOfIntervention, setOptionsPlaceOfIntervention] = useState([]);
    const [placesOfIntervention, setplacesOfIntervention] = useState([]);
    const [ openedModalAlreadyAdded, { open: openModalAlreadyAdded, close: closeModalAlreadyAdded}] = useDisclosure(false)

    const navigate = useNavigate();

    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/p/login");
            return;
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const form = useForm({
        initialValues: {
            occupation: [],
            placesOfIntervention: []
        }
    })

    const handlePlaceOfInterventionQueryChange = async (value) => {
        if(placesOfIntervention.includes(value)){
            setQueryPlaceOfIntervention('')
            openModalAlreadyAdded()
        } else {
            setQueryPlaceOfIntervention(value);    
                try {
                    const token = localStorage.getItem('token');
                    const response = await fetch('https://boldee-97d8d36af3bd.herokuapp.com/adm/bu/search-places', {
                        method: 'POST',
                        body: JSON.stringify({ query: value }),
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`
                        }          
                    })
    
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                        }
      
                    const data = await response.json();
                    const suggestionsMap = new Map();
                    data.forEach((feature) => {
                        const nom = feature.nom || '';
                        const code = feature.code || '';
                        const suggestionKey = `${code} ${nom}`;
    
                        suggestionsMap.set(suggestionKey, {
                            value: `${code} ${nom}`,
                            label: `${code} ${nom}`,
                            nom: nom,
                            code: code
                        });
                        });
    
                        const uniqueSuggestions = Array.from(suggestionsMap.values());
    
                    setOptionsPlaceOfIntervention(uniqueSuggestions);
                } catch (error) {
                }
            
        }
    
    };
    const handleAddCity = () => {
        if (queryPlaceOfIntervention) {
            setplacesOfIntervention((prevSelected) => [...prevSelected, queryPlaceOfIntervention]);
            setQueryPlaceOfIntervention("");
        }
    };

    const handleDeleteCity = (city) => {
        const updatedCities = [...placesOfIntervention];
        const index = updatedCities.indexOf(city);
        if (index > -1) {
          updatedCities.splice(index, 1);
          setplacesOfIntervention(updatedCities);
        }
      };

    const handleFormSubmit = async(values, _event) => {
        _event.preventDefault();
        try {
            const token = localStorage.getItem('token');
            const response = await fetch("https://boldee-97d8d36af3bd.herokuapp.com/p/search", {
                method: "POST",
                body: JSON.stringify({
                    occupation: values.occupation, 
                    placesOfIntervention: placesOfIntervention
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            const list = await response.json();
            list.forEach((offer) => {
                const rangeDate = offer.workRangeDates
                offer.startDate = format(parseISO(rangeDate[0]), 'dd/MM/yyyy')
                offer.endDate = format(parseISO(rangeDate[1]), 'dd/MM/yyyy')
            })
            setlistResults(list)
            window.localStorage.setItem('offerResult', JSON.stringify(list));
        } catch (error) {
        }
    }
    
   const subOneDayOfToday = subDays(new Date(),1);
   const yesterday = subOneDayOfToday.toISOString();
   
   return(
        <>
            {isMobile ? (
                <>
                    <form onSubmit={form.onSubmit(handleFormSubmit)}>
                    
                    <AppHdWithCloseRight>Rechercher des offres</AppHdWithCloseRight>

                    <div className="width90pCenter roboto">
                        <MultiSelect
                            data={[
                                { value: 'Entreprise de travaux', label: 'Entreprise de travaux' },
                                { value: 'Electricien', label: 'Electricien' },
                                { value: 'Menuisier', label: 'Menuisier' },
                                { value: 'Plombier', label: 'Plombier' },
                                { value: 'Entreprise de nettoyage, Gardien, Concierge', label: 'Entreprise de nettoyage, Gardien, Concierge' },
                                { value: 'Jardinier- Paysagiste', label: 'Jardinier- Paysagiste' },
                                { value: 'Entreprise de maintenance et de dépannage', label: 'Entreprise de maintenance et de dépannage' },
                                { value: 'Chauffagiste', label: 'Chauffagiste' },
                                { value: 'Désinfection, Désinsectisation, Dératisation', label: 'Désinfection, Désinsectisation, Dératisation' },
                                { value: 'Architecte, Bureaux d\'études', label: 'Architecte, Bureaux d\'études' },
                                { value: 'Assureur', label: 'Assureur' },
                                { value: 'Avocat spécialisé en droit immobilier', label: 'Avocat spécialisé en droit immobilier' },
                                { value: 'Autre', label: 'Autre' }
                            ]}
                            label="Métiers"
                            placeholder="Choisissez un ou plusieurs métiers"
                            clearable
                            {...form.getInputProps('occupation')}
                            />
                        
                        <div className="mrgB10"></div>
                        <div className='horizontal iEnd mrgB10'>
                            <Autocomplete
                                label="Département d'intervention"
                                placeholder="Numéro ou Nom de départment"
                                withAsterisk
                                value={queryPlaceOfIntervention}
                                onChange={handlePlaceOfInterventionQueryChange}
                                data={optionsPlaceOfIntervention}
                                textFieldProps={{ required: true }}
                                style={{ width: '100%' }}
                            />

                            <Button className='mrgL3'color='dark' onClick={handleAddCity} disabled={!queryPlaceOfIntervention}>
                                Ajouter
                            </Button>
                        </div>
                        {openModalAlreadyAdded  
                            ?   <Modal 
                                    centered
                                    size="55%"                                        
                                    opened={openedModalAlreadyAdded}
                                    onClose={closeModalAlreadyAdded}
                                    withCloseButton={false}>
                                    <p className="useRoboto txt15 txtCenter noMrgTB">
                                        Département déjà enregistré
                                    </p>
                                </Modal> 
                            :   ""
                        }     
                        <div className='horizontal wraped'>
                            {placesOfIntervention.map((city) => (
                            <div key={city} >
                                <div className='mrgR10'  onClick={() => handleDeleteCity(city)}>
                                    <Badge color='gray'>
                                            <p className='useRoboto txt13 majMin medium noMrgTB bT'>{city} x</p>
                                    </Badge>
                                    
                                </div>
                            </div>
                            ))}
                        </div>

                        <div className="mrgB20"></div>


                        <PrestaRectangleButtonMain type="submit">
                            <p id="searchOfferSubmitGa4" className='noMrgTB'>Rechercher</p>
                        </PrestaRectangleButtonMain>

                    </div>
                    </form>
                    <div className="mrgB20"></div>

                    {listResults 
                        ?
                            <>
                                <div>
                                    {listResults.map((offer, index) => (
                                        <div key = {index} style = {{margin : '10px'}}>
                                            {offer.deadlineToReceiveQuotationDate > yesterday
                                                ?
                                                    <Link to={`/p/resultOfferInfo/${offer._id}`} className="aReset bT">
                                                        <Box>
                                                            <div className="horizontal mrgR10">
                                                                    <div className="vertical mrgR10 jCenter">                        
                                                                        <div className="mrgL20">
                                                                            {offer.pictures.length > 0
                                                                                    ?   <img 
                                                                                            className="roundImg70 txt9"
                                                                                            src={offer.pictures[0]}
                                                                                            alt='photo_chantier'/>
                                                                                    :   <>
                                                                                            {offer.author.company.logo
                                                                                                ?   <img className="roundImg70 noMrgTB"
                                                                                                        src={offer.author.company.logo}
                                                                                                        alt='photo_chantier'/>
                                                                                                :   <div className="noAvatarProfile">{offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                                                            }
                                                                                            
                                                                                        </>
                                                                            }
                                                                            
                                                                        </div>                         
                                                                    </div>
                                                                    <div className="vertical jCenter mrgL10">
                                                                        <div className="horizontal iBtm">
                                                                                <div>
                                                                                    <p className="txt13 capitals thin noMrgT mrgB5">{offer.author.company.name}</p>
                                                                                </div>
                                                                                <div >
                                                                                    {offer.urgent 
                                                                                        ?   <Badge color="red">Urgent</Badge>
                                                                                        :   ""
                                                                                    }
                                                                                </div>
                                                                        </div>
                                                                                                    
                                                                                <div>
                                                                                    <p className="txt15 mediumplus lineH15 noMrgT mrgB5">
                                                                                        {offer.title}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="horizontal iCenter">
                                                                                    <IconHammer width={13} height={13} className="mrgR5"/>
                                                                                    <p className="noMrgTB txt13 finalDotsMinus">{offer.occupation}</p>
                                                                                </div>
                                                                                <div className="horizontal iCenter">
                                                                                    <IconCalendarEvent width={13} height={13} className="mrgR5"/>
                                                                                    <p className="noMrgTB txt13">Du {offer.startDate} au {offer.endDate}</p>
                                                                                </div>
                                                                                <div className="horizontal iCenter">
                                                                                    <IconLocation width={13} height={13} className="mrgR5"/>

                                                                                    <p className="noMrgTB txt13">{offer.cityOfIntervention}</p>
                                                                                </div>
                                                                            
                                                                    </div>
                                                            </div>    
                                                        </Box>
                                                    </Link>
                                                :   ""
                                            }

                                        </div>
                                    ))}  
                                </div>
                            </>
                        :
                            ""
                    }  
                </>
            ) : (
                <>
                    <AppDesktopLogo />
                    <form onSubmit={form.onSubmit(handleFormSubmit)}>                    
                    <AppHdWithCloseRight>Rechercher des offres</AppHdWithCloseRight>
                    <div className="width50pCenter roboto">
                        <MultiSelect
                            data={[
                                { value: 'Entreprise de travaux', label: 'Entreprise de travaux' },
                                { value: 'Electricien', label: 'Electricien' },
                                { value: 'Menuisier', label: 'Menuisier' },
                                { value: 'Plombier', label: 'Plombier' },
                                { value: 'Entreprise de nettoyage, Gardien, Concierge', label: 'Entreprise de nettoyage, Gardien, Concierge' },
                                { value: 'Jardinier- Paysagiste', label: 'Jardinier- Paysagiste' },
                                { value: 'Entreprise de maintenance et de dépannage', label: 'Entreprise de maintenance et de dépannage' },
                                { value: 'Chauffagiste', label: 'Chauffagiste' },
                                { value: 'Désinfection, Désinsectisation, Dératisation', label: 'Désinfection, Désinsectisation, Dératisation' },
                                { value: 'Architecte, Bureaux d\'études', label: 'Architecte, Bureaux d\'études' },
                                { value: 'Assureur', label: 'Assureur' },
                                { value: 'Avocat spécialisé en droit immobilier', label: 'Avocat spécialisé en droit immobilier' },
                                { value: 'Autre', label: 'Autre' }
                            ]}
                            label="Métiers"
                            placeholder="Choisissez un ou plusieurs métiers"
                            clearable
                            size='md'
                            {...form.getInputProps('occupation')}
                            />
                        
                        <div className="mrgB20"></div>
                        <div className='horizontal iEnd mrgB10'>
                            <Autocomplete
                                label="Département d'intervention"
                                placeholder="Numéro ou Nom de départment"
                                withAsterisk
                                value={queryPlaceOfIntervention}
                                onChange={handlePlaceOfInterventionQueryChange}
                                data={optionsPlaceOfIntervention}
                                textFieldProps={{ required: true }}
                                style={{ width: '100%' }}
                                size='md'
                            />

                            <Button size='md' className='mrgL3'color='dark' onClick={handleAddCity} disabled={!queryPlaceOfIntervention}>
                                <p className='txt16 useRoboto medium'>Ajouter</p>
                            </Button>
                        </div>
                        {openModalAlreadyAdded  
                            ?   <Modal 
                                    centered
                                    size="auto"                                        
                                    opened={openedModalAlreadyAdded}
                                    onClose={closeModalAlreadyAdded}
                                    withCloseButton={false}>
                                    <p className="useRoboto txt18 txtCenter noMrgTB">
                                        Département déjà enregistré
                                    </p>
                                </Modal> 
                            :   ""
                        }    
                        
                        <div className='horizontal wraped'>
                            {placesOfIntervention.map((city) => (
                            <div key={city} >
                                <div className='mrgR10 mrgT5'  onClick={() => handleDeleteCity(city)}>
                                    <Badge size='lg' color='gray'>
                                            <p className='useRoboto txt16 majMin medium noMrgTB bT'>{city} x</p>
                                    </Badge>                                    
                                </div>
                            </div>
                            ))}
                        </div>

                        <div className="mrgB20"></div>


                        <PrestaRectangleButtonMain type="submit">
                            <p id="searchOfferSubmitGa4" className='noMrgTB'>Rechercher</p>
                        </PrestaRectangleButtonMain>

                    </div>
                    </form>
                    <div className="mrgB20"></div>

                    {listResults 
                        ?
                            <>
                                <div className='width50pCenter'>
                                    {listResults.map((offer, index) => (
                                        <div key = {index} style = {{margin : '10px'}}>
                                            {offer.deadlineToReceiveQuotationDate > yesterday
                                                ?
                                                    <Link to={`/p/resultOfferInfo/${offer._id}`} className="aReset bT">
                                                        <Box>
                                                            <div className="horizontal iStart useRoboto mrgT20 mrgB10">
                                                                    <div className="vertical mrgL30 mrgR20">                        
                                                                        <div>
                                                                            {offer.pictures.length > 0
                                                                                    ?   <img 
                                                                                            className="roundImg100 noMrgTB"
                                                                                            src={offer.pictures[0]}
                                                                                            alt='photo_chantier'/>
                                                                                    :   <>
                                                                                            {offer.author.company.logo
                                                                                                ?   <img className="roundImg100 noMrgTB"
                                                                                                        src={offer.author.company.logo}
                                                                                                        alt='photo_chantier'/>
                                                                                                :   <div className="noAvatarProfile">{offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                                                            }                                                                                            
                                                                                        </>
                                                                            }
                                                                            
                                                                        </div>                         
                                                                    </div>
                                                                    <div className="vertical jCenter mrgL10">
                                                                        <div className="horizontal iBtm">
                                                                                <div>
                                                                                    <p className="txt16 noMrgTB mrgR5 dGreyT noMrgB capitals">
                                                                                        {offer.author.company.name}
                                                                                    </p>
                                                                                </div>
                                                                                <div >
                                                                                    {offer.urgent 
                                                                                        ?   <Badge size='lg' color="red">Urgent</Badge>
                                                                                        :   ""
                                                                                    }
                                                                                </div>
                                                                        </div>
                                                                                                    
                                                                                <div>
                                                                                    <p className="txt18 mediumplus noMrgT mrgB5 lineH20 w90">
                                                                                        {offer.title}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="horizontal iCenter mrgB5">
                                                                                    <IconHammer width={20} height={20} className="mrgR5"/>
                                                                                    <p className="noMrgTB txt16 mrgR5 mrgL5">{offer.occupation}</p>
                                                                                </div>
                                                                                <div className="horizontal iCenter mrgB5">
                                                                                    <IconCalendarEvent width={20} height={20} className="mrgR5"/>
                                                                                    <p className="noMrgTB txt16 mrgR5 mrgL5">Du {offer.startDate} au {offer.endDate}</p>
                                                                                </div>
                                                                                <div className="horizontal iCenter mrgB5">
                                                                                    <IconLocation width={20} height={20} className="mrgR5"/>
                                                                                    <p className="noMrgTB txt16 mrgR5 mrgL5">{offer.cityOfIntervention}</p>
                                                                                </div>
                                                                            
                                                                    </div>
                                                            </div>    
                                                        </Box>
                                                    </Link>
                                                :   ""
                                            }

                                        </div>
                                    ))}  
                                </div>
                            </>
                        :
                            ""
                    }  
                </>
            )}
        </>
    );
}

export default OfferSearch;



