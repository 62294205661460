import { useState } from "react";
import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
// import {Route, Link} from "react-router-dom";

import {
  BrowserRouter,
  Routes, // Just Use Routes instead of "Switch"
  Route
} from "react-router-dom";

import "./App.css";

import Home from "./components/Pages/Home";
import SignUp from "./components/Auth/SignUp";
import LoginPresta from "./components/Auth/Presta/LoginPresta";
import LoginSyndic from "./components/Auth/Syndic/LoginSyndic";
import LoginAdmin from "./components/Auth/Admin/LoginAdmin";
import SyndicForgotPassword from "./components/Auth/Syndic/ForgotPwsSyndic";
import SyndicResetPassword from "./components/Auth/Syndic/ResetPwsSyndic";
import PrestaForgotPassword from "./components/Auth/Presta/ForgotPwsPresta";
import PrestaResetPassword from "./components/Auth/Presta/ResetPwsPresta";
import Contact from "./components/Pages/Contact";
import PageNotFound from "./components/Pages/PageNotFound.js";

// ADMIN
import AddCustomer from "./components/Admin/AddCustomer";
import AddCompany from "./components/Admin/AddCompany";
import AdminMenu from "./components/Admin/AdminMenu";

// SYNDICS
// onglet offres de travaux
import SyndicOffresDeTravaux from "./components/Syndics/App/Offers/WorkOffer.js";
import AddNewOfferSyndic from "./components/Syndics/App/Offers/AddNewOffer/AddNewOffer.js";
import NewOfferRecap from "./components/Syndics/App/Offers//AddNewOffer/AddNewOfferPreview.js";
import EditOfferBeforePublication from "./components/Syndics/App/Offers/AddNewOffer/EditOfferBeforePublication";
import PublishedOffer from "./components/Syndics/App/Offers/PublishedOffer.js";
import EditOffer from "./components/Syndics/App/Offers/EditOffer/EditOffer";
import ReceivedQuotationList from "./components/Syndics/App/Offers/RdvAndQuotation/Quotation/OfferQuotation.js";
import ReceivedRDVDemandList from "./components/Syndics/App/Offers/RdvAndQuotation/RDV/OfferRDV.js";
import RdvAndQuotation from "./components/Syndics/App/Offers/RdvAndQuotation/RdvAndQuotation";
import FavoritePrestaMatch from "./components/Syndics/App/Offers/RdvAndQuotation/FavoritePrestaMatchs";
import SendRdvInfoForm from "./components/Syndics/App/Offers/RdvAndQuotation/RDV/SendRdvInfo.js";
import RdvDetails from "./components/Syndics/App/Offers/RdvAndQuotation/RDV/RDVDetails";
import RdvOfferInfo from "./components/Syndics/App/Offers/RdvOfferInfo";
import PrestaPage from "./components/Syndics/App/Offers/PrestaPage";
import PrestaPageRdv from "./components/Syndics/App/Offers/PrestaPageRdv";
import EditRdv from "./components/Syndics/App/Offers/RdvAndQuotation/RDV/EditRDV.js";

// onglet favoris
import MyFavoritePresta from "./components/Syndics/App/Favoris/FavoritePresta";
import PrestaPageInfo from "./components/Syndics/App/Favoris/FavoritePrestaInfo";
import OfferListToSend from "./components/Syndics/App/Favoris/SendOfferToPresta";
import AddPrestaToFavorite from "./components/Syndics/App/Favoris/AddPrestaToFavorite";
import PrestaPageToAddToFav from "./components/Syndics/App/Favoris/PrestaPageInfoToAdd";
import AddNewSpeOffer from "./components/Syndics/App/Favoris/SpecificOrder/AddNewSpeOffer.js";
import NewSpeOfferRecap from "./components/Syndics/App/Favoris/SpecificOrder/AddNewSpeOfferPreview.js";
import EditSpeOfferBeforePublication from "./components/Syndics/App/Favoris/SpecificOrder/EditSpeOfferBeforePublication.js";

// onglet profil
import ProfileMenu from "./components/Syndics/App/Profile/ProfileMenu";
import MySyndicAccount from "./components/Syndics/App/Profile/Account/AccountInfo";
import ModifyMyAccount from "./components/Syndics/App/Profile/Account/EditAccountInfo";
import SyndicChangePsw from "./components/Syndics/App/Profile/Parameters/ChangePassword";
import SyndicDisclaimer from "./components/Syndics/App/Profile/Disclaimer/Disclaimer.js";
import SyndicContact from "./components/Syndics/App/Profile/Contact/Contact";

// OFFRE
import OfferCard from "./components/Offer/OfferCard.js";

// PRESTATAIRES
// onglet match
import Matchlist from "./components/Prestataires/App/Matchs/MatchList";
import SyndicInfo from "./components/Prestataires/App/Offers/SyndicInfo";
import SendQuotation from "./components/Prestataires/App/Matchs/SendQuotation/SendQuotation";
import ListPrestaToRecommend from "./components/Prestataires/App/Matchs/Recommendation/RecommendAConfrere";
import PrestaInfo from "./components/Prestataires/App/Matchs/Recommendation/PrestaInfo";
//onglet offres enregistrées
import SavedOffers from "./components/Prestataires/App/Offers/SavedOffers";
import OfferInfo from "./components/Prestataires/App/Offers/OfferInfo";
//onglet devis
import QuotationDashboard from "./components/Prestataires/App/Quotation/QuotationDashboard";
import QuotationInfoAndEdit from "./components/Prestataires/App/Quotation/QuotationInfoAndEdit.js";
import QuotationInfo from "./components/Prestataires/App/Quotation/QuotationInfo";
import OfferInfoFromQuotation from "./components/Prestataires/App/Quotation/OfferInfo";
//onglet rdv
import PrestaMyRdv from "./components/Prestataires/App/Rdv/RdvList";
import RdvInfo from "./components/Prestataires/App/Rdv/RdvInfo";
import OfferInfoFromRdv from "./components/Prestataires/App/Rdv/OfferInfo";
//module cross sur les ongles match et offres
import OfferSearch from "./components/Prestataires/App/OfferSearch/OfferSearch";
import OfferInfoSaved from "./components/Prestataires/App/OfferSearch/OfferInfo";
//onglet profil
import PrestaProfileMenu from "./components/Prestataires/App/Profile/ProfileMenu";
import MyPrestaAccount from "./components/Prestataires/App/Profile/Account/Account.js";
import ModifyMyPrestaAccount from "./components/Prestataires/App/Profile/Account/EditAccount";
import MyPrestaPage from "./components/Prestataires/App/Profile/MyPage/PrestaPage.js";
import WhoAddedMeInFavorite from "./components/Prestataires/App/Profile/MyPage/WhoAddedMeInFavorite";
import SyndicInfoById from "./components/Prestataires/App/Profile/MyPage/SyndicInfo";
import AddPlaceOfIntervention from "./components/Prestataires/App/Profile/PlaceOfIntervention/AddPlaceOfIntervention";
import PrestaChangePsw from "./components/Prestataires/App/Profile/Parameters/ChangePassword";
import PrestaDisclaimer from "./components/Prestataires/App/Profile/Disclaimer/Disclaimer";
import PrestaContact from "./components/Prestataires/App/Profile/Contact/Contact";
import SendSyndicContact from "./components/Prestataires/App/Profile/Recommendation/SendSyndicContact";

const App = () => {
  const [id, setId] = useState(null);

  return (
    <MantineProvider>
      <ModalsProvider>
        <div className="useRoboto">
          {/* <Navbar></Navbar> */}
          <BrowserRouter>
            <Routes>
              {/* AUTH */}
              <Route path="/" element={<Home />} />
              <Route path="/s/forgot-password" element={<SyndicForgotPassword />} />
              <Route path="/s/reset-password" element={<SyndicResetPassword />} />
              <Route path="/p/forgot-password" element={<PrestaForgotPassword />} />
              <Route path="/p/reset-password" element={<PrestaResetPassword />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/s/login" element={<LoginSyndic />} />
              <Route path="/p/login" element={<LoginPresta />} />
              <Route path="/adm/login" element={<LoginAdmin />} />
              <Route path="/contact" element={<Contact />} />

              {/* ADMIN */}
              <Route path="/adm/addcust" element={<AddCustomer />} />
              <Route path="/adm/addcomp" element={<AddCompany />} />
              <Route path="/adm/menu" element={<AdminMenu />} />


              {/* SYNDICS */}
              <Route path="/s/offer-card" element={<OfferCard />} />
              {/* Onglet offre de travaux */}
              <Route
                path="/s/offers/list"
                element={<SyndicOffresDeTravaux />}
              />
              <Route path="/s/offers/add" element={<AddNewOfferSyndic />} />
              <Route
                path="/s/offers/add-preview/:id"
                element={<NewOfferRecap />}
              />
              <Route
                path="/s/offers/preoffer-edit/:id"
                element={<EditOfferBeforePublication />}
              />
              <Route path="/s/offers/offer/:id" element={<PublishedOffer />} />
              <Route path="/s/offers/offer-edit/:id" element={<EditOffer />} />
              <Route
                path="/s/offers/offer/quo/:id"
                element={<ReceivedQuotationList />}
              />
              <Route
                path="/s/offers/offer/rdv/:id"
                element={<ReceivedRDVDemandList />}
              />
              <Route
                path="/s/offers/offer-rdv&quo/:id"
                element={<RdvAndQuotation />}
              />
              <Route
                path="s/offers/offer/rdv-info-send-form/:id"
                element={<SendRdvInfoForm />}
              />
              <Route
                path="/s/offers/offer-info/:id"
                element={<RdvOfferInfo />}
              />
              <Route
                path="/s/offers/offer/details-rdv/:id"
                element={<RdvDetails />}
              />
              <Route
                path="/s/offers/edit-rdv/:id"
                element={<EditRdv />}
              />
              <Route
                path="/s/offers/favorite-p-match/:id"
                element={<FavoritePrestaMatch />}
              />
              <Route
                path="/s/offers/offer/company-page/:id"
                element={<PrestaPage />}
              />
              <Route
                path="/s/offers/offer/rdv/company-page/:id"
                element={<PrestaPageRdv />}
              />
              
              {/* Onglet favoris */}
              <Route
                path="/s/favPresta/fav-list"
                element={<MyFavoritePresta />}
              />
              <Route
                path="/s/favPresta/p-page-info/:id"
                element={<PrestaPageInfo />}
              />
              <Route
                path="/s/favPresta/offers-list/:id"
                element={<OfferListToSend />}
              />
              <Route
                path="/s/favPresta/add-list"
                element={<AddPrestaToFavorite />}
              />
              <Route
                path="/s/favPresta/p-page-to-add/:id"
                element={<PrestaPageToAddToFav />}
              />
              <Route
                path="/s/favPresta/add/:id"
                element={<AddNewSpeOffer />}
              />
              <Route
                path="/s/favPresta/add-preview/:id"
                element={<NewSpeOfferRecap />}
              />

              <Route
                path="s/favPresta/preoffer-edit/:id"
                element={<EditSpeOfferBeforePublication/>}
              />

              {/* Onglet profil */}
              <Route path="/s/profile/menu" element={<ProfileMenu />} />
              <Route path="/s/profile/account" element={<MySyndicAccount />} />
              <Route path="/s/profile/edit" element={<ModifyMyAccount />} />
              <Route
                path="/s/profile/param/psw-edit"
                element={<SyndicChangePsw />}
              />
              <Route
                path="/s/profile/disclaimer"
                element={<SyndicDisclaimer />}
              />
              <Route path="/s/profile/contact" element={<SyndicContact />} />

              {/* PRESTATAIRES */}
              {/* onglet match */}
              <Route path="/p/matchs/list" element={<Matchlist />} />
              <Route path="/p/matchs/s-info/:id" element={<SyndicInfo />} />
              <Route
                path="/p/matchs/send-quo/:id"
                element={<SendQuotation />}
              />
              <Route
                path="/p/matchs/p-list-reco/:id"
                element={<ListPrestaToRecommend setId={setId} />}
              />
              <Route
                path="/p/matchs/p-info-reco/:id"
                element={<PrestaInfo id={id} />}
              />
              <Route path="/p/search" element={<OfferSearch />} />
              <Route path="/p/resultOfferInfo/:id" element={<OfferInfoSaved/>} />   
              {/* onglet offres */}
              <Route path="/p/offers/list" element={<SavedOffers />} />
              <Route path="/p/offers/offer-info/:id" element={<OfferInfo />} />

              {/* onglet devis */}
              <Route path="/p/quo/list" element={<QuotationDashboard />} />
              <Route
                path="/p/quo/edit/:id"
                element={<QuotationInfoAndEdit />}
              />
              <Route path="/p/quo/:id" element={<QuotationInfo />} />
              <Route path="/p/quo/info-offer/:id" element={<OfferInfoFromQuotation />} />

              
              {/* onglet rdv */}
              <Route path="/p/rdv/list" element={<PrestaMyRdv />} />
              <Route path="/p/rdv/info/:id" element={<RdvInfo />} />
              <Route path="/p/rdv/info-offer/:id" element={<OfferInfoFromRdv/>}/>
              {/* onglet profil */}
              <Route path="/p/profile/menu" element={<PrestaProfileMenu />} />
              <Route path="/p/profile/account" element={<MyPrestaAccount />} />
              <Route
                path="/p/profile/edit"
                element={<ModifyMyPrestaAccount />}
              />
              <Route
                path="/p/profile/company-page"
                element={<MyPrestaPage />}
              />
              <Route
                path="/p/profile/favoriteof"
                element={<WhoAddedMeInFavorite />}
              />
              <Route
                path="/p/profile/s-info/:id"
                element={<SyndicInfoById />}
              />
              <Route
                path="/p/profile/intervention-places"
                element={<AddPlaceOfIntervention />}
              />
              <Route
                path="/p/profile/param/psw-edit"
                element={<PrestaChangePsw />}
              />
              <Route
                path="/p/profile/disclaimer"
                element={<PrestaDisclaimer />}
              />
              <Route path="/p/profile/contact" element={<PrestaContact />} />
              <Route path="/p/profile/reco" element={<SendSyndicContact/>}/>
            
              <Route
                    path="*"
                    element={<PageNotFound />}
                />

            </Routes>
          </BrowserRouter>
          {/* <Footer></Footer> */}
        </div>
      </ModalsProvider>
    </MantineProvider>
  );
};

export default App;
