import { React, useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../../../../contexts/authContext';
import { useNavigate } from 'react-router-dom';

import { useForm } from '@mantine/form';
import { TextInput, Textarea, Select, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import AppHdWithReturnLeft from '../../../../../composents/Headers/AppHeaders/AppHdWithReturnLeft/AppHdWithReturnLeft';
import PrestaRectangleButtonMain from '../../../../../composents/Buttons/Presta/RectangleButtonMain/RectangleButtonMain';
import RectangleButtonDisabled from '../../../../../composents/Buttons/All/RectangleButtonDisabled/RectangleButtonDisabled';
import AppDesktopLogo from '../../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

function PrestaContact() {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [opened, { open, close }] = useDisclosure(false);

    const navigate = useNavigate();

    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/p/login");
            return;
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const form = useForm({
        initialValues: {
            service: "",
            object: "",
            message: "",
        },
        
        validate: {
            service: (value) => (value.length < 1 ? 'Sélectionnez' : null),
            object : (value) => (
                    value.length < 1 
                    ? 'Renseignez l\'objet de votre message'
                    :value.length > 50 
                    ? '50 caractères maximum'
                    : null
                ),
            message : (value) => (
                value.length < 10 
                ? 'Entre 10 et 500 caractères maximum'
                : value.length > 500 
                ? 'Entre 10 et 500 caractères maximum' 
                : null
            )
        }
    })

    const handleFormSubmit = async (values, _event) => {
        _event.preventDefault();
        try {
            const token = localStorage.getItem('token');
            await fetch("https://boldee-97d8d36af3bd.herokuapp.com/p/profile/contact", {
                method: "POST",
                body: JSON.stringify({
                    service: values.service,
                    object: values.object,
                    message: values.message,
                    userType : 'presta'
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            open();
        } catch (error) {
        }
    }

    return (
        <>
            {isMobile ? (
                <form onSubmit={form.onSubmit(handleFormSubmit)}>
                    <AppHdWithReturnLeft>Contactez-nous</AppHdWithReturnLeft>

                    <div className="width95pCenter">
                        <Select
                            label="Service"
                            placeholder="Sélectionnez"
                            data={[
                                { value: 'commercial', label: 'Commercial' },
                                { value: 'technique', label: 'Technique' },
                                { value: 'administratif', label: 'Administratif' },
                                { value: 'autre', label: 'Autre' },
                            ]}
                            withAsterisk
                            {...form.getInputProps('service')}
                        />

                        <TextInput
                            label="Objet"
                            placeholder="Objet"
                            withAsterisk
                            {...form.getInputProps('object')}
                        />

                        <div className="mrgB5"></div>

                        <Textarea
                            label="Message"
                            placeholder="Votre message"
                            withAsterisk
                            {...form.getInputProps('message')}
                        />

                        <div className="mrgB5"></div>

                    </div>

                    <div className="mrgB20"></div>

                    <div className="width95pCenter">
                        {form.isDirty() 
                            ?   <PrestaRectangleButtonMain type="submit">
                                    Envoyer
                                </PrestaRectangleButtonMain>
                            :   <RectangleButtonDisabled>
                                    Envoyer
                                </RectangleButtonDisabled>
                        }
                        
                        {open
                            ? <Modal
                                centered
                                size="50%"
                                opened={opened}
                                onClose={close}
                                withCloseButton={false}
                                className='useRoboto'>
                                <p className="txtCenter noMrgTB txt15">
                                    Message envoyé
                                </p>
                            </Modal>
                            : ""
                        }
                    </div>
                </form>
            ) : (
                <>
                    <AppDesktopLogo/>
                    <form onSubmit={form.onSubmit(handleFormSubmit)}>
                        <AppHdWithReturnLeft>Contactez-nous</AppHdWithReturnLeft>
                        <div className='mrgB30'></div>
                        <div className="width50pCenter">
                            <Select
                                label="Service"
                                placeholder="Sélectionnez"
                                data={[
                                    { value: 'commercial', label: 'Commercial' },
                                    { value: 'technique', label: 'Technique' },
                                    { value: 'administratif', label: 'Administratif' },
                                    { value: 'autre', label: 'Autre' },
                                ]}
                                withAsterisk
                                size='md'
                                {...form.getInputProps('service')}
                            />

                            <div className='mrgB20'></div>

                            <TextInput
                                label="Objet"
                                placeholder="Objet"
                                withAsterisk
                                size='md'
                                {...form.getInputProps('object')}
                            />

                            <div className="mrgB20"></div>

                            <Textarea
                                label="Message"
                                placeholder="Votre message"
                                withAsterisk
                                size='md'
                                {...form.getInputProps('message')}
                            />

                            <div className="mrgB30"></div>

                        <div>
                            {form.isDirty() 
                                ?   <PrestaRectangleButtonMain type="submit">
                                        <p className='txt16'>Envoyer</p>
                                    </PrestaRectangleButtonMain>
                                :   <RectangleButtonDisabled>
                                        <p className='txt16'>Envoyer</p>
                                    </RectangleButtonDisabled>
                            }
                            
                            {open
                                ? <Modal
                                    centered
                                    size="auto"
                                    opened={opened}
                                    onClose={close}
                                    withCloseButton={false}>
                                    <p className="useRoboto txtCenter noMrgTB txt16">
                                        Message envoyé
                                    </p>
                                </Modal>
                                : ""
                            }
                        </div>
                        </div>
                    </form>
                </>
            )}
        </>
    )
}

export default PrestaContact;

