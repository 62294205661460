import React from "react";
import certibatLogo from '../../../../assets/images/certibat.png'
import classes from './LogoCertibat.module.css';

const LogoCertibat = (props) => {
    return(
        <div className={classes.Logo}>
            <img src={certibatLogo} alt="logo"/>
        </div>
    )
}
export default LogoCertibat;