import React from "react";
import qualifelecLogo from '../../../../assets/images/qualifelec.jpg'
import classes from './LogoQualifelec.module.css';

const LogoQualifelec = (props) => {
    return(
        <div className={classes.Logo}>
            <img src={qualifelecLogo} alt="logo"/>
        </div>
    )
}
export default LogoQualifelec;