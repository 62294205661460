import React from "react";
import handibatLogo from '../../../../assets/images/handibat.png'
import classes from './LogoHandibat.module.css';

const LogoHandibat = (props) => {
    return(
        <div className={classes.Logo}>
            <img src={handibatLogo} alt="logo"/>
        </div>
    )
}
export default LogoHandibat;