import React from "react";
import boldeeLogo from '../../../assets/images/logo_boldee_BW.png'
import classes from './LogoBurgerMenu.module.css';

const LogoBurgerMenu = () => {
    return(
        <div className={classes.Logo}>
            <img src={boldeeLogo} alt="logo"/>
        </div>
    )
}
export default LogoBurgerMenu;