import { React, useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../../../contexts/authContext';
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDisclosure } from '@mantine/hooks';

import { IconClock, IconInfoCircle, IconHammer, IconCalendarEvent, IconHourglassEmpty, IconMapPin, IconPhone } from '@tabler/icons-react';
import { Badge, Loader, Modal } from '@mantine/core';
import { parseISO, format } from 'date-fns';
import { fr } from 'date-fns/locale';

import AppHdWithCloseRight from '../../../../composents/Headers/AppHeaders/AppHdWithCloseRight/AppHdWithCloseRight';
import Box from '../../../../composents/Boxes/Standard/Box'
import PrestaRectangleButtonMain from '../../../../composents/Buttons/Presta/RectangleButtonMain/RectangleButtonMain';
import AppDesktopLogo from '../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

const RdvInfo = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [rdv, setRdv] = useState(null);
    const [openedContactInfo, { open : openContactInfo, close : closeContactInfo}] = useDisclosure(false);

    let {id} = useParams()
    const navigate = useNavigate();

    useEffect(() => {
        getSingleRdv();
        const token = getToken();
        if (!token) {
            navigate("/p/login");
            return;
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);
    
    const getSingleRdv = async() => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/p/rdv/info/${id}`, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`
                }
              });
            const rdv = await response.json();
            const rangeDate = rdv.offer.workRangeDates
            rdv.startDate = format(parseISO(rangeDate[0]), 'dd/MM/yyyy')
            rdv.endDate = format(parseISO(rangeDate[1]), 'dd/MM/yyyy')
            setRdv(rdv);  
        } catch (error) {
        }
    };

    const handleCall = (event) => {
        const phoneNumber = event.currentTarget.id
        window.location.href = `tel:${phoneNumber}`
    };

    const showContact = () => {
        openContactInfo()
    };

    return(
        rdv ?
            <>
                {isMobile ? (
                    <>
                        <AppHdWithCloseRight>Informations du RDV</AppHdWithCloseRight>
                            <Box>
                                <div className='horizontal iStart spaceBtw'>
                                    <div className="vertical spaceBtw mrgL10">
                                        <div className="horizontal iCenter mrgL10">
                                            <IconCalendarEvent  width={15}/>
                                            <p className="txt16 bold mrgL5 mrgT5 mrgB5 cap">{format(parseISO(rdv.dateOfAppointment), 'eeee dd MMMM', {locale: fr})}</p>
                                        </div>
                                        <div className="horizontal iCenter mrgL10">
                                            <IconHourglassEmpty width={15} className="mrgR5"/>
                                            <p className="txt15 bold mrgR10 noMrgTB">{rdv.duration}</p>
                                        </div>
                                    </div>
                                    <div className="horizontal iCenter mrgR10">
                                        <IconClock width={15} className="mrgR5"/>
                                        <p className="txt15 bold mrgR10 mrgT5 mrgB5">{format(parseISO(rdv.timeOfAppointment), 'HH:mm')}</p>
                                    </div>
                                    </div>
                            </Box>

                            <div className="mrgB10"></div>

                            <Box>
                                <Link className="aReset bT" to={`/p/matchs/s-info/${rdv.offer._id}`}>
                                    <div className='horizontal iCenter mrgL20'>
                                        {rdv.offer.author.avatar
                                            ?   <div>
                                                    <img className="roundImg70"
                                                        src={rdv.offer.author.avatar}
                                                        alt='avatar'/>
                                                </div> 
                                            :   <div className="noAvatarProfile">{rdv.offer.author.firstname.charAt(0)}{rdv.offer.author.lastname.charAt(0)}</div>
                                        }
                                        <div className="vertical mrgL10">
                                            <p className="txt15 medium noMrgTB mrgL10">{rdv.offer.author.title} {rdv.offer.author.firstname} {rdv.offer.author.lastname}</p>
                                            <p className="txt13 mrgT5 noMrgB mrgL10">{rdv.offer.author.occupation}</p>
                                            <p className="txt13 mrgT5 noMrgB mrgL10 medium capitals">{rdv.offer.author.company.name}</p>
                                        </div>
                                    </div>
                                </Link>
                            </Box>

                            <div className="mrgB10"></div>

                            <Link to={`/p/rdv/info-offer/${rdv.offer._id}`} className="aReset bT">
                                <Box>
                                    <div className="horizontal iCenter mrgL20">
                                        <div className="vertical mrgR10">
                                            {rdv.offer.pictures.length > 0
                                                ?   <img className="roundImg70"
                                                        src={rdv.offer.pictures[0]}
                                                        alt='photo_chantier'/>
                                                :   <>
                                                    {rdv.offer.author.company.logo 
                                                        ?   <img className="roundImg70"
                                                                src={rdv.offer.author.company.logo}
                                                                alt='photo_chantier'/>
                                                        :   <div className="noAvatarProfile">{rdv.offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                    }
                                                    </>
                                            }
                                            
                                        </div>
                                        <div className="vertical jCenter mrgL10">
                                            <div className="horizontal spaceBtw mrgB5">
                                                {rdv.offer.urgent 
                                                    ?   <Badge color="red">Urgent</Badge> 
                                                    :   ""
                                                }
                                            </div>
                                            <div>
                                                <p className="txt15 medium noMrgT mrgB5">{rdv.offer.title}</p>
                                            </div>
                                            <div className="horizontal iCenter lineHmin">
                                                <IconHammer width={13} height={13} className="mrgR10"/>
                                                <p className="noMrg txt13">{rdv.offer.occupation}</p>
                                            </div>
                                            <div className="mrgB5"></div>
                                            <div className="horizontal iCenter">
                                                <IconCalendarEvent width={13} height={13} className="mrgR10"/>
                                                <p className="noMrg txt13">Du {rdv.startDate} au {rdv.endDate}</p>
                                            </div>    
                                        </div>
                                    </div>
                                </Box>
                            </Link>

                            <div className="mrgB20"></div>

                            <div className="width95pCenter">
                                <p className="txt15 mrgB5 mrgL20 bold">Adresse du RDV</p>
                            </div>
                            
                            <Box>
                                <div className="mrgL10">
                                        <div className="horizontal iCenter mrgL10">
                                            <IconMapPin width={15}/>
                                            <p className="txt15 mrgL10 noMrgTB cap">{rdv.address}</p>
                                        </div>
                                        
                                        {rdv.instructions.length > 1
                                            ?
                                            <>
                                                    <hr className='width95pCenter mrgL10'></hr>
                                                    <div className="horizontal iCenter mrgL10">
                                                        <IconInfoCircle width={15} className="mrgR5"/>
                                                        <p className="txt15 mrgL5 noMrgTB cap">{rdv.instructions}</p>
                                                    </div>
                                                </>
                                            :   ""
                                        }
                                </div>
                            </Box>

                            <div className="mrgB30"></div>

                            <div className="width90pCenter">
                                <PrestaRectangleButtonMain id={rdv.offer.author.phone} onClick={handleCall}>
                                    Contacter
                                </PrestaRectangleButtonMain>
                            </div>

                            <div className="mrgB30"></div>
                    </>
                ) : (
                    <>
                        <AppDesktopLogo />
                        <AppHdWithCloseRight>Informations du RDV</AppHdWithCloseRight>
                            <div className='width50pCenter'>    
                                <Box>
                                    <div className='horizontal iStart spaceBtw mrgT20 mrgB20'>
                                        <div className="vertical spaceBtw mrgL20">
                                            <div className="horizontal iCenter mrgL10">
                                                <IconCalendarEvent  width={20} className='mrgB10'/>
                                                <p className="txt18 bold mrgL10 mrgT5 mrgB10 cap">{format(parseISO(rdv.dateOfAppointment), 'eeee dd MMMM', {locale: fr})}</p>
                                            </div>
                                            <div className="horizontal iCenter mrgL10">
                                                <IconHourglassEmpty width={20} className="mrgB5"/>
                                                <p className="txt18 bold mrgL10 noMrgTB">{rdv.duration}</p>
                                            </div>
                                        </div>
                                        <div className="horizontal iCenter mrgR20">
                                            <IconClock width={20} className="mrgB5"/>
                                            <p className="txt18 bold mrgR10 mrgL10 mrgT5 mrgB5">{format(parseISO(rdv.timeOfAppointment), 'HH:mm')}</p>
                                        </div>
                                    </div>
                                </Box>

                                <div className="mrgB10"></div>

                                <Box>
                                    <Link className="aReset bT" to={`/p/matchs/s-info/${rdv.offer._id}`}>
                                        <div className='horizontal iCenter mrgL20 mrgT10 mrgB10'>
                                            {rdv.offer.author.avatar
                                                ?   <div>
                                                        <img className="roundImg100"
                                                            src={rdv.offer.author.avatar}
                                                            alt='avatar'/>
                                                    </div> 
                                                :   <div className="noAvatarProfile">{rdv.offer.author.firstname.charAt(0)}{rdv.offer.author.lastname.charAt(0)}</div>
                                            }
                                            <div className="vertical mrgL20">
                                                <p className="txt18 mediumplus noMrgTB mrgL10">{rdv.offer.author.title} {rdv.offer.author.firstname} {rdv.offer.author.lastname}</p>
                                                <p className="txt16 mrgT5 noMrgB mrgL10">{rdv.offer.author.occupation}</p>
                                                <p className="txt16 mrgT5 noMrgB mrgL10 medium capitals">{rdv.offer.author.company.name}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </Box>

                                <div className="mrgB10"></div>

                                <Link to={`/p/rdv/info-offer/${rdv.offer._id}`} className="aReset bT">
                                    <Box>
                                        <div className="horizontal iCenter mrgL20 mrgT20 mrgB20">
                                            <div className="vertical mrgR10">
                                                {rdv.offer.pictures.length > 0
                                                    ?   <img className="roundImg100"
                                                            src={rdv.offer.pictures[0]}
                                                            alt='photo_chantier'/>
                                                    :   <>
                                                        {rdv.offer.author.company.logo 
                                                            ?   <img className="roundImg100"
                                                                    src={rdv.offer.author.company.logo}
                                                                    alt='photo_chantier'/>
                                                            :   <div className="noAvatarProfile">{rdv.offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                        }
                                                        </>
                                                }
                                                
                                            </div>
                                            <div className="vertical jCenter mrgL20">
                                                <div className="horizontal spaceBtw mrgB5">
                                                    {rdv.offer.urgent 
                                                        ?   <Badge size='lg' color="red">Urgent</Badge> 
                                                        :   ""
                                                    }
                                                </div>
                                                <div>
                                                    <p className="txt18 mediumplus noMrgT mrgB5">{rdv.offer.title}</p>
                                                </div>
                                                <div className="horizontal iCenter mrgB5">
                                                    <IconHammer width={20} height={20} className="mrgR10"/>
                                                    <p className="noMrgTB txt16">{rdv.offer.occupation}</p>
                                                </div>
                                                <div className="horizontal iCenter">
                                                    <IconCalendarEvent width={20} height={20} className="mrgR10"/>
                                                    <p className="noMrgB mrgT5 txt16">Du {rdv.startDate} au {rdv.endDate}</p>
                                                </div>    
                                            </div>
                                        </div>
                                    </Box>
                                </Link>

                                <div className="mrgB20"></div>

                                <div>
                                    <p className="txt18 mrgB10 mrgL20 bold">Adresse du RDV</p>
                                </div>
                                
                                <Box>
                                    <div className="mrgL10 mrgT10 mrgB10">
                                            <div className="horizontal iCenter mrgL10 ">
                                                <IconMapPin width={20} className='mrgB10'/>
                                                <p className="txt18 mrgL10 noMrgTB cap mrgB10">{rdv.address}</p>
                                            </div>
                                            
                                            {rdv.instructions.length > 1
                                                ?
                                                <>
                                                        <hr className='width95pCenter mrgL10'></hr>
                                                        <div className="horizontal iCenter mrgL10">
                                                            <IconInfoCircle width={20} className="mrgR5 mrgT10"/>
                                                            <p className="txt18 mrgL5 noMrgTB cap mrgT10">{rdv.instructions}</p>
                                                        </div>
                                                    </>
                                                :   ""
                                            }
                                    </div>
                                </Box>

                                <div className="mrgB30"></div>

                                <div>
                                    <PrestaRectangleButtonMain id={rdv.offer.author.phone} onClick={showContact}>
                                        <p className='txt16'>Contacter</p>
                                    </PrestaRectangleButtonMain>
                                </div>
                                { openContactInfo 
                                    ?   <Modal 
                                            centered
                                            size="auto"
                                            opened={openedContactInfo}
                                            onClose={closeContactInfo}
                                            title="Contacter">
                                            <div className='mrgR40 mrgL40'>
                                                <p className="useRoboto txtCenter bold txt18 noMrgT mrgB10">
                                                    {rdv.offer.author.company.name} 
                                                </p>
                                                <p className="horizontal txtCenter iCenter useRoboto txt16 noMrgT mrgB10">
                                                    <div className='noMrgTB mrgR5'><IconPhone width={20} height={20}/></div>
                                                    <p className='noMrgT mrgB5 txt18'>{rdv.offer.author.phone}</p>
                                                </p>
                                            </div>
                                        </Modal>
                                    :   ""
                                } 
                                <div className="mrgB150"></div>
                            </div>
                    </>
                )}
            </>
        :
        <Loader />
    )
}

export default RdvInfo;