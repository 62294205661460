import React from "react";
import iso9001Logo from '../../../../assets/images/iso9001.jpg'
import classes from './LogoIso9001.module.css';

const LogoIso9001 = (props) => {
    return(
        <div className={classes.Logo}>
            <img src={iso9001Logo} alt="logo"/>
        </div>
    )
}
export default LogoIso9001;