import { React, useMemo, useEffect, useState, useContext } from 'react';
import { PrestaFncContext} from '../../../../contexts/prestaFncContext';
import { AuthContext } from '../../../../contexts/authContext';
import { useParams, Link, useNavigate } from "react-router-dom";

import { Carousel } from '@mantine/carousel';
import { IconPhoto, IconHammer, IconLocation, IconBuilding, IconCalendarEvent, IconCalendarDue, IconHeart, IconHeartFilled, IconFileCheck, IconCheck, IconChevronRight } from '@tabler/icons-react';
import { Badge, Loader } from '@mantine/core';
import { parseISO, format, formatDistanceToNowStrict } from "date-fns";
import { fr } from "date-fns/locale";

import GoBackClosePageOverPictureButton from '../../../../composents/Buttons/All/GoBackClosePageOverPictureButton/GoBackClosePageOverPictureButton';
import Box from '../../../../composents/Boxes/Standard/Box';
import RectangleButtonMain from '../../../../composents/Buttons/Presta/RectangleButtonMain/RectangleButtonMain';
import SyndicRectangleButton from '../../../../composents/Buttons/Syndic/RectangleButton/RectangleButtonMain';
import PrestaRectangleButtonDisabled from '../../../../composents/Buttons/All/RectangleButtonDisabled/RectangleButtonDisabled';
import AppDesktopLogo from '../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

const OfferInfoSaved = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const { handleNewRdvDemandSubmit } = useContext(PrestaFncContext);
    const [userData, setUserData ] = useState(null)
    const [offer, setOffer] = useState();
    const [isLoading, setIsLoading] = useState(true);

    let {id} = useParams()

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const token = getToken();
            if (!token) {
                navigate("/p/login");
                return;
            }
            try {
                await Promise.all([getUser(), getSingleOffer()]);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
            };
    
        fetchData();

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const getUser = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch('https://boldee-97d8d36af3bd.herokuapp.com/p/getUser', {
                method: "GET",    
                headers: {
                    Authorization: `Bearer ${token}`
                    }
        });
            const data = await response.json()
            setUserData(data)
        }   catch (error) {
            return null;
        }
    }

    const getSingleOffer = async() => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/p/resultOfferInfo/${id}`, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`
                }
              });
              const offer = await response.json();
              const rangeDate = offer.workRangeDates
              offer.startDate = format(parseISO(rangeDate[0]), 'dd/MM/yyyy');
              offer.endDate = format(parseISO(rangeDate[1]), 'dd/MM/yyyy');
              const dDate = offer.deadlineToReceiveQuotationDate
              offer.deadlineDate = format(parseISO(dDate), 'dd/MM/yyyy');              
              const deadline = new Date(offer.deadlineToReceiveQuotationDate);
              let result = formatDistanceToNowStrict(deadline, {
                unit: "day",
                locale: fr
              });
              result = result.split(" ")[0];
              offer.deadline = result;
              setOffer(offer);
        } catch (error) { 
            console.error(error);
    }
    }

    useMemo( async () => {
        await getSingleOffer()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const openLinkToDocs = (event) => {
        window.open(offer.linkToDocs, '_blank');
    }

    const handleSaveSubmit = async (event) => {
        const idSave = event.currentTarget.id;
        try {
            const token = localStorage.getItem('token');
            await fetch("https://boldee-97d8d36af3bd.herokuapp.com/p/resultOfferInfo/save", {
                method: "POST",
                body: JSON.stringify({
                    id: idSave,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            await getSingleOffer()
        } catch (error) {
        }
      };

    if (isLoading) {
    return <Loader />;
    }

    return(
        <>
            {isMobile ? (
                <>
                    <div>
                        <Carousel
                            height={350}
                            slideSize="33.333333%"
                            slideGap="md"
                            breakpoints={[
                                { maxWidth: 'md', slideSize: '50%' },
                                { maxWidth: 'sm', slideSize: '100%', slideGap: 0 },
                            ]}
                            loop
                            align="start">
                                {offer.pictures.length > 0
                                        ?   <>
                                            {offer.pictures.map((url) => (
                                                    <Carousel.Slide key={url}>
                                                    <img
                                                        className="imgCarroussel"
                                                        src={url}
                                                        alt='photo_chantier'/>
                                                </Carousel.Slide>
                                                ))}
                                            </>
                                        :   <>
                                                {offer.author.company.logo
                                                    ?   <Carousel.Slide className='horizontal iCenter jCenter'>
                                                            <img    
                                                                className="bLiteGrey carrouselLogoImg "
                                                                src={offer.author.company.logo}
                                                                alt='logo'/>
                                                        </Carousel.Slide> 
                                                        :   <div className='horizontal iCenter jCenter w100 dGreyT'><IconPhoto width={48} height={48}/></div>
                                                }
                                            </>     
                                    }
                        </Carousel>
            
                        <div id="syndic-logo-OffreDeTravaux">
                            <Link to ={`/p/matchs/s-info/${id}`} className='aReset'>
                                {offer.author.company.logo
                                    ?   <img className='img68x68' 
                                            src={offer.author.company.logo}
                                            alt='logo'/>
                                    :   <div className="img68x68 horizontal iCenter jCenter bLiteGrey txt30 dGreyT medium txtCenter">{offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                }
                            </Link>

                        </div>  
                                
                        <GoBackClosePageOverPictureButton/>         
                    </div>

                    <div className="offer-first-lines-info">
                        <div className="horizontal iCenter spaceBtw">
                            <div>
                                {offer.urgent 
                                    ?   <Badge color="red">Urgent</Badge> 
                                    :   ""
                                }
                            </div>
                            <div className="horizontal iBtm">
                                <h2 className="txt20 noMrgTB mrgR2">{offer.deadline}</h2>
                                <span>J</span>
                            </div>
                        </div>
                        <div className='horizontal iStart spaceBtw'>
                            <div>
                                <h1 className= "txt20 lineH20 noMrgB">{offer.title}</h1>
                            </div>
                            <div>
                                
                                    {offer.savedBy.includes(userData._id)        
                                        ?   <div> 
                                                <IconHeartFilled className='terracotaT' width={25} height={30}/>
                                            </div>
                                        :    <div>
                                                <button className="btnReset bWhite noPdg" type='submit'>
                                                <IconHeart className='terracotaT noPdg' id={offer._id} onClick={handleSaveSubmit} strokeWidth="1.0" width={25} height={30}/>
                                                </button>
                                            </div>
                                    }
                            </div>
                        </div>
                        <div>
                            <p className="txt15 lineH15 noMrgB mrgT5">{offer.description}</p>
                        </div>

                        <div>
                            {offer.linkToDocs
                                ? <div onClick={openLinkToDocs} className='horizontal iCenter'>
                                    <p className="underline underlineOffset3 mrgT8 mrgB7 mrgR2">Voir documents</p> <IconChevronRight size={15} className='mrgT8 mrgB7'/>
                                    </div>
                                : ""
                            }
                        </div>
                    </div>

                    <div id="positionUp">
                    <div className="redbox">
                        <div className="vertical wraped">
                            <div className="horizontal iCenter mrgB5 mrgR20">
                                <IconHammer width={13} className="mrgL10 mrgR10"/>
                                <span className = "bold">{offer.occupation}</span>
                            </div>
                            <div className="horizontal spaceBtw wraped">
                                <div className="horizontal iCenter mrgB5 mrgR20">
                                    <IconLocation width={13} className="mrgL10 mrgR10"/>
                                    <span className = "bold ">{offer.cityOfIntervention}</span>
                                </div>
                                {offer.nbOfLots 
                                    ?   <div className="horizontal iCenter mrgB5">
                                            <IconBuilding width={13} className="mrgL10 mrgR10"/>
                                            <span className = "bold mrgR20">{offer.nbOfLots} lots</span>
                                        </div>
                                    :   ""
                                }   
                            </div>
                        </div>
                    </div>

                    <div className="mrgB10"></div>
                    
                    <Box>
                        <div className="mrgB10"></div>
                        <div>
                            <div className="horizontal iCenter"><IconCalendarEvent width={18} className="mrgL10 mrgR10"/>
                                <span className="txt15">Du {offer.startDate} au {offer.endDate}</span></div>
                            <div className="txt13 mrgL40 dGreyT">Dates prévisionnelles de travaux</div>    
                        </div>

                        <div className="mrgB20"></div>

                        <div>
                            <div className="horizontal iCenter"><IconCalendarDue width={18} className="mrgL10 mrgR10"/>
                                <span className="txt15">{offer.deadlineDate}</span></div>
                            <div className="txt13 mrgL40 dGreyT">Date limite de réception des devis</div>    
                        </div>

                        <div className="mrgB20"></div>

                        <div>
                            {offer.quotationReceptionAllowed 
                                ?   <div className="horizontal iCenter">
                                        <IconFileCheck width={18} className="mrgL10 mrgR10"/>
                                        <span className="txt15">Envoi de devis sans RDV de chiffrage possible</span>
                                    </div>
                                :   ""
                            }  
                        </div>

                    </Box>

                    </div>

                    {!offer.rdvSelected && !offer.quotationReceptionAllowed
                            ? 
                                <>
                                <div className="width95pCenter">
                                    {offer.receivedQuotations.length > 0
                                        ?   <>  
                                                {offer.receivedQuotations.map(quot => quot.author).includes(userData._id)
                                                    ?  <RectangleButtonMain>
                                                            <div className='horizontal iCenter jCenter'>
                                                                <p className="noMrgTB mrgR5">Devis envoyé</p>
                                                                <IconCheck  className="noMrgTB" width={15}/>
                                                            </div>
                                                        </RectangleButtonMain>    
                                                    :   <>
                                                            <Link to={`/p/matchs/send-quo/${offer._id}`}>
                                                                <SyndicRectangleButton>
                                                                    <p className='txt13 noMrgTB'>Envoyer devis</p>
                                                                </SyndicRectangleButton>
                                                            </Link>
                                                            <div className='mrgB30'></div>
                                                        </>
                                                }
                                                <div className='mrgB30'></div>
                                            </>
                                        :   <>
                                                <Link to={`/p/matchs/send-quo/${offer._id}`}>
                                                    <SyndicRectangleButton>
                                                        <p className='txt13 noMrgTB'>Envoyer devis</p>
                                                    </SyndicRectangleButton>
                                                </Link>
                                                <div className='mrgB30'></div>
                                            </>
                                    }
                                </div>
                                </>  

                        :   <>
                                <div className="width95pCenter">
                                    {offer.rdvSelected 
                                        ?   <>
                                                {offer.rdvDemands.length > 0
                                                    ?   <>  
                                                        {offer.rdvDemands.map((rdv)=> rdv.applicant).includes(userData._id)
                                                            ?   
                                                            <> 
                                                                <RectangleButtonMain>
                                                                    <div className='horizontal iCenter jCenter'>
                                                                        <p className="noMrgTB mrgR5">Demande RDV</p>
                                                                        <IconCheck  className="noMrgTB" width={15}/>
                                                                    </div>
                                                                </RectangleButtonMain>  
                                                            </>
                                                            :   <form id={offer._id} onSubmit={handleNewRdvDemandSubmit}>
                                                                    <div>
                                                                        <SyndicRectangleButton type='submit'>
                                                                            Demander un RDV
                                                                        </SyndicRectangleButton>
                                                                    </div>
                                                                </form>
                                                        }
                                                        </>
                                                    :   <form id={offer._id} onSubmit={handleNewRdvDemandSubmit}>
                                                            <div>
                                                                <SyndicRectangleButton type='submit'>
                                                                    Demander un RDV
                                                                </SyndicRectangleButton>
                                                            </div>
                                                        </form>
                                                    }
                                            </>
                                        :   <PrestaRectangleButtonDisabled>
                                                RDV non requis
                                            </PrestaRectangleButtonDisabled>
                                    }

                                    <div className="mrgB10"></div>
                                                        
                                    <div>
                                        {offer.quotationReceptionAllowed 
                                            ?   <>
                                                {offer.receivedQuotations.length > 0
                                                    ?   <>  
                                                            {offer.receivedQuotations.map((quot) => quot.author).includes(userData._id)
                                                                ?   <RectangleButtonMain>
                                                                        <div className='horizontal iCenter jCenter'>
                                                                            <p className="noMrgTB mrgR5">Devis envoyé</p>
                                                                            <IconCheck  className="noMrgTB" width={15}/>
                                                                        </div>
                                                                    </RectangleButtonMain>
                                                                :
                                                                    <Link to={`/p/matchs/send-quo/${offer._id}`}>
                                                                        <SyndicRectangleButton>
                                                                            Envoyer un devis
                                                                        </SyndicRectangleButton>
                                                                    </Link>
                                                            }
                                                        </>
                                                    :   <Link to={`/p/matchs/send-quo/${offer._id}`}>
                                                            <SyndicRectangleButton>
                                                                Envoyer un devis
                                                            </SyndicRectangleButton>
                                                        </Link>
                                                }
                                                </>
                                            :   
                                                <PrestaRectangleButtonDisabled>
                                                    Envoyer un devis
                                                </PrestaRectangleButtonDisabled>
                                                
                                        }
                                    </div>   
                                </div>
                                <div id="mrgB50"></div>
                            </>
                    }
                </>
            ) : (
                <>
                    <AppDesktopLogo />
                    <div className='mrgT50 width50pCenter'>
                        <GoBackClosePageOverPictureButton/>         
                    </div>
                    <div className='width50pCenter horizontal'>
                        <div className='w400'>
                            <div className='mrgB10'></div>
                            <Carousel
                                style={{
                                    border:'1px solid #ebebeb',
                                }}
                                height={390}
                                slideSize="98%"
                                slideGap="sm"
                                breakpoints={[
                                    { maxWidth: 'md', slideSize: '70%' },
                                    { maxWidth: 'sm', slideSize: '100%', slideGap: 0 },
                                ]}
                                loop
                                align="start">
                                    {offer.pictures.length > 0
                                            ?   <>
                                                {offer.pictures.map((url) => (
                                                        <Carousel.Slide key={url}>
                                                        <img
                                                            className="imgCarroussel"
                                                            src={url}
                                                            alt='photo_chantier'/>
                                                    </Carousel.Slide>
                                                    ))}
                                                </>
                                            :   <>
                                                    {offer.author.company.logo
                                                        ?   <Carousel.Slide className='horizontal iCenter jCenter'>
                                                                <img    
                                                                    className="bLiteGrey carrouselLogoImg "
                                                                    src={offer.author.company.logo}
                                                                    alt='logo'/>
                                                            </Carousel.Slide> 
                                                            :   <div className='horizontal iCenter jCenter w100 dGreyT'><IconPhoto width={48} height={48}/></div>
                                                    }
                                                </>     
                                        }
                            </Carousel>            
                        
                            <Link to ={`/p/matchs/s-info/${id}`} className='aReset'>
                                <div id="syndic-logo-OffreDeTravaux">
                                    {offer.author.company.logo
                                        ?   <img className='img68x68' 
                                                src={offer.author.company.logo}
                                                alt='logo'/>
                                        :   <div className="noLogoOnPic">{offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                    }
                                </div> 
                            </Link>                        
                        </div>         
                    
                        <div className='vertical width50pCenter mrgL20 mrgT10'>
                            <div className="offer-first-lines-info">
                                <div className="horizontal iCenter spaceBtw">
                                    <div>
                                        {offer.urgent 
                                            ?   <Badge size="lg" color="red">Urgent</Badge> 
                                            :   ""
                                        }
                                    </div>
                                    <div className="horizontal iBtm">
                                        <h2 className="txt25 noMrgTB mrgR2">{offer.deadline}</h2>
                                        <span>J</span>
                                    </div>
                                </div>
                                <div>
                                    <h1 className= "txt20 cap">{offer.title}</h1>
                                </div>
                                <div>
                                    <p className="txt16 lineH18 noMrgB txtJustify w100">{offer.description}</p>
                                </div>
                                <div>
                                    {offer.linkToDocs
                                        ? <div onClick={openLinkToDocs} className='horizontal iCenter'>
                                            <p className="underline underlineOffset3 mrgT8 mrgB7 mrgR2">Voir documents</p> <IconChevronRight size={15} className='mrgT8 mrgB7'/>
                                            </div>
                                        : ""
                                    }
                                </div>
                            
                                <div>
                                    <div className="redbox">
                                        <div className="vertical">
                                            <div className="horizontal iCenter mrgB5 mrgR20">
                                                <IconHammer width={18} className="mrgL10 mrgR10"/>
                                                <span className="medium txt16">{offer.occupation}</span>
                                            </div>
                                            <div className="horizontal iCenter mrgB5 mrgR20">
                                                <IconLocation width={18} className="mrgL10 mrgR10"/>
                                                <span className="medium txt16">{offer.cityOfIntervention}</span>
                                            </div>
                                            {offer.nbOfLots 
                                                ?   <div className="horizontal iCenter mrgB5">
                                                        <IconBuilding width={18} className="mrgL10 mrgR10"/>
                                                        <span className = "medium txt16">{offer.nbOfLots} lots</span>
                                                    </div>
                                                :   ""
                                            }   
                                        </div>
                                    </div>

                                    <div className="mrgB20"></div>
                                    
                                    <div className='vertical mrgL10'>
                                        
                                        <div className="horizontal iStart">
                                            <IconCalendarEvent width={18} className="mrgL5 mrgR10"/>
                                            <div className='vertical'>
                                                <span className="txt16">Du {offer.startDate} au {offer.endDate}</span>
                                                <div className="txt15 dGreyT">Dates prévisionnelles de travaux</div>    
                                            </div>
                                        </div>

                                        <div className="mrgB10"></div>

                                        <div>
                                            <div className="horizontal iStart">
                                                <IconCalendarDue width={18} className="mrgL5 mrgR10"/>
                                                <div className='vertical'>
                                                    <span className="txt16">{offer.deadlineDate}</span>
                                                    <div className="txt15 dGreyT">Date limite de réception des devis</div>    
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mrgB10"></div>

                                        <div>
                                            {offer.quotationReceptionAllowed 
                                                ?   <div className="horizontal iStart">
                                                        <IconFileCheck width={18} className="mrgL5 mrgR10"/>
                                                        <span className="txt16">Envoi de devis sans RDV de chiffrage possible</span>
                                                    </div>
                                                :   ""
                                            }  
                                        </div>
                                    </div>
                                </div>                               
                            </div>                
                        </div>
                    </div>
                    {!offer.rdvSelected && !offer.quotationReceptionAllowed
                        ? 
                            <>
                            <div className="width50pCenter mrgT20">
                                {offer.receivedQuotations.length > 0
                                    ?   <>  
                                            {offer.receivedQuotations.map(quot => quot.author).includes(userData._id)
                                                ?  <RectangleButtonMain>
                                                        <div className='horizontal iCenter jCenter'>
                                                            <p className="noMrgTB mrgR5 txt16">Devis envoyé</p>
                                                            <IconCheck  className="noMrgTB" width={15}/>
                                                        </div>
                                                    </RectangleButtonMain>    
                                                :   <>
                                                        <Link to={`/p/matchs/send-quo/${offer._id}`}>
                                                            <SyndicRectangleButton>
                                                                <p className='txt16 noMrgTB txt16'>Envoyer devis</p>
                                                            </SyndicRectangleButton>
                                                        </Link>
                                                        <div className='mrgB30'></div>
                                                    </>
                                            }
                                            <div className='mrgB30'></div>
                                        </>
                                    :   <>
                                            <Link to={`/p/matchs/send-quo/${offer._id}`}>
                                                <SyndicRectangleButton>
                                                    <p className='txt16 noMrgTB txt16'>Envoyer devis</p>
                                                </SyndicRectangleButton>
                                            </Link>
                                            <div className='mrgB30'></div>
                                        </>
                                }
                            </div>
                            </>  

                        :   <>
                                <div className="width50pCenter mrgT20">
                                    {offer.rdvSelected 
                                        ?   <>
                                                {offer.rdvDemands.length > 0
                                                    ?   <>  
                                                        {offer.rdvDemands.map((rdv)=> rdv.applicant).includes(userData._id)
                                                            ?   
                                                            <> 
                                                                <RectangleButtonMain>
                                                                    <div className='horizontal iCenter jCenter'>
                                                                        <p className="noMrgTB mrgR5 txt16">Demande RDV</p>
                                                                        <IconCheck  className="noMrgTB" width={15}/>
                                                                    </div>
                                                                </RectangleButtonMain>  
                                                            </>
                                                            :   <form id={offer._id} onSubmit={handleNewRdvDemandSubmit}>
                                                                    <div>
                                                                        <SyndicRectangleButton type='submit'>
                                                                            <p className='txt16'>Demander un RDV</p>
                                                                        </SyndicRectangleButton>
                                                                    </div>
                                                                </form>
                                                        }
                                                        </>
                                                    :   <form id={offer._id} onSubmit={handleNewRdvDemandSubmit}>
                                                            <div>
                                                                <SyndicRectangleButton type='submit'>
                                                                    <p className='txt16'>Demander un RDV</p>
                                                                </SyndicRectangleButton>
                                                            </div>
                                                        </form>
                                                    }
                                            </>
                                        :   <PrestaRectangleButtonDisabled>
                                                <p className='txt16'>RDV non requis</p>
                                            </PrestaRectangleButtonDisabled>
                                    }

                                    <div className="mrgB10"></div>
                                                        
                                    <div>
                                        {offer.quotationReceptionAllowed 
                                            ?   <>
                                                {offer.receivedQuotations.length > 0
                                                    ?   <>  
                                                            {offer.receivedQuotations.map((quot) => quot.author).includes(userData._id)
                                                                ?   <RectangleButtonMain>
                                                                        <div className='horizontal iCenter jCenter'>
                                                                            <p className="noMrgTB mrgR5 txt16">Devis envoyé</p>
                                                                            <IconCheck  className="noMrgTB" width={15}/>
                                                                        </div>
                                                                    </RectangleButtonMain>
                                                                :
                                                                    <Link to={`/p/matchs/send-quo/${offer._id}`}>
                                                                        <SyndicRectangleButton>
                                                                            <p className='txt16'>Envoyer un devis</p>
                                                                        </SyndicRectangleButton>
                                                                    </Link>
                                                            }
                                                        </>
                                                    :   <Link to={`/p/matchs/send-quo/${offer._id}`}>
                                                            <SyndicRectangleButton>
                                                                <p className='txt16'>Envoyer un devis</p>
                                                            </SyndicRectangleButton>
                                                        </Link>
                                                }
                                                </>
                                            :   
                                                <PrestaRectangleButtonDisabled>
                                                    <p className='txt16'>Envoyer un devis</p>
                                                </PrestaRectangleButtonDisabled>
                                                
                                        }
                                    </div>   
                                </div>
                                <div id="mrgB50"></div>
                            </>
                    }
                </>
            )}
        </>
    )
}

export default OfferInfoSaved;