import { React, useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../../../contexts/authContext';
import { useNavigate, useParams } from "react-router-dom";

import { TextInput, Loader, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { IconPhoto, IconPhone } from '@tabler/icons-react';
import { parseISO, formatDistanceToNowStrict } from 'date-fns'
import { fr } from "date-fns/locale";

import Box from '../../../../composents/Boxes/Standard/Box';
import AppHdWithCloseRight from '../../../../composents/Headers/AppHeaders/AppHdWithCloseRight/AppHdWithCloseRight';
import PrestaRectangleButtonMain from '../../../../composents/Buttons/Presta/RectangleButtonMain/RectangleButtonMain';
import RectangleButtonDisabled from '../../../../composents/Buttons/All/RectangleButtonDisabled/RectangleButtonDisabled';
import LogoQualibat from '../../../../composents/Logo/QualificationLogos/Qualibat/LogoQualibat'; 
import LogoArtisansDeConfiance from '../../../../composents/Logo/QualificationLogos/ArtisanDeConfiance/LogoArtisansDeConfiance'; 
import LogoCertibat from '../../../../composents/Logo/QualificationLogos/Certibat/LogoCertibat';
import LogoEcoArtisan from '../../../../composents/Logo/QualificationLogos/EcoArtisan/LogoEcoArtisan';
import LogoEcocert from '../../../../composents/Logo/QualificationLogos/EcoCert/LogoEcocert';
import LogoHandibat from '../../../../composents/Logo/QualificationLogos/Handibat/LogoHandibat';
import LogoIso9001 from '../../../../composents/Logo/QualificationLogos/Iso9001/LogoIso9001';
import LogoQualieau from '../../../../composents/Logo/QualificationLogos/Qualieau/LogoQualieau';
import LogoQualienr from '../../../../composents/Logo/QualificationLogos/Qualienr/LogoQualienr';
import LogoQualifelec from '../../../../composents/Logo/QualificationLogos/Qualifelec/LogoQualifelec';
import LogoQualipropre from '../../../../composents/Logo/QualificationLogos/Qualipropre/LogoQualipropre';
import LogoRGE from '../../../../composents/Logo/QualificationLogos/RGE/LogoRGE';
import LogoSilverbat from '../../../../composents/Logo/QualificationLogos/Silverbat/LogoSilverbat';
import AppDesktopLogo from '../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';



const PrestaPageToAddToFav = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [company, setCompany] = useState(null);
    const [prestaId, setPrestaId] = useState(null);
    const [opened, { open, close }] = useDisclosure(false);
    const [creationDate, setCreationDate] = useState(null);
    const [prestaCieName, setprestaCieName] = useState(null);
    const [openedConfirmationModal, { open: openConfirmationModal, close: closeConfirmationModal}] = useDisclosure(false);
    const [openedContactInfo, { open : openContactInfo, close : closeContactInfo}] = useDisclosure(false);
    const navigate = useNavigate();

    let {id} = useParams()

    useEffect(() => {
        getSinglePresta();
        const token = getToken();
        if (!token) {
            navigate("/s/login");
            return;
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const getSinglePresta = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/favPresta/p-page-to-add/${id}`, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`
                }
              });
            const company = await response.json();
            setCompany(company);
            setprestaCieName(company.name)
  
            const creationDate =company.creationDate
            const date = parseISO(creationDate, 'dd/mm/yyyy', new Date())
            let result = formatDistanceToNowStrict(
                date, {locale: fr}
            )
            setCreationDate(result)
        } catch (error) { 
            console.error(error);
    }
    }

    const form = useForm({
        initialValues: {
            email: '',
        },

        validate: {
            email : (value) => (
                value.length < 1
                ? 'Renseignez votre E-mail'
                : /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) 
                ? null 
                : 'E-mail non valide'
            ),   
        }
    })

    const handleFormSubmit = async (values, _event) => {
        _event.preventDefault();
        const idPresta = _event.currentTarget.id

        try {
            const token = localStorage.getItem('token');
            await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/favPresta/p-ext-reco/${idPresta}`, {
                method: "POST",
                body: JSON.stringify({
                    email: values.email,
                    recipientCieName : prestaCieName
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            openConfirmationModal()
        } catch (error) {
        }
    }

    const handleAddPrestaToFavClick = async event => {
        open()
        const idPresta = event.currentTarget.id
        setPrestaId(idPresta)

        try {
            const token = localStorage.getItem('token');
            await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/favPresta/p-page-to-add/${idPresta}`, {
                method: "POST",
                body: JSON.stringify({
                    id: idPresta,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            setTimeout(() => {
                navigate('/s/favPresta/fav-list');
            }, 1000)
        } catch (error) {
        }
    }

    const handleCall = (event) => {
        const phoneNumber = event.currentTarget.id
        window.location.href = `tel:${phoneNumber}`
    }

    const showContact = () => {
        openContactInfo()
    }

    return(
      company || creationDate ?
        <>
            {isMobile ? (
                <>
                <AppHdWithCloseRight>{company.name}</AppHdWithCloseRight>

                <div className="width90pCenter">
                    {company.isItAmember 
                        ?   <>
                                <div className="prestapage-logo-container">
                                    {company.logo
                                        ?   <img className="roundImg70"
                                                src={company.logo}
                                                alt='logo'/>
                                        :   <div className='horizontal iCenter jCenter w100 dGreyT'><IconPhoto width={48} height={48}/></div>
                                    }
                                </div>

                                <div className="vertical mrgB10 width90pCenter">
                                        <div className="horizontal spaceBtw">
                                            <div><h1 className="txt20 noMrgTB">{company.name}</h1></div>
                                            <div><button className='callBtn' id={company.address.phonecie} onClick={handleCall}>Contacter</button></div>

                                        </div>
                                        <div><p className="txt13 mrgT10 noMrgB bold">{company.prestaUserAccount.occupation.join(' - ')}</p></div>
                                        <div className="vertical">
                                            <div><p className="txt13 noMrgTB">{company.address.no}, {company.address.street}</p> </div>
                                            <div><p className="txt13 noMrgT mrgB5">{company.address.postalcode} {company.address.city}</p></div>
                                            <div><p className="txt13 noMrgTB">{company.emailcie}</p> </div>
                                        </div>
                                </div>

                                <>
                                <Box>
                                    <div className="indicateurs bLiteGrey horizontal spaceArd mrgB10">
                                        <div className="vertical">
                                            <div><h1>{creationDate}</h1></div>
                                            <div><p>d'expérience</p></div>
                                        </div>
                                        <div className="vertical">
                                            <div><h1>{(company.prestaUserAccount.addedToFavBy).length}</h1></div>
                                            <div><p>ajouté en favoris</p></div>
                                        </div>
                                        <div className="vertical">
                                            <div><h1>{(company.prestaUserAccount.recommendedBySyndicOffers).length}</h1></div>
                                            <div><p>recommandation(s)</p></div>
                                        </div>
                                    </div>
                                </Box>
                            
                                <div className=" width90pCenter mrgB10">
                                    <p className="txt13 txtJustify">{company.description}</p>
                                </div>

                                {company.qualifications.length > 0
                                    ?
                                        <div className='horizontal width90pCenter iCenter jCenter wraped'>
                                            {company.qualifications.map((certification, index) => (
                                                <div key={index}>
                                                    {certification === 'artisandeconfiance'
                                                        ?   <div className='mrgR20'><LogoArtisansDeConfiance/></div>
                                                        :   ''
                                                    }
                                                    {certification === 'certibat'
                                                        ?   <div className='mrgR20'><LogoCertibat/></div>
                                                        :   ''
                                                    }
                                                    {certification === 'ecoartisan'
                                                        ?   <div className='mrgR20'><LogoEcoArtisan/></div>
                                                        :   ''
                                                    }
                                                    {certification === 'ecocert'
                                                        ?   <div className='mrgR20'><LogoEcocert/></div>
                                                        :   ''
                                                    }
                                                    {certification === 'handibat'
                                                        ?   <div className='mrgR20'><LogoHandibat/></div>
                                                        :   ''
                                                    }
                                                    {certification === 'iso9001'
                                                        ?   <div className='mrgR20'><LogoIso9001/></div>
                                                        :   ''
                                                    }
                                                    {certification === 'qualibat'
                                                        ?   <div className='mrgR20'><LogoQualibat/></div>
                                                        :   ''
                                                    }
                                                    {certification === 'qualieau'
                                                        ?   <div className='mrgR20'><LogoQualieau/></div>
                                                        :   ''
                                                    }
                                                    {certification === 'qualienr'
                                                        ?   <div className='mrgR20'><LogoQualienr/></div>
                                                        :   ''
                                                    }
                                                    {certification === 'qualifelec'
                                                        ?   <div className='mrgR20'><LogoQualifelec/></div>
                                                        :   ''
                                                    }
                                                    {certification === 'qualipropre'
                                                        ?   <div className='mrgR20'><LogoQualipropre/></div>
                                                        :   ''
                                                    }
                                                    {certification === 'rge'
                                                        ?   <div className='mrgR20'><LogoRGE/></div>
                                                        :   ''
                                                    }
                                                    {certification === 'silverbat'
                                                        ?   <div className='mrgR20'><LogoSilverbat/></div>
                                                        :   ''
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    :   ""
                                }

                                <a href={company.website} target="_blank" rel="noopener noreferrer" className="aReset bT">
                                    <div className="txtCenter">
                                        <p className="txt15">{company.website}</p>
                                    </div>
                                </a>                

                                <div className="mrgB30"></div>
                            </>

                            <div className="width90pCenter mrgB30">
                                <button
                                    className="sBtn"
                                    id={company.prestaUserAccount._id}
                                    onClick={handleAddPrestaToFavClick}>
                                    Ajouter à mes favoris
                                </button>
                                {open && prestaId === company.prestaUserAccount._id 
                                    ?   <Modal 
                                            centered
                                            size="30%"
                                            opened={opened}
                                            onClose={close}
                                            withCloseButton={false}>
                                            <p className="txtCenter noMrgTB useRoboto txt15">
                                                Ajouté
                                            </p>
                                        </Modal> 
                                    :   ""
                                }
                            </div>         

                            </>
                        :   <>
                                <p className=" txt13 dGreyT txtCenter">Cette société n'est pas référencée chez Boldee. Vous pouvez lui indiquer que vous souhaitez l'ajouter en favoris.</p>
                                
                                <div className="horizontal spaceBtw iEnd mrgB10">
                                        <div className="vertical">
                                            <div><h1 className="txt18 noMrgTB">{company.name}</h1></div>

                                            <div><p className="txt13 noMrgTB bold">{company.prestaUserAccount.occupation.join(' - ')}</p></div>
                                            <div className="vertical">
                                                <div><p className="txt13 noMrgTB">{company.address.no}, {company.address.street}</p> </div>
                                                <div><p className="txt13 noMrgT mrgB5">{company.address.postalcode} {company.address.city}</p></div>
                                            </div>
                                        
                                        </div>    
                                    
                                            

                                </div>
                                <form id={company.prestaUserAccount._id} onSubmit={form.onSubmit(handleFormSubmit)}>
                                    <TextInput
                                        withAsterisk
                                        label="Ajouter à mes favoris :"
                                        placeholder="Email"
                                        {...form.getInputProps('email')}
                                    />
                                    <div className="mrgB10"></div>
                                            
                                    {form.isDirty() 
                                        ?   <PrestaRectangleButtonMain type="submit">
                                                <p id='syndicRecoNewPresta' className='noMrgTB'>Envoyer</p> 
                                            </PrestaRectangleButtonMain>
                                        :   <RectangleButtonDisabled>
                                                Envoyer
                                            </RectangleButtonDisabled>
                                    }
                                    { openConfirmationModal  
                                        ?   <Modal 
                                                centered
                                                size="55%"
                                                opened={openedConfirmationModal}
                                                onClose={closeConfirmationModal}
                                                withCloseButton={false}>
                                                <p className="useRoboto txt15 txtCenter noMrgTB">
                                                    Recommandation envoyée
                                                </p>
                                            </Modal> 
                                        :   ""
                                    }
                                </form>
                            

                            </>
                    }
                </div>
                </>
            ) : (
                <>
                    <AppDesktopLogo/>
                    <AppHdWithCloseRight>
                        <p className='txt20'>{company.name}</p>
                    </AppHdWithCloseRight>

                    <div className="prestapage-container">
                        {company.isItAmember 
                            ?   <>
                                    <div className="prestapage-logo-container">
                                        {company.logo
                                            ?   <img 
                                                    src={company.logo}
                                                    alt='logo'/>
                                            :   <div className='horizontal iCenter jCenter w100 dGreyT mrgT20'><IconPhoto width={48} height={48}/></div>
                                        }
                                    </div>

                                    <div className="vertical mrgB10">
                                            <div className="horizontal spaceBtw">
                                                <div><h1 className="txt25 noMrgT mrgB10">{company.name}</h1></div>
                                                <div><button className='callBtn' id={company.address.phonecie} onClick={showContact}>Contacter</button></div>

                                            </div>
                                            <div><p className="txt18 lineH20 mrgT5 mrgB10 medium">{company.prestaUserAccount.occupation.join(' - ')}</p></div>
                                            <div className="vertical mrgT5 mrgB20">
                                                <div><p className="txt16 noMrgTB">{company.address.no}, {company.address.street}</p> </div>
                                                <div><p className="txt16 noMrgT mrgB10">{company.address.postalcode} {company.address.city}</p></div>
                                                <div><p className="txt16 noMrgTB">{company.emailcie}</p> </div>
                                            </div>
                                    </div>
                                    { openContactInfo 
                                        ?   <Modal 
                                                centered
                                                size="auto"
                                                opened={openedContactInfo}
                                                onClose={closeContactInfo}
                                                title="Contacter">
                                                <div className='mrgR40 mrgL40'>
                                                    <p className="useRoboto txtCenter bold txt18 noMrgT mrgB10">
                                                        {company.name} 
                                                    </p>
                                                    <p className="horizontal txtCenter iCenter useRoboto txt16 noMrgT mrgB10">
                                                        <div className='noMrgTB mrgR5'><IconPhone width={20} height={20}/></div>
                                                        <p className='noMrgT mrgB5 txt18'>{company.address.phonecie}</p>
                                                    </p>
                                                </div>
                                            </Modal> 
                                        :   ""
                                    }
                                    <>
                                    <Box>
                                        <div className="indicateurs bLiteGrey horizontal spaceArd mrgB10 mrgT10">
                                            <div className="vertical">
                                                <div><h1>{creationDate}</h1></div>
                                                <div className='noMrgT'><p>d'expérience</p></div>
                                            </div>
                                            <div className="vertical">
                                                <div><h1>{(company.prestaUserAccount.addedToFavBy).length}</h1></div>
                                                <div className='noMrgT'><p>ajouté en favoris</p></div>
                                            </div>
                                            <div className="vertical">
                                                <div><h1>{(company.prestaUserAccount.recommendedBySyndicOffers).length}</h1></div>
                                                <div className='noMrgT'><p>recommandation(s)</p></div>
                                            </div>
                                        </div>
                                    </Box>
                                
                                    <div className="mrgT10 mrgB20">
                                        <p className="txt16 txtJustify">{company.description}</p>
                                    </div>

                                    {company.qualifications.length > 0
                                        ?
                                            <div className='horizontal width90pCenter iCenter jCenter wraped'>
                                                {company.qualifications.map((certification, index) => (
                                                    <div key={index}>
                                                        {certification === 'artisandeconfiance'
                                                            ?   <div className='mrgR20'><LogoArtisansDeConfiance/></div>
                                                            :   ''
                                                        }
                                                        {certification === 'certibat'
                                                            ?   <div className='mrgR20'><LogoCertibat/></div>
                                                            :   ''
                                                        }
                                                        {certification === 'ecoartisan'
                                                            ?   <div className='mrgR20'><LogoEcoArtisan/></div>
                                                            :   ''
                                                        }
                                                        {certification === 'ecocert'
                                                            ?   <div className='mrgR20'><LogoEcocert/></div>
                                                            :   ''
                                                        }
                                                        {certification === 'handibat'
                                                            ?   <div className='mrgR20'><LogoHandibat/></div>
                                                            :   ''
                                                        }
                                                        {certification === 'iso9001'
                                                            ?   <div className='mrgR20'><LogoIso9001/></div>
                                                            :   ''
                                                        }
                                                        {certification === 'qualibat'
                                                            ?   <div className='mrgR20'><LogoQualibat/></div>
                                                            :   ''
                                                        }
                                                        {certification === 'qualieau'
                                                            ?   <div className='mrgR20'><LogoQualieau/></div>
                                                            :   ''
                                                        }
                                                        {certification === 'qualienr'
                                                            ?   <div className='mrgR20'><LogoQualienr/></div>
                                                            :   ''
                                                        }
                                                        {certification === 'qualifelec'
                                                            ?   <div className='mrgR20'><LogoQualifelec/></div>
                                                            :   ''
                                                        }
                                                        {certification === 'qualipropre'
                                                            ?   <div className='mrgR20'><LogoQualipropre/></div>
                                                            :   ''
                                                        }
                                                        {certification === 'rge'
                                                            ?   <div className='mrgR20'><LogoRGE/></div>
                                                            :   ''
                                                        }
                                                        {certification === 'silverbat'
                                                            ?   <div className='mrgR20'><LogoSilverbat/></div>
                                                            :   ''
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                        :   ""
                                    }

                                    <a href={company.website} target="_blank" rel="noopener noreferrer" className="aReset bT">
                                        <div className="txtCenter">
                                            <p className="txt16">{company.website}</p>
                                        </div>
                                    </a>                

                                    <div className="mrgB30"></div>
                                </>

                                <div className="mrgB30">
                                    <button
                                        className="sBtn"
                                        id={company.prestaUserAccount._id}
                                        onClick={handleAddPrestaToFavClick}>
                                        <p className='txt16 noMrgTB'>Ajouter à mes favoris</p>
                                    </button>
                                    {open && prestaId === company.prestaUserAccount._id 
                                        ?   <Modal 
                                                centered
                                                size="auto"
                                                opened={opened}
                                                onClose={close}
                                                withCloseButton={false}>
                                                <p className="txtCenter noMrgTB useRoboto txt15">
                                                    Ajouté
                                                </p>
                                            </Modal> 
                                        :   ""
                                    }
                                </div>         

                                </>
                            :   <>
                                    <p className=" txt18 dGreyT txtCenter">Cette société n'est pas référencée chez Boldee. Vous pouvez lui indiquer que vous souhaitez l'ajouter en favoris.</p>

                                    <div className="horizontal spaceBtw iEnd mrgB20 mrgT20">
                                            <div className="vertical">
                                                <div><h1 className="txt25 noMrgTB">{company.name}</h1></div>                                                
                                                <div><p className="txt16 noMrgT mrgB5 bold">{company.prospectOccupation.join(' - ')}</p></div>
                                                <div className="vertical">
                                                    <div><p className="txt16 noMrgTB">{company.address.no}, {company.address.street}</p> </div>
                                                    <div><p className="txt16 noMrgT mrgB5">{company.address.postalcode} {company.address.city}</p></div>
                                                </div>
                                            </div>
                                    </div>

                                    <form id={company.prestaUserAccount._id} onSubmit={form.onSubmit(handleFormSubmit)}>
                                        <TextInput
                                            withAsterisk
                                            label="Ajouter à mes favoris :"
                                            placeholder="Email"
                                            {...form.getInputProps('email')}
                                            size='md'
                                        />
                                        <div className="mrgB20"></div>
                                                
                                        {form.isDirty() 
                                            ?   <PrestaRectangleButtonMain type="submit">
                                                    <p className='txt16 noMrgTB' id='syndicRecoNewPresta'>Envoyer</p>
                                                </PrestaRectangleButtonMain>
                                            :   <RectangleButtonDisabled>
                                                    <p className='txt16'>Envoyer</p>
                                                </RectangleButtonDisabled>
                                        }
                                        { openConfirmationModal  
                                            ?   <Modal 
                                                    centered
                                                    size="auto"
                                                    opened={openedConfirmationModal}
                                                    onClose={closeConfirmationModal}
                                                    withCloseButton={false}>
                                                    <p className="useRoboto txt16 txtCenter noMrgTB">
                                                        Recommandation envoyée
                                                    </p>
                                                </Modal> 
                                            :   ""
                                        }
                                    </form>
                                

                                </>
                        }
                    </div>
                </>
            )}
        </>              
        : 
        <Loader />
    )

}

export default PrestaPageToAddToFav;
