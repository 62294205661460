import { React, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/authContext';
import { useNavigate } from 'react-router-dom';

import { Group, TextInput, Button, MultiSelect, FileInput, Modal, Autocomplete } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useForm } from '@mantine/form';

import SyndicRectangleButton from '../../composents/Buttons/Syndic/RectangleButton/RectangleButtonMain';
import AppHdWithReturnLeft from '../../composents/Headers/AppHeaders/AppHdWithReturnLeft/AppHdWithReturnLeft';

const AddCompany = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [ openedValidationModal, { open: openValidationModal, close: closeValidationModal}] = useDisclosure(false)
    const [ openedConfirmationModal, { open: openConfirmationModal, close: closeConfirmationModal}] = useDisclosure(false)

    const [queryStreet, setQueryStreet] = useState('');
    const [optionsStreet, setOptionsStreet] = useState([]);
    const [streetSubmit, setStreetSubmit] = useState('');

    const [queryCityCie, setQueryCityCie] = useState('');
    const [optionsCityCie, setOptionsCityCie] = useState([]);
    const [cityCieSubmit, setCityCieSubmit] = useState('');

    const [ logo, setLogo ] = useState();
    const [ websiteUrl, setWebsiteUrl ] = useState();
    const [ error, setError ] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/adm/login");
            return;
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
      
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const form = useForm({
        initialValues: {
            type: 'prestataire',
            namecie: '',
            siretnb : '',
            prospectOccupation: '',
            logo: '',
            no: '',
            street: '',
            postalcode: '',
            city: '',
            country: '',
            phonecie: '',
            emailcie: ''
        },

        validate: {
            namecie : (value) => (
                value.length < 1 
                ? 'Renseignez la désignation de la société'
                : value.length > 30
                ? '30" caractères maximum'
                : null
            ),
            siretnb : (value) => (
                value && !/^[0-9]{14}$/.test(value) 
                ? 'Numéro de siret invalide' 
                : null),
            prospectOccupation : (value) => (
                value.length < 1 
                ? 'Sélectionnez'
                : null
            ),
            no : (value) => (value.length < 1 ? 'Saisir le n° de la voirie' : null),
            postalcode : (value) => (
                value && !/^[0-9]{5}$/.test(value) 
                ? 'Code postal invalide' 
                : null),
            country : (value) => (
                value.length < 1 
                ? 'Renseignez la commune de la société'
                : value.length > 35 
                ? '35 caractères max.'
                : null
            ),
            phonecie : (value) => (
                value && !/^[0-9]{10}$/.test(value) 
                ? 'Numéro de téléphone invalide' 
                : null),
            emailcie : (value) => (
                value.length < 1
                ? 'Renseignez l\'E-mail de contact de la société'
                : /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) 
                ? null 
                : 'E-mail non valide'
            )
        }
    });

    const handleStreetQueryChange = async (value) => {
        setQueryStreet(value);
    
        if (value) {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch('https://boldee-97d8d36af3bd.herokuapp.com/adm/bu/search-address', {
                    method: 'POST',
                    body: JSON.stringify({ query: value }),
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }          
                })

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                  }
                
                const data = await response.json();
                const suggestions = data.features
                    .map((feature) => ({
                        value: feature.properties.street || '',
                        label: feature.properties.street || '',
                    }))
                    .filter((item, index, self) =>
                        index === self.findIndex((t) => t.value === item.value)
                    );
                    

                setOptionsStreet(suggestions);
            } catch (error) {
            }
        }
    }

    const handleStreetQuerySubmit = (streetSelected) => {
        setStreetSubmit(streetSelected)
    };

    const handleCityCieQueryChange = async (value) => {
        setQueryCityCie(value);
    
        if (value) {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch('https://boldee-97d8d36af3bd.herokuapp.com/adm/bu/search-address', {
                    method: 'POST',
                    body: JSON.stringify({ query: value }),
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }          
                })

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                  }
                const data = await response.json();
                const suggestions = data.features
                    .map((feature) => ({
                        value: feature.properties.city || '',
                        label: feature.properties.city || '',
                    }))
                    .filter((item, index, self) =>
                        index === self.findIndex((t) => t.value === item.value)
                    );
                    
                setOptionsCityCie(suggestions);
            } catch (error) {
            }
        }
    }

    const handleCityCieQuerySubmit = (citySelected) => {
        setCityCieSubmit(citySelected)
    };

    const HandleOpenValidationModal = () => {
        openValidationModal();
    }

    const handleUrlChange = (event) => {
       const inputValue = event.currentTarget.value
       if(inputValue.startsWith('https://' || 'http://')) {
        setWebsiteUrl(inputValue)
       } else {
        setWebsiteUrl('https://' + inputValue)
       }
    } 

    const handleCompanieFormSubmit = async (values, _event) => {
        if(logo === undefined) {
            setLogo('')
        }
        _event.preventDefault();

        try {
            const token = localStorage.getItem('token');
            const response = await fetch("https://boldee-97d8d36af3bd.herokuapp.com/adm/bu/addcomp", {
                method: "POST",
                body: JSON.stringify({
                    type: 'prestataire',
                    name: values.namecie,
                    siret_nb: values.siretnb,
                    prospectOccupation: values.prospectOccupation,
                    logo: logo,
                    no: values.no,
                    street: streetSubmit.target.value,
                    postalcode: values.postalcode,
                    city: cityCieSubmit.target.value,
                    country: values.country,
                    phonecie: values.phonecie,
                    emailcie: values.emailcie,
                    website: websiteUrl
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            if(response.ok) {
                setTimeout(() => {
                    closeValidationModal()
                    openConfirmationModal();
                    window.location.reload();
                }, 1000)
            } else {
                const errorResponse = await response.json();
                if (errorResponse.error === 'Cette entreprise est déjà créée ou utilisée.') {
                    setError('Cette entreprise est déjà créée ou utilisée.');
                }  
            }
        } catch (error) {
        }
    }

    const fileBrowseHandler = (event) => {
        let reader = new FileReader()
        reader.readAsDataURL(event)
        reader.onload = () => {
          setLogo(reader.result)
        };
        reader.onerror = function (error) {
        }
    }

    const handlecloseValidationModal = () => {
        setError('');
        closeValidationModal();
    }

    return(
        <>{isMobile ? (
                <>
                    <div>
                        <AppHdWithReturnLeft><p className='terracotaT'>Ajouter une entreprise prestataire non membre</p></AppHdWithReturnLeft>
                        <p className="txt15 txtCenter">Concerne seulement les entreprises qui ne sont pas clientes</p>
                        
                        <div className='width90pCenter'>
                            <form>
                                <TextInput
                                    label="Désignation sociale de la société"
                                    placeholder="Nom de l'entreprise"
                                    withAsterisk
                                    {...form.getInputProps('namecie')}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    placeholder="N° de Siret"
                                    label="N° de Siret"
                                    withAsterisk
                                    {...form.getInputProps('siretnb')}
                                />
                                <div className="mrgB10"></div>
                                
                                <MultiSelect 
                                    label="Métier(s)"
                                    placeholder="Sélectionnez"
                                    data={[
                                        { value: 'Entreprise de travaux', label: 'Entreprise de travaux' },
                                        { value: 'Electricien', label: 'Electricien' },
                                        { value: 'Menuisier', label: 'Menuisier' },
                                        { value: 'Plombier', label: 'Plombier' },
                                        { value: 'Entreprise de nettoyage, Gardien, Concierge', label: 'Entreprise de nettoyage, Gardien, Concierge' },
                                        { value: 'Jardinier- Paysagiste', label: 'Jardinier- Paysagiste' },
                                        { value: 'Entreprise de maintenance et de dépannage', label: 'Entreprise de maintenance et de dépannage' },
                                        { value: 'Chauffagiste', label: 'Chauffagiste' },
                                        { value: 'Désinfection, Désinsectisation, Dératisation', label: 'Désinfection, Désinsectisation, Dératisation' },
                                        { value: 'Architecte, Bureaux d\'études', label: 'Architecte, Bureaux d\'études' },
                                        { value: 'Assureur', label: 'Assureur' },
                                        { value: 'Avocat spécialisé en droit immobilier', label: 'Avocat spécialisé en droit immobilier' },
                                        { value: 'Autre', label: 'Autre' }
                                    ]}
                                    withAsterisk
                                    {...form.getInputProps('prospectOccupation')}
                                />
                                <div className="mrgB10"></div>

                                    
                                <FileInput
                                    placeholder="Télécharger un logo"
                                    label="Logo"
                                    onChange={fileBrowseHandler}
                                    clearable
                                    accept="image/png,image/jpeg,image/jpg"/>
                            
                                <p className="thin txt15 mrgT20 mrgB5 terracotaT">Adresse postale</p>
                                <TextInput
                                    placeholder="N°"
                                    label="Numéro de rue"
                                    withAsterisk
                                    {...form.getInputProps('no')}
                                />
                                <div className="mrgB10"></div>

                                <Autocomplete
                                    label="Nom de rue"
                                    placeholder="Nom de rue"
                                    withAsterisk
                                        value={queryStreet}
                                        onChange={handleStreetQueryChange}
                                        onSelect={handleStreetQuerySubmit}
                                        data={optionsStreet}
                                        textFieldProps={{ required: true }}
                                    />
                                <div className="mrgB10"></div>

                                <TextInput
                                    placeholder="Code postal"
                                    label="Code postal"
                                    withAsterisk
                                    {...form.getInputProps('postalcode')}
                                />
                                <div className="mrgB10"></div>

                                <Autocomplete
                                    placeholder="Commune"
                                    label="Commune"
                                    withAsterisk
                                    value={queryCityCie}
                                    onChange={handleCityCieQueryChange}
                                    onSelect={handleCityCieQuerySubmit}
                                    data={optionsCityCie}
                                    textFieldProps={{ required: true }}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    placeholder="Pays"
                                    label="Pays"
                                    withAsterisk
                                    {...form.getInputProps('country')}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    label="N° de téléphone siège / agence"
                                    placeholder="XXXXXXXXXX"
                                    withAsterisk
                                    {...form.getInputProps('phonecie')}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    label="Email général siège / agence"
                                    placeholder="Email"
                                    withAsterisk
                                    {...form.getInputProps('emailcie')}
                                />
                                <div className="mrgB10"></div>

                                <TextInput
                                    label="Site Web"
                                    placeholder="http://www.societe.com"
                                    value={websiteUrl}
                                    onChange={handleUrlChange}
                                />
                                <div className="mrgB30"></div>

                                <SyndicRectangleButton onClick={HandleOpenValidationModal}>
                                    Enregistrer
                                </SyndicRectangleButton>
                                {openValidationModal  
                                    ?   <Modal 
                                            centered
                                            withCloseButton={false}    
                                            opened={openedValidationModal} 
                                            onClose={closeValidationModal}
                                            size="60%">
                                                <Group noWrap mt="md" className="useRoboto txtCenter mrgT5 txt15">
                                                    Valider la création de la société nom membre
                                                </Group>
                                                {error && <p className='txt13 rT'>Erreur : {error}</p>}
                                                <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20 w80">
                                                <Button variant="outline" color="dark" onClick={handlecloseValidationModal} className='useRoboto'>
                                                    Annuler
                                                </Button>
                                                <Button variant="filled" color="dark"  onClick={form.onSubmit(handleCompanieFormSubmit)} className='useRoboto medium'>
                                                    Valider
                                                </Button>
                                            </Group>       
                                        </Modal> 
                                    :   ""
                                }
                                {openConfirmationModal  
                                    ?   <Modal 
                                            centered
                                            size="55%"
                                            
                                            opened={openedConfirmationModal}
                                            onClose={closeConfirmationModal}
                                            withCloseButton={false}>
                                            <p className="useRoboto txt15 txtCenter noMrgTB">
                                                Compagnie enregistrée avec succès
                                            </p>
                                        </Modal> 
                                    :   ""
                                }        
                                <div className="mrgB30"></div>
                            </form>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div>
                        <AppHdWithReturnLeft><p className='terracotaT'>Ajouter une entreprise prestataire non membre</p></AppHdWithReturnLeft>
                        <p className="txt18 txtCenter">Concerne seulement les entreprises qui ne sont pas clientes</p>
                        
                        <div className='width50pCenter'>
                            <form>
                                <TextInput
                                    label="Désignation sociale de la société"
                                    placeholder="Nom de l'entreprise"
                                    size="md"
                                    withAsterisk
                                    {...form.getInputProps('namecie')}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    placeholder="N° de Siret"
                                    label="N° de Siret"
                                    size="md"
                                    withAsterisk
                                    {...form.getInputProps('siretnb')}
                                />
                                <div className="mrgB20"></div>
                                
                                <MultiSelect 
                                    label="Métier(s)"
                                    placeholder="Sélectionnez"
                                    size="md"
                                    data={[
                                        { value: 'Entreprise de travaux', label: 'Entreprise de travaux' },
                                        { value: 'Electricien', label: 'Electricien' },
                                        { value: 'Menuisier', label: 'Menuisier' },
                                        { value: 'Plombier', label: 'Plombier' },
                                        { value: 'Entreprise de nettoyage, Gardien, Concierge', label: 'Entreprise de nettoyage, Gardien, Concierge' },
                                        { value: 'Jardinier- Paysagiste', label: 'Jardinier- Paysagiste' },
                                        { value: 'Entreprise de maintenance et de dépannage', label: 'Entreprise de maintenance et de dépannage' },
                                        { value: 'Chauffagiste', label: 'Chauffagiste' },
                                        { value: 'Désinfection, Désinsectisation, Dératisation', label: 'Désinfection, Désinsectisation, Dératisation' },
                                        { value: 'Architecte, Bureaux d\'études', label: 'Architecte, Bureaux d\'études' },
                                        { value: 'Assureur', label: 'Assureur' },
                                        { value: 'Avocat spécialisé en droit immobilier', label: 'Avocat spécialisé en droit immobilier' },
                                        { value: 'Autre', label: 'Autre' }
                                    ]}
                                    withAsterisk
                                    {...form.getInputProps('prospectOccupation')}
                                />
                                <div className="mrgB20"></div>

                                    
                                <FileInput
                                    placeholder="Télécharger un logo"
                                    label="Logo"
                                    onChange={fileBrowseHandler}
                                    size="md"
                                    clearable
                                    accept="image/png,image/jpeg,image/jpg"/>
                            
                                <p className="thin txt18 mrgT20 mrgB5 terracotaT">Adresse postale</p>
                                <TextInput
                                    placeholder="N°"
                                    label="Numéro de rue"
                                    size="md"
                                    withAsterisk
                                    {...form.getInputProps('no')}
                                />
                                <div className="mrgB20"></div>

                                <Autocomplete
                                    label="Nom de rue"
                                    placeholder="Nom de rue"
                                    size="md"
                                    withAsterisk
                                        value={queryStreet}
                                        onChange={handleStreetQueryChange}
                                        onSelect={handleStreetQuerySubmit}
                                        data={optionsStreet}
                                        textFieldProps={{ required: true }}
                                    />
                                <div className="mrgB20"></div>

                                <TextInput
                                    placeholder="Code postal"
                                    label="Code postal"
                                    size="md"
                                    withAsterisk
                                    {...form.getInputProps('postalcode')}
                                />
                                <div className="mrgB20"></div>

                                <Autocomplete
                                    placeholder="Commune"
                                    label="Commune"
                                    size="md"
                                    withAsterisk
                                    value={queryCityCie}
                                    onChange={handleCityCieQueryChange}
                                    onSelect={handleCityCieQuerySubmit}
                                    data={optionsCityCie}
                                    textFieldProps={{ required: true }}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    placeholder="Pays"
                                    label="Pays"
                                    size="md"
                                    withAsterisk
                                    {...form.getInputProps('country')}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    label="N° de téléphone siège / agence"
                                    placeholder="XXXXXXXXXX"
                                    size="md"
                                    withAsterisk
                                    {...form.getInputProps('phonecie')}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    label="Email général siège / agence"
                                    placeholder="Email"
                                    size="md"
                                    withAsterisk
                                    {...form.getInputProps('emailcie')}
                                />
                                <div className="mrgB20"></div>

                                <TextInput
                                    label="Site Web"
                                    placeholder="http://www.societe.com"
                                    size="md"
                                    value={websiteUrl}
                                    onChange={handleUrlChange}
                                />
                                <div className="mrgB30"></div>

                                <SyndicRectangleButton onClick={HandleOpenValidationModal}>
                                    Enregistrer
                                </SyndicRectangleButton>
                                {openValidationModal  
                                    ?   <Modal 
                                            centered
                                            withCloseButton={false}    
                                            opened={openedValidationModal} 
                                            onClose={closeValidationModal}
                                            size="60%">
                                                <Group noWrap mt="md" className="useRoboto txtCenter mrgT5 txt15">
                                                    Valider la création de la société nom membre
                                                </Group>
                                                {error && <p className='txt13 rT'>Erreur : {error}</p>}
                                                <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20 w80">
                                                <Button variant="outline" color="dark" onClick={handlecloseValidationModal} className='useRoboto'>
                                                    Annuler
                                                </Button>
                                                <Button variant="filled" color="dark"  onClick={form.onSubmit(handleCompanieFormSubmit)} className='useRoboto medium'>
                                                    Valider
                                                </Button>
                                            </Group>       
                                        </Modal> 
                                    :   ""
                                }
                                {openConfirmationModal  
                                    ?   <Modal 
                                            centered
                                            size="55%"
                                            
                                            opened={openedConfirmationModal}
                                            onClose={closeConfirmationModal}
                                            withCloseButton={false}>
                                            <p className="useRoboto txt15 txtCenter noMrgTB">
                                                Compagnie enregistrée avec succès
                                            </p>
                                        </Modal> 
                                    :   ""
                                }        
                                <div className="mrgB30"></div>
                            </form>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
export default AddCompany;