import React from "react";
import ecocertLogo from '../../../../assets/images/ecocert.jpg'
import classes from './LogoEcocert.module.css';

const LogoEcocert = (props) => {
    return(
        <div className={classes.Logo}>
            <img src={ecocertLogo} alt="logo"/>
        </div>
    )
}
export default LogoEcocert;