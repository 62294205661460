import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IconZoomInFilled  } from '@tabler/icons-react';
import classes from './SavedOffersHd.module.css';


const SavedOffersHd = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [clicked, setClick] = useState(false);
    const [urgentActive, setUrgentActive] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };      
          window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleClick = () => {
        setClick(!clicked);
        setUrgentActive(!urgentActive);
        props.setUrgentList(true)        
        if(clicked) {
            props.setUrgentList(false)
        }
    }
    return(
        <>
            {isMobile ? (
                <>
                <div className={classes.appHeading}>
                    <div className={classes.navback}>
                        <button
                            className={classes.btnReset}
                            clicked={clicked}
                            style={{ color: urgentActive ? "red" : "black",
                                    fontWeight: urgentActive ? 'bold' : ""}}
                            onClick={handleClick}>
                            Urgent
                        </button>
                    </div>
                    <div className={classes.navtitle}>
                        <h1>Mes offres</h1>
                    </div>
                    <div className={classes.navrightside}>
                        <button className={classes.btnReset}>
                            <Link to="/p/search" className="aReset bT">
                                <IconZoomInFilled id="searchOfferFromSavedOffersga4" className={classes.blackIcon}/>
                            </Link>
                        </button>
                    </div>
                </div>
                </>
            ) :(
                <>
                <div className={classes.appHeading}>
                    <div className={classes.navback}>
                        <button
                            className={classes.btnReset}
                            clicked={clicked}
                            style={{ color: urgentActive ? "red" : "black",
                                    fontWeight: urgentActive ? 'bold' : ""}}
                            onClick={handleClick}>
                                <p className='txt16 mediumplus'>Urgent</p>
                        </button>
                    </div>
                    <div className={classes.navtitle}>
                        <h1>Mes offres</h1>
                    </div>
                    <div className={classes.navrightside}>
                        <button className={classes.btnReset}>
                            <Link to="/p/search" className="aReset bT">
                                <IconZoomInFilled id="searchOfferFromSavedOffersga4" className={classes.blackIcon}/>
                            </Link>
                        </button>
                    </div>
                </div>
                </>
            )}
        </>
    )
}

export default SavedOffersHd;