import { React, useState, useEffect }from 'react';
import { useNavigate } from 'react-router-dom';
import { TextInput, Modal, Group, Button } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';

import Navbar from '../../Navbar/NavbarPublic';
import Footer from '../../Footer/Footer';
import SyndicRectangleButton from '../../../composents/Buttons/Syndic/RectangleButton/RectangleButtonMain';
import PrestaRectangleButtonSndBlack from '../../../composents/Buttons/Presta/RectangleButtonSndBlack/RectangleButtonSndBlack';
import RectangleButtonDisabled from '../../../composents/Buttons/All/RectangleButtonDisabled/RectangleButtonDisabled';
import LogoLogin from '../../../composents/Logo/LoginLogo/LogoLogin';

const SyndicForgotPassword = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const navigate = useNavigate();
    
    const [ openedValidationModal, { open: openValidationModal, close: closeValidationModal}] = useDisclosure(false);
    const [ openedConfirmationModal, { open: openConfirmationModal, close: closeConfirmationModal}] = useDisclosure(false);
    const [ error, setError ] = useState(null);
  
    useEffect(()=>{
        window.scrollTo(0,0);
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
      
          window.addEventListener('resize', handleResize);
      
          return () => {
            window.removeEventListener('resize', handleResize);
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

    const form = useForm({
        initialValues: {
            email: '',
        },

        validate: {
            email : (value) => (
                value.length < 1
                ? 'Renseignez votre E-mail'
                : /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) 
                ? null 
                : 'E-mail non valide'
            ),   
        }
    })

    const handleOpenModal = () => {
        openValidationModal()
    }

    
    const handleSendEmail = async (values, _event) => {
        _event.preventDefault();
        try {
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/forgot-password`, {
                method: "POST",
                body: JSON.stringify({
                    email: values.email,
                }),
                headers: {
                    "Content-Type": "application/json",
                }
            });
            if(response.ok) {
                closeValidationModal()
                openConfirmationModal()
                setTimeout(() => {
                    navigate('/s/login');
                }, 1000)
            } else {
                const errorResponse = await response.json();
                if (errorResponse.error === 'Email non valide.') {
                    setError('Email non valide.');
                }  
            }
        } catch (error) {
        }
    }

    const goBack = () => {
        navigate(-1)
    }

    const handlecloseValidationModal = () => {
        setError('');
        closeValidationModal();
    }

    return(
        <>
            <Navbar/>
            {isMobile ? (
                <>
                    <div className="width90pCenter mrgT100 txtCenter">
                        <div className="mrgT150" />
                        <LogoLogin/>
                        <p id="signUpTitle">Mot de passe oublié</p>
                    </div>
                    
                    <div className="width90pCenter mrgT20 w300p">

                        <form>
                            <TextInput
                                withAsterisk
                                label="Saisir votre Email"
                                placeholder="Email"
                                {...form.getInputProps('email')}
                            />
                            <div className="mrgB30"></div>

                            {form.isDirty() 
                                ?   <SyndicRectangleButton onClick={handleOpenModal}>
                                        Générer un nouveau mot de passe
                                    </SyndicRectangleButton>
                                :   <RectangleButtonDisabled>
                                        Générer un nouveau mot de passe
                                    </RectangleButtonDisabled>
                            }
                            { openValidationModal 
                                ?    <Modal 
                                        centered
                                        withCloseButton={false}    
                                        opened={openedValidationModal } 
                                        onClose={closeValidationModal }
                                        size="auto">
                                            <Group noWrap mt="md" className="txtCenter txt15 mrgT5 useRoboto">
                                                Générer un nouveau mot de passe ?
                                            </Group>
                                            {error && <p className='txt13 rT'>Erreur : {error}</p>}

                                            <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20 w80 useRoboto txt15">
                                            <Button variant="outline" color="dark" onClick={handlecloseValidationModal}>
                                                Annuler
                                            </Button>
                                            <Button variant="filled" color="dark" type="submit" onClick={form.onSubmit(handleSendEmail)} >
                                                Générer
                                            </Button>
                                        </Group>       
                                    </Modal>
                                :   ""
                            }

                            { openConfirmationModal  
                                ?   <Modal 
                                        centered
                                        size="55%"
                                        opened={openedConfirmationModal}
                                        onClose={closeConfirmationModal}
                                        withCloseButton={false}>
                                        <p className="useRoboto txt15 txtCenter noMrgTB">
                                            Un e-mail vous a été envoyé vous a été envoyé
                                        </p>
                                    </Modal> 
                                :   ""
                            }
                            
                            <div className="mrgB10"></div>

                            <PrestaRectangleButtonSndBlack onClick={goBack}>
                                Annuler
                            </PrestaRectangleButtonSndBlack>
                        </form>
                        
                    </div>
                    <div className="mrgB150"></div>
                </>
            ) : (
                <>
                    <div className="width90pCenter mrgT100 txtCenter">
                        <div className="mrgT200" />
                        <p id="signUpTitle">Mot de passe oublié</p>
                        <p className="txt30 bold mrgT5">Accès Syndic</p>
                    </div>
                    
                    <div className="width30pCenter mrgT20">

                        <form>
                            <TextInput
                                withAsterisk
                                label="Saisir votre Email"
                                placeholder="Email"
                                size='lg'
                                {...form.getInputProps('email')}
                            />
                            <div className="mrgB30"></div>

                            {form.isDirty() 
                                ?   <SyndicRectangleButton onClick={handleOpenModal}>
                                        Générer un nouveau mot de passe
                                    </SyndicRectangleButton>
                                :   <RectangleButtonDisabled>
                                        Générer un nouveau mot de passe
                                    </RectangleButtonDisabled>
                            }
                            { openValidationModal 
                                ?    <Modal 
                                        centered
                                        withCloseButton={false}    
                                        opened={openedValidationModal} 
                                        onClose={closeValidationModal}
                                        size="auto">
                                            <Group noWrap mt="md" className="txtCenter txt20 mrgT20 mrgR10 mrgL10 useRoboto">
                                                Générer un nouveau mot de passe ?
                                            </Group>
                                            <p className='txtCenter'>{error && <p className='txt16 rT'>Erreur : {error}</p>}</p>
                                            <Group mt="xl" className="txtCenter horizontal jCenter mrgT30 mrgB10">
                                                <Button variant="outline" size="md" color="dark" onClick={handlecloseValidationModal} className='useRoboto txt18 medium'>
                                                    <p className='txt16'>Annuler</p>
                                                </Button>
                                                <Button variant="filled" size="md" color="dark" type="submit" onClick={form.onSubmit(handleSendEmail)} className='useRoboto txt18 medium'>
                                                    <p className='txt16'>Générer</p>
                                                </Button>
                                        </Group>       
                                    </Modal>
                                :   ""
                            }

                            { openConfirmationModal  
                                ?   <Modal 
                                        centered
                                        size="auto"
                                        opened={openedConfirmationModal}
                                        onClose={closeConfirmationModal}
                                        withCloseButton={false}>
                                        <p className="useRoboto txt20 txtCenter noMrgTB">
                                            Un e-mail vous a été envoyé
                                        </p>
                                    </Modal> 
                                :   ""
                            }
                            
                            <div className="mrgB10"></div>

                            <PrestaRectangleButtonSndBlack onClick={goBack}>
                                Annuler
                            </PrestaRectangleButtonSndBlack>
                        </form>
                        
                    </div>
                    <div className="mrgB150"></div>

                </>
            )}            
            <Footer/>

        </>
    )
}

export default SyndicForgotPassword;