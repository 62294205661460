import { React, useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../../../../../contexts/authContext';
import { Link, useParams, useNavigate } from "react-router-dom";

import { useDisclosure } from '@mantine/hooks';
import { Accordion, Loader, Modal, Group, Button, Badge } from '@mantine/core';
import { IconPhone } from '@tabler/icons-react';
import { format, parseISO } from 'date-fns';

const ReceivedQuotationList = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [listPendQuotation, setlistPendQuotation] = useState([]);
    const [listAllQuotation, setlistAllQuotation] = useState([]);
    const [refusedQuotId, setRefusedQuotId] = useState();
    const [acceptedQuotId, setAcceptedQuotId] = useState();
    const [acceptedQuotation, setAcceptedQuotation] = useState();
    const [refuseModal, setRefuseModal] = useState(true);
    const [opened, { close, open }] = useDisclosure(false);
    const [openedContactInfo, { open : openContactInfo, close : closeContactInfo}] = useDisclosure(false);

    let {id} = useParams()

    const navigate = useNavigate();

    useEffect(() =>  {
        const token = getToken();
        if (!token) {
            navigate("/s/login");
            return;
        }
        getFetchData();

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
            };
            window.addEventListener('resize', handleResize);
            return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
   

    async function getFetchData() {
        try{
            const [pendQuotResult, allQuotResult ] = await Promise.all([getPendQuotations(), getAllQuotations()]);
            setlistPendQuotation(await pendQuotResult);
            setlistAllQuotation(await allQuotResult);
            setAcceptedQuotation(allQuotResult.find(quotation => quotation.status === "acceptedBySyndic"))
        } catch (error) {
        }
    }

    async function getAllQuotations() { 
        try{
            const token = localStorage.getItem('token');
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/offers/offer/quo/${id}`, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`
                }
              });
              return response.json();
            } catch (error) { 
                console.error(error);
                return null;
        }
    }
   
    async function getPendQuotations() { 
        try{
            const token = localStorage.getItem('token');
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/offers/offer/quo-pend/${id}`, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`
                }
              });

              return response.json();
              
            } catch (error) { 
                console.error(error);
                return null;
        }
    }

    const handleOpenRefuseModal = (event) => {
        open()
        setRefusedQuotId(event.currentTarget.id)
        setRefuseModal(true)        
    }
    
    const handleRefuseClick = async (event) => {
        const quotRefusedId = event.currentTarget.id;
        setRefusedQuotId (quotRefusedId) ;

        try {
            const token = localStorage.getItem('token');
            await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/offers/refuse-quo/${quotRefusedId}`, {
                method: "POST",
                body: JSON.stringify({
                    id: id,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            await getFetchData()
        } catch (error) {
        }
    }

    const handleOpenAcceptModal = (event) => {
        open()
        setAcceptedQuotId(event.currentTarget.id)
        setRefuseModal(false) 
    }

    const handleAcceptClick = async (e) => {
        const quotAcceptedId = e.currentTarget.id;
        setAcceptedQuotId(quotAcceptedId);
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/offers/accept-quo/${quotAcceptedId}`, {
                method: "POST",
                body: JSON.stringify({
                    id: id,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            const acceptedQuotation = await response.json();
            setAcceptedQuotation(acceptedQuotation)
            await getFetchData()
        } catch (error) {
        }
    }

    const handleViewed = async(event) => {
        const quotId = event.currentTarget.id;
        try {
            const token = localStorage.getItem('token');
            await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/offers/quot-view/${quotId}`, {
                method: "POST",
                body: JSON.stringify({
                    id: id,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
          
        } catch (error) {
        }
    }

    const openPDF = (event) => {
        let win = window.open()
        win.document.write('<iframe src="' + event.currentTarget.id  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    }

    const handleCall = (event) => {
        const phoneNumber = event.currentTarget.id
        window.location.href = `tel:${phoneNumber}`
    }

    const showContact = () => {
        openContactInfo()
    }

    return(
        listAllQuotation ?
            <>  
                {acceptedQuotation
                    ?   <>
                        {isMobile ? (
                            <div className='width95pCenter'>
                                <>
                                    <p className="txt15 bold mrgT5 txtCenter greenT">Devis accepté le {format(parseISO(acceptedQuotation.acceptDate), 'dd/MM/yy')}</p>  
                                    <div className ="bLiteGrey">
                                        <Link to={`/s/offers/offer/company-page/${acceptedQuotation.author.company._id}`} className="aReset bT">
                                            <div className="horizontal iCenter mrgL20 ">
                                                <div className='mrgT20'>
                                                    {acceptedQuotation.author.company.logo
                                                        ?   <img   
                                                                className='roundImg70'
                                                                src={acceptedQuotation.author.company.logo}
                                                                alt='logo'/>
                                                        :   <div className="noAvatarProfile">{acceptedQuotation.author.company.name.charAt(0)}</div>
                                                    }
                                                </div>
                                                <div className='vertical mrgL20'>
                                                    <p className="txt15 noMrgB bold mrgT8">{acceptedQuotation.author.company.name}</p>
                                                    <p className="txt13 noMrgTB finalDotsMinus">{acceptedQuotation.author.occupation.join(' - ')}</p>
                                                </div>
                                                                                
                                            </div>
                                        </Link>
                                            
                                        <div className="mrgB10"></div>
                    
                                        <div className="horizontal jCenter iCenter mrgR10 w80 ">
                                            <button id={acceptedQuotation.downloadLink} onClick={openPDF} className="btn-accepted-rdv bGreen mrgR10 mrgB20" >
                                                Devis
                                            </button>
                                            <button className="btn-accepted-rdv bBlack mrgB20" id={acceptedQuotation.author.phone} onClick={handleCall}>
                                                Contacter
                                            </button>
                                        </div>
                                    </div>
                                </>
                                    
                                    
                                <div className="mrgB20"></div>
                                    <Accordion variant="default" defaultValue="">
                                        <Accordion.Item value="deletedQuot">
                                            <Accordion.Control>
                                                <p className="roboto noMrgTB bold">Devis supprimés</p>
                                            </Accordion.Control>
                                            <div className='mrgB10'></div>
                                            {listAllQuotation.length > 0 && listAllQuotation.some(quotation => quotation.status === 'refusedBySyndic')
                                                ?   <>
                                                        <Accordion.Panel> 
                                                            {listAllQuotation.map((quotation, index) => (
                                                                <div key = {index} style = {{margin : '5px'}}>
                                                                    {quotation.status === 'refusedBySyndic' 
                                                                        ?   <div className="bLiteGrey horizontal iCenter spaceBtw">
                                                                                <div><p className="txt13 mrgT5 mrgB5 mrgL10">{quotation.author.company.name}</p></div>
                                                                                <div><p className="txt13 mrgT5 mrgB5 mrgR10">Refusé le {format(parseISO(quotation.sendDate), 'dd/MM/yy')}</p></div>
                                                                                
                                                                            </div>
                                                                        :   ""
                                                                    }
                                                                </div>
                                                            ))}
                                                        </Accordion.Panel>  
                                                    </>
                                                :   <p className='txt13 dGreyT mrgL20 mrgT5'>Vous n'avez pas refusé de devis</p>
            
                                            }
                                        </Accordion.Item>
                                    </Accordion>   
                                </div>
                        ) : (
                            <div className='width50pCenter'>
                                <p className="txt20 bold mrgT50 txtCenter greenT">Devis accepté le {format(parseISO(acceptedQuotation.acceptDate), 'dd/MM/yy')}</p>  
                                <div className ="bLiteGrey">
                                        <div className="horizontal iCenter mrgL20 ">
                                            <div className='mrgT20 mrgB20'>
                                                {acceptedQuotation.author.company.logo
                                                    ?   <img   
                                                            className='roundImg100'
                                                            src={acceptedQuotation.author.company.logo}
                                                            alt='logo'/>
                                                    :   <div className="noAvatarProfile">{acceptedQuotation.author.company.name.charAt(0)}</div>
                                                }
                                            </div>
                                            <div className='vertical mrgL20 mrgT20 mrgB20'>
                                                <Link to={`/s/offers/offer/company-page/${acceptedQuotation.author.company._id}`} className="aReset bT">
                                                    <p className="txt18 useRoboto mrgB5 bold mrgT20">{acceptedQuotation.author.company.name}</p>
                                                    <p className="txt16 useRoboto noMrgTB mrgR10">{acceptedQuotation.author.occupation.join(' - ')}</p>
                                                </Link>
                                                
                                                <div className="mrgB20"></div>
                            
                                                <div className="horizontal mrgR10">
                                                    <button id={acceptedQuotation.downloadLink} onClick={openPDF} className="btn-accepted-rdv bGreen mrgR10 mrgB20" >
                                                        <p className='txt16 noMrgTB'>Devis</p>
                                                    </button>
                                                    <button className="btn-accepted-rdv bBlack mrgB20" id={acceptedQuotation.author.phone} onClick={showContact}>
                                                        <p className='txt16 noMrgTB'>Contacter</p>
                                                    </button>
                                                </div>
                                            </div>
                                            { openContactInfo 
                                                    ?   <Modal 
                                                            centered
                                                            size="auto"
                                                            opened={openedContactInfo}
                                                            onClose={closeContactInfo}
                                                            title="Contacter">
                                                            <div className='mrgR40 mrgL40'>
                                                                <p className="useRoboto txtCenter bold txt18 noMrgT mrgB10">
                                                                    {acceptedQuotation.author.company.name} 
                                                                </p>
                                                                <p className="horizontal txtCenter iCenter useRoboto txt16 noMrgT mrgB10">
                                                                    <div className='noMrgTB mrgR5'><IconPhone width={20} height={20}/></div>
                                                                    <p className='noMrgT mrgB5 txt18'>{acceptedQuotation.author.company.address.phonecie}</p>
                                                                </p>
                                                            </div>
                                                        </Modal> 
                                                    :   ""
                                                }                   
                                        </div>
                                    
                                </div>
                            
                                <div className="mrgB20"></div>
                                <Accordion variant="default" defaultValue="">
                                    <Accordion.Item value="deletedQuot">
                                        <Accordion.Control>
                                            <p className="useRoboto noMrgTB bold txt18">Devis supprimés</p>
                                        </Accordion.Control>
                                        <div className='mrgB10'></div>
                                        {listAllQuotation.length > 0 && listAllQuotation.some(quotation => quotation.status === 'refusedBySyndic')
                                            ?   <>
                                                    <Accordion.Panel> 
                                                        {listAllQuotation.map((quotation, index) => (
                                                            <div key = {index} style = {{margin : '10px'}}>
                                                                {quotation.status === 'refusedBySyndic' 
                                                                    ?   <div className="bLiteGrey horizontal iCenter spaceBtw">
                                                                            <div><p className="txt16 useRoboto mrgT10 mrgB10 mrgL20 medium">{quotation.author.company.name}</p></div>
                                                                            <div><p className="txt16 useRoboto mrgT10 mrgB10 mrgR20">Refusé le {format(parseISO(quotation.sendDate), 'dd/MM/yy')}</p></div>
                                                                        </div>
                                                                    :   ""
                                                                }
                                                            </div>
                                                        ))}
                                                    </Accordion.Panel>  
                                                </>
                                            :   <p className='txt16 dGreyT mrgL20 mrgT5'>Vous n'avez pas refusé de devis</p>
        
                                        }
                                    </Accordion.Item>
                                </Accordion>   
                            </div>
                        )}
                        </>
                    :   
                        <>
                        {isMobile ? (
                            <div className="width95pCenter">
                                <Accordion variant="default" defaultValue="quotation">
                                    <Accordion.Item value="quotation">
                                        <Accordion.Control>
                                            <p className="useRoboto noMrgTB mrgB10 bold">Devis reçus</p>
                                        </Accordion.Control>
                                        <div className="mrgB10"></div>
                                        {listPendQuotation.length > 0
                                            ?   <>
                                                    <Accordion.Panel>
                                                        {listPendQuotation.map((quotation, index) => (
                                                            <div key = {index} style = {{margin : '10px'}}>
                                                                {quotation.status === 'pending' 
                                                                        ?   <>
                                                                                <div className ="bLiteGrey">
                                                                                    <div className="vertical mrgL10">
                                                                                        <div className='horizontal iCenter'>
                                                                                            <p className="txt13 mrgT10 mrgL15 mrgB5 roboto">{format(parseISO(quotation.sendDate), 'dd/MM/yy')}</p>
                                                                                            {quotation.viewedBySyndic 
                                                                                                ?   ""
                                                                                                :   <Badge color="green" className='mrgT5 mrgL5'>NEW</Badge>
                                                                                                    
                                                                                            }
                                                                                        </div>
                                                                                        <div className="horizontal iCenter mrgL10">
                                                                                            <div>
                                                                                                {quotation.author.company.logo
                                                                                                    ?   <img 
                                                                                                            className='roundImg70'
                                                                                                            src={quotation.author.company.logo}
                                                                                                            alt='logo'/>
                                                                                                    :   <div className="noAvatarProfile">{quotation.author.company.name.charAt(0)}</div>
                                                                                                }
                                                                                            </div>
                                                                                            <div className="vertical jCenter mrgL10">
                                                                                                <Link to={`/s/offers/offer/company-page/${quotation.author.company._id}`} className="aReset bT">
                                                                                                    <div className=" horizontal">
                                                                                                        <h1 className="txt15 noMrgTB roboto">{quotation.author.company.name}</h1>
                                                                                                    </div>
                                                                                                </Link>
                                                                                                <p className="txt13 noMrgTB roboto finalDots">{quotation.author.occupation.join(' - ')}</p>
                                                                                                <div className='horizontal'>
                                                                                                    <p className='txt13 bold mrgR5 noMrgTB roboto'>{(quotation.author.addedToFavBy).length}</p>
                                                                                                    <p className='txt13 noMrgTB roboto'>ajouté en favoris</p>
                                                                                                </div>
                                                                                            </div>                         
                                                                                        </div>
                                                                                    </div>
                                                                                    
                                                                                    <div className="mrgB10"></div>

                                                                                    <div className="horizontal jCenter iCenter">
                                                                                        <button
                                                                                            className="btn-action-for-rdv bDarkGrey mrgR10 mrgB10"
                                                                                            id={quotation._id}
                                                                                            onClick={handleOpenRefuseModal}
                                                                                            >
                                                                                            Refuser
                                                                                        </button>

                                                                                        { open && refusedQuotId === quotation._id && refuseModal 
                                                                                            ?    <Modal 
                                                                                                    centered
                                                                                                    withCloseButton={false}    
                                                                                                    opened={opened} 
                                                                                                    onClose={close}
                                                                                                    size="60%">
                                                                                                    <Group noWrap mt="md" className="useRoboto mrgT5 vertical jCenter lineH10">
                                                                                                        <p className='noMrgTB txt15'>Refuser le devis de </p>
                                                                                                        <p className='noMrgTB txt15 medium'>{quotation.author.company.name} ?</p>
                                                                                                    </Group>

                                                                                                    <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20">
                                                                                                        <Button variant="outline" color="dark" onClick={close} className='useRoboto'>
                                                                                                            Annuler
                                                                                                        </Button>
                                                                                                        <Button variant="filled" color="dark" id={quotation._id} onClick={handleRefuseClick} className='useRoboto medium'>
                                                                                                            Refuser
                                                                                                        </Button>
                                                                                                    </Group>       
                                                                                                </Modal>
                                                                                            :   ""
                                                                                        }
                                                                                            
                                                                                        <div className="mrgB5"></div>

                                                                                        <button 
                                                                                            className="btn-action-for-rdv bGreen mrgR10 mrgB10"
                                                                                            id={quotation._id}
                                                                                            onClick={handleOpenAcceptModal}
                                                                                            >
                                                                                            Accepter
                                                                                        </button>
                                                                                            { open && acceptedQuotId === quotation._id && !refuseModal 
                                                                                                ?   <Modal  
                                                                                                        centered
                                                                                                        withCloseButton={false}    
                                                                                                        opened={opened} 
                                                                                                        onClose={close}
                                                                                                        size="auto">
                                                                                                        <Group noWrap mt="md" className=" useRoboto vertical jCenter mrgT5 lineH10">
                                                                                                            <p className='noMrgTB txt15 lineH10'>Accepter le devis de</p> 
                                                                                                            <p className='noMrgTB txt15 medium'>{quotation.author.company.name} ?</p>
                                                                                                            <p className='noMrgTB txt13 mrgR5 mrgL5'>Tous les autres devis seront refusés.</p>
                                                                                                        </Group>

                                                                                                        <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20 w70">
                                                                                                            <Button variant="outline" color="dark" onClick={close} className='useRoboto'>
                                                                                                                Annuler
                                                                                                            </Button>
                                                                                                            <Button variant="filled" color="dark" id={quotation._id} onClick={handleAcceptClick} className='useRoboto medium'>
                                                                                                                Accepter
                                                                                                            </Button>
                                                                                                        </Group>       
                                                                                                    </Modal>
                                                                                                :   ""
                                                                                            }
                                                                                                                        
                                                                                            
                                                                                                <button className="btn-action-for-rdv bBlack mrgB10" onClick={handleViewed} id={quotation._id}>
                                                                                                    <div id={quotation.downloadLink} onClick={openPDF} className='aReset wT'>
                                                                                                        Voir devis
                                                                                                    </div>
                                                                                                </button>
                                                                                            
                                                                                    </div>
                                                                                </div> 
                                                                            </> 
                                                                        :   <></>
                                                                }
                                                            </div>
                                                        ))}
                                                    
                                                    </Accordion.Panel>
                                                </>
                                                :   <p className='txt13 dGreyT mrgL20 mrgT5'>Vous n'avez pas reçu de devis</p>
                                        }    
                                    </Accordion.Item>
                                </Accordion>
        
                                <Accordion variant="default" defaultValue="">
                                    <Accordion.Item value="deletedQuot">
                                        <Accordion.Control>
                                            <p className="useRoboto mrgB10 bold">Devis supprimés</p>
                                        </Accordion.Control>
                                        <div className="mrgB10"></div>
                                        {listAllQuotation.length > 0 && listAllQuotation.some(quotation => quotation.status === 'refusedBySyndic')
                                            ?   <>
                                                    <Accordion.Panel> 
                                                        {listAllQuotation.map((quotation, index) => (
                                                            <div key = {index} style = {{margin : '5px'}}>
                                                                {quotation.status === 'refusedBySyndic' 
                                                                    ?   
                                                                        <div className="bLiteGrey horizontal iCenter spaceBtw"> 
                                                                            <div><p className="txt13 mrgT5 mrgB5 mrgL10">{quotation.author.company.name}</p></div>
                                                                            <div><p className="txt13 mrgT5 mrgB5 mrgR10">Refusé le {format(parseISO(quotation.sendDate), 'dd/MM/yy')}</p></div>
                                                                        </div>
                                                                    :   ""
                                                                }
                                                            </div>
                                                        ))}
                                                    </Accordion.Panel>  
                                                </>
                                            :   <p className='txt13 dGreyT mrgL20 mrgT5'>Vous n'avez pas refusé de devis</p>
                                        }
                                        

                                    </Accordion.Item>
                                </Accordion>
                                <div id='mrgB50'></div>   
                            </div>
                        ) : (
                            <div className="width50pCenter">
                                <Accordion variant="default" defaultValue="quotation">
                                    <Accordion.Item value="quotation">
                                        <Accordion.Control>
                                            <p className="useRoboto mrgB20 bold txt18">Devis reçus</p>
                                        </Accordion.Control>
                                        <div className="mrgB10"></div>
                                        {listPendQuotation.length > 0
                                            ?   <>
                                                    <Accordion.Panel>
                                                        {listPendQuotation.map((quotation, index) => (
                                                            <div key = {index} style = {{margin : '10px'}}>
                                                                {quotation.status === 'pending' 
                                                                        ?   <>
                                                                                <div className ="bLiteGrey">
                                                                                    <div className="vertical mrgL10">
                                                                                        <div className="horizontal iCenter mrgT20 mrgL10 mrgB10">
                                                                                            <div className='vertical spaceBtw mrgL10'>
                                                                                                <p className="useRoboto txt16 mrgL20">
                                                                                                    {format(parseISO(quotation.sendDate), 'dd/MM/yy')}
                                                                                                </p>
                                                                                                {quotation.author.company.logo
                                                                                                    ?   <img 
                                                                                                            className='roundImg100'
                                                                                                            src={quotation.author.company.logo}
                                                                                                            alt='logo'/>
                                                                                                    :   <div className="noAvatarProfile">{quotation.author.company.name.charAt(0)}</div>
                                                                                                }
                                                                                            </div>
                                                                                            <div className="vertical mrgL30">
                                                                                                <div>
                                                                                                    {quotation.viewedBySyndic 
                                                                                                            ?   ""
                                                                                                            :   <Badge size='lg' color="green" className='mrgB5'>NEW</Badge>
                                                                                                                
                                                                                                        }
                                                                                                </div>
                                                                                                <Link to={`/s/offers/offer/company-page/${quotation.author.company._id}`} className="aReset bT">
                                                                                                    <div className="horizontal">
                                                                                                        <h1 className="txt18 useRoboto mrgB5 lineH20">{quotation.author.company.name}</h1>
                                                                                                    </div>  
                                                                                                    <p className="txt16 lineH18 useRoboto noMrgTB">{quotation.author.occupation.join(' - ')}</p>
                                                                                                
                                                                                                    <div className='horizontal noMrgT'>
                                                                                                        <p className='txt16 useRoboto bold mrgR5 noMrgT'>{(quotation.author.addedToFavBy).length}</p>
                                                                                                        <p className='txt16 useRoboto noMrgT'>ajouté en favoris</p>
                                                                                                    </div>
                                                                                                </Link>
                                                                                    
                                                                                                <div className="mrgB10"></div>

                                                                                                <div className="horizontal iCenter">
                                                                                                    <button
                                                                                                        className="btn-action-for-rdv bDarkGrey mrgR10 mrgB10"
                                                                                                        id={quotation._id}
                                                                                                        onClick={handleOpenRefuseModal}
                                                                                                        >
                                                                                                        Refuser
                                                                                                    </button>

                                                                                                    { open && refusedQuotId === quotation._id && refuseModal 
                                                                                                        ?    <Modal 
                                                                                                                centered
                                                                                                                withCloseButton={false}    
                                                                                                                opened={opened} 
                                                                                                                onClose={close}
                                                                                                                size="auto">
                                                                                                                <Group noWrap mt="md" className="useRoboto mrgT10 vertical jCenter lineH10">
                                                                                                                    <p className='noMrgTB txt16'>Refuser le devis de </p>
                                                                                                                    <p className='noMrgT mrgB10 txt16 mediumplus'>{quotation.author.company.name} ?</p>
                                                                                                                </Group>

                                                                                                                <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20">
                                                                                                                    <Button variant="outline" size="md" color="dark" onClick={close} className='useRoboto'>
                                                                                                                        <p className='txt16'>Annuler</p>
                                                                                                                    </Button>
                                                                                                                    <Button variant="filled" size="md" color="dark" id={quotation._id} onClick={handleRefuseClick} className='useRoboto medium'>
                                                                                                                        <p className='txt16'>Refuser</p>
                                                                                                                    </Button>
                                                                                                                </Group>       
                                                                                                            </Modal>
                                                                                                        :   ""
                                                                                                    }
                                                                                                        
                                                                                                    <div className="mrgB5"></div>

                                                                                                    <button 
                                                                                                        className="btn-action-for-rdv bGreen mrgR10 mrgB10"
                                                                                                        id={quotation._id}
                                                                                                        onClick={handleOpenAcceptModal}
                                                                                                        >
                                                                                                        <p className='txt16 noMrgTB'>Accepter</p>
                                                                                                    </button>
                                                                                                        { open && acceptedQuotId === quotation._id && !refuseModal 
                                                                                                            ?   <Modal  
                                                                                                                    centered
                                                                                                                    withCloseButton={false}    
                                                                                                                    opened={opened} 
                                                                                                                    onClose={close}
                                                                                                                    size="auto">
                                                                                                                    <Group noWrap mt="md" className=" useRoboto vertical jCenter mrgT10 lineH10">
                                                                                                                        <p className='noMrgTB txt16 lineH10'>Accepter le devis de</p> 
                                                                                                                        <p className='noMrgTB txt16 mediumplus'>{quotation.author.company.name} ?</p>
                                                                                                                        <p className='noMrgTB txt15 mrgR5 mrgL5 mrgB10'>Tous les autres devis seront refusés.</p>
                                                                                                                    </Group>

                                                                                                                    <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20 w70">
                                                                                                                        <Button variant="outline" size="md" color="dark" onClick={close} className='useRoboto'>
                                                                                                                            <p className='txt16'>Annuler</p>
                                                                                                                        </Button>
                                                                                                                        <Button variant="filled" size="md" color="dark" id={quotation._id} onClick={handleAcceptClick} className='useRoboto medium'>
                                                                                                                            <p className='txt16'>Accepter</p>
                                                                                                                        </Button>
                                                                                                                    </Group>       
                                                                                                                </Modal>
                                                                                                            :   ""
                                                                                                        }
                                                                                                                                    
                                                                                                        
                                                                                                    <button className="btn-action-for-rdv bBlack mrgB10" onClick={handleViewed} id={quotation._id}>
                                                                                                        <div id={quotation.downloadLink} onClick={openPDF} className='aReset wT'>
                                                                                                            <p className='txt16 noMrgTB'>Voir devis</p>
                                                                                                        </div>
                                                                                                    </button>
                                                                                                        
                                                                                                </div>
                                                                                            </div>                         
                                                                                        </div>
                                                                                    </div>
                                                                                </div> 
                                                                            </> 
                                                                        :   <></>
                                                                }
                                                            </div>
                                                        ))}
                                                    
                                                    </Accordion.Panel>
                                                </>
                                                :   <p className='txt16 dGreyT mrgL20 mrgT5'>Vous n'avez pas reçu de devis</p>
                                        }    
                                    </Accordion.Item>
                                </Accordion>
        
                                <Accordion>
                                    <Accordion.Item value="deletedQuot">
                                        <Accordion.Control>
                                            <p className="useRoboto mrgB20 bold txt18">Devis supprimés</p>
                                        </Accordion.Control>
                                        <div className="mrgB10"></div>
                                        {listAllQuotation.length > 0 && listAllQuotation.some(quotation => quotation.status === 'refusedBySyndic')
                                            ?   <>
                                                    <Accordion.Panel> 
                                                        {listAllQuotation.map((quotation, index) => (
                                                            <div key = {index} style = {{margin : '5px'}}>
                                                                {quotation.status === 'refusedBySyndic' 
                                                                    ?   
                                                                        <div className="bLiteGrey horizontal iCenter spaceBtw"> 
                                                                            <div><p className="txt16 useRoboto mrgT10 mrgB10 mrgL20 medium">{quotation.author.company.name}</p></div>
                                                                            <div><p className="txt16 useRoboto mrgT10 mrgB10 mrgR20">Refusé le {format(parseISO(quotation.sendDate), 'dd/MM/yy')}</p></div>
                                                                        </div>
                                                                    :   ""
                                                                }
                                                            </div>
                                                        ))}
                                                    </Accordion.Panel>  
                                                </>
                                            :   <p className='txt16 dGreyT mrgL20 mrgT5'>Vous n'avez pas refusé de devis</p>
                                        }
                                        

                                    </Accordion.Item>
                                </Accordion>
                                <div id='mrgB50'></div>   
                            </div>
                        )}
                        </>
                }
            </> 
           
        : 
        <Loader />
        
    )  
}

export default ReceivedQuotationList;


    