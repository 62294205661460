import { React, useMemo, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../../../contexts/authContext';
import { useParams, useNavigate } from "react-router-dom";

import { useDisclosure } from '@mantine/hooks';
import { Modal, TextInput, NumberInput, Textarea, Box, Autocomplete, Select, Checkbox, Stepper, FileButton, Button, Loader } from '@mantine/core';
import { useForm } from '@mantine/form';
import { DatePickerInput } from '@mantine/dates';
import { IconX, IconCalendar, IconPlus } from '@tabler/icons-react';
import 'dayjs/locale/fr';
import { subDays, addDays, addYears } from 'date-fns';

import AppHdWithReturnLeft from '../../../../../composents/Headers/AppHeaders/AppHdWithReturnLeft/AppHdWithReturnLeft'
import SyndicRectangleButton from '../../../../../composents/Buttons/Syndic/RectangleButton/RectangleButtonMain';
import SyndicRectangleButtonSnd from '../../../../../composents/Buttons/Syndic/RectangleButton/RectangleButtonSecond';
import AppDesktopLogo from '../../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

const EditOffer = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [offerInfo, setOfferInfo] = useState();
    const [previewPictures, setPreviewPictures] = useState([]);
    const [opened, { open, close }] = useDisclosure(false);

    const [query, setQuery] = useState('');
    const [options, setOptions] = useState([]);
    const [cityOfIntervention, setCityOfIntervention] = useState('');

    const [ openedModalLinkToDocs, { open : openModalLinkToDocs, close: closeModalLinkToDocs}]= useDisclosure(false);
    const [ openedImgLimit, { open : openModalImgLimit, close : closeModalImgLimit }] = useDisclosure(false);
    const [ openedExistingImg, { open: openModalExistingImg, close: closeModalExistingImg}] = useDisclosure(false)
    const [ openedTooBigImg, { open: openModalTooBigImg, close: closeModalTooBigImg}] = useDisclosure(false)
    const [ openedModalWrongType, { open: openModalWrongType, close: closeModalWrongType}] = useDisclosure(false);
    const [isEditing, setIsEditing] = useState(false);
    const navigate = useNavigate();

    let {id} = useParams()
    
    useEffect(() => {
        const fetchData = async () => {
            const token = getToken();
            if (!token) {
                navigate("/s/login");
                return;
            }
            
            try {
                await getOfferInfo();
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
            };
            window.addEventListener('resize', handleResize);
            return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const form = useForm({
        initialValues: {
          title: "",
          urgent: "",
          occupation: "",
          workRangeDates: "",
          deadlineToReceiveQuotationDate: "",
          rdvSelected: "",
          quotationReceptionAllowed: "",
          nbOfLots: "",
          description: "",
          linkToDocs: "",
          pictures: []
        },
        validate: {
            title : (value) => (
                value.length < 1
                ? 'Renseignez le titre de votre offre'
                : value.length > 90 
                ? '90 caractères maximum' 
                : null
            ),
            occupation : (value) => (value.length < 1 ? 'Sélectionnez le métier recherché': null),
            workRangeDates : (value) => (value[0].length || value[1].length < 1 ? 'Sélectionnez les dates prévisionnelles de travaux' : null),
            description : (value) => (value.length > 300 ? '300 caractères maximum' : null),
        },
      });

    const getOfferInfo = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/offers/offer/${id}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const offerInfo = await response.json();
            const rangeDate = offerInfo.workRangeDates
            offerInfo.startDate = new Date(rangeDate[0])
            offerInfo.endDate = new Date(rangeDate[1])

            setOfferInfo(offerInfo);
            setQuery(offerInfo.cityOfIntervention)
            setCityOfIntervention(offerInfo.cityOfIntervention)
            setPreviewPictures(offerInfo.pictures)

            form.setValues({
                title: offerInfo.title,
                urgent: offerInfo.urgent,
                occupation: offerInfo.occupation,
                workRangeDates: [offerInfo.startDate, offerInfo.endDate],
                deadlineToReceiveQuotationDate: new Date(offerInfo.deadlineToReceiveQuotationDate),
                rdvSelected: offerInfo.rdvSelected,
                quotationReceptionAllowed: offerInfo.quotationReceptionAllowed,
                nbOfLots: Number(offerInfo.nbOfLots),
                description: offerInfo.description,
                linkToDocs: offerInfo.linkToDocs
              });
            
        } catch (error) {
        }
    }

    useMemo( async () => {
        await getOfferInfo()
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    //date max pour le calendrier de la deadline d'envoi de devis
    const minDeadline = subDays(form.values.workRangeDates.at(0),1)

    const handleQueryChange = async (value) => {
        setQuery(value);
        if (value) {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch('https://boldee-97d8d36af3bd.herokuapp.com/s/offers/search-cityOfIntervention', {
                    method: 'POST',
                    body: JSON.stringify({ query: value }),
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }          
                })

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                  }
                const data = await response.json();
                const suggestionsMap = new Map();

                data.features.forEach((feature) => {
                const city = feature.properties.city || '';
                const postcode = feature.properties.postcode || '';
                const suggestionKey = `${city}-${postcode}`;

                suggestionsMap.set(suggestionKey, {
                    value: `${postcode} ${city}`,
                    label: `${postcode} ${city}`,
                    postcode: postcode,
                    city: city
                });
                });

                const uniqueSuggestions = Array.from(suggestionsMap.values());
                setOptions(uniqueSuggestions);
            } catch (error) {
            }
        }
    
    };
  

    const handleQuerySubmit = (citySelected) => {
        setCityOfIntervention(citySelected.target.value)
    };   

    const handleFormSubmit =  async (values, _event) => {
        _event.preventDefault();
        if (isEditing) {
            try {
                const token = localStorage.getItem('token');
                await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/offers/offer-edit/${id}`, {
                    method: "POST",
                    body: JSON.stringify({
                        id: id,
                        title : values.title,
                        urgent : values.urgent,
                        cityOfIntervention : cityOfIntervention,
                        occupation : values.occupation,
                        workRangeDates : values.workRangeDates,
                        deadlineToReceiveQuotationDate : values.deadlineToReceiveQuotationDate,
                        rdvSelected : values.rdvSelected,
                        quotationReceptionAllowed : values.quotationReceptionAllowed,
                        nbOfLots : values.nbOfLots,
                        description : values.description,
                        linkToDocs : values.linkToDocs,
                        pictures : previewPictures
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`
                    }
                });
                // const data = await response.json(); 
                // setOfferInfo(data)
                open();
                setTimeout(() => {
                    navigate(`/s/offers/offer/${id}`);
                }, 500) 
            } catch (error) {
            }
        }
      };


    const handleFormChange = (event) => {
        const { name, value } = event.currentTarget;
        form.setFieldValue(name, value);
        setOfferInfo((prevOfferInfo) => ({
            ...prevOfferInfo,
            [name]: value,
          }));
    };

    const fileBrowseHandler = (event) => {
        let updatedPreviewPictures = [];
        const limitImages = 3
        const maxSizeInBytes = 10*1024*1024

        for(let i = 0;i < event.length;i++){
            if(event[i].size > maxSizeInBytes) {
                openModalTooBigImg()
            } else if(event[i].type !== 'image/png' && event[i].type !== 'image/jpeg' && event[i].type !== 'image/jpg'){
                openModalWrongType()
            } else if (event[i].size <= maxSizeInBytes) {
 
                let reader = new FileReader();
                reader.readAsDataURL(event[i])
                reader.onload = () => {
                    if(!previewPictures.concat(updatedPreviewPictures).includes(reader.result)){
                        updatedPreviewPictures.push(reader.result);
                        if(previewPictures.concat(updatedPreviewPictures).length <= limitImages ) {
                            setPreviewPictures(previewPictures.concat(updatedPreviewPictures))
                        } else if (previewPictures.concat(updatedPreviewPictures).length > limitImages){
                            setPreviewPictures(previewPictures.concat(updatedPreviewPictures).slice(0,3))
                            openModalImgLimit()
                        }
                    } else if (previewPictures.concat(updatedPreviewPictures).includes(reader.result)) {
                        openModalExistingImg()
                    }
                }
                reader.onerror = function (error) {
                }
            }
        }      
    }
    
    const handleVerifyLinkToDocs = (event) => {
        event.preventDefault();
        const linkToDocForVerification = form.values.linkToDocs.trim();
        if(!linkToDocForVerification) {
            openModalLinkToDocs();
        } else {
            window.open(linkToDocForVerification, '_blank');
        }        
    }
    

    const deleteImg = (event) => {
        const pictureToDelete = previewPictures.filter(picture => picture !== event.currentTarget.value);
        setPreviewPictures(pictureToDelete);
    }

     //navigation entre les étapes du formulaire
    const [active, setActive] = useState(0);
    const nextStep = () => {
        setActive((current) => {
          if (current === 2) {
            setIsEditing(true);
          }
          return current < 3 ? current + 1 : current;
        });
      };
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

    return(
        offerInfo ?
            <>
                {isMobile ? (
                    <>
                        <AppHdWithReturnLeft isEditOffer={true} >
                            Modifier mon offre
                        </AppHdWithReturnLeft>
                        <form onSubmit={form.onSubmit(handleFormSubmit)}>
                            <div className="width90pCenter roboto">
                            <Stepper color="gray" size="xs" active={active} onStepClick={setActive} breakpoint={60}>
            
                                {/* 1 step / 3 */}
                                <Stepper.Step label="Votre besoin" >
                                    <Box id="stepForm-mrg-t">
                                        
                                        <TextInput
                                            label="Titre de l'offre"
                                            placeholder="Titre de l'offre"
                                            withAsterisk
                                            onChange={handleFormChange}
                                            {...form.getInputProps('title')}
                                        />
                                        <div className="mrgB10"></div>

                                        <Select
                                            label="Métier(s) recherché(s)"
                                            placeholder={offerInfo.occupation}
                                            data={[
                                                { value: 'Entreprise de travaux', label: 'Entreprise de travaux' },
                                                { value: 'Electricien', label: 'Electricien' },
                                                { value: 'Menuisier', label: 'Menuisier' },
                                                { value: 'Plombier', label: 'Plombier' },
                                                { value: 'Entreprise de nettoyage, Gardien, Concierge', label: 'Entreprise de nettoyage, Gardien, Concierge' },
                                                { value: 'Jardinier- Paysagiste', label: 'Jardinier- Paysagiste' },
                                                { value: 'Entreprise de maintenance et de dépannage', label: 'Entreprise de maintenance et de dépannage' },
                                                { value: 'Chauffagiste', label: 'Chauffagiste' },
                                                { value: 'Désinfection, Désinsectisation, Dératisation', label: 'Désinfection, Désinsectisation, Dératisation' },
                                                { value: 'Architecte, Bureaux d\'études', label: 'Architecte, Bureaux d\'études' },
                                                { value: 'Assureur', label: 'Assureur' },
                                                { value: 'Avocat spécialisé en droit immobilier', label: 'Avocat spécialisé en droit immobilier' },
                                                { value: 'Autre', label: 'Autre' }
                                            ]}
                                            withAsterisk
                                            onChange={handleFormChange}
                                            {...form.getInputProps('occupation')}
                                        />
                                        <div className="mrgB10"></div>

                                        <Autocomplete
                                            label="Code postal du lieu d'intervention"
                                            withAsterisk
                                            value={query}
                                            onChange={handleQueryChange}
                                            onSelect={handleQuerySubmit}
                                            data={options}
                                            textFieldProps={{ required: true }}
                                            />
                                        <div className="mrgB10"></div>
                                    
                                        <Checkbox
                                            labelPosition="left"
                                            label="Est-ce une urgence ?"
                                            size="md"
                                            color="dark"
                                            {...form.getInputProps('urgent', { type: 'checkbox' })} 
                                            defaultChecked={offerInfo.urgent}
                                        />
                                        <div className="mrgB10"></div>
                                        
                                        <DatePickerInput
                                            icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                                            type="range"
                                            valueFormat="DD MMM YYYY"                          
                                            locale="fr"
                                            label="Dates prévisionnelles de travaux"
                                            placeholder={'Du '+ offerInfo.startDate+' au '+ offerInfo.endDate}
                                            minDate= {new Date()}
                                            maxDate = {addYears(new Date(),3)}
                                            allowSingleDateInRange                                    
                                            onChange={handleFormChange}
                                            withAsterisk
                                            {...form.getInputProps('workRangeDates')}
                                            />

                                        <div className="mrgB30"></div>
                                        
                                        <SyndicRectangleButton onClick={nextStep}>
                                            Continuer
                                        </SyndicRectangleButton>
                                    </Box>
                                </Stepper.Step>

                                {/* 2nd step/3 */}
                                <Stepper.Step label="Vos modalités">
                                    <Box id="stepForm-mrg-t">
                                        <p id="editDeadlineGa4" className='noMrgTB'>
                                            <DatePickerInput 
                                                icon={<IconCalendar size="1.1rem" stroke={1.5} />}                         
                                                valueFormat="DD MMM YYYY"
                                                locale="fr" 
                                                minDate={addDays(new Date(),1)}
                                                maxDate={minDeadline}
                                                placeholder={new Date(offerInfo.deadlineToReceiveQuotationDate)}
                                                label="Délai de réception des devis" 
                                                description="Renseignez la date d'échéance jusqu'à laquelle vous souhaitez recevoir les devis. Votre offre reste publiée tant que cette date n'est pas échue."
                                                excludeDate={(date) => date.getDay() === 0 || date.getDay() === 6}
                                                withAsterisk
                                                onChange={handleFormChange}
                                                {...form.getInputProps('deadlineToReceiveQuotationDate')}
                                            />
                                        </p>
                                        <div className="mrgB10"></div>
                
                                        <Checkbox
                                            labelPosition="left"
                                            label="RDV pour établir un devis"
                                            color="dark"
                                            size="md"
                                            {...form.getInputProps('rdvSelected', { type: 'checkbox' })}
                                            defaultChecked={offerInfo.rdvSelected}
                                        />
                                        <div className="mrgB10"></div>
                                        
                                        {offerInfo.rdvSelected || offerInfo.quotationReceptionAllowed
                                            ?   <>
                                                {form.isDirty('rdvSelected') && !form.values.rdvSelected
                                                    ?   ""     
                                                    :   <Checkbox
                                                            labelPosition="left"
                                                            label="Autoriser les prestataires à envoyer des devis sans RDV de chiffrage"
                                                            size="md"
                                                            color="dark"
                                                            {...form.getInputProps('quotationReceptionAllowed', { type: 'checkbox' })}
                                                            defaultChecked={offerInfo.quotationReceptionAllowed}
                                                        />
                                                } 
                                                </>
                                            :   ""
                                        }

                                        <div className="mrgB30"></div>

                                        <SyndicRectangleButtonSnd onClick={prevStep}>
                                            Retour
                                        </SyndicRectangleButtonSnd>

                                        <div className="mrgB10"></div>

                                        <SyndicRectangleButton onClick={nextStep}>
                                            Continuer
                                        </SyndicRectangleButton>
                                    </Box>
                                </Stepper.Step>

                                {/* 3rd step/3 */}
                                <Stepper.Step  label="Boostez & Publiez">
                                    <Box id="stepForm-mrg-t">
                                        <NumberInput
                                            placeholder={offerInfo.nbOfLots}
                                            label="Nombre de lots"
                                            max={99999}
                                            min={1}
                                            onChange={handleFormChange}
                                            {...form.getInputProps('nbOfLots')}
                                        />
                                        <div className="mrgB10"></div>
                                        
                                        <Textarea
                                            defaultValue={offerInfo.description}
                                            label="Description du projet"
                                            onChange={handleFormChange}
                                            {...form.getInputProps('description')}
                                            />
                                        <div className="mrgB10"></div>

                                        <div className='horizontal iEnd'>
                                            <div className='linkToDocsInput'>
                                                <TextInput
                                                    label="Lien vers document(s) de l'offre"
                                                    placeholder='https://'
                                                    onChange={handleFormChange}                        
                                                    {...form.getInputProps('linkToDocs')}
                                                />
                                            </div>
                                                <button onClick={handleVerifyLinkToDocs} className='btnReset mrgLauto useRoboto linkToDocsBtn'>
                                                    Vérifier lien
                                                </button>                                    
                                        </div>
                                        <div className="mrgB10"></div>
                                        
                                        {openModalLinkToDocs  
                                            ?   <Modal 
                                                    centered
                                                    size="50%"
                                                    opened={openedModalLinkToDocs}
                                                    onClose={closeModalLinkToDocs}
                                                    withCloseButton={false}>
                                                    <p className="useRoboto txt15 txtCenter noMrgTB">
                                                        Aucun lien à vérifier
                                                    </p>
                                                </Modal> 
                                            :   ""
                                        }

                                        <div className="horizontal iCenter">
                                            {previewPictures.length > 0
                                                ?   <div className='horizontal mrgT5 mrgB5'>
                                                        {previewPictures.map((picture => (
                                                            <div key = {picture} style = {{marginRight : '5px'}} className='vertical imgRelative'>
                                                                <img className="img65x65" src={picture} alt='photo_chantier'/>
                                                                <button value={picture} onClick={deleteImg} className='btnReset imgDeleteImgBtnAbsolute mrgR5 mrgT5 bold bLiteTransparent'><IconX width={13} height={13}/></button>
                                                            </div>  
                                                        )))}
                                                    </div>
                                                : ""
                                            }

                                            <FileButton
                                                className='img68x68T mrgT5' 
                                                multiple
                                                onChange={fileBrowseHandler}
                                                accept="image/png,image/jpeg,image/jpg">
                                                {(props) => <Button color="gray" {...props}>
                                                    <div className='vertical iCenter'>
                                                        <IconPlus width={20}/> 
                                                        <p className='noMrgTB'>Ajouter photo</p>
                                                    </div> 
                                                </Button>}
                                            </FileButton>
                                            {openModalImgLimit  
                                                ?   <Modal 
                                                        centered
                                                        size="50%"
                                                        opened={openedImgLimit}
                                                        onClose={closeModalImgLimit}
                                                        withCloseButton={false}>
                                                        <p className="useRoboto txt15 txtCenter noMrgTB">
                                                            Chargement de 3 photos maximum
                                                        </p>
                                                    </Modal> 
                                                :   ""
                                            }

                                            {openModalExistingImg  
                                                ?   <Modal 
                                                        centered
                                                        size="50%"
                                                        opened={openedExistingImg}
                                                        onClose={closeModalExistingImg}
                                                        withCloseButton={false}>
                                                        <p className="useRoboto txt15 txtCenter noMrgTB">
                                                            Photo déjà téléchargée
                                                        </p>
                                                    </Modal> 
                                                :   ""
                                            }
                                            
                                            {openModalTooBigImg  
                                                ?   <Modal 
                                                        centered
                                                        size="50%"
                                                        opened={openedTooBigImg}
                                                        onClose={closeModalTooBigImg}
                                                        withCloseButton={false}>
                                                        <p className="useRoboto txt15 txtCenter noMrgTB">
                                                            10 Mo Maximum par photo
                                                        </p>
                                                    </Modal> 
                                                :   ""
                                            }
                                            {openModalWrongType 
                                                ?   <Modal 
                                                        centered
                                                        size="50%"
                                                        opened={openedModalWrongType}
                                                        onClose={closeModalWrongType}
                                                        withCloseButton={false}>
                                                        <p className="useRoboto txt15 txtCenter noMrgTB">
                                                            Fichiers autorisés : .png, .jpeg, .jpg
                                                        </p>
                                                    </Modal> 
                                                :   ""
                                            }
                                        </div>           
                                        
                                        <div className="mrgB30"></div>

                                            <SyndicRectangleButtonSnd onClick={prevStep}>
                                                    Retour
                                            </SyndicRectangleButtonSnd>
                                                
                                            <div className="mrgB10"></div>
                                            

                                            {active === 2 && (
                                            <SyndicRectangleButton type="submit" onClick={() => setIsEditing(true)}>                          
                                                <p id='syndicEditOffer' className='noMrgTB'>Modifier</p>    
                                            </SyndicRectangleButton>
                                            )}

                                            {open ?
                                                <Modal 
                                                    centered
                                                    size="auto"
                                                    opened={opened}
                                                    onClose={close}
                                                    withCloseButton={false}>
                                                    <p className="txtCenter noMrgTB useRoboto txt15">
                                                        Offre modifiée
                                                    </p>
                                                </Modal> 
                                            : 
                                                ""
                                            }
                                    </Box>
                                </Stepper.Step>
                            </Stepper>
                    
                            </div>

                        
                        </form>
                    </>
                ) : (
                    <>
                        <AppDesktopLogo/>

                        <AppHdWithReturnLeft isEditOffer={true}>
                            Modifier mon offre
                        </AppHdWithReturnLeft>
                        <form onSubmit={form.onSubmit(handleFormSubmit)}>
                            <div className="width50pCenter roboto">
                            <Stepper color="gray" size="md" active={active} onStepClick={setActive} breakpoint={60}>
                                {/* 1 step / 3 */}
                                <Stepper.Step label="Votre besoin" >
                                    <Box id="stepForm-mrg-t">
                                        <TextInput
                                            label="Titre de l'offre"
                                            placeholder="Titre de l'offre"
                                            withAsterisk
                                            size="md"
                                            onChange={handleFormChange}
                                            {...form.getInputProps('title')}
                                        />
                                        <div className="mrgB20"></div>

                                        <Select
                                            label="Métier(s) recherché(s)"
                                            placeholder={offerInfo.occupation}
                                            data={[
                                                { value: 'Entreprise de travaux', label: 'Entreprise de travaux' },
                                                { value: 'Electricien', label: 'Electricien' },
                                                { value: 'Menuisier', label: 'Menuisier' },
                                                { value: 'Plombier', label: 'Plombier' },
                                                { value: 'Entreprise de nettoyage, Gardien, Concierge', label: 'Entreprise de nettoyage, Gardien, Concierge' },
                                                { value: 'Jardinier- Paysagiste', label: 'Jardinier- Paysagiste' },
                                                { value: 'Entreprise de maintenance et de dépannage', label: 'Entreprise de maintenance et de dépannage' },
                                                { value: 'Chauffagiste', label: 'Chauffagiste' },
                                                { value: 'Désinfection, Désinsectisation, Dératisation', label: 'Désinfection, Désinsectisation, Dératisation' },
                                                { value: 'Architecte, Bureaux d\'études', label: 'Architecte, Bureaux d\'études' },
                                                { value: 'Assureur', label: 'Assureur' },
                                                { value: 'Avocat spécialisé en droit immobilier', label: 'Avocat spécialisé en droit immobilier' },
                                                { value: 'Autre', label: 'Autre' }
                                            ]}
                                            withAsterisk
                                            size="md"
                                            onChange={handleFormChange}
                                            {...form.getInputProps('occupation')}
                                        />
                                        <div className="mrgB20"></div>

                                        <Autocomplete
                                            label="Code postal du lieu d'intervention"
                                            withAsterisk
                                            value={query}
                                            onChange={handleQueryChange}
                                            onSelect={handleQuerySubmit}
                                            data={options}
                                            textFieldProps={{ required: true }}
                                            size="md" 
                                            />
                                        <div className="mrgB30"></div>
                                    
                                        <Checkbox
                                            labelPosition="left"
                                            label={
                                                <p className='txt16 noMrgT'>
                                                    Est-ce une urgence ?
                                                </p>}
                                            size="md"
                                            color="dark"
                                            {...form.getInputProps('urgent', { type: 'checkbox' })} 
                                            defaultChecked={offerInfo.urgent}
                                        />
                                        <div className="mrgB10"></div>
                                        
                                        <DatePickerInput
                                            icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                                            type="range"
                                            valueFormat="DD MMM YYYY"                          
                                            locale="fr"
                                            label="Dates prévisionnelles de travaux"
                                            placeholder={'Du '+ offerInfo.startDate+' au '+offerInfo.endDate}
                                            minDate= {new Date()}
                                            maxDate = {addYears(new Date(),3)}
                                            allowSingleDateInRange                                    
                                            onChange={handleFormChange}
                                            withAsterisk
                                            size="md"
                                            {...form.getInputProps('workRangeDates')}
                                            />

                                        <div className="mrgB30"></div>
                                        
                                        <SyndicRectangleButton onClick={nextStep}>
                                            <p className='txt16'>Continuer</p>
                                        </SyndicRectangleButton>
                                    </Box>
                                    <div className='mrgB100'></div>
                                </Stepper.Step>

                                {/* 2nd step/3 */}
                                <Stepper.Step label="Vos modalités">
                                    <Box id="stepForm-mrg-t">
                                        <p id="editDeadlineGa4" className='noMrgTB'>
                                            <DatePickerInput 
                                                icon={<IconCalendar size="1.1rem" stroke={1.5} />}                         
                                                valueFormat="DD MMM YYYY"
                                                locale="fr" 
                                                minDate={addDays(new Date(),1)}
                                                maxDate={minDeadline}
                                                placeholder={new Date(offerInfo.deadlineToReceiveQuotationDate)}
                                                label="Délai de réception des devis" 
                                                description= {
                                                    <p className='txt16 noMrgT'>
                                                        Renseignez la date d'échéance jusqu'à laquelle vous souhaitez recevoir les devis. Votre offre reste publiée tant que cette date n'est pas échue.
                                                    </p>
                                                }                                             
                                                excludeDate={(date) => date.getDay() === 0 || date.getDay() === 6}
                                                withAsterisk
                                                size="md"
                                                onChange={handleFormChange}
                                                {...form.getInputProps('deadlineToReceiveQuotationDate')}
                                            />
                                        </p>
                                        <div className="mrgB30"></div>
                
                                        <Checkbox
                                            labelPosition="left"
                                            label= {
                                                <p className='txt16 noMrgT'>
                                                    Nécessité d'un RDV sur site pour établir un devis
                                                </p>
                                            }   
                                            color="dark"
                                            size="md"
                                            {...form.getInputProps('rdvSelected', { type: 'checkbox' })}
                                            defaultChecked={offerInfo.rdvSelected}
                                        />
                                        <div className="mrgB20"></div>
                                        
                                        {offerInfo.rdvSelected || offerInfo.quotationReceptionAllowed
                                            ?   <>
                                                {form.isDirty('rdvSelected') && !form.values.rdvSelected
                                                    ?   ""     
                                                    :   <>
                                                        <Checkbox
                                                            labelPosition="left"
                                                            label={
                                                                <p className='txt16 noMrgT'>
                                                                    Autoriser les prestataires à envoyer des devis sans RDV de chiffrage
                                                                </p>
                                                            }                                                            
                                                            size="md"
                                                            color="dark"
                                                            {...form.getInputProps('quotationReceptionAllowed', { type: 'checkbox' })}
                                                            defaultChecked={offerInfo.quotationReceptionAllowed}
                                                        />
                                                        <div className="mrgB10"></div>
                                                        </>
                                                } 
                                                </>
                                            :   ""
                                        }

                                        <div className="mrgB30"></div>

                                        <SyndicRectangleButtonSnd onClick={prevStep}>
                                            <p className='txt16'>Retour</p>
                                        </SyndicRectangleButtonSnd>

                                        <div className="mrgB10"></div>

                                        <SyndicRectangleButton onClick={nextStep}>
                                            <p className='txt16'>Continuer</p>
                                        </SyndicRectangleButton>
                                        <div id="mrgB50"></div>
                                    </Box>
                                    <div className='mrgB100'></div>
                                </Stepper.Step>

                                {/* 3rd step/3 */}
                                <Stepper.Step  label="Boostez & Publiez">
                                    <Box id="stepForm-mrg-t">
                                        <NumberInput
                                            placeholder={offerInfo.nbOfLots}
                                            label="Nombre de lots"
                                            max={99999}
                                            min={1}
                                            size="md"
                                            onChange={handleFormChange}
                                            {...form.getInputProps('nbOfLots')}
                                        />
                                        <div className="mrgB20"></div>

                                        <Textarea
                                            defaultValue={offerInfo.description}
                                            label="Description du projet"
                                            size="md"
                                            onChange={handleFormChange}
                                            {...form.getInputProps('description')}
                                            />
                                        <div className="mrgB20"></div>

                                        <div className='horizontal iEnd'>
                                            <div className='linkToDocsInput'>
                                                <TextInput
                                                    label="Lien vers document(s) de l'offre"
                                                    placeholder='https://'
                                                    onChange={handleFormChange}
                                                    size="md"                        
                                                    {...form.getInputProps('linkToDocs')}
                                                />
                                            </div>
                                            <button onClick={handleVerifyLinkToDocs} className='btnReset mrgLauto useRoboto linkToDocsBtn'>
                                                Vérifier lien
                                            </button>                                    
                                        </div>
                                        <div className="mrgB20"></div>
                                        
                                        {openModalLinkToDocs  
                                            ?   <Modal 
                                                    centered
                                                    size="auto"
                                                    opened={openedModalLinkToDocs}
                                                    onClose={closeModalLinkToDocs}
                                                    withCloseButton={false}>
                                                    <p className="useRoboto txt15 txtCenter noMrgTB">
                                                        Aucun lien à vérifier
                                                    </p>
                                                </Modal> 
                                            :   ""
                                        }

                                        <p className="medium txt16 mrgB5">Photos (3 maximum)</p>

                                        <div className="horizontal">
                                            {previewPictures.length > 0
                                                ?   <div className='horizontal mrgT5 mrgB5'>
                                                        {previewPictures.map((picture => (
                                                            <div key = {picture} style = {{marginRight : '5px'}} className='vertical imgRelative'>
                                                                <img className="img100x100" src={picture} alt='photo_chantier'/>
                                                                <button value={picture} onClick={deleteImg} className='btnReset imgDeleteImgBtnAbsolute mrgR5 mrgT5 bold bLiteTransparent'><IconX width={20} height={20}/></button>
                                                            </div>  
                                                        )))}
                                                    </div>
                                                : ""
                                            }

                                            <FileButton
                                                className='img100x100T mrgT5' 
                                                multiple
                                                onChange={fileBrowseHandler}
                                                accept="image/png,image/jpeg,image/jpg">
                                                {(props) => <Button color="gray" {...props}>
                                                    <div className='vertical iCenter'>
                                                        <IconPlus width={20}/> 
                                                        <p className='noMrgTB txt16'>Ajouter photo</p>
                                                    </div> 
                                                </Button>}
                                            </FileButton>
                                            {openModalImgLimit  
                                                ?   <Modal 
                                                        centered
                                                        size="auto"
                                                        opened={openedImgLimit}
                                                        onClose={closeModalImgLimit}
                                                        withCloseButton={false}>
                                                        <p className="useRoboto txt16 txtCenter noMrgTB">
                                                            Chargement de 3 photos maximum
                                                        </p>
                                                    </Modal> 
                                                :   ""
                                            }

                                            {openModalExistingImg  
                                                ?   <Modal 
                                                        centered
                                                        size="auto"
                                                        opened={openedExistingImg}
                                                        onClose={closeModalExistingImg}
                                                        withCloseButton={false}>
                                                        <p className="useRoboto txt16 txtCenter noMrgTB">
                                                            Photo déjà téléchargée
                                                        </p>
                                                    </Modal> 
                                                :   ""
                                            }
                                            
                                            {openModalTooBigImg  
                                                ?   <Modal 
                                                        centered
                                                        size="auto"
                                                        opened={openedTooBigImg}
                                                        onClose={closeModalTooBigImg}
                                                        withCloseButton={false}>
                                                        <p className="useRoboto txt16 txtCenter noMrgTB">
                                                            10 Mo Maximum par photo
                                                        </p>
                                                    </Modal> 
                                                :   ""
                                            }
                                            {openModalWrongType 
                                                ?   <Modal 
                                                        centered
                                                        size="auto"
                                                        opened={openedModalWrongType}
                                                        onClose={closeModalWrongType}
                                                        withCloseButton={false}>
                                                        <p className="useRoboto txt16 txtCenter noMrgTB">
                                                            Fichiers autorisés : .png, .jpeg, .jpg
                                                        </p>
                                                    </Modal> 
                                                :   ""
                                            }
                                        </div>           
                                        
                                        <div className="mrgB30"></div>

                                            <SyndicRectangleButtonSnd onClick={prevStep}>
                                                <p className='txt16'>Retour</p>    
                                            </SyndicRectangleButtonSnd>
                                                
                                            <div className="mrgB10"></div>
                                            

                                            {active === 2 && (
                                            <SyndicRectangleButton type="submit" onClick={() => setIsEditing(true)}>                          
                                                <p className='txt16 noMrgTB' id='syndicEditOffer'>Modifier</p>
                                            </SyndicRectangleButton>
                                            )}

                                            {open ?
                                                <Modal 
                                                    centered
                                                    size="auto"
                                                    opened={opened}
                                                    onClose={close}
                                                    withCloseButton={false}>
                                                    <p className="txtCenter noMrgTB useRoboto txt16">
                                                        Offre modifiée
                                                    </p>
                                                </Modal> 
                                            : 
                                                ""
                                            }
                                    </Box>
                                    <div className='mrgB100'></div>

                                </Stepper.Step>
                            </Stepper>
                    
                            </div>

                        
                        </form>
                    </>
                )}
            </> 
      : 
      <Loader />
    )
}

export default EditOffer;