import { React, useMemo, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../../../contexts/authContext';
import { Link, useParams, useNavigate } from "react-router-dom";

import { Loader,  Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import Box from "../../../../../composents/Boxes/Standard/Box";
import AppHdWithReturnLeft from "../../../../../composents/Headers/AppHeaders/AppHdWithReturnLeft/AppHdWithReturnLeft";
import { IconHammer, IconLocation } from '@tabler/icons-react';
import AppDesktopLogo from '../../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

const FavoritePrestaMatch = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [listMatch, setlistMatch] = useState(null);
    const [prestaId, setPrestaId] = useState(null);
    const [opened, { open, close }] = useDisclosure(false);

    const {id} = useParams()

    const navigate = useNavigate();

    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/s/login");
            return;
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
      
          window.addEventListener('resize', handleResize);
      
          return () => {
            window.removeEventListener('resize', handleResize);
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const getMatches = async () => { 
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/offers/favorite-p-match/${id}`, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`
                }
            });
            const list = await response.json();
            setlistMatch(list)
        } catch (error) { console.error(error);
      }
    }  

    useMemo( async () => {
        await getMatches()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSendClick = async (event) => {
        open()
        const _id = event.currentTarget.id
        setPrestaId(_id)

        try {
            const token = localStorage.getItem('token');
            await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/offers/sendOffer-to-Fav/${id}`, {
                method: "POST",
                body: JSON.stringify({
                    id: id,
                    _id: _id,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            await getMatches()
        } catch (error) {
        }
    }

    const handleSendingCancel = async (event) => {
        const _id = event.currentTarget.id
        setPrestaId(_id)

        try {
            const token = localStorage.getItem('token');
            await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/offers/cancelSending-to-Fav/${id}`, {
                method: "POST",
                body: JSON.stringify({
                    id: id,
                    _id: _id,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            await getMatches()
        } catch (error) {
        }
    }

    return(
        listMatch ?
            <>
                {isMobile ? (
                    <>
                        <AppHdWithReturnLeft>Mes prestataires favoris</AppHdWithReturnLeft>
                        <div className="width90pCenter">
                            <p className="txt15 txtCenter dGreyT">Transmettez votre offre à vos prestataires favoris</p>
                        </div>
                        {listMatch.map((presta, index) => (
                            <div key = {index} style = {{margin : '10px'}}>
                                <Box>
                                    <div className="horizontal spaceBtw iCenter">
                                        <Link to={`/s/offers/offer/company-page/${presta.company._id}`} className="aReset bT">
                                            <div className="mrgL10 horizontal iCenter">
                                                <div>
                                                {presta.company.logo
                                                    ?   <img className="roundImg70" src={presta.company.logo} alt='logo'/>
                                                    :   <div className="noAvatarProfile">{presta.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                }
                                                </div>
                                                <div className="vertical mrgL10">
                                                    <p className="capitals txt15 bold noMrgTB">{presta.company.name}</p>
                                                    <p className="txt12 capitals thin noMrgTB">{presta.username}</p>
                                                    <div className='horizontal iStart mrgT5'>
                                                        <div><IconHammer width={13}/></div>
                                                        <p className="txt13 mrgT5 mrgB5 mrgL5 mrgR10 lineH15">
                                                            {presta.occupation.join(" - ")}
                                                        </p>
                                                    </div>
                                                    <div className='horizontal iStart'>
                                                        <div><IconLocation width={13}/></div>
                                                        <p className="txt13 mrgT5 mrgB5 mrgL5 mrgR10">
                                                            {presta.company.address.city}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>

                                        <div className='mrgR10'>
                                            
                                        {!presta.recommendedBySyndicOffers.includes(id)
                                            ?
                                            <button 
                                                className="btn-transferAOffer"
                                                id={presta._id}
                                                onClick={handleSendClick}>
                                                Envoyer
                                            </button>
                                            :
                                            <button 
                                                className=" btn-offerTransfered mrgLauto"
                                                id={presta._id}
                                                onClick={handleSendingCancel}>
                                                Envoyé
                                            </button>
                                            }
                                            {open && prestaId === presta._id ?
                                                <Modal 
                                                    centered
                                                    size="auto"
                                                    opened={opened}
                                                    onClose={close}
                                                    withCloseButton={false}>
                                                    <div className="txtCenter noMrgTB vertical useRoboto">
                                                        <p className="noMrgTB txt15">Votre offre a été envoyée à</p>
                                                        <p className='noMrgB mrgT5 medium txt15'>{presta.company.name}</p> 
                                                    </div>
                                                </Modal> 
                                            : 
                                                ""
                                            }                                
                                        </div>
                                    </div>
                                </Box>
                            </div>
                        ))}
                            
                    </>
                ) : (
                    <>
                        <AppDesktopLogo/>
                        <AppHdWithReturnLeft>Mes prestataires favoris</AppHdWithReturnLeft>
                        <div className="width50pCenter">
                            <p className="txt18 txtCenter dGreyT mrgB30">Transmettez votre offre à vos prestataires favoris</p>
                        
                            {listMatch.map((presta, index) => (
                                <div key = {index} style = {{margin : '20px'}}>
                                    <Box>
                                        <div className="horizontal spaceBtw iCenter">
                                            <Link to={`/s/offers/offer/company-page/${presta.company._id}`} className="aReset bT">
                                                <div className="horizontal iCenter mrgT20 mrgB20 mrgL20">
                                                    <div>
                                                        {presta.company.logo
                                                            ?   <img className="roundImg100" src={presta.company.logo} alt='logo'/>
                                                            :   <div className="noAvatarProfile">{presta.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                        }
                                                    </div>
                                                    <div className="vertical mrgL20">
                                                        <p className="capitals txt18 bold noMrgTB">
                                                            {presta.company.name}
                                                        </p>
                                                        <p className="txt16 capitals thin noMrgT mrgB5">
                                                            {presta.username}
                                                        </p>
                                                        <div className='horizontal iStart'>
                                                            <div className='mrgT5'><IconHammer width={18}/></div>
                                                            <p className="txt16 mrgT5 mrgB5 mrgL10 lineH20 mrgR20">
                                                                {presta.occupation.join(" - ")}
                                                            </p>
                                                        </div>
                                                        <div className='horizontal iStart'>
                                                            <div><IconLocation width={18}/></div>
                                                            <p className="txt16 noMrgTB mrgL10">
                                                                {presta.company.address.city}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>

                                            <div className='mrgR20'>
                                                
                                            {!presta.recommendedBySyndicOffers.includes(id)
                                                ?
                                                <button 
                                                    className="btn-transferAOffer mrgLauto"
                                                    id={presta._id}
                                                    onClick={handleSendClick}>
                                                   Envoyer
                                                </button>
                                                :
                                                <button 
                                                    className="btn-offerTransfered mrgLauto"
                                                    id={presta._id}
                                                    onClick={handleSendingCancel}>
                                                    Envoyé
                                                </button>
                                                }
                                                {open && prestaId === presta._id ?
                                                    <Modal 
                                                        centered
                                                        size="auto"
                                                        opened={opened}
                                                        onClose={close}
                                                        withCloseButton={false}>
                                                        <div className="txtCenter noMrgTB vertical useRoboto">
                                                            <p className="noMrgTB txt16">Votre offre a été envoyée à</p>
                                                            <p className='noMrgB mrgT5 medium txt16'>{presta.company.name}</p> 
                                                        </div>
                                                    </Modal> 
                                                : 
                                                    ""
                                                }                                
                                            </div>
                                        </div>
                                    </Box>
                                </div>
                            ))}
                        </div>
                            
                    </>
                )}
            </>
        : 
            <Loader />
    )
}

export default FavoritePrestaMatch;

