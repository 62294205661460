import { React, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IconChevronLeft } from '@tabler/icons-react';
import { Modal, Group, Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import classes from './AppHdWithReturnLeft.module.css';

// utilisation de props pour le cas de l'utilisation ou non d'un modal pour le bouton retour

const AppHdWithReturnLeft = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const isEditOffer = props.isEditOffer
    const prestaId = props.prestaId
    const navigate = useNavigate();
    const [opened, { open, close }] = useDisclosure(false);
    
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const HandleOpenModal = () => {
        open()
    }

    const goBack = () => {
        if(prestaId) {
            navigate(-1)
        } else {
            navigate(-1)
        }
    }


    return(
        isEditOffer ?
            <>
            {isMobile ? (
                <>
                    <div className={classes.appHeading}>
                        <div className={classes.navback}>   
                            <IconChevronLeft width={18}/>      
                            <button className={classes.btnResetNavback} onClick={HandleOpenModal}> 
                                Retour
                            </button>
                            { open 
                                ?   <Modal 
                                        centered
                                        withCloseButton={false}    
                                        opened={opened} 
                                        onClose={close}
                                        size="auto">
                                            <Group noWrap mt="md" className="useRoboto txtCenter mrgT5 txt15">
                                                Abandonner la modification de votre offre ?
                                            </Group>

                                            <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20 w80">
                                                <Button variant="outline" color="dark" onClick={close} className='useRoboto'>
                                                    Annuler
                                                </Button>
                                                <Button variant="filled" color="dark" onClick={goBack} className='useRoboto medium'>
                                                    Abandonner
                                                </Button>
                                        </Group>       
                                    </Modal> 
                                :   ""
                            }
                        </div>
                        <div className={classes.navtitle}>
                            <h1>{props.children}</h1>
                        </div>
                        <div className={classes.navrightsideBlank}>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className={classes.appHeading}>
                        <div className={classes.navback}>   
                            <IconChevronLeft width={25}/>      
                            <button className={classes.btnResetNavback} onClick={HandleOpenModal}> 
                                Retour
                            </button>
                            { open 
                                ?   <Modal 
                                        centered
                                        withCloseButton={false}    
                                        opened={opened} 
                                        onClose={close}
                                        size="auto">
                                            <Group noWrap mt="md" className="useRoboto txtCenter mrgT5 txt16">
                                                Abandonner la modification de votre offre ?
                                            </Group>

                                            <Group mt="xl" className="txtCenter horizontal mrgT20 jCenter" >
                                                <Button size="md" variant="outline" color="dark" onClick={close} className='useRoboto'>
                                                    <p className='txt16'>Annuler</p>
                                                </Button>
                                                <Button size="md" variant="filled" color="dark" onClick={goBack} className='useRoboto medium'>
                                                    <p className='txt16'>Abandonner</p>
                                                </Button>
                                        </Group>       
                                    </Modal> 
                                :   ""
                            }
                        </div>
                        <div className={classes.navtitle}>
                            <h1>{props.children}</h1>
                        </div>
                        <div className={classes.navrightsideBlank}>
                        </div>
                    </div>
                </>
            )}
            </>   
        :   
            //retour sans action modal
            <>
                <div className={classes.appHeading}>
                    
                    <div className={classes.navback}>   
                        <IconChevronLeft width={18}/>      
                        <button className={classes.btnResetNavback}>
                            <Link to="#"
                                onClick={goBack}>
                                Retour
                            </Link>
                        </button>
                    </div>
                    <div className={classes.navtitle}>
                        <h1>{props.children}</h1>
                    </div>
                    <div className={classes.navrightsideBlank}>
                    </div>
                </div>
            </>
        
    )
}

export default AppHdWithReturnLeft;