import React from "react";
import qualieauLogo from '../../../../assets/images/qualieau.jpg'
import classes from './LogoQualieau.module.css';

const LogoQualieau = (props) => {
    return(
        <div className={classes.Logo}>
            <img src={qualieauLogo} alt="logo"/>
        </div>
    )
}
export default LogoQualieau;