import { React, useMemo, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../../contexts/authContext';
import { Link, useNavigate } from 'react-router-dom';

import { IconHammer, IconLocation, IconEye, IconBoxMultiple, IconMessage } from '@tabler/icons-react';
import { Badge, Loader } from '@mantine/core';
import { format, parseISO } from 'date-fns';

import Box from '../../../../composents/Boxes/Standard/Box';
import AppNavbarSyndic from '../../../Navbar/NavbarSyndic';
import WorkOffersHd from '../../../../composents/Headers/Customized/WorkOfferHd/WorkOffersHd';

const SyndicOffresDeTravaux = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const { getToken, getUserType, handleLogoutPresta } = useContext(AuthContext);
    const [listOffer, setlistOffer] = useState()
    const [urgentList, setUrgentList] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/s/login");
            return;
        }

        const userType = getUserType();
        if(userType !== 'syndic'){
            localStorage.setItem("userType", "");
            handleLogoutPresta()
            navigate("/s/login");
            return;
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const getSyndicOffers = async () => { 
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/offers/list`, {
                method: "GET",
                headers: {
                Authorization: `Bearer ${token}`
                }
            });
            let list = await response.json();
            let filtered = list.sort(function(a,b){
                return new Date(b.createdAt) - new Date(a.createdAt);
              });
            setlistOffer(filtered)
        } catch (error) { console.error(error);
      }
    };

    useMemo( async () => {
        await getSyndicOffers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    const today = new Date().toISOString()
    
    return(
        listOffer || urgentList ? 
            <>
                {isMobile ? (
                    <>
                        <AppNavbarSyndic/>
                        <div>
                            <WorkOffersHd setUrgentList={setUrgentList}/>
                        </div>
                        
                        <div className="mrgT100 mrgB100">
                                {urgentList 
                                    ?   <>
                                            {listOffer.length <= 0
                                                ?   <p className='dGreyT txtCenter txt15'>Vous n'avez pas d'offre urgente</p>
                                                :   <>
                                                        {listOffer.map((offer, index) => (
                                                            <div key = {index} style = {{margin : '10px'}}>
                                                                {offer.urgent 
                                                                    ?   <>
                                                                            {offer.deadlineToReceiveQuotationDate < today
                                                                                ?   <Link to={`/s/offers/offer/${offer._id}`} className="aReset bT">               
                                                                                        <Box>
                                                                                            <div className="horizontal">
                                                                                                <div className="vertical mrgL10 mrgR10">                        
                                                                                                    <p className="txt13 noMrgTB mrgR5 mrgB5 txtCenter">
                                                                                                        {format(parseISO(offer.createdAt), 'dd/MM/yy')}
                                                                                                    </p>
                                                                                                    
                                                                                                    {offer.pictures.length > 0 
                                                                                                        ?   <div>
                                                                                                                <img 
                                                                                                                    className="roundImg70 noMrgTB"
                                                                                                                    src={offer.pictures[0]}
                                                                                                                    alt='photo_chantier'/>
                                                                                                            </div>
                                                                                                        :   <>
                                                                                                                {offer.author.company.logo
                                                                                                                    ?   
                                                                                                                        <img    
                                                                                                                            className="roundImg70 noMrgTB"
                                                                                                                            src={offer.author.company.logo}
                                                                                                                            alt='logo'/>

                                                                                                                    :   <div className="noAvatarProfile">{offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                                                                                }
                                                                                                            </>     
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="vertical w100">
                                                                                                    <div className="horizontal iCenter mrgB5">
                                                                                                        <div>
                                                                                                            {offer.urgent 
                                                                                                                ?   <Badge color="dark">Urgent</Badge>
                                                                                                                :   ""
                                                                                                            }
                                                                                                        </div>
                                                                                                        <div className='mrgLauto mrgR10'>
                                                                                                            {offer.assigned.assigned === true
                                                                                                                ?   <Badge color="dark" >
                                                                                                                        {offer.assigned.assignedTo.company.name}                                                                                             
                                                                                                                    </Badge>
                                                                                                                :   ""
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    
                                                                                                    <div>
                                                                                                        <h2 className="txt15 lineH15 noMrgT mrgB10 cap dGreyT mrgR5">{offer.title}</h2>
                                                                                                    </div>
                                                                                                    <div className="horizontal iCenter lineH13 mrgB5">
                                                                                                        <IconHammer width={16} height={13} className="dGreyT mrgR5"/>
                                                                                                        <p className="noMrg txt13 jumpline dGreyT finalDotsMed">{offer.occupation}</p>
                                                                                                    </div>
                                                                                                    <div className="horizontal iCenter">
                                                                                                        <IconLocation width={13} height={13} className="dGreyT mrgR5"/>
                                                                                                        <p className="noMrg txt13 dGreyT finalDotsMed">{offer.cityOfIntervention}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Box>
                                                                                    </Link>
                                                                                :   <Link to={`/s/offers/offer/${offer._id}`} className="aReset bT">               
                                                                                        <Box>
                                                                                            <div className="horizontal">
                                                                                                <div className="vertical mrgL10 mrgR10">                        
                                                                                                    <p className="txt13 noMrgTB mrgR5 mrgB5 txtCenter">
                                                                                                        {format(parseISO(offer.createdAt), 'dd/MM/yy')}
                                                                                                    </p>
                                                                                                    
                                                                                                    {offer.pictures.length > 0 
                                                                                                        ?   <div>
                                                                                                                <img 
                                                                                                                    className="roundImg70 noMrgTB"
                                                                                                                    src={offer.pictures[0]}
                                                                                                                    alt='photo_chantier'/>
                                                                                                            </div>
                                                                                                        :   <>
                                                                                                                {offer.author.company.logo
                                                                                                                    ?   <img    
                                                                                                                            className="roundImg70 noMrgTB"
                                                                                                                            src={offer.author.company.logo}
                                                                                                                            alt='logo'/>
                                                                                                                        
                                                                                                                    :   <div className="noAvatarProfile">{offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                                                                                }
                                                                                                            </>     
                                                                                                    }
                                                                                                </div>

                                                                                                <div className="vertical w100">
                                                                                                    <div className="horizontal iStart mrgB5" style={{ position: 'relative', display : 'flex'}}>
                                                                                                        <div>
                                                                                                            {offer.urgent && (
                                                                                                                <div className="badge-container">
                                                                                                                    <Badge color="red" style={{ position: 'absolute', left: 0, top: 0}}>
                                                                                                                        Urgent
                                                                                                                    </Badge>
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            {offer.assigned.assigned === true 
                                                                                                                ?   <Badge color="blue" style={{ position: 'absolute', right: 5, top: 0}}>
                                                                                                                        {offer.assigned.assignedTo.company.name}
                                                                                                                    </Badge>
                                                                                                                :   ""
                                                                                                            }
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            {offer.receivedQuotations.length > 0 ? (
                                                                                                                <div className="badge-container">
                                                                                                                    {offer.receivedQuotations.map((quotation, index) => {
                                                                                                                        const isNewQuotation = quotation.offer._id === offer._id && !quotation.viewedBySyndic;
                                                                                                                        return (
                                                                                                                            <div key={index} >
                                                                                                                                {isNewQuotation && (
                                                                                                                                <Badge color="green" style={{ position: 'absolute', top: 0, right: "10px"}}>
                                                                                                                                    NEW
                                                                                                                                </Badge>
                                                                                                                                )}
                                                                                                                            </div>
                                                                                                                        );
                                                                                                                })}
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                            ""
                                                                                                            )}
                                                                                                        </div> 
                                                                                                    </div>
                                                                                                    <div className="text-following-badges">

                                                                                                        <div>
                                                                                                            <h2 className="txt15 lineH15 noMrgT mrgB10 cap mrgR5">{offer.title}</h2>
                                                                                                        </div>
                                                                                                        <div className="horizontal iCenter lineH13 mrgB5">
                                                                                                            <IconHammer width={16} height={13} className="mrgR5"/>
                                                                                                            <p className="noMrg txt13 jumpline finalDotsMed">{offer.occupation}</p>
                                                                                                        </div>
                                                                                                        <div className="horizontal iCenter">
                                                                                                            <IconLocation width={13} height={13} className="mrgR5"/>

                                                                                                            <p className="noMrg txt13 finalDotsMed">{offer.cityOfIntervention}</p>
                                                                                                        </div>
                                                                                                    </div>    
                                                                                                
                                                                                                    
                                                                                                    
                                                                                                        
                                                                                                </div>
                                                                                            </div>
                                                                                        </Box>
                                                                                    </Link>
                                                                            }
                                                                        </>  
                                                                    :   ""
                                                                }
                                                            </div>
                                                        ))}
                                                    </>
                                            }   
                                        </>
                                    :   <>  
                                            {listOffer.length <= 0 
                                                ?   <p className='dGreyT txtCenter txt15'>Vous n'avez pas d'offre publiée</p>
                                                :   <>
                                                        {listOffer.map((offer, index) => (
                                                            <div key = {index} style = {{margin : '10px'}}>
                                                                {offer.deadlineToReceiveQuotationDate < today
                                                                    ?   <Link to={`/s/offers/offer/${offer._id}`} className="aReset bT">               
                                                                        <Box>
                                                                            <div className="horizontal iCenter">
                                                                                <div className="vertical mrgL10 mrgR10">                        
                                                                                    <p className="txt13 noMrgTB mrgR5 mrgB5 txtCenter">
                                                                                        {format(parseISO(offer.createdAt), 'dd/MM/yy')}
                                                                                    </p>
                                                                                    
                                                                                    {offer.pictures.length > 0 
                                                                                        ?   <div>
                                                                                                <img 
                                                                                                    className="roundImg70 noMrgTB"
                                                                                                    src={offer.pictures[0]}
                                                                                                    alt='photo_chantier'/>
                                                                                            </div>
                                                                                        :   <>
                                                                                                {offer.author.company.logo
                                                                                                    ?   <img    
                                                                                                            className="roundImg70 noMrgTB"
                                                                                                            src={offer.author.company.logo}
                                                                                                            alt='logo'/>
                                                                                                        
                                                                                                    :   <div className="noAvatarProfile">{offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                                                                }
                                                                                            </>     
                                                                                    }
                                                                                </div>

                                                                                <div className="vertical w100">
                                                                                    <div className="horizontal iCenter mrgB5">
                                                                                        <div >
                                                                                            {offer.urgent 
                                                                                                ?   <Badge color="dark">Urgent</Badge>
                                                                                                :   ""
                                                                                            }
                                                                                        </div>
                                                                                        <div className='mrgLauto mrgR10'>
                                                                                            {offer.assigned.assigned === true
                                                                                                ?   <Badge color="dark">
                                                                                                        {offer.assigned.assignedTo.company.name}
                                                                                                    </Badge>
                                                                                                :   ""
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <h2 className="txt15 lineH15 noMrgT mrgB10 cap dGreyT mrgR5">
                                                                                            {offer.title}
                                                                                        </h2>
                                                                                    </div>
                                                                                    <div className="horizontal iCenter lineH13 mrgB5">
                                                                                        <IconHammer width={16} height={13} className="dGreyT mrgR5"/>
                                                                                        <p className="noMrg txt13 jumpline dGreyT finalDotsMed">{offer.occupation}</p>
                                                                                    </div>
                                                                                    <div className="horizontal iCenter">
                                                                                        <IconLocation width={13} height={13} className="dGreyT mrgR5"/>

                                                                                        <p className="noMrg txt13 dGreyT finalDotsMed">{offer.cityOfIntervention}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Box>
                                                                        </Link>
                                                                    :   <Link to={`/s/offers/offer/${offer._id}`} className="aReset bT">               
                                                                            <Box>
                                                                                <div className="horizontal iCenter">
                                                                                    <div className="vertical mrgL10 mrgR10">                        
                                                                                        <p className="txt13 noMrgTB mrgR5 mrgB5 txtCenter">
                                                                                            {format(parseISO(offer.createdAt), 'dd/MM/yy')}
                                                                                        </p>
                                                                                        
                                                                                        {offer.pictures.length > 0 
                                                                                            ?   <div>
                                                                                                    <img 
                                                                                                        className="roundImg70 noMrgTB"
                                                                                                        src={offer.pictures[0]}
                                                                                                        alt='photo_chantier'/>
                                                                                                </div>
                                                                                            :   <>
                                                                                                    {offer.author.company.logo
                                                                                                        ?   <img    
                                                                                                                className="roundImg70 noMrgTB"
                                                                                                                src={offer.author.company.logo}
                                                                                                                alt='logo'/>
                                                                                                            
                                                                                                        :   <div className="noAvatarProfile">{offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                                                                    }
                                                                                                </>     
                                                                                        }                                                                      
                                                                                    </div>
                                                                                    <div className="vertical w100">
                                                                                        <div className="horizontal iStart mrgB5" style={{ position: 'relative', display : 'flex'}}>
                                                                                            <div>
                                                                                                {offer.urgent && (
                                                                                                    <div className="badge-container">
                                                                                                    <Badge color="red" style={{ position: 'absolute', left: 0, top: 0}}>
                                                                                                        Urgent
                                                                                                    </Badge>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                            <div>
                                                                                                {offer.assigned.assigned === true 
                                                                                                    ?   <Badge color="blue" style={{ position: 'absolute', right: 5, top: 0}}>
                                                                                                            {offer.assigned.assignedTo.company.name}
                                                                                                        </Badge>
                                                                                                    :   ""
                                                                                                }
                                                                                            </div>
                                                                                            <div>
                                                                                                {offer.receivedQuotations.length > 0 && (
                                                                                                    <div className="badge-container">
                                                                                                        {offer.receivedQuotations.map((quotation, index) => {
                                                                                                            const isNewQuotation = quotation.offer._id === offer._id && !quotation.viewedBySyndic;
                                                                                                            return (
                                                                                                            <div key={index} >
                                                                                                                {isNewQuotation && (
                                                                                                                <Badge color="green" style={{ position: 'absolute', top: 0, right: "10px"}}>
                                                                                                                    NEW
                                                                                                                </Badge>
                                                                                                                )}
                                                                                                            </div>
                                                                                                            );
                                                                                                        })}
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                        
                                                                                        <div className="text-following-badges">
                                                                                            <div>
                                                                                                <h2 className="txt15 lineH15 noMrgT mrgB10 cap mrgR5">{offer.title}</h2>
                                                                                            </div>
                                                                                            <div className="horizontal iCenter lineH13 mrgB5">
                                                                                                <IconHammer width={16} height={13} className="mrgR5"/>
                                                                                                <p className="noMrg txt13 jumpline finalDotsMed">{offer.occupation}</p>
                                                                                            </div>
                                                                                            <div className="horizontal iCenter">
                                                                                                <IconLocation width={13} height={13} className="mrgR5"/>

                                                                                                <p className="noMrg txt13 finalDotsMed">{offer.cityOfIntervention}</p>
                                                                                            </div>  
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Box>
                                                                        </Link>
                                                                }
                                                            </div>
                                                        ))}
                                                    </> 
                                            }
                                        </>
                                }
                        </div>    
                    </>
                ) : (
                    <>
                        <AppNavbarSyndic/>
                        <div>
                            <WorkOffersHd setUrgentList={setUrgentList}/>
                        </div>
                        
                        <div className="mrgT20 mrgB100">
                                {urgentList 
                                    ?   <>
                                            {listOffer.length <= 0
                                                ?   <div>
                                                        <p className='dGreyT txtCenter txt18'>Vous n'avez pas d'offre urgente</p>
                                                        
                                                    </div>
                                                :   <>
                                                        {listOffer.map((offer, index) => (
                                                            <div key = {index} style = {{margin : '20px'}}>
                                                                {offer.urgent 
                                                                    ?   <>
                                                                            {offer.deadlineToReceiveQuotationDate < today
                                                                                ?   <div className="width50pCenter horizontal iStart bLiteGrey">
                                                                                        <div className="horizontal iCenter">
                                                                                            <div className="vertical jCenter mrgL30 mrgB20">                        
                                                                                                <p className="txt16 mrgT20 mrgB10 txtCenter dGreyT">
                                                                                                    {format(parseISO(offer.createdAt), 'dd/MM/yy')}
                                                                                                </p>
                                                                                                
                                                                                                {offer.pictures.length > 0 
                                                                                                    ?   <div>
                                                                                                            <img 
                                                                                                                className="roundImg100 noMrgTB"
                                                                                                                src={offer.pictures[0]}
                                                                                                                alt='photo_chantier'/>
                                                                                                        </div>
                                                                                                    :   <>
                                                                                                            {offer.author.company.logo
                                                                                                                ?   
                                                                                                                    <img    
                                                                                                                        className="roundImg100 noMrgTB"
                                                                                                                        src={offer.author.company.logo}
                                                                                                                        alt='logo'/>

                                                                                                                :   <div className="noAvatarProfile">{offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                                                                            }
                                                                                                        </>     
                                                                                                }
                                                                                            </div>

                                                                                            <div className="vertical mrgL30 mrgR30">
                                                                                                <div className="horizontal iStart">
                                                                                                    <div className='mrgB10 mrgR5'>
                                                                                                        <div className="badge-container">    
                                                                                                            {offer.urgent 
                                                                                                                ?   <Badge color="dark" size="lg">Urgent</Badge>
                                                                                                                :   ""
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        {offer.assigned.assigned === true
                                                                                                            ?   <Badge color="dark" size="lg">
                                                                                                                    {offer.assigned.assignedTo.company.name}                                                                                             
                                                                                                                </Badge>
                                                                                                            :   ""
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                
                                                                                                <div className="text-following-badges">
                                                                                                    <div>
                                                                                                        <div>
                                                                                                            <h2 className="txt18 lineH20 noMrgT mrgB10 cap dGreyT mrgR5">{offer.title}</h2>
                                                                                                        </div>
                                                                                                        <div className="horizontal iCenter lineH15 mrgB5">
                                                                                                            <IconHammer width={25} height={20} className="dGreyT mrgR10"/>
                                                                                                            <p className="noMrg txt18 jumpline dGreyT">{offer.occupation}</p>
                                                                                                        </div>
                                                                                                        <div className="horizontal iCenter">
                                                                                                            <IconLocation width={25} height={20} className="dGreyT mrgR10"/>
                                                                                                            <p className="noMrg txt18 dGreyT">{offer.cityOfIntervention}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='vertical mrgT20 mrgR20 mrgLauto'>
                                                                                            <div className='horizontal'>
                                                                                                <Link to={`/s/offers/offer/${offer._id}`} className="aReset bT">
                                                                                                    <IconEye className='mrgR10' width={20}/>
                                                                                                </Link>
                                                                                                <Link to={`/s/offers/offer-rdv&quo/${offer._id}`} className="aReset bT">
                                                                                                    <IconBoxMultiple width={20}/>
                                                                                                </Link>
                                                                                            </div>
                                                                                        </div>
                                                                                        
                                                                                    </div>
                                                                                :   <div className='width50pCenter horizontal iStart bLiteGrey'>
                                                                                        <div className="horizontal iCenter">
                                                                                            <div className="vertical jCenter mrgL30 mrgB20">                        
                                                                                                <p className="txt16 mrgT20 mrgB10 txtCenter">
                                                                                                    {format(parseISO(offer.createdAt), 'dd/MM/yy')}
                                                                                                </p>
                                                                                                
                                                                                                {offer.pictures.length > 0 
                                                                                                    ?   <div>
                                                                                                            <img 
                                                                                                                className="roundImg100 noMrgTB"
                                                                                                                src={offer.pictures[0]}
                                                                                                                alt='photo_chantier'/>
                                                                                                        </div>
                                                                                                    :   <>
                                                                                                            {offer.author.company.logo
                                                                                                                ?   <img    
                                                                                                                        className="roundImg100 noMrgTB"
                                                                                                                        src={offer.author.company.logo}
                                                                                                                        alt='logo'/>
                                                                                                                    
                                                                                                                :   <div className="noAvatarProfile">{offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                                                                            }
                                                                                                        </>     
                                                                                                }
                                                                                            </div>

                                                                                            <div className="vertical mrgL30 mrgR30 w100">
                                                                                                <div className="horizontal iStart">
                                                                                                    <div className='mrgB10 mrgR5'>
                                                                                                        {offer.urgent && (
                                                                                                            <div className="badge-container">
                                                                                                                <Badge color="red" size="lg" >
                                                                                                                    Urgent
                                                                                                                </Badge>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        {offer.assigned.assigned === true 
                                                                                                            ?   <Badge color="blue" size="lg">
                                                                                                                    {offer.assigned.assignedTo.company.name}
                                                                                                                </Badge>
                                                                                                            :   ""
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        {offer.receivedQuotations.length > 0 ? (
                                                                                                            <div className="badge-container">
                                                                                                                {offer.receivedQuotations.map((quotation, index) => {
                                                                                                                const isNewQuotation = quotation.offer._id === offer._id && !quotation.viewedBySyndic;
                                                                                                                return (
                                                                                                                    <div key={index}>
                                                                                                                        {isNewQuotation && (
                                                                                                                            <Badge color="green" size="lg" style={{ position: 'absolute'}}>
                                                                                                                                NEW
                                                                                                                            </Badge>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                    );
                                                                                                                })}
                                                                                                            </div>
                                                                                                        ) : (
                                                                                                        ""
                                                                                                        )}
                                                                                                    </div>

                                                                                                </div>

                                                                                                <div className="text-following-badges">
                                                                                                    <div>
                                                                                                        <div>
                                                                                                            <h2 className="txt18 lineH20 noMrgT mrgB10 cap mrgR5">{offer.title}</h2>
                                                                                                        </div>
                                                                                                        <div className="horizontal iCenter lineH15 mrgB5">
                                                                                                            <IconHammer width={25} height={20} className="mrgR10"/>
                                                                                                            <p className="noMrg txt18 jumpline">{offer.occupation}</p>
                                                                                                        </div>
                                                                                                        <div className="horizontal iCenter">
                                                                                                            <IconLocation width={25} height={20} className="mrgR10"/>
                                                                                                            <p className="noMrg txt18">{offer.cityOfIntervention}</p>
                                                                                                        </div>
                                                                                                    </div>        
                                                                                                </div>    
                                                                                            </div>
                                                                                        </div>
                                                                                            <div className='vertical mrgT20 mrgR20 mrgLauto'>
                                                                                                <div className='horizontal'>
                                                                                                    <Link to={`/s/offers/offer/${offer._id}`} className="aReset bT">
                                                                                                        <IconEye className='mrgR10' width={20}/>
                                                                                                    </Link>
                                                                                                    <Link to={`/s/offers/offer-rdv&quo/${offer._id}`} className="aReset bT">
                                                                                                        <IconBoxMultiple width={20}/>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </div>
                                                                                    </div>
                                                                                    
                                                                            }
                                                                        </>  
                                                                    :   ""
                                                                }
                                                            </div>
                                                        ))}
                                                    </>
                                            }   
                                        </>
                                    :   <>  
                                            {listOffer.length <= 0 
                                                ?   <div className="width50pCenter vertical">
                                                        <div className='emptyListNotification'>
                                                            <div className= 'horizontal iCenter mrgT10'>
                                                                <IconMessage width={100} height={45}/>
                                                                <p className='txt20 bold'>
                                                                    Vous n'avez pas d'offre publiée
                                                                </p>
                                                            </div>
                                                         
                                                        <div className='horizontal iCenter'>
                                                            <IconMessage width={100} height={45} color='F8F8F8'/>
                                                            <Link to="/s/offers/add" className='aReset'><p className='txt18 terracotaT mrgT10 mrgB30 mrgR50'>Créer une offre</p></Link>
                                                            <Link to="/s/favPresta/fav-list" className='aReset'><p className='txt18 terracotaT mrgT10 mrgB30'>Créer et envoyer une offre à un prestataire favori</p></Link>
                                                        </div> 
                                                        </div>                                                        
                                                    </div>
                                                :   <>
                                                        {listOffer.map((offer, index) => (
                                                            <div key = {index} style = {{margin : '20px'}}>
                                                                {offer.deadlineToReceiveQuotationDate < today
                                                                    ?   <div className="width50pCenter horizontal iStart bLiteGrey">
                                                                                <div className="horizontal iCenter">
                                                                                    <div className="vertical jCenter mrgL30 mrgB20">                        
                                                                                        <p className="txt16 mrgT20 mrgB10 txtCenter dGreyT">
                                                                                            {format(parseISO(offer.createdAt), 'dd/MM/yy')}
                                                                                        </p>
                                                                                        
                                                                                        {offer.pictures.length > 0 
                                                                                            ?   <div>
                                                                                                    <img 
                                                                                                        className="roundImg100 noMrgTB"
                                                                                                        src={offer.pictures[0]}
                                                                                                        alt='photo_chantier'/>
                                                                                                </div>
                                                                                            :   <>
                                                                                                    {offer.author.company.logo
                                                                                                        ?   
                                                                                                            <img    
                                                                                                                className="roundImg100 noMrgTB"
                                                                                                                src={offer.author.company.logo}
                                                                                                                alt='logo'/>

                                                                                                        :   <div className="noAvatarProfile">{offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                                                                    }
                                                                                                </>     
                                                                                        }
                                                                                    </div>

                                                                                    <div className="vertical mrgL30 mrgR30">
                                                                                        <div className="horizontal iStart">
                                                                                            <div className='mrgB10 mrgR5'>
                                                                                                <div className="badge-container">    
                                                                                                    {offer.urgent 
                                                                                                        ?   <Badge color="dark" size="lg">Urgent</Badge>
                                                                                                        :   ""
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                {offer.assigned.assigned === true
                                                                                                    ?   <Badge color="dark" size="lg">
                                                                                                            {offer.assigned.assignedTo.company.name}
                                                                                                        </Badge>
                                                                                                    :   ""
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        
                                                                                        <div className="text-following-badges">
                                                                                            <div>
                                                                                                <div>
                                                                                                    <h2 className="txt18 lineH20 noMrgT mrgB10 cap dGreyT mrgR5">{offer.title}</h2>
                                                                                                </div>
                                                                                                <div className="horizontal iCenter lineH15 mrgB5">
                                                                                                    <IconHammer width={25} height={20} className="dGreyT mrgR10"/>
                                                                                                    <p className="noMrg txt18 jumpline dGreyT">{offer.occupation}</p>
                                                                                                </div>
                                                                                                <div className="horizontal iCenter">
                                                                                                    <IconLocation width={25} height={20} className="dGreyT mrgR10"/>
                                                                                                    <p className="noMrg txt18 dGreyT">{offer.cityOfIntervention}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='vertical mrgT20 mrgR20 mrgLauto'>
                                                                                    <div className='horizontal'>
                                                                                        <Link to={`/s/offers/offer/${offer._id}`} className="aReset bT">
                                                                                            <IconEye className='mrgR10' width={20}/>
                                                                                        </Link>
                                                                                        <Link to={`/s/offers/offer-rdv&quo/${offer._id}`} className="aReset bT">
                                                                                            <IconBoxMultiple width={20}/>
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                        </div>
                                                                    :    <div className='width50pCenter horizontal iStart bLiteGrey'>
                                                                                <div className="horizontal iCenter">
                                                                                    <div className="vertical jCenter mrgL30 mrgB20">                        
                                                                                        <p className="txt16 mrgT20 mrgB10 txtCenter">
                                                                                            {format(parseISO(offer.createdAt), 'dd/MM/yy')}
                                                                                        </p>
                                                                                        
                                                                                        {offer.pictures.length > 0 
                                                                                            ?   <div>
                                                                                                    <img 
                                                                                                        className="roundImg100 noMrgTB"
                                                                                                        src={offer.pictures[0]}
                                                                                                        alt='photo_chantier'/>
                                                                                                </div>
                                                                                            :   <>
                                                                                                    {offer.author.company.logo
                                                                                                        ?   <img    
                                                                                                                className="roundImg100 noMrgTB"
                                                                                                                src={offer.author.company.logo}
                                                                                                                alt='logo'/>
                                                                                                            
                                                                                                        :   <div className="noAvatarProfile">{offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                                                                    }
                                                                                                </>     
                                                                                        }
                                                                                    </div>

                                                                                    <div className="vertical mrgL30 mrgR30">
                                                                                        <div className="horizontal iStart">
                                                                                            <div className='mrgB10 mrgR5'>
                                                                                                {offer.urgent && (
                                                                                                    <div className="badge-container">
                                                                                                        <Badge color="red" size="lg" >
                                                                                                            Urgent
                                                                                                        </Badge>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                            <div>
                                                                                                {offer.assigned.assigned === true 
                                                                                                    ?   <Badge color="blue" size="lg">
                                                                                                            {offer.assigned.assignedTo.company.name}
                                                                                                        </Badge>
                                                                                                    :   ""
                                                                                                }
                                                                                            </div>
                                                                                            <div>
                                                                                                {offer.receivedQuotations.length > 0 ? (
                                                                                                    <div className="badge-container">
                                                                                                        {offer.receivedQuotations.map((quotation, index) => {
                                                                                                        const isNewQuotation = quotation.offer._id === offer._id && !quotation.viewedBySyndic;
                                                                                                        return (
                                                                                                            <div key={index}>
                                                                                                                {isNewQuotation && (
                                                                                                                    <Badge color="green" size="lg" style={{ position: 'absolute'}}>
                                                                                                                        NEW
                                                                                                                    </Badge>
                                                                                                                )}
                                                                                                            </div>
                                                                                                            );
                                                                                                        })}
                                                                                                    </div>
                                                                                                ) : (
                                                                                                ""
                                                                                                )}
                                                                                            </div>

                                                                                        </div>

                                                                                        <div className="text-following-badges">
                                                                                            <div>
                                                                                                <div>
                                                                                                    <h2 className="txt18 lineH20 noMrgT mrgB10 cap mrgR5">{offer.title}</h2>
                                                                                                </div>
                                                                                                <div className="horizontal iCenter lineH15 mrgB5">
                                                                                                    <IconHammer width={25} height={20} className="mrgR10"/>
                                                                                                    <p className="noMrg txt18 jumpline">{offer.occupation}</p>
                                                                                                </div>
                                                                                                <div className="horizontal iCenter">
                                                                                                    <IconLocation width={25} height={20} className="mrgR10"/>
                                                                                                    <p className="noMrg txt18">{offer.cityOfIntervention}</p>
                                                                                                </div>
                                                                                            </div>        
                                                                                        </div>    
                                                                                    </div>
                                                                                </div>
                                                                                <div className='vertical mrgT20 mrgR20 mrgLauto'>
                                                                                    <div className='horizontal'>
                                                                                        <Link to={`/s/offers/offer/${offer._id}`} className="aReset bT">
                                                                                            <IconEye className='mrgR10' width={20}/>
                                                                                        </Link>
                                                                                        <Link to={`/s/offers/offer-rdv&quo/${offer._id}`} className="aReset bT">
                                                                                            <IconBoxMultiple width={20}/>
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                        </div>
                                                                }
                                                            </div>
                                                        ))}
                                                    </> 
                                            }
                                        </>
                                }
                        </div> 
                    </>
                )}
            </> 
        : 
            <Loader />
    ) 
}

export default SyndicOffresDeTravaux;

