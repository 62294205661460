import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IconPlus } from '@tabler/icons-react';
import classes from './WorkOffersHd.module.css';

const WorkOffersHd = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [clicked, setClick] = useState(false);
    const [urgentActive, setUrgentActive] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };      
          window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleClick = () => {
        setClick(!clicked);
        setUrgentActive(!urgentActive);
        props.setUrgentList(true)        
        if(clicked) {
            props.setUrgentList(false)
        }
    }
    return(
        <>
        {isMobile ? (
            <>
                <div className={classes.appHeading}>
                        <div className={classes.navback}>
                            <button className={classes.btnReset}
                                    clicked={clicked}
                                    style={{ color: urgentActive ? "red" : "black",
                                            fontWeight: urgentActive ? 'bold' : ""}}
                                    onClick={handleClick}>
                                Urgent
                            </button>
                        </div>
                        <div className={classes.navtitle}>
                            <h1>Offres de travaux</h1>
                        </div>
                        <div className={classes.navrightside}>
                            <button className={classes.btnReset}>
                                <Link to="/s/offers/add" className="aReset bT">
                                <IconPlus/>
                                </Link></button>
                    </div>
                </div>
            </>
        ) : (
            <>
                <div className={classes.appHeading}>
                        <div className={classes.navback}>
                            <button className={classes.btnReset}
                                    clicked={clicked}
                                    style={{ color: urgentActive ? "red" : "black",
                                            fontWeight: urgentActive ? 'bold' : ""}}
                                    onClick={handleClick}>
                                <p className='txt16 mediumplus'>Urgent</p>
                            </button>
                        </div>
                        <div className={classes.navtitle}>
                            <h1>Offres de travaux</h1>
                        </div>
                        <div className={classes.navrightside}>
                            <button className={classes.btnReset}>
                                <Link to="/s/offers/add" className="aReset bT">
                                    <IconPlus width={30} height={30}/>
                                </Link></button>
                    </div>
                </div>
            </>
        )}
        </>
    )
}

export default WorkOffersHd;