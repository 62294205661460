import { React, useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../../../../contexts/authContext';
import { useNavigate } from 'react-router-dom';

import { useDisclosure } from '@mantine/hooks';
import { Avatar, FileButton, Button, Group, Select, TextInput, Modal, MultiSelect, Loader } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconPencil } from '@tabler/icons-react';

import AppHdWithLeftReturn from '../../../../../composents/Headers/AppHeaders/AppHdWithReturnLeft/AppHdWithReturnLeft';
import PrestaRectangleButtonMain from '../../../../../composents/Buttons/Presta/RectangleButtonMain/RectangleButtonMain';
import AppDesktopLogo from '../../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

const ModifyMyPrestaAccount = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [ userData, setUserData ] = useState()
    const [ opened, { open, close }] = useDisclosure(false);
    const [ avatar, setAvatar] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const token = getToken();
            if (!token) {
                navigate("/p/login");
                return;
            }

            try {
                await getUser();
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const form = useForm({
        initialValues: {
            avatar: '',
            title: '',
            firstname: '',
            lastname: '',
            occupation: '',
            phone: ''
        },

        validate: {
            lastname: (value) => (
                value.length < 1
                    ? 'Renseignez votre nom'
                    : value.length > 30
                        ? '30 caractères maximum'
                        : null
            ),
            firstname: (value) => (
                value.length < 1
                    ? 'Renseignez votre prénom'
                    : value.length > 30
                    ? '30 caractères maximum'
                    : null
            ),
            occupation: (value) => (
                value.length < 1
                    ? 'Renseignez votre métier'
                    : null
            ),
            phone: (value) => (value.length < 10 || value.length > 10 ? 'Le numéro de téléphone doit comporter 10 chiffres' : null)
        }
    })

    const getUser = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch('https://boldee-97d8d36af3bd.herokuapp.com/p/getUser', {
                method: "GET",    
                headers: {
                    Authorization: `Bearer ${token}`
                    }
        });
            const data = await response.json()
            setUserData(data)
            setAvatar(data.avatar)
            form.setValues({
                title: data.title,
                firstname: data.firstname,
                lastname: data.lastname,
                occupation: data.occupation,
                phone: data.phone
              });
        }   catch (error) {
        }
    }

    const handleFormChange = (event) => {
        const { name, value } = event.currentTarget;
        form.setFieldValue( name, value );
    };

    const handleFormSubmit = async (values, _event) => {
        _event.preventDefault();
        try {
            const token = localStorage.getItem('token');
            const response = await fetch("https://boldee-97d8d36af3bd.herokuapp.com/p/profile/edit", {
                method: "POST",
                body: JSON.stringify({
                    avatar: avatar || null,
                    title: values.title,
                    firstname: values.firstname,
                    lastname: values.lastname,
                    occupation: values.occupation,
                    phone: values.phone,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            const data = await response.json();
            setUserData(data);
            sessionStorage.setItem("user", JSON.stringify(data));
            open();
            setTimeout(() => {
                window.location.reload();
            }, 700);
        } catch (error) {
        }
    }

    const fileBrowseHandler = (event) => {
        let reader = new FileReader()
        reader.readAsDataURL(event)
        reader.onload = () => {
            setAvatar(reader.result)
            reader.onerror = function (error) {
            }
        };
             
    }
    return (
        userData ?
        <>
            {isMobile ? (
                <>
                    <form onSubmit={form.onSubmit(handleFormSubmit)}>
                        <AppHdWithLeftReturn>Modifier mon profil</AppHdWithLeftReturn>

                        <>
                            <div className="vertical iCenter">
                                {userData.avatar || avatar
                                    ? <>{<Avatar src={avatar || userData.avatar} alt="avatar" radius={50} size={100} />}</>
                                    : <div className="noAvatarProfile">{userData.firstname.charAt(0)}{userData.lastname.charAt(0)}</div>
                                }

                                <Group position="center">
                                    <FileButton leftIcon={<IconPencil size="1rem" />} className='mrgT5 useRoboto medium txt13 bT' variant="subtle" onChange={fileBrowseHandler} accept="image/png,image/jpeg,image/jpg">
                                        {(props) => <Button {...props}>Modifier mon avatar</Button>}
                                    </FileButton>
                                </Group>
                            </div>

                            <div className="width90pCenter">
                                <Select
                                    label="Titre"
                                    placeholder={userData.title}
                                    data={[
                                        { value: 'Mme', label: 'Madame' },
                                        { value: 'M.', label: 'Monsieur' },
                                    ]}
                                    onChange={handleFormChange}
                                    {...form.getInputProps('title')}
                                />

                                <div className="mrgB5"></div>

                                <TextInput
                                    label="Prénom"
                                    placeholder={userData.firstname}
                                    onChange={handleFormChange}
                                    {...form.getInputProps('firstname')}
                                />

                                <div className="mrgB5"></div>

                                <TextInput
                                    label="Nom"
                                    placeholder={userData.lastname}
                                    onChange={handleFormChange}
                                    {...form.getInputProps('lastname')}
                                />

                                <div className="mrgB5"></div>

                                <MultiSelect
                                    label="Métiers"
                                    data={[
                                        { value: 'Entreprise de travaux', label: 'Entreprise de travaux' },
                                        { value: 'Electricien', label: 'Electricien' },
                                        { value: 'Menuisier', label: 'Menuisier' },
                                        { value: 'Plombier', label: 'Plombier' },
                                        { value: 'Entreprise de nettoyage, Gardien, Concierge', label: 'Entreprise de nettoyage, Gardien, Concierge' },
                                        { value: 'Jardinier- Paysagiste', label: 'Jardinier- Paysagiste' },
                                        { value: 'Entreprise de maintenance et de dépannage', label: 'Entreprise de maintenance et de dépannage' },
                                        { value: 'Chauffagiste', label: 'Chauffagiste' },
                                        { value: 'Désinfection, Désinsectisation, Dératisation', label: 'Désinfection, Désinsectisation, Dératisation' },
                                        { value: 'Architecte, Bureaux d\'études', label: 'Architecte, Bureaux d\'études' },
                                        { value: 'Assureur', label: 'Assureur' },
                                        { value: 'Avocat spécialisé en droit immobilier', label: 'Avocat spécialisé en droit immobilier' },
                                        { value: 'Autre', label: 'Autre' }
                                    ]}
                                    clearable
                                    onChange={handleFormChange}
                                    {...form.getInputProps('occupation')}
                                />

                                <div className="mrgB5"></div>

                                <TextInput
                                    label="N° de téléphone"
                                    placeholder={userData.phone}
                                    onChange={handleFormChange}
                                    {...form.getInputProps('phone')}
                                />
                            </div>

                            <div className="mrgB20"></div>

                            <div className="width90pCenter">
                            
                                <PrestaRectangleButtonMain type="submit">
                                    <p id="prestaChangesProfilInfoga4" className='noMrgTB'>Enregistrer</p>
                                </PrestaRectangleButtonMain>

                                {open
                                    ? <Modal
                                        centered
                                        size="50%"
                                        opened={opened}
                                        onClose={close}
                                        withCloseButton={false}
                                        className='useRoboto'>
                                        <p className="txtCenter noMrgTB txt15">
                                            Modifications sauvegardées
                                        </p>
                                    </Modal>
                                    : ""
                                }
                            </div>

                            <div className="mrgB30"></div>
                        </>
                    </form>
                </>
            ) :(
                <>
                    <AppDesktopLogo/>
                    <form onSubmit={form.onSubmit(handleFormSubmit)}>
                        <AppHdWithLeftReturn>Modifier mon profil</AppHdWithLeftReturn>

                        <>
                            <div className="vertical iCenter">
                                {userData.avatar || avatar
                                    ? <>{<Avatar src={avatar || userData.avatar} alt="avatar" radius={50} size={100} />}</>
                                    : <div className="noAvatarProfile">{userData.firstname.charAt(0)}{userData.lastname.charAt(0)}</div>
                                }

                                <Group position="center">
                                    <FileButton leftIcon={<IconPencil width={20} />} className='mrgT5 useRoboto medium bT' variant="subtle" onChange={fileBrowseHandler} accept="image/png,image/jpeg,image/jpg">
                                        {(props) => <Button {...props}><p className='txt18'>Modifier mon avatar</p></Button>}
                                    </FileButton>
                                </Group>
                            </div>

                            <div className="width50pCenter">
                                <Select
                                    label="Titre"
                                    placeholder={userData.title}
                                    data={[
                                        { value: 'Mme', label: 'Madame' },
                                        { value: 'M.', label: 'Monsieur' },
                                    ]}
                                    onChange={handleFormChange}
                                    size='md'
                                    {...form.getInputProps('title')}
                                />

                                <div className="mrgB10"></div>

                                <TextInput
                                    label="Prénom"
                                    placeholder={userData.firstname}
                                    onChange={handleFormChange}
                                    size='md'
                                    {...form.getInputProps('firstname')}
                                />

                                <div className="mrgB5"></div>

                                <TextInput
                                    label="Nom"
                                    placeholder={userData.lastname}
                                    onChange={handleFormChange}
                                    size='md'
                                    {...form.getInputProps('lastname')}
                                />

                                <div className="mrgB10"></div>

                                <MultiSelect
                                    label="Métiers"
                                    data={[
                                        { value: 'Entreprise de travaux', label: 'Entreprise de travaux' },
                                        { value: 'Electricien', label: 'Electricien' },
                                        { value: 'Menuisier', label: 'Menuisier' },
                                        { value: 'Plombier', label: 'Plombier' },
                                        { value: 'Entreprise de nettoyage, Gardien, Concierge', label: 'Entreprise de nettoyage, Gardien, Concierge' },
                                        { value: 'Jardinier- Paysagiste', label: 'Jardinier- Paysagiste' },
                                        { value: 'Entreprise de maintenance et de dépannage', label: 'Entreprise de maintenance et de dépannage' },
                                        { value: 'Chauffagiste', label: 'Chauffagiste' },
                                        { value: 'Désinfection, Désinsectisation, Dératisation', label: 'Désinfection, Désinsectisation, Dératisation' },
                                        { value: 'Architecte, Bureaux d\'études', label: 'Architecte, Bureaux d\'études' },
                                        { value: 'Assureur', label: 'Assureur' },
                                        { value: 'Avocat spécialisé en droit immobilier', label: 'Avocat spécialisé en droit immobilier' },
                                        { value: 'Autre', label: 'Autre' }
                                    ]}
                                    clearable
                                    onChange={handleFormChange}
                                    size='md'
                                    {...form.getInputProps('occupation')}
                                />

                                <div className="mrgB10"></div>

                                <TextInput
                                    label="N° de téléphone"
                                    placeholder={userData.phone}
                                    onChange={handleFormChange}
                                    size='md'
                                    {...form.getInputProps('phone')}
                                />
                            </div>

                            <div className="mrgB30"></div>

                            <div className="width50pCenter">
                                <PrestaRectangleButtonMain type="submit">
                                    <p id="prestaChangesProfilInfoga4" className='txt16 noMrgTB'>Enregistrer</p>
                                </PrestaRectangleButtonMain>

                                {open
                                    ? <Modal
                                        centered
                                        size="auto"
                                        opened={opened}
                                        onClose={close}
                                        withCloseButton={false}>
                                        <p className="txtCenter noMrgTB txt16 useRoboto">
                                            Modifications sauvegardées
                                        </p>
                                    </Modal>
                                    : ""
                                }
                            </div>

                            <div className="mrgB100"></div>
                        </>
                    </form>
                </>
            )}
        </>
        :
        <Loader />
    )
}

export default ModifyMyPrestaAccount;

