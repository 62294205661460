import { React, useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../../../../contexts/authContext';
import { Link, useNavigate } from "react-router-dom";

import { Loader } from '@mantine/core';
import { parseISO, formatDistanceToNowStrict } from 'date-fns';
import { fr } from "date-fns/locale";

import AppHdWithCloseRight from '../../../../../composents/Headers/AppHeaders/AppHdWithCloseRight/AppHdWithCloseRight';
import LogoQualibat from '../../../../../composents/Logo/QualificationLogos/Qualibat/LogoQualibat'; 
import LogoArtisansDeConfiance from '../../../../../composents/Logo/QualificationLogos/ArtisanDeConfiance/LogoArtisansDeConfiance'; 
import LogoCertibat from '../../../../../composents/Logo/QualificationLogos/Certibat/LogoCertibat';
import LogoEcoArtisan from '../../../../../composents/Logo/QualificationLogos/EcoArtisan/LogoEcoArtisan';
import LogoEcocert from '../../../../../composents/Logo/QualificationLogos/EcoCert/LogoEcocert';
import LogoHandibat from '../../../../../composents/Logo/QualificationLogos/Handibat/LogoHandibat';
import LogoIso9001 from '../../../../../composents/Logo/QualificationLogos/Iso9001/LogoIso9001';
import LogoQualieau from '../../../../../composents/Logo/QualificationLogos/Qualieau/LogoQualieau';
import LogoQualienr from '../../../../../composents/Logo/QualificationLogos/Qualienr/LogoQualienr';
import LogoQualifelec from '../../../../../composents/Logo/QualificationLogos/Qualifelec/LogoQualifelec';
import LogoQualipropre from '../../../../../composents/Logo/QualificationLogos/Qualipropre/LogoQualipropre';
import LogoRGE from '../../../../../composents/Logo/QualificationLogos/RGE/LogoRGE';
import LogoSilverbat from '../../../../../composents/Logo/QualificationLogos/Silverbat/LogoSilverbat';
import AppDesktopLogo from '../../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';
import Box from '../../../../../composents/Boxes/Standard/Box';

const MyPrestaPage = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [ userData, setUserData ] = useState(null)
    const [creationDate, setCreationDate ] = useState(null)

    const navigate = useNavigate();
    
    const fetchUserData = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch('https://boldee-97d8d36af3bd.herokuapp.com/p/getUser', {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const data = await response.json();
            setUserData(data);

            const date = parseISO(data.company.creationDate, 'dd/mm/yyyy', new Date());
            let result = formatDistanceToNowStrict(
                date, { locale: fr }
            );
            setCreationDate(result);
        } catch (error) {
            return null;
        }
    };

    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/p/login");
            return;
        }
        
        fetchUserData();

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
      
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // useEffect(() => {
    //     if (creationDate) {
    //         console.log('creationDate', creationDate);
    //     }
    // }, [creationDate]);

    return(
        userData || creationDate ?
            <>
                {isMobile ? (
                    <>
                        <AppHdWithCloseRight>{userData.company.name}</AppHdWithCloseRight>

                        <div className="prestapage-container">
                            <div className="prestapage-logo-container">
                                {userData.company.logo
                                    ?   <img src={userData.company.logo} alt='logo'/>
                                    :   <div className="noAvatarProfile">{userData.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                }
                                
                            </div>
                            <hr></hr>
                            
                            
                            <div className="vertical mrgB10 width90pCenter">
                                    <div><h1 className="txt18 mrgT10 mrgB10">{userData.company.name}</h1></div>

                                    <div>
                                        <p className="txt13 noMrgTB bold">{userData.occupation.join(' - ')}</p>
                                    </div>
                                    <div className="vertical">
                                        <div><p className="txt13 noMrgTB">{userData.company.address.no}, {userData.company.address.street}</p> </div>
                                        <div><p className="txt13 noMrgT mrgB5">{userData.company.address.postalcode} {userData.company.address.city}</p></div>
                                        <div><p className="txt13 noMrgTB">{userData.company.emailcie}</p> </div>
                                    </div>
                            </div>

                            <div className="width90pCenter indicateurs bLiteGrey horizontal spaceArd mrgB5">
                                <div className="vertical mrgT10 mrgB10">
                                    <div><h1>{creationDate}</h1></div>
                                    <div><p>d'expérience</p></div>
                                </div>
                                <Link to="/p/profile/favoriteof" className="aReset terracotaT">
                                    <div className="vertical mrgT10 mrgB10">
                                        <div><h1>{(userData.addedToFavBy).length}</h1></div>
                                        <div><p>ajouté en favoris</p></div>
                                    </div>
                                </Link>
                                <div className="vertical mrgT10 mrgB10">
                                    <div><h1>{(userData.recommendedBySyndicOffers).length}</h1></div>
                                    <div><p>recommandation(s)</p></div>
                                </div>
                            </div>

                            <div className=" width90pCenter mrgB30">
                                    <p className="txt13 txtJustify">{userData.company.description}</p>
                            </div>

                            {userData.company.qualifications.length > 0
                                ?
                                    <div className='horizontal width90pCenter iCenter jCenter wraped'>
                                        {userData.company.qualifications.map((certification, index) => (
                                            <div key={index}>
                                                {certification === 'artisandeconfiance'
                                                    ?   <div className='mrgR20'><LogoArtisansDeConfiance/></div>
                                                    :   ''
                                                }
                                                {certification === 'certibat'
                                                    ?   <div className='mrgR20'><LogoCertibat/></div>
                                                    :   ''
                                                }
                                                {certification === 'ecoartisan'
                                                    ?   <div className='mrgR20'><LogoEcoArtisan/></div>
                                                    :   ''
                                                }
                                                {certification === 'ecocert'
                                                    ?   <div className='mrgR20'><LogoEcocert/></div>
                                                    :   ''
                                                }
                                                {certification === 'handibat'
                                                    ?   <div className='mrgR20'><LogoHandibat/></div>
                                                    :   ''
                                                }
                                                {certification === 'iso9001'
                                                    ?   <div className='mrgR20'><LogoIso9001/></div>
                                                    :   ''
                                                }
                                                {certification === 'qualibat'
                                                    ?   <div className='mrgR20'><LogoQualibat/></div>
                                                    :   ''
                                                }
                                                {certification === 'qualieau'
                                                    ?   <div className='mrgR20'><LogoQualieau/></div>
                                                    :   ''
                                                }
                                                {certification === 'qualienr'
                                                    ?   <div className='mrgR20'><LogoQualienr/></div>
                                                    :   ''
                                                }
                                                {certification === 'qualifelec'
                                                    ?   <div className='mrgR20'><LogoQualifelec/></div>
                                                    :   ''
                                                }
                                                {certification === 'qualipropre'
                                                    ?   <div className='mrgR20'><LogoQualipropre/></div>
                                                    :   ''
                                                }
                                                {certification === 'rge'
                                                    ?   <div className='mrgR20'><LogoRGE/></div>
                                                    :   ''
                                                }
                                                {certification === 'silverbat'
                                                    ?   <div className='mrgR20'><LogoSilverbat/></div>
                                                    :   ''
                                                }
                                            </div>
                                        ))}
                                    </div>
                                :   ""
                            }

                            <a href={userData.company.website} target="_blank" rel="noopener noreferrer" className="aReset bT">
                                <div className="txtCenter">
                                    <p className="txt15">{userData.company.website}</p>
                                </div>
                            </a>

                                        

                            <div className="mrgB30"></div>
                        
                        </div>
                    </>
                ) : (
                    <>
                        <AppDesktopLogo/>
                        <AppHdWithCloseRight>
                            <p className='txt20'>{userData.company.name}</p>
                        </AppHdWithCloseRight>
                        <div className="prestapage-container">
                            <div className="prestapage-logo-container">
                                {userData.company.logo
                                    ?   <img src={userData.company.logo} alt='logo'/>
                                    :   <div className="noAvatarProfile">{userData.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                }                                
                            </div>                          
                            
                            <div className="vertical mrgB10">
                                    <div><h1 className="txt25 noMrgT mrgB10">{userData.company.name}</h1></div>

                                    <div>
                                        <p className="txt16 lineH20 mrgT5 mrgB10 medium">{userData.occupation.join(' - ')}</p>
                                    </div>
                                    <div className="vertical mrgT5 mrgB20">
                                        <div><p className="txt16 noMrgTB">{userData.company.address.no}, {userData.company.address.street}</p> </div>
                                        <div><p className="txt16 noMrgT mrgB10">{userData.company.address.postalcode} {userData.company.address.city}</p></div>
                                        <div><p className="txt16 noMrgTB">{userData.company.emailcie}</p> </div>
                                    </div>
                            </div>

                            <Box>
                                <div className="indicateurs horizontal spaceArd mrgB10 mrgT10">
                                    <div className="vertical">
                                        <div><h1>{creationDate}</h1></div>
                                        <div className='noMrgT'><p>d'expérience</p></div>
                                    </div>
                                    <Link to="/p/profile/favoriteof" className="aReset terracotaT">
                                        <div className="vertical">
                                            <div><h1>{(userData.addedToFavBy).length}</h1></div>
                                            <div className='noMrgT'><p>ajouté en favoris</p></div>
                                        </div>
                                    </Link>
                                    <div className="vertical">
                                        <div><h1>{(userData.recommendedBySyndicOffers).length}</h1></div>
                                        <div className='noMrgT'><p>recommandation(s)</p></div>
                                    </div>
                                </div>
                            </Box>

                            <div className="mrgT10 mrgB20">
                                    <p className="txt16 txtJustify">{userData.company.description}</p>
                            </div>

                            {userData.company.qualifications.length > 0
                                ?
                                    <div className='horizontal width90pCenter iCenter jCenter wraped'>
                                        {userData.company.qualifications.map((certification, index) => (
                                            <div key={index}>
                                                {certification === 'artisandeconfiance'
                                                    ?   <div className='mrgR20'><LogoArtisansDeConfiance/></div>
                                                    :   ''
                                                }
                                                {certification === 'certibat'
                                                    ?   <div className='mrgR20'><LogoCertibat/></div>
                                                    :   ''
                                                }
                                                {certification === 'ecoartisan'
                                                    ?   <div className='mrgR20'><LogoEcoArtisan/></div>
                                                    :   ''
                                                }
                                                {certification === 'ecocert'
                                                    ?   <div className='mrgR20'><LogoEcocert/></div>
                                                    :   ''
                                                }
                                                {certification === 'handibat'
                                                    ?   <div className='mrgR20'><LogoHandibat/></div>
                                                    :   ''
                                                }
                                                {certification === 'iso9001'
                                                    ?   <div className='mrgR20'><LogoIso9001/></div>
                                                    :   ''
                                                }
                                                {certification === 'qualibat'
                                                    ?   <div className='mrgR20'><LogoQualibat/></div>
                                                    :   ''
                                                }
                                                {certification === 'qualieau'
                                                    ?   <div className='mrgR20'><LogoQualieau/></div>
                                                    :   ''
                                                }
                                                {certification === 'qualienr'
                                                    ?   <div className='mrgR20'><LogoQualienr/></div>
                                                    :   ''
                                                }
                                                {certification === 'qualifelec'
                                                    ?   <div className='mrgR20'><LogoQualifelec/></div>
                                                    :   ''
                                                }
                                                {certification === 'qualipropre'
                                                    ?   <div className='mrgR20'><LogoQualipropre/></div>
                                                    :   ''
                                                }
                                                {certification === 'rge'
                                                    ?   <div className='mrgR20'><LogoRGE/></div>
                                                    :   ''
                                                }
                                                {certification === 'silverbat'
                                                    ?   <div className='mrgR20'><LogoSilverbat/></div>
                                                    :   ''
                                                }
                                            </div>
                                        ))}
                                    </div>
                                :   ""
                            }

                            <a href={userData.company.website} target="_blank" rel="noopener noreferrer" className="aReset bT">
                                <div className="txtCenter">
                                    <p className="txt16">{userData.company.website}</p>
                                </div>
                            </a>  
                            <div className="mrgB30"></div>                        
                        </div>
                    </>
                )}
            </>
        : 
        <Loader />
    )
}

export default MyPrestaPage;

