import { React, useEffect, useState } from 'react';
import LogoNavbar from '../../composents/Logo/NavbarLogo/Logo';

const Maintenance = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    useEffect(() => {
        window.scrollTo(0,0);
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <>
        {isMobile ? (
            <>
             <div className='txtCenter mrgL30 mrgT10 mrgB100'><LogoNavbar /></div>
             <div className="bLiteGrey width90pCenter useRoboto vertical txtCenter">                    
                 <div className='mrgT10'></div>
                 <h1 className='mrgT20 mrgB5'>Site en maintenance</h1>
                 <hr className='hrHomeForm noMrgB'></hr>
                 <h3 className='mrgB5 mrgL10 mrgR10'>Le site Boldee.co est actuellement en maintenance pour mise à jour ⚙️ et innovation. 🚀</h3>
                 <h3 className='noMrgTB mrgL10 mrgR10'>Nous vous invitons à vous reconnecter ultérieurement.</h3>

                 <p className='noMrgB thin mrgL10 mrgR10'>Pour toutes informations complémentaires :</p>
                 <a href="mailto:contact@boldee.co" className="aReset bT">
                     <p className='mrgB5 terracotaT bold'>contact@boldee.co</p>
                 </a>
                 <p className='noMrgB thin mrgL10 mrgR10'>Nous vous remercions par avance de votre compréhension.</p>

                 <div className="mrgB30"></div>           

             </div>
             </>
        ) : (
            <>
                <div className='txtCenter mrgL30 mrgT10 mrgB150'><LogoNavbar /></div>
                <div className="bLiteGrey width40pCenter useRoboto vertical txtCenter">                    
                    <h1 className='mrgT50 mrgB5'>Site en maintenance</h1>
                    <hr className='hrMaintenanceMsg noMrgB'></hr>
                    <h2 className='noMrgB'>Le site Boldee.co est actuellement en maintenance</h2>
                    <h2 className='noMrgT mrgB10'>pour mise à jour ⚙️ et innovation. 🚀</h2>
                    <h2 className='mrgT10 mrgB10'>Nous vous invitons à vous reconnecter ultérieurement.</h2>

                    <h3 className='noMrgB thin'>Pour toutes informations complémentaires :</h3>
                    <a href="mailto:contact@boldee.co" className="aReset bT">
                        <h3 className='noMrgB terracotaT'>contact@boldee.co</h3>
                    </a>
                    <h3 className='noMrgB thin'>Nous vous remercions par avance de votre compréhension.</h3>

                    <div id="mrgB50"></div>           

                </div>
            </>
        )}
        </>
    );
  }

  export default Maintenance
  