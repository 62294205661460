import classes from './Box.module.css'
import { Container } from '@mantine/core'

const Box = (props) => {
    return(
        <Container
            className={classes.container}>
            {props.children}</Container>
        
    )
}

export default Box;