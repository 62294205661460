import { React,useMemo, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../../contexts/authContext';
import { PrestaFncContext } from '../../../../contexts/prestaFncContext';
import { Link, useNavigate } from 'react-router-dom';

import { IconHammer, IconLocation, IconCalendarEvent, IconX, IconMessage } from '@tabler/icons-react';
import { Badge, Loader } from '@mantine/core';

import SavedOffersHd from '../../../../composents/Headers/Customized/SavedOffersHd/SavedOffersHd';
import AppNavbarPresta from '../../../Navbar/NavbarPresta';
import Box from '../../../../composents/Boxes/Standard/Box';

const SavedOffers = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const { listSavedOffer, handleUnsave, getSavedOffers } = useContext(PrestaFncContext)

    const [urgentList, setUrgentList] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/p/login");
            return;
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);

    useMemo( async () => {
        await getSavedOffers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const today = new Date().toISOString()

    return(
        listSavedOffer ?
            <>
                {isMobile ? (
                    <>
                        <AppNavbarPresta></AppNavbarPresta>
                        <SavedOffersHd setUrgentList={setUrgentList}/> 
                                {urgentList 
                                    ?   
                                        <div className="mrgB100">
                                        {listSavedOffer.length > 0 
                                            ?   <>
                                                    { listSavedOffer.map(offer => offer.urgent).includes(true)
                                                        ?   <>
                                                                {listSavedOffer.map((offer, index) => (
                                                                    <div key = {index} style = {{margin : '10px'}}>
                                                                        {offer.urgent && offer.deadlineToReceiveQuotationDate >= today
                                                                        ?    <Box>
                                                                                <div className="horizontal">
                                                                                    <div className="vertical jCenter mrgL20 mrgR20">                        
                                                                                        <div>
                                                                                            {offer.pictures.length > 0
                                                                                                ?   <img className="roundImg70 noMrgTB"
                                                                                                        src={offer.pictures[0]}
                                                                                                        alt='photo_chantier'/>
                                                                                                :   <>
                                                                                                        {offer.author.company.logo
                                                                                                            ?   <img className="roundImg70 noMrgTB"
                                                                                                                    src={offer.author.company.logo}
                                                                                                                    alt='photo_chantier'/>
                                                                                                            :   <div className="noAvatarProfile">{offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                                                                        
                                                                                                        }
                                                                                                    </>
                                                                                            }
                                                                                            
                                                                                        </div>

                                                                                        <div className="horizontal iBtm jCenter">
                                                                                            <p className="txt15 noMrgTB bold mrgR2 txtCenter">{offer.deadline}</p>
                                                                                            <span className='txt13'>J</span>
                                                                                        </div>
                                                                                                            
                                                                                    </div>
                                                                                    <div className="vertical w100">
                                                                                        <div className="horizontal iCenter JCenter noMrgTB">
                                                                                            <div className="horizontal wraped iBtm">
                                                                                                    <div>
                                                                                                        <p className="txt12 noMrgTB mrgR5 dGreyT noMrgB capitals">
                                                                                                            {offer.author.company.name}
                                                                                                        </p>
                                                                                                    </div> 
                                                                                                    <div className='mrgR2'>
                                                                                                        {offer.urgent 
                                                                                                            ?   <Badge color="red">Urgent</Badge>
                                                                                                            :   ""
                                                                                                        }
                                                                                                    </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                                
                                                                                            <Link to={`/p/offers/offer-info/${offer._id}`} className="aReset bT">
                                                                                                <div>
                                                                                                    <p className="txt15 medium noMrgT mrgB5 lineH20 w90">{offer.title}</p>
                                                                                                </div>
                                                                                                <div className="horizontal iCenter">
                                                                                                    <IconHammer width={16} height={13} className="mrgR5"/>
                                                                                                    <p className="noMrg txt13 jumpline mrgL3 finalDotsMinus">{offer.occupation}</p>
                                                                                                </div>
                                                                                                <div className="horizontal iCenter">
                                                                                                    <IconCalendarEvent width={15} height={13} className="mrgR5"/>
                                                                                                    <p className="noMrg txt13 jumpline mrgL3">Du {offer.startDate} au {offer.endDate}</p>
                                                                                                </div>
                                                                                                <div className="horizontal iCenter">
                                                                                                    <IconLocation width={13} height={13} className="mrgR5"/>
                                                                                                    <p className="noMrg txt13 jumpline mrgL3">{offer.cityOfIntervention}</p>
                                                                                                </div>

                                                                                                <div className="horizontal iCenter wraped">
                                                                                                    
                                                                                                </div>
                                                                                            </Link>
                                                                                        </div>     
                                                                                    </div>
                                                                                    <div className='mrgLauto'>
                                                                                        <IconX width={15} className="mrgR5" id={offer._id} onClick={handleUnsave}/>
                                                                                    </div>
                                                                                </div>
                                                                            </Box>
                                                                        :   ""
                                                                        }
                                                                            
                                                                    </div>
                                                                ))}
                                                            </>
                                                        :   <p className='txt13 dGreyT width95pCenter txtCenter'>Vous n'avez pas sauvegardé d'offre urgente.</p>
                                                    }
                                                </>
                                            :   <>
                                                    <p className='txt13 dGreyT width95pCenter txtCenter'>Vous n'avez pas sauvegardé d'offre urgente.</p>
                                                    
                                                </>
                                        }
                                        </div>
                                    :   
                                        <>       
                                        {listSavedOffer.length > 0
                                            ?
                                                <div className="mrgB100">
                                                    {listSavedOffer.map((offer, index) => (
                                                        <div key = {index} style = {{margin : '10px'}}>
                                                            {offer.deadlineToReceiveQuotationDate >= today
                                                                ?   <Box>
                                                                    <div className="horizontal">
                                                                        <div className="vertical jCenter mrgL20 mrgR20">                        
                                                                            <div>
                                                                                {offer.pictures.length > 0
                                                                                    ?   <img className="roundImg70 noMrgTB"
                                                                                            src={offer.pictures[0]}
                                                                                            alt='photo_chantier'/>
                                                                                    :   <>        
                                                                                            {offer.author.company.logo
                                                                                                ?   <img className="roundImg70 noMrgTB"
                                                                                                        src={offer.author.company.logo}
                                                                                                        alt='photo_chantier'/>
                                                                                                :   <div className="noAvatarProfile">{offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                                                            
                                                                                            }   
                                                                                        </>    
                                                                                }
                                                                            </div>

                                                                            <div className="horizontal iBtm jCenter">
                                                                                <p className="txt15 noMrgTB bold mrgR2 txtCenter">{offer.deadline}</p>
                                                                                <span className='txt13'>J</span>
                                                                            </div>                    
                                                                        </div>
                                                                        <div className="vertical w100">
                                                                            <div className="horizontal iCenter JCenter noMrgTB">
                                                                                <div className="horizontal wraped iBtm">
                                                                                    <p className="txt12 noMrgTB mrgR5 dGreyT noMrgB capitals">
                                                                                        {offer.author.company.name}
                                                                                    </p> 
                                                                                    <div className='mrgR2'>
                                                                                        {offer.urgent 
                                                                                            ?   <Badge color="red">Urgent</Badge>
                                                                                            :   ""
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                      
                                                                                <Link to={`/p/offers/offer-info/${offer._id}`} className="aReset bT">
                                                                                    <div>
                                                                                        <p className="txt15 medium noMrgT mrgB5 lineH20 w90">{offer.title}</p>
                                                                                    </div>
                                                                                    <div className="horizontal iCenter">
                                                                                        <IconHammer width={16} height={13} className="mrgR5"/>
                                                                                        <p className="noMrg txt13 jumpline mrgL3 finalDotsMinus">{offer.occupation}</p>
                                                                                    </div>
                                                                                    <div className="horizontal iCenter">
                                                                                        <IconCalendarEvent width={15} height={13} className="mrgR5"/>
                                                                                        <p className="noMrg txt13 jumpline mrgL3">Du {offer.startDate} au {offer.endDate}</p>
                                                                                    </div>
                                                                                    <div className="horizontal iCenter">
                                                                                        <IconLocation width={13} height={13} className="mrgR5"/>
                                                                                        <p className="noMrg txt13 jumpline mrgL3">{offer.cityOfIntervention}</p>
                                                                                    </div>

                                                                                    <div className="horizontal iCenter wraped">
                                                                                        
                                                                                    </div>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className='mrgLauto'>
                                                                            <IconX width={15} className="mrgR5" id={offer._id} onClick={handleUnsave}/>
                                                                        </div>
                                                                    </div>
                                                                    </Box>
                                                                :   ""
                                                            }
                                                        </div>
                                                    ))}
                                                </div>
                                            :
                                            <p className='txt13 thin dGreyT txtCenter'>Vous n'avez pas d'offre enregistrée</p>
                                        } 
                                        </>
                                }
                            
                    </>
                ) : (
                    <>
                        <AppNavbarPresta></AppNavbarPresta>
                        <SavedOffersHd setUrgentList={setUrgentList}/> 
                            <div className='width50pCenter'>    
                                {urgentList 
                                    ?   
                                        <div className="mrgB100">
                                        {listSavedOffer.length > 0 
                                            ?   <>
                                                    { listSavedOffer.map(offer => offer.urgent).includes(true)
                                                        ?   <>
                                                                {listSavedOffer.map((offer, index) => (
                                                                    <div key = {index} style = {{margin : '10px'}}>
                                                                        {offer.urgent && offer.deadlineToReceiveQuotationDate >= today
                                                                        ?    <Box>
                                                                                <div className="horizontal iStart useRoboto mrgT20 mrgB10">
                                                                                    <div className="vertical mrgL30 mrgR20">                        
                                                                                        <div>
                                                                                            {offer.pictures.length > 0
                                                                                                ?   <img className="roundImg100 noMrgTB"
                                                                                                        src={offer.pictures[0]}
                                                                                                        alt='photo_chantier'/>
                                                                                                :   <>
                                                                                                        {offer.author.company.logo
                                                                                                            ?   <img className="roundImg100 noMrgTB"
                                                                                                                    src={offer.author.company.logo}
                                                                                                                    alt='photo_chantier'/>
                                                                                                            :   <div className="noAvatarProfile">{offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                                                                        
                                                                                                        }
                                                                                                    </>
                                                                                            }
                                                                                            
                                                                                        </div>

                                                                                        <div className="horizontal iBtm jCenter">
                                                                                            <p className="txt18 mrgT5 noMrgB bold mrgR2 txtCenter">{offer.deadline}</p>
                                                                                            <span className='txt15'>J</span>
                                                                                        </div>
                                                                                                            
                                                                                    </div>
                                                                                    <div className="vertical jCenter mrgL10">
                                                                                            <div className="horizontal iBtm">
                                                                                                <div>
                                                                                                    <p className="txt16 noMrgTB mrgR5 dGreyT noMrgB capitals">
                                                                                                        {offer.author.company.name}
                                                                                                    </p>
                                                                                                </div> 
                                                                                                <div className='mrgR2'>
                                                                                                    {offer.urgent 
                                                                                                        ?   <Badge size='lg' color="red">Urgent</Badge>
                                                                                                        :   ""
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        <div>
                                                                                            <Link to={`/p/offers/offer-info/${offer._id}`} className="aReset bT">
                                                                                                <div>
                                                                                                    <p className="txt18 mediumplus noMrgT mrgB5 lineH20 w90">{offer.title}</p>
                                                                                                </div>
                                                                                                <div className="horizontal iCenter mrgB5">
                                                                                                    <IconHammer width={20} height={20} className="mrgR5"/>
                                                                                                    <p className="noMrgTB txt16 mrgR5 mrgL5">{offer.occupation}</p>
                                                                                                </div>
                                                                                                <div className="horizontal iCenter mrgB5">
                                                                                                    <IconCalendarEvent width={20} height={20} className="mrgR5"/>
                                                                                                    <p className="noMrgTB txt16 mrgR5 mrgL5">Du {offer.startDate} au {offer.endDate}</p>
                                                                                                </div>
                                                                                                <div className="horizontal iCenter mrgB5">
                                                                                                    <IconLocation width={20} height={20} className="mrgR5"/>
                                                                                                    <p className="noMrgTB txt16 mrgR5 mrgL5">{offer.cityOfIntervention}</p>
                                                                                                </div>

                                        
                                                                                            </Link>
                                                                                        </div>     
                                                                                    </div>
                                                                                    <div className='mrgLauto'>
                                                                                        <IconX width={23} className="mrgR20" id={offer._id} onClick={handleUnsave}/>
                                                                                    </div>
                                                                                </div>
                                                                            </Box>
                                                                        :   ""
                                                                        }
                                                                            
                                                                    </div>
                                                                ))}
                                                            </>
                                                        :   <p className='txt18 dGreyT width50pCenter txtCenter'>Vous n'avez pas sauvegardé d'offre urgente.</p>
                                                    }
                                                </>
                                            :   <>
                                                    <p className='txt18 dGreyT width50pCenter txtCenter'>Vous n'avez pas sauvegardé d'offre urgente.</p>
                                                    
                                                </>
                                        }
                                        </div>
                                    :   
                                        <>       
                                        {listSavedOffer.length > 0
                                            ?
                                                <div className="mrgB100">
                                                    {listSavedOffer.map((offer, index) => (
                                                        <div key = {index} style = {{margin : '10px'}}>
                                                            {offer.deadlineToReceiveQuotationDate >= today
                                                                ?   <Box>
                                                                    <div className="horizontal iStart useRoboto mrgT20 mrgB10">
                                                                        <div className="vertical mrgL30 mrgR20">                        
                                                                            <div>
                                                                                {offer.pictures.length > 0
                                                                                    ?   <img className="roundImg100 noMrgTB"
                                                                                            src={offer.pictures[0]}
                                                                                            alt='photo_chantier'/>
                                                                                    :   <>        
                                                                                            {offer.author.company.logo
                                                                                                ?   <img className="roundImg100 noMrgTB"
                                                                                                        src={offer.author.company.logo}
                                                                                                        alt='photo_chantier'/>
                                                                                                :   <div className="noAvatarProfile">{offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                                                            
                                                                                            }   
                                                                                        </>    
                                                                                }
                                                                            </div>

                                                                            <div className="horizontal iBtm jCenter">
                                                                                <p className="txt18 mrgT5 noMrgB bold mrgR2 txtCenter">{offer.deadline}</p>
                                                                                <span className='txt15'>J</span>
                                                                            </div>                    
                                                                        </div>
                                                                        <div className="vertical jCenter mrgL10">
                                                                            <div className="horizontal iBtm">
                                                                                    <p className="txt16 noMrgTB mrgR5 dGreyT noMrgB capitals">
                                                                                        {offer.author.company.name}
                                                                                    </p>  
                                                                                    <div className='mrgR2'>
                                                                                        {offer.urgent 
                                                                                            ?   <Badge size='lg' color="red">Urgent</Badge>
                                                                                            :   ""
                                                                                        }
                                                                                    </div>
                                                                            </div>
                                                                            <div>                                                                                     
                                                                                <Link to={`/p/offers/offer-info/${offer._id}`} className="aReset bT">
                                                                                    <div>
                                                                                        <p className="txt18 mediumplus noMrgT mrgB5 lineH20 w90">
                                                                                            {offer.title}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="horizontal iCenter mrgB5">
                                                                                        <IconHammer width={20} height={20} className="mrgR5"/>
                                                                                        <p className="noMrgTB txt16 mrgR5 mrgL5">{offer.occupation}</p>
                                                                                    </div>
                                                                                    <div className="horizontal iCenter mrgB5">
                                                                                        <IconCalendarEvent width={20} height={20} className="mrgR5"/>
                                                                                        <p className="noMrgTB txt16 mrgR5 mrgL5">Du {offer.startDate} au {offer.endDate}</p>
                                                                                    </div>
                                                                                    <div className="horizontal iCenter mrgB5">
                                                                                        <IconLocation width={20} height={20} className="mrgR5"/>
                                                                                        <p className="noMrgTB txt16 mrgR5 mrgL5">{offer.cityOfIntervention}</p>
                                                                                    </div>

                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className='mrgLauto'>
                                                                            <IconX width={23} className="mrgR20" id={offer._id} onClick={handleUnsave}/>
                                                                        </div>
                                                                    </div>
                                                                    </Box>
                                                                :   ""
                                                            }
                                                        </div>
                                                    ))}
                                                </div>
                                            :
                                                <>
                                                    <div className="vertical">
                                                            <div className='emptyListNotification'>
                                                                <div className= 'horizontal iCenter mrgT10'>
                                                                    <IconMessage width={100} height={45}/>
                                                                    <p className='txt20 bold'>
                                                                        Vous n'avez pas ajouté d'offres à vos favoris
                                                                    </p>
                                                                </div>
                                                            
                                                            <div className='horizontal iCenter'>
                                                                <IconMessage width={100} height={45} color='F8F8F8'/>
                                                                <Link to="/p/matchs/list" className='aReset'><p className='txt18 terracotaT mrgT10 mrgB30 mrgR50'>Consulter mes offres personnalisées</p></Link>
                                                                <Link to="/p/search" className='aReset'><p className='txt18 terracotaT mrgT10 mrgB30'>Accéder à toutes les offres</p></Link>
                                                            </div> 
                                                            </div>                                                        
                                                        </div>
                                                </>
                                        } 
                                        </>
                                }
                            </div>
                    </>
                )}
            </>
        : 
        <Loader />   
    )
}

export default SavedOffers;

