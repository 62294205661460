import classes from './RectangleButtonSndBlack.module.css'

const PrestaRectangleButtonSndBlack = (props) => {
    return(
        <button
            className={classes.sRoundButton}
            type={props.type || "button"}
            onClick={props.onClick}
            >{props.children}
        </button>
    )
}

export default PrestaRectangleButtonSndBlack;