import { React, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../../../contexts/authContext';
import { useParams, useNavigate } from "react-router-dom";

import { SegmentedControl, Loader } from '@mantine/core';

import RdvAndQuotationHd from '../../../../../composents/Headers/Customized/RdvAndQuotationHd/RdvAndQuotationHd';
import ReceivedQuotationList from './Quotation/OfferQuotation';
import ReceivedRDVDemandList from './RDV/OfferRDV';
import AppDesktopLogo from './../../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';


const RdvAndQuotation = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [offer, setOffer] = useState(null);

    const prevPage = localStorage.getItem('page');
   
    const [value, setValue] = useState(prevPage);
    
    let {id} = useParams()

    const navigate = useNavigate();

    useEffect(() => {
        setValue(prevPage);
        getSingleOffer();
        const token = getToken();
        if (!token) {
            navigate("/s/login");
            return;
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
            };
            window.addEventListener('resize', handleResize);
            return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const getSingleOffer = async() => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/s/offers/offer/${id}`, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`
                }
              });
              const offer = await response.json();
              setOffer(offer);
        } catch (error) { 
            console.error(error);
        }   
    }    

    return(
        offer ?
            <>
                {isMobile ? (
                    <div>
                    
                        <RdvAndQuotationHd>{offer.title}</RdvAndQuotationHd>

                        <div className="width95pCenter mrgB20">          
                            <SegmentedControl 
                                fullWidth 
                                value={value}
                                onChange={setValue}
                                size="md"
                                color="dark"
                                orientation='horizontal'
                                data={[
                                    { label: 'RDV', value: 'rdv'},
                                    { label: 'Devis', value: 'devis'},
                                ]}
                            />
                        </div>
                        
                        <div>
                            {value === 'rdv' ? 
                            <><ReceivedRDVDemandList/></>
                            : <><ReceivedQuotationList/></>}
                        </div>
        
                    </div>
                ) : (
                    <div>
                        <AppDesktopLogo />
                        <RdvAndQuotationHd>{offer.title}</RdvAndQuotationHd>
                        <div className="width50pCenter mrgT20 mrgB20">          
                            <SegmentedControl 
                                fullWidth 
                                value={value}
                                onChange={setValue}
                                size="md"
                                color="dark"
                                orientation='horizontal'
                                data={[
                                    { label: 'RDV', value: 'rdv'},
                                    { label: 'Devis', value: 'devis'},
                                ]}
                            />
                        </div>
                        
                        <div>
                            {value === 'rdv' ? 
                            <><ReceivedRDVDemandList/></>
                            : <><ReceivedQuotationList/></>}
                        </div>
        
                    </div>
                )}
            </>
            :
            <Loader/>
    )
    
}

export default RdvAndQuotation;

