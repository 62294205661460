import { React, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../../contexts/authContext'
import { Link, useNavigate } from 'react-router-dom';

import { useDisclosure } from '@mantine/hooks';
import { IconUser, IconDoor, IconPhone, IconAt } from '@tabler/icons-react';
import { Avatar, Loader, Modal, Group, Button } from '@mantine/core';

import AppHdWithReturnLeft from '../../../../../composents/Headers/AppHeaders/AppHdWithReturnLeft/AppHdWithReturnLeft';
import Box from '../../../../../composents/Boxes/Standard/Box';
import PrestaRectangleButtonSndBlack from '../../../../../composents/Buttons/Presta/RectangleButtonSndBlack/RectangleButtonSndBlack';
import LogoutButton from '../../../../../composents/Buttons/All/Logout/Logout';
import AppDesktopLogo from '../../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';


const MyPrestaAccount = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const { handleLogoutPresta, getToken } = useContext(AuthContext);
    const [ openedLogoutModal, { open: openLogoutModal, close: closeLogoutModal}] = useDisclosure(false)
    const [ userData, setUserData ] = useState(null)

    const navigate = useNavigate();
    
    useEffect(() => {
        window.scrollTo(0, 0);
        const token = getToken();
        if (!token) {
            navigate("/p/login");
            return;
        }
        getUser();
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const getUser = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch('https://boldee-97d8d36af3bd.herokuapp.com/p/getUser', {
                method: "GET",    
                headers: {
                    Authorization: `Bearer ${token}`
                }
        });
            const data = await response.json()
            setUserData(data)
        }   catch (error) {
            return null;
        }
    }

    const handleOpenLogoutModal = () => {
        openLogoutModal()
    }
    const handlecloseValidationModal = () => {
        closeLogoutModal()
    }

    const handleLogout = async () => {
        await handleLogoutPresta();
        navigate("/p/login");
    };
    

    return(
        userData ?
            <>
                {isMobile ? (
                    <>
                    
                        <AppHdWithReturnLeft>Mon compte</AppHdWithReturnLeft>    
                        <div className="vertical iCenter">
                            {userData.avatar    
                                ?   <Avatar src={userData.avatar} alt="avatar" radius={50} size={100} />
                                :   <div className="noAvatarProfile">{userData.firstname.charAt(0)}{userData.lastname.charAt(0)}</div>
                            }

                            <div className="mrgB20"></div>

                            <Box>
                                <div  className=" width90pCenter vertical mrgT10 mrgB10">
                                    <div className='horizontal'>
                                            <p className="noMrgTB mrgR5"><IconUser width={20} height={14}/></p>
                                            <div className="vertical">
                                                <div className='horizontal'>
                                                    <p className="txt15 medium noMrgTB mrgR5">{userData.title}</p>
                                                    <p className="txt15 medium noMrgTB mrgR5">{userData.firstname}</p>
                                                    <p className="txt15 medium noMrgTB capitals">{userData.lastname}</p>
                                                </div>
                                            <p className="noMrgTB mrgL20"></p><p className="txt13 noMrgTB">{userData.occupation.join(' - ')}</p> 
                                            </div>
                                    </div>
                                    
                                    <div className="horizontal">
                                        <p className="noMrgB mrgR5"><IconDoor  width={20} height={14}/></p>
                                        <div className='vertical'>
                                            <p className="txt15 medium noMrgB capitals">{userData.company.name}</p>
                                            <p className="txt13 noMrgTB">{userData.company.address.no}, {userData.company.address.street}</p>
                                            <p className="txt13 noMrgTB">{userData.company.address.postalcode} {userData.company.address.city}</p>
                                        </div>
                                    </div>
                                    
                                    <div className="horizontal mrgB5">
                                        <p className="noMrgB mrgR5"><IconPhone  width={20} height={14}/></p><p className="txt15 noMrgB">{[(userData.phone).slice(0, 2), " ", (userData.phone).slice(2,4), " ", (userData.phone).slice(4,6), " ", (userData.phone).slice(6,8), " ", (userData.phone).slice(8,10)].join('')}</p>                            
                                    </div>
                                    <div className="horizontal">
                                        <p className="noMrg mrgR5"><IconAt width={20} height={14}/></p><p className="txt15 noMrg">{userData.email}</p>
                                    </div>
                                </div>
                            </Box>

                            <div className="mrgB20"></div>

                            <div className ="width90pCenter">
                            <Link to={`/p/profile/edit`}>
                                <PrestaRectangleButtonSndBlack>
                                    Modifier
                                </PrestaRectangleButtonSndBlack>
                            </Link>

                                <div className="mrgB10"></div>

                            <LogoutButton 
                                onClick={handleOpenLogoutModal}>
                                    Déconnexion
                            </LogoutButton>
                            {openLogoutModal  
                                ?   <Modal 
                                        centered
                                        withCloseButton={false}    
                                        opened={openedLogoutModal} 
                                        onClose={closeLogoutModal}
                                        size="auto">
                                            <Group noWrap mt="md" className="useRoboto txtCenter mrgT5 txt15">
                                                    Êtes-vous sûr de vouloir vous déconnecter ?
                                            </Group>
                                            <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20 w80">
                                            <Button variant="outline" color="dark" onClick={handlecloseValidationModal} className='useRoboto'>
                                                Annuler
                                            </Button>
                                            <Button variant="filled" color="dark"  onClick={handleLogout} className='useRoboto medium'>
                                                Déconnecter
                                            </Button>
                                        </Group>       
                                    </Modal> 
                                :   ""
                            }
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <AppDesktopLogo/>
                        <AppHdWithReturnLeft>Mon compte</AppHdWithReturnLeft>    
                        <div className="width50pCenter vertical iCenter">
                            {userData.avatar    
                                ?   <Avatar src={userData.avatar} alt="avatar" radius={50} size={100} />
                                :   <div className="noAvatarProfile">{userData.firstname.charAt(0)}{userData.lastname.charAt(0)}</div>
                            }

                            <div className="mrgB20"></div>

                            <Box>
                                <div className="width90pCenter vertical mrgT20 mrgB10">
                                    <div className='horizontal mrgT10'>
                                            <p className="noMrgTB mrgR20"><IconUser width={25} height={23}/></p>
                                            <div className="vertical">
                                                <div className='horizontal mrgB5'>
                                                    <p className="txt18 medium noMrgTB mrgR5">{userData.title}</p>
                                                    <p className="txt18 medium noMrgTB mrgR5">{userData.firstname}</p>
                                                    <p className="txt18 medium noMrgTB capitals">{userData.lastname}</p>
                                                </div>
                                                <p className="txt16 noMrgT mrgB20">{userData.occupation.join(' - ')}</p> 
                                            </div>
                                    </div>
                                    <div className='mrgB10'></div>
                                    <div className="horizontal iStart mrgB20">
                                            <p className="noMrgTB mrgR20"><IconDoor  width={25} height={20}/></p>
                                        <div className='vertical noMrgT'>
                                            <p className="txt18 medium noMrgT mrgB5 capitals">{userData.company.name}</p>
                                            <p className="txt16 noMrgTB">{userData.company.address.no}, {userData.company.address.street}</p>
                                            <p className="txt16 noMrgTB">{userData.company.address.postalcode} {userData.company.address.city}</p>
                                        </div>
                                    </div>
                                    <div className='mrgB10'></div>

                                    <div className="horizontal iCenter mrgB10">
                                        <p className="noMrgTB mrgR20"><IconPhone  width={25} height={20}/></p>
                                        <p className="txt16 noMrgTB">{[(userData.phone).slice(0, 2), " ", (userData.phone).slice(2,4), " ", (userData.phone).slice(4,6), " ", (userData.phone).slice(6,8), " ", (userData.phone).slice(8,10)].join('')}</p>                            
                                    </div>
                                    <div className="horizontal iCenter">
                                        <p className="noMrgTB mrgR20"><IconAt width={25} height={20}/></p>
                                        <p className="txt16 noMrgTB">{userData.email}</p>
                                    </div>
                                    <div className='mrgB20'></div>
                                </div>
                            </Box>

                            <div className="mrgB30"></div>

                            <div className ="w100">
                                <Link to={`/p/profile/edit`}>
                                    <PrestaRectangleButtonSndBlack>
                                        <p className='txt16 noMrgTB'>Modifier</p>
                                    </PrestaRectangleButtonSndBlack>
                                </Link>

                            <div className="mrgB20"></div>

                            <LogoutButton 
                                onClick={handleOpenLogoutModal}>
                                    <p className='txt16 noMrgTB'>Déconnexion</p>
                            </LogoutButton>
                            {openLogoutModal  
                                ?   <Modal 
                                        centered
                                        withCloseButton={false}    
                                        opened={openedLogoutModal} 
                                        onClose={closeLogoutModal}
                                        size="auto">
                                            <Group noWrap mt="md" className="useRoboto txtCenter mrgT10 txt16">
                                                    Êtes-vous sûr de vouloir vous déconnecter ?
                                            </Group>
                                            <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20">
                                            <Button size='md' variant="outline" color="dark" onClick={handlecloseValidationModal} className='useRoboto'>
                                                <p className='txt16'>Annuler</p>
                                            </Button>
                                            <Button size='md' variant="filled" color="dark"  onClick={handleLogout} className='useRoboto medium'>
                                                <p className='txt16'>Déconnecter</p>
                                            </Button>
                                        </Group>       
                                    </Modal> 
                                :   ""
                            }
                            <div className='mrgB100'></div>
                            </div>
                        </div>
                    </>
                )}
            </>
        : 
        <Loader />
    )
}   

export default MyPrestaAccount;

