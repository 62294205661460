import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";

import { IconStar, IconArticle, IconUser } from '@tabler/icons-react';
import LogoBurgerMenuApp from '../../composents/Logo/BurgerMenuLogoApp/LogoBurgerMenuApp';


const AppNavbarSyndic = () => {  
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    
    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 768);
        };
    
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
          
    return(
        <div className="app-navbar">
            {isMobile ? (
                <>
                    <ul id="app-navbar-container">
                        <NavLink to = "/s/favPresta/fav-list" activeClassName="active">
                            <IconStar/>
                            <p id="navFavorisSyndicga4">Favoris</p>
                        </NavLink>
                        <NavLink to = "/s/offers/list" activeClassName="active">
                            <IconArticle/>
                            <p id="navOffresSyndic">Offres de travaux</p>
                        </NavLink>
                        <NavLink to = "/s/profile/menu" activeClassName="active">
                            <IconUser/>
                            <p id="navProfilSyndic">Profil</p>
                        </NavLink>
                    </ul>
                </>
            ) : (
                <>
                    <div className='horizontal spaceBtw iCenter appMenuBorder'>
                            <LogoBurgerMenuApp/>
                        <div className='horizontal mrgR100 wMenu spaceBtw txt18'>
                            <NavLink to = "/s/favPresta/fav-list" activeClassName="active" className='aReset bT medium'>
                                <p id="navFavorisSyndicga4" className='mrgT5 mrgB10 mediumplus'>Favoris</p>
                            </NavLink>
                            <NavLink to = "/s/offers/list" activeClassName="active" className='aReset bT medium'>
                                <p id="navOffresSyndic" className='mrgT5 mrgB10 mediumplus'>Offres de travaux</p>
                            </NavLink>
                            <NavLink to = "/s/profile/menu" activeClassName="active" className='aReset bT medium'>
                                <p id="navProfilSyndic" className='mrgT5 mrgB10 mediumplus'>Profil</p>
                            </NavLink>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
    
}

export default AppNavbarSyndic;