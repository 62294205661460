import { React, useEffect, useContext, useState, useMemo } from 'react';
import { AuthContext } from '../../contexts/authContext';
import { Link, useNavigate } from 'react-router-dom';

import { useDisclosure } from '@mantine/hooks';
import { Modal, Group, Button, Loader } from '@mantine/core';
import { IconUserPlus, IconHomePlus, IconPower, IconChevronRight } from '@tabler/icons-react';

import AppHdSingleTitle from '../../composents/Headers/AppHeaders/AppHdSingleTitle/AppHdSingleTitle'
import Box from '../../composents/Boxes/Standard/Box';

const AdminMenu = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const { handleLogoutAdmin, getToken, getUserType } = useContext(AuthContext);
    const [openedLogoutModal, { open: openLogoutModal, close: closeLogoutModal}] = useDisclosure(false)
    const [userData, setUserData] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/adm/login");
            return;
        };

        const userType = getUserType();
        if(userType !== 'admin'){
            localStorage.setItem("userType", "");
            handleLogoutAdmin()
            navigate("/adm/login");
            return;
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
      
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const getAdminUser = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch('https://boldee-97d8d36af3bd.herokuapp.com/adm/getUser', {
                method: "GET",    
                headers: {
                    Authorization: `Bearer ${token}`
                }
        });
            const data = await response.json()
            setUserData(data)
        }   catch (error) {
            return null;
        }
    };

    useMemo( async () => {
        await getAdminUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleOpenLogoutModal = () => {
        openLogoutModal()
    }
    const handlecloseValidationModal = () => {
        closeLogoutModal()
    }

    const handleLogout = async () => {
        await handleLogoutAdmin();
        navigate("/adm/login");
      };

    return(
        userData ?
            <>{isMobile ? (
                <>
                    <AppHdSingleTitle>Profil Administrateur</AppHdSingleTitle>
                    <div className="mrgB20"></div>
                    <div>

                        <div className="vertical mrgL20">
                            <p className='txt18 bold noMrgB useRoboto'>Bienvenue {userData.firstname} {userData.lastname}</p>
                        </div>

                    
                        <div className="mrgB20"></div>

                        <Box>
                            <div  className="profil-menu vertical mrgL10 mrgR20">
                                <Link to={`/adm/addcust`} className="aReset bT">
                                    <div className="horizontal iCenter spaceBtw">
                                        <div className="horizontal iCenter paddingMenuProfil">
                                            <IconUserPlus width={25} className="mrgR10"/>
                                            <p>Ajouter un nouveau client</p>
                                        </div>
                                        <IconChevronRight width={16}/>                                </div>
                                    <hr></hr>
                                </Link>

                                <Link to="/adm/addcomp" className="aReset bT">
                                    <div className="horizontal iCenter spaceBtw">
                                        <div className="horizontal iCenter paddingMenuProfil">
                                            <IconHomePlus  width={25} className="mrgR10"/>
                                            <p>Ajouter une entreprise prestataire non membre</p>
                                    </div>
                                    <IconChevronRight width={16}/>                                </div>
                                    <hr></hr>
                                </Link>
                                <div className="horizontal iCenter spaceBtw">
                                    <div className="horizontal iCenter paddingMenuProfil">
                                        <button className="btnReset horizontal noPdg jStart bLiteGrey" onClick= {handleOpenLogoutModal}>
                                            <IconPower   width={25} className="mrgR10"/>
                                            <p>Deconnexion</p>
                                        </button>
                                        {openLogoutModal  
                                            ?   <Modal 
                                                    centered
                                                    withCloseButton={false}    
                                                    opened={openedLogoutModal} 
                                                    onClose={closeLogoutModal}
                                                    size="auto">
                                                        <Group noWrap mt="md" className="useRoboto txtCenter mrgT5 txt15">
                                                                Êtes-vous sûr de vouloir vous déconnecter ?
                                                        </Group>
                                                        <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20 w80">
                                                        <Button variant="outline" color="dark" onClick={handlecloseValidationModal} className='useRoboto'>
                                                            Annuler
                                                        </Button>
                                                        <Button variant="filled" color="dark"  onClick={handleLogout} className='useRoboto medium'>
                                                            Déconnecter
                                                        </Button>
                                                    </Group>       
                                                </Modal> 
                                            :   ""
                                        }
                                    </div>
                                        
                                </div>
                            </div>
                        </Box>
                        
                        <div className='mrgB10'></div>

                        <div>
                            <p id="versionApp">Boldee V.beta 1</p>
                        </div>
                    
                        <div className='mrgB150'></div>

                    </div>
                </>
                ) : (
                    <>
                    <AppHdSingleTitle>Profil Administrateur</AppHdSingleTitle>
                    <div className="mrgB20"></div>
                    <div>

                        <div className="vertical mrgL20">
                            <p className='txt20 bold noMrgB useRoboto width50pCenter'>Bienvenue {userData.firstname} {userData.lastname}</p>
                        </div>

                    
                        <div className="mrgB20"></div>

                        <Box>
                            <div  className="profil-menu vertical mrgL10 mrgR20">
                                <Link to={`/adm/addcust`} className="aReset bT">
                                    <div className="horizontal iCenter spaceBtw">
                                        <div className="horizontal iCenter paddingMenuProfil">
                                            <IconUserPlus width={25} className="mrgR10"/>
                                            <p>Ajouter un nouveau client</p>
                                        </div>
                                        <IconChevronRight width={16}/>                                </div>
                                    <hr></hr>
                                </Link>

                                <Link to="/adm/addcomp" className="aReset bT">
                                    <div className="horizontal iCenter spaceBtw">
                                        <div className="horizontal iCenter paddingMenuProfil">
                                            <IconHomePlus  width={25} className="mrgR10"/>
                                            <p>Ajouter une entreprise prestataire non membre</p>
                                    </div>
                                    <IconChevronRight width={16}/>                                </div>
                                    <hr></hr>
                                </Link>
                                <div className="horizontal iCenter spaceBtw">
                                    <div className="horizontal iCenter paddingMenuProfil">
                                        <button className="btnReset horizontal noPdg jStart bLiteGrey" onClick= {handleOpenLogoutModal}>
                                            <IconPower   width={25} className="mrgR10"/>
                                            <p>Deconnexion</p>
                                        </button>
                                        {openLogoutModal  
                                            ?   <Modal 
                                                    centered
                                                    withCloseButton={false}    
                                                    opened={openedLogoutModal} 
                                                    onClose={closeLogoutModal}
                                                    size="auto">
                                                        <Group noWrap mt="md" className="useRoboto txtCenter mrgT5 txt18">
                                                                Êtes-vous sûr de vouloir vous déconnecter ?
                                                        </Group>
                                                        <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20 w80">
                                                        <Button size="md" variant="outline" color="dark" onClick={handlecloseValidationModal} className='useRoboto'>
                                                            <p className='noMrgTB txt16'>Annuler</p>
                                                        </Button>
                                                        <Button size="md" variant="filled" color="dark"  onClick={handleLogout} className='useRoboto medium'>
                                                        <p className='noMrgTB txt16'>Déconnecter</p>
                                                        </Button>
                                                    </Group>       
                                                </Modal> 
                                            :   ""
                                        }
                                    </div>
                                        
                                </div>
                            </div>
                        </Box>
                        
                        <div className='mrgB10'></div>

                        <div>
                            <p id="versionApp">Boldee V.beta 1</p>
                        </div>
                    
                        <div className='mrgB150'></div>

                    </div>
                </>
            )}   
            </>
        : 
        <Loader />
    )
    
}

export default AdminMenu;


