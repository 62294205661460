import { React, useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../../../contexts/authContext';
import { Link, useParams, useNavigate } from "react-router-dom";

import { IconHammer, IconLocation, IconCalendarEvent, IconEye, IconPencil  } from '@tabler/icons-react';
import { Badge, Loader, Modal, Group, Button, FileButton } from '@mantine/core';
import { parseISO, format } from 'date-fns';
import { useDisclosure } from '@mantine/hooks';

import AppHdWithCloseRight from '../../../../composents/Headers/AppHeaders/AppHdWithCloseRight/AppHdWithCloseRight';
import Box from '../../../../composents/Boxes/Standard/Box';
import DeleteButton from '../../../../composents/Buttons/All/DeleteButton/DeleteButton';
import PrestaRectangleButtonMain from '../../../../composents/Buttons/Presta/RectangleButtonMain/RectangleButtonMain';
import AppDesktopLogo from '../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

const QuotationInfoAndEdit = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [actualQuotation, setActualQuotation] = useState();
    const [quotationFile, setQuotationFile] = useState();
    const [deleteQuotation, setDeleteQuotation] = useState(false);
    const [opened, { open, close }] = useDisclosure(false);
    const [ openedModalTooBigPdf, { open: openModalTooBigPdf, close: closeModalTooBigPdf}] = useDisclosure(false);
    const [ openedModalWrongType, { open: openModalWrongType, close: closeModalWrongType}] = useDisclosure(false);
    const [quotationName, setQuotationName] = useState();
    const [downloadLink, setDownloadLink] = useState();
    const [offerId, setOfferId] = useState();
    const navigate = useNavigate()

    let {id} = useParams()

    useEffect(() => {
        getSingleQuotation();
        const token = getToken();
        if (!token) {
            navigate("/p/login");
            return;
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);
    
    const getSingleQuotation = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/p/quo/edit/${id}`, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`
                }
              });
              const actualQuotation = await response.json();
              const rangeDate = actualQuotation.offer.workRangeDates
              actualQuotation.startDate = format(parseISO(rangeDate[0]), 'dd/MM/yyyy');
              actualQuotation.endDate = format(parseISO(rangeDate[1]), 'dd/MM/yyyy');
              
              setActualQuotation(actualQuotation)
              setQuotationFile(actualQuotation.quotationFile);
              setQuotationName(actualQuotation.quotationName)
              setDownloadLink(actualQuotation.downloadLink)
              setOfferId(actualQuotation.offer._id)

        } catch (error) {
        }
    };

    const fileBrowseHandler = async (event) => {
        setQuotationName(event.name)
        const maxSizeMo = 10 * 1024 * 1024
        if(event.size > maxSizeMo) {
            setQuotationName(actualQuotation.quotationName)
            setQuotationFile('')
            openModalTooBigPdf()
        } else if(event.type !== 'application/pdf' && event.type !== 'image/png' && event.type !== 'image/jpeg' && event.type !== 'image/jpg'){ 
            setQuotationName(actualQuotation.quotationName)
            setQuotationFile('')
            openModalWrongType()
        } else if (event.size <= maxSizeMo) {
            let reader = new FileReader()
            reader.readAsDataURL(event)
            reader.onload = () => {
            setQuotationFile(reader.result)
            setDownloadLink(reader.result)          
            }    
        
            await getSingleQuotation()
            reader.onerror = function (error) {
            }
        };
    }

    const openPDF = () => {
        let win = window.open()
        win.document.write('<iframe src="' + downloadLink  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');

    }

    const handleOpenDeleteModal = async () => {
        setDeleteQuotation(true);
        open()
    }

    const handleOpenModifyModal = async () => {
        setDeleteQuotation(false);
        open()
    }

    const handleFormSubmit = async() => {
        open()  
        try {
            const token = localStorage.getItem('token');
            await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/p/quo/edit/${id}`, {
                method: "POST",
                body: JSON.stringify({
                    quotationFile : quotationFile,
                    quotationName : quotationName,
                    downloadLink : downloadLink
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            setTimeout(() => {
                navigate('/p/quo/list');
            }, 500)

        } catch (error) {
        }
    }

    const handleDeleteSubmit = async () => {
        try {
            const token = localStorage.getItem('token');
            await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/p/quo/delete/${id}`, {    
                method: "POST",
                body: JSON.stringify({
                    _id : offerId
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            setTimeout(() => {
                navigate('/p/quo/list');
            }, 500)
        } catch (error) {
        }
    }
   


    return (
        actualQuotation || deleteQuotation ?
            <>
                {isMobile ? (
                    <>
                        <AppHdWithCloseRight>Information devis</AppHdWithCloseRight>

                        <Link to={`/p/quo/info-offer/${offerId}`} className="aReset bT">               
                            <Box>
                                <div className="horizontal iCenter mrgR10">
                                    <div className="vertical mrgR10 jCenter">                         
                                        <div className="mrgL20">
                                        {actualQuotation.offer.pictures.length > 0
                                            ?    <img 
                                                    className="roundImg70"
                                                    src={actualQuotation.offer.pictures[0]}
                                                    alt='photo_chantier'/> 
                                            :   <>
                                                    {actualQuotation.offer.author.company.logo
                                                        ?   <img 
                                                                className="roundImg70"
                                                                src={actualQuotation.offer.author.company.logo}
                                                                alt='photo_chantier'/>
                                                        :   <div className="noAvatarProfile">{actualQuotation.offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                    }
                                                </>
                                        }   
                                        </div>                            
                                    </div>

                                    <div className="vertical jCenter mrgL10">
                                        <div className='horizontal iBtm'>
                                            <p className="txt11 thin noMrgTB capitals">{actualQuotation.offer.author.company.name}</p> 

                                            <div className='mrgR5'></div>

                                            <div>
                                                {actualQuotation.offer.urgent 
                                                    ?   <Badge color="red">Urgent</Badge> 
                                                    :   ""
                                                }
                                            </div>

                                            <div className='mrgR5'></div>
                                                                                

                                        </div>

                                        <div>
                                            <p className="txt15 lineH15 mediumplus noMrgT mrgB5">{actualQuotation.offer.title}</p>
                                        </div>
                                        
                                        <div className="horizontal iCenter">
                                            <IconHammer width={13} height={13} className="mrgR5"/>
                                            <p className="noMrgTB txt13 mrgL3">{actualQuotation.offer.occupation}</p>
                                        </div>
                                        <div className="horizontal iCenter">
                                            <IconCalendarEvent width={13} height={13} className="mrgR5"/>
                                            <p className="noMrgTB txt13 mrgL3">Du {actualQuotation.startDate} au {actualQuotation.endDate}</p>
                                        </div>
                                        <div className="horizontal iCenter">
                                            <IconLocation width={13} height={13} className="mrgR5"/>

                                            <p className="noMrgTB txt13 mrgL3">{actualQuotation.offer.cityOfIntervention}</p>
                                        </div>     
                                    </div>
                                </div>
                            </Box>
                        </Link>

                        <div className="mrgB20"></div>

                        <div className="vertical width90pCenter">
                            <div className="horizontal iCenter mrgB10">
                                <div><p className="txt15 mediumplus noMrgT mrgB5 mrgR5">Devis envoyé le {format(parseISO(actualQuotation.sendDate), 'dd/MM/yyyy')}</p></div>
                                <div>
                                    {actualQuotation.viewedBySyndic 
                                        ?   <Badge color="green" className='noMrgT mrgB5'>Vu</Badge>
                                        :   ""
                                    }
                                </div>
                            </div>
                            <div className='vertical iStart'>
                                {actualQuotation && (
                                    <>
                                        {/* <p className='txt15 noMrgTB'>Fichier choisi : </p> */}
                                        <div className='horizontal jCenter iCenter w100 spaceBtw mrgB10'>
                                            <div><p className='bold mrgT5 mrgB5 txt15'>{quotationName}</p></div>
                                            <div>
                                                <div onClick={openPDF} className='aReset bT'>
                                                    <IconEye width={22} className='mrgL10'/>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                                <FileButton leftIcon={<IconPencil size="1rem" />} className='mrgT5 useRoboto medium txt13 bT w100' variant="outline" color="gray" onChange={fileBrowseHandler} accept="image/png,image/jpeg,image/jpg">
                                    {(props) => <Button {...props}>Modifier Devis</Button>}
                                </FileButton>
                            </div>
                            {openModalTooBigPdf 
                                        ?   <Modal 
                                                centered
                                                size="50%"
                                                opened={openedModalTooBigPdf}
                                                onClose={closeModalTooBigPdf}
                                                withCloseButton={false}>
                                                <p className="useRoboto txt15 txtCenter noMrgTB">
                                                    10 Mo Maximum par fichier
                                                </p>
                                            </Modal> 
                                        :   ""
                                    }
                                    {openModalWrongType 
                                        ?   <Modal 
                                                centered
                                                size="50%"
                                                opened={openedModalWrongType}
                                                onClose={closeModalWrongType}
                                                withCloseButton={false}>
                                                <p className="useRoboto txt15 txtCenter noMrgTB">
                                                    Fichiers autorisés : .pdf, .png, .jpg, .jpeg
                                                </p>
                                            </Modal> 
                                        :   ""
                                    }
                            <p className="txtJustify txt13 dGreyT lineH15 mrgB30">
                                Vérifier votre fichier et sa qualité avant de l'envoyer.
                                Tout document illisible ou ne correspondant pas à un devis sera refusé.
                            </p>
                        </div>

                        <div className='width90pCenter'>                  
                            <PrestaRectangleButtonMain onClick={handleOpenModifyModal}>
                                Enregistrer
                            </PrestaRectangleButtonMain>
                        </div>  
                        { open && deleteQuotation === false
                            ?   <Modal 
                                    centered
                                    withCloseButton={false}    
                                    opened={opened} 
                                    onClose={close}
                                    size="auto"
                                    className='useRoboto'>
                                        <p className="txtCenter mrgT5 txt15">Modifier votre devis ?</p>

                                        <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20">
                                            <Button variant="outline" color="dark" onClick={close} className='useRoboto'>
                                                Annuler
                                            </Button>
                                            <Button onClick={handleFormSubmit} variant="filled" color="dark" id={actualQuotation._id} className='useRoboto medium'>
                                                Modifier
                                            </Button>
                                        </Group>       
                                </Modal>  
                            :   ""
                        }                        
                        <div className="mrgB30"></div>

                        <div className="width90pCenter">
                                <DeleteButton onClick={handleOpenDeleteModal}>
                                    Supprimer devis
                                </DeleteButton>
                                { open && deleteQuotation === true
                                    ?   <Modal 
                                            centered
                                            withCloseButton={false}    
                                            opened={opened} 
                                            onClose={close}
                                            size="auto"
                                            className='useRoboto'>
                                                <p className="txt15 txtCenter mrgT5 noMrgB"> Supprimer votre devis ?</p>
                                                <p className="txtCenter noMrgTB txt12">Cette action est définitive.</p>

                                                <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20">
                                                    <Button variant="outline" color="dark" onClick={close} className='useRoboto medium'>
                                                        Annuler
                                                    </Button>
                                                    <Button variant="filled" color="dark" className='useRoboto medium' onClick={handleDeleteSubmit}>
                                                        Supprimer
                                                    </Button>
                                                </Group>       
                                        </Modal>
                                    :   ""
                                }
                        </div>
                        
                    </>
                ) :(
                    <>
                        <AppDesktopLogo/>
                        <AppHdWithCloseRight>Information devis</AppHdWithCloseRight>
                        <div className='width50pCenter'>
                            <Link to={`/p/quo/info-offer/${offerId}`} className="aReset bT">               
                                <Box>
                                    <div className="horizontal iCenter useRoboto mrgT10 mrgB10">
                                        <div className="vertical mrgL20 mrgR20">                         
                                            <div>
                                            {actualQuotation.offer.pictures.length > 0
                                                ?    <img 
                                                        className="roundImg100"
                                                        src={actualQuotation.offer.pictures[0]}
                                                        alt='photo_chantier'/> 
                                                :   <>
                                                        {actualQuotation.offer.author.company.logo
                                                            ?   <img 
                                                                    className="roundImg100"
                                                                    src={actualQuotation.offer.author.company.logo}
                                                                    alt='photo_chantier'/>
                                                            :   <div className="noAvatarProfile">{actualQuotation.offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                        }
                                                    </>
                                            }   
                                            </div>                            
                                        </div>

                                        <div className="vertical jCenter">
                                            <div className='horizontal iBtm'>
                                                <p className="txt16 thin noMrgTB capitals">{actualQuotation.offer.author.company.name}</p> 

                                                <div className='mrgR10'></div>

                                                <div>
                                                    {actualQuotation.offer.urgent 
                                                        ?   <Badge size='lg' color="red">Urgent</Badge> 
                                                        :   ""
                                                    }
                                                </div>
                                            </div>

                                            <div>
                                                <p className="txt18 mediumplus noMrgT mrgB5">{actualQuotation.offer.title}</p>
                                            </div>
                                            
                                            <div className="horizontal iCenter mrgB5">
                                                <IconHammer width={20} height={20} className="mrgR10"/>
                                                <p className="noMrgTB txt16 mrgR5">{actualQuotation.offer.occupation}</p>
                                            </div>
                                            <div className="horizontal iCenter mrgB5">
                                                <IconCalendarEvent width={20} height={20} className="mrgR10"/>
                                                <p className="noMrgTB txt16 mrgR5">Du {actualQuotation.startDate} au {actualQuotation.endDate}</p>
                                            </div>
                                            <div className="horizontal iCenter mrgB5">
                                                <IconLocation width={20} height={20} className="mrgR10"/>
                                                <p className="noMrgTB txt16 mrgR5">{actualQuotation.offer.cityOfIntervention}</p>
                                            </div>     
                                        </div>
                                    </div>
                                </Box>
                            </Link>
                        </div>
                        <div className="mrgB20"></div>

                        <div className="vertical width50pCenter">
                            <div className="horizontal iCenter mrgB10">
                                <div><p className="txt18 mediumplus noMrgT mrgB5 mrgR10 mrgL20">Devis envoyé le {format(parseISO(actualQuotation.sendDate), 'dd/MM/yyyy')}</p></div>
                                <div>
                                    {actualQuotation.viewedBySyndic 
                                        ?   <Badge size='lg' color="green" className='noMrgT mrgB5'>Vu</Badge>
                                        :   ""
                                    }
                                </div>
                            </div>
                            <div className='vertical iStart'>
                                {actualQuotation && (
                                    <Box>
                                        <div className='horizontal jCenter iCenter spaceBtw mrgT10 mrgB10 mrgL20 mrgR20'>
                                            <div><p className='bold mrgT5 mrgB5 txt18'>{quotationName}</p></div>
                                            <div>
                                                <div onClick={openPDF} className='aReset bT'>
                                                    <IconEye width={23} className='mrgL10'/>
                                                </div>
                                            </div>
                                        </div>
                                    </Box>
                                )}

                                <div className='mrgB10'></div>

                                <FileButton size='lg' leftIcon={<IconPencil width={23} />} className='mrgT5 useRoboto bT w100' variant="outline" color="gray" onChange={fileBrowseHandler} accept="image/png,image/jpeg,image/jpg">
                                    {(props) => <Button {...props}><p className='txt18'>Modifier Devis</p></Button>}
                                </FileButton>
                            </div>
                            {openModalTooBigPdf 
                                    ?   <Modal 
                                            centered
                                            size="auto"
                                            opened={openedModalTooBigPdf}
                                            onClose={closeModalTooBigPdf}
                                            withCloseButton={false}>
                                            <p className="useRoboto txt16 txtCenter noMrgTB">
                                                10 Mo Maximum par fichier
                                            </p>
                                        </Modal> 
                                    :   ""
                                }
                                {openModalWrongType 
                                    ?   <Modal 
                                            centered
                                            size="auto"
                                            opened={openedModalWrongType}
                                            onClose={closeModalWrongType}
                                            withCloseButton={false}>
                                            <p className="useRoboto txt16 txtCenter noMrgTB">
                                                Fichiers autorisés : .pdf, .png, .jpg, .jpeg
                                            </p>
                                        </Modal> 
                                    :   ""
                                }
                            <p className="txtJustify txt15 dGreyT lineH15 mrgB30">
                                Vérifier votre fichier et sa qualité avant de l'envoyer.
                                Tout document illisible ou ne correspondant pas à un devis sera refusé.
                            </p>
                        </div>

                        <div className='width50pCenter'>                  
                            <PrestaRectangleButtonMain onClick={handleOpenModifyModal}>
                                Enregistrer
                            </PrestaRectangleButtonMain>
                        </div>  
                        { open && deleteQuotation === false
                            ?   <Modal 
                                    centered
                                    withCloseButton={false}    
                                    opened={opened} 
                                    onClose={close}
                                    size="auto"
                                    className='useRoboto'>
                                        <p className="txtCenter mrgT5 txt16">Modifier votre devis ?</p>

                                        <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20">
                                            <Button size='md' variant="outline" color="dark" onClick={close} className='useRoboto'>
                                                <p className='txt16 medium'>Annuler</p>
                                            </Button>
                                            <Button size='md' onClick={handleFormSubmit} variant="filled" color="dark" id={actualQuotation._id} className='useRoboto medium'>
                                            <p className='txt16'>Modifier</p>
                                            </Button>
                                        </Group>       
                                </Modal>  
                            :   ""
                        }                        
                        <div className="mrgB30"></div>

                        <div className="width50pCenter">
                                <DeleteButton onClick={handleOpenDeleteModal}>
                                    Supprimer devis
                                </DeleteButton>
                                { open && deleteQuotation === true
                                    ?   <Modal 
                                            centered
                                            withCloseButton={false}    
                                            opened={opened} 
                                            onClose={close}
                                            size="auto"
                                            className='useRoboto'>
                                                <p className="txt16 txtCenter mrgT5 noMrgB"> Supprimer votre devis ?</p>
                                                <p className="txtCenter noMrgTB txt15">Cette action est définitive.</p>

                                                <Group mt="xl" className="txtCenter horizontal spaceArd mrgT20">
                                                    <Button variant="outline" color="dark" onClick={close} className='useRoboto medium'>
                                                    <p className='txt16 medium'>Annuler</p>

                                                    </Button>
                                                    <Button variant="filled" color="dark" className='useRoboto medium' onClick={handleDeleteSubmit}>
                                                        <p className='txt16'>Supprimer</p>
                                                    </Button>
                                                </Group>       
                                        </Modal>
                                    :   ""
                                }
                        </div>
                        
                    </>
                )}
            </>
        : 
        <Loader />
    )
}

export default QuotationInfoAndEdit;


