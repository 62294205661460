import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconX, IconChevronLeft  } from '@tabler/icons-react';

import classes from './ClosePageOverPictureButton.module.css'

const ClosePageOverPictureButton = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const goToOfferlist = () => {
        navigate('/s/offers/list');
    }
    return(
        <>
            {isMobile ? (
                <>
                    <button
                        className={classes.closeBtn}
                        type={props.type || "button"}
                        onClick={props.onClick}
                        ><IconX className={classes.iconX}
                                onClick={goToOfferlist}/>
                    </button>
                </>
            ) : (
                <>
                    <button
                        className={classes.closeBtn}
                        type={props.type || "button"}
                        onClick={props.onClick}>
                            <div className='horizontal iCenter' onClick={goToOfferlist}>
                                <div>
                                    <IconChevronLeft
                                    width={20}  
                                    className={classes.iconX}
                                    />
                                </div>
                                <div>
                                    <p className='txt16 medium noMrgTB mrgL5'>
                                        Retour liste
                                    </p>
                                </div>
                            </div>
                    </button>
                </>
            )}
        </>
    )
}
export default ClosePageOverPictureButton;