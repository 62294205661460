import { React, useEffect, useContext, useState } from 'react';
import { PrestaFncContext } from '../../../../contexts/prestaFncContext';
import { AuthContext } from '../../../../contexts/authContext';
import { Link, useNavigate } from 'react-router-dom';

import { Carousel } from '@mantine/carousel';
import { IconPhoto, IconMessage, IconHammer, IconLocation, IconBuilding, IconChevronRight, IconCalendarEvent, IconHeartFilled , IconShare2, IconCheck, IconHeart } from '@tabler/icons-react';
import { Badge, Loader } from '@mantine/core';

import MatchHd from '../../../../composents/Headers/Customized/MatchHd/MatchHd';
import AppNavbarPresta from '../../../Navbar/NavbarPresta';
import MatchActionButton from '../../../../composents/Buttons/Presta/MatchAction/MatchActionButton';
import MatchActionButtonDisabled from '../../../../composents/Buttons/Presta/MatchActionDisabled/MatchActionButtonDisabled';
import MatchActionButtonNull from '../../../../composents/Buttons/Presta/MatchActionNull/MatchActionButtonNull';

const Matchlist = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const { getToken, getUserType, handleLogoutSyndic} = useContext(AuthContext);
    const { listOffer, handleSaveSubmit, handleUnsave, handleNewRdvDemandSubmit, getOffers } = useContext(PrestaFncContext)
    const [userData, setUserData ] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
          const token = getToken();
          if (!token) {
            navigate("/p/login");
            return;
          }
          const userType = getUserType();
            if(userType !== 'prestataire'){
                localStorage.setItem("userType", "");
                handleLogoutSyndic()
                navigate("/p/login");
                return;
            }
          try {
            await Promise.all([getUser(), getOffers()]);
            setIsLoading(false);
          } catch (error) {
            console.error(error);
            setIsLoading(false);
          }
        };
    
        fetchData();
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
            };
            window.addEventListener('resize', handleResize);
            return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);
      
    const getUser = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch('https://boldee-97d8d36af3bd.herokuapp.com/p/getUser', {
                method: "GET",    
                headers: {
                    Authorization: `Bearer ${token}`
                }
        });
            const data = await response.json()
            setUserData(data)
        }   catch (error) {
            return null;
        }
    }
    
    const today = new Date().toISOString()

    const openLinkToDocs = (event) => {
        window.open(event, '_blank');
    }
    
    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            {isMobile ? (
                <>
                    <AppNavbarPresta/>
                    
                    <MatchHd>Mes matchs</MatchHd>
                    {listOffer.length === 0 
                        ?   <div className='vertical mrgL30 mrgT20'>  
                                <p className='noMrgT bT txt14 bold'>
                                    Paramétrez vos offres Matchs
                                </p>
                                <Link to="/p/profile/intervention-places" className='aReset'>
                                    <div className='horizontal iCenter mrgT10'>
                                        <p className='noMrgTB terracotaT txt14'>Mettre à jour mes lieux d'intervention</p>
                                        <IconChevronRight className= "terracotaT noMrgTB" height={18} width={20}/>
                                    </div>
                                </Link>
                                <Link to="/p/profile/account" className='aReset'>
                                    <div className='horizontal iCenter mrgT20'>
                                        <p className='noMrgTB terracotaT txt14'>Mettre à jour mon ou mes métier(s)</p>
                                        <IconChevronRight className= "terracotaT noMrgTB" height={18} width={20}/>
                                    </div>
                                </Link>
                            </div>
                        :""
                    }
                    {listOffer.map((offer, index) => (
                        <div key = {index}>  
                            {offer.deadlineToReceiveQuotationDate >= today 
                                ?   <>
                                        <div className='topBorder'>
                                            <Carousel
                                                height={350}
                                                slideSize="33.333333%"
                                                slideGap="md"
                                                breakpoints={[
                                                    { maxWidth: 'md', slideSize: '50%' },
                                                    { maxWidth: 'sm', slideSize: '100%', slideGap: 0 },
                                                ]}
                                                loop
                                                align="start">
                                                {offer.pictures.length > 0
                                                    ?   <>
                                                        {offer.pictures.map((url) => (
                                                            <Carousel.Slide key={url}>
                                                            <img    
                                                                className="imgCarroussel"
                                                                src={url}
                                                                alt='photo_chantier'/>
                                                            </Carousel.Slide>
                                                        ))}
                                                        </>
                                                    :   <>
                                                            {offer.author.company.logo
                                                                ?   <Carousel.Slide className='horizontal iCenter jCenter'>
                                                                        <img    
                                                                            className="bLiteGrey carrouselLogoImg "
                                                                            src={offer.author.company.logo}
                                                                            alt='logo'/>
                                                                    </Carousel.Slide> 
                                                                    :   <div className='horizontal iCenter jCenter w100 dGreyT'><IconPhoto width={48} height={48}/></div>
                                                            }
                                                        </>     
                                                        
                                                }
                                            </Carousel>
                                            <Link className="aReset" to={`/p/matchs/s-info/${offer._id}`}>
                                                <div id="syndic-logo-OffreDeTravaux" >
                                                    {offer.author.company.logo
                                                        ?   <img className='img68x68' 
                                                                src={offer.author.company.logo}
                                                                alt='logo'/>
                                                        :   <div className="img68x68 horizontal iCenter jCenter bLiteGrey txt30 dGreyT medium txtCenter">{offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                    }
                                                </div>
                                            </Link>
                                        </div>
                                            
                                        <div className="offer-first-lines-info">
                                            <div className="horizontal spaceBtw">
                                                <div className="vertical">
                                                    <div className='horizontal iCenter wraped'>
                                                            {offer.recommendTo.find(presta => presta._id === userData._id)
                                                                ?   <Badge color="yellow" className='mrgB5'>{offer.author.company.name} VOUS PROPOSE</Badge>
                                                                :   ""
                                                            }
                                                            
                                                        <div className='horizontal wraped'>
                                                            {offer.recommendFor.map((presta, index) => (
                                                                <div key = {index}>
                                                                    {presta._id !== userData._id && (
                                                                        <Badge color="indigo" className='mrgB5 mrgR5'>
                                                                            RECO {presta.company.name}
                                                                        </Badge>
                                                                    )}
                                                                 </div>
                                                            ))}
                                                            {offer.assigned.assigned ?
                                                                <Badge color="orange" className='mrgB5 mrgR5'>
                                                                    EXCLUSIVITÉ {offer.author.company.name}
                                                                </Badge>
                                                            : ""
                                                            }  
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="horizontal iCenter">
                                                        <IconHammer width={15} className="mrgR5"/>
                                                        <p className="txt15 noMrgTB mrgR5 mrgL10">{offer.occupation}</p>
                                                        <div>{offer.urgent ?
                                                            <Badge color="red">Urgent</Badge> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="horizontal iCenter">
                                                        <IconCalendarEvent width={15} className="mrgR5"/>
                                                        <p className="txt15 noMrgTB mrgL10">Du {offer.startDate} au {offer.endDate}</p>
                                                    </div>
                                                    <div className="horizontal iCenter">
                                                        <IconLocation width={15}className="mrgR5"/>
                                                        <p className="txt15 noMrgTB mrgL10" >{offer.cityOfIntervention}</p>
                                                    </div>
                                                    {offer.nbOfLots > 1 
                                                        ?        
                                                        <div className="horizontal iCenter">
                                                            <IconBuilding width={15}className="mrgR5"/>
                                                            <p className="txt15 noMrgTB mrgL10" >{offer.nbOfLots} lots</p>
                                                        </div>
                                                        : ""
                                                    }
                                                </div>

                                                <div className="vertical iCenter">
                                                    <div className="horizontal iBtm">
                                                        <h2 className="txt20 noMrgTB mrgR2">{offer.deadline}</h2>
                                                        <span>J</span>
                                                    </div>

                                                    {offer.savedBy.includes(userData._id)        
                                                        ?   <div>
                                                                <IconHeartFilled className='terracotaT' width={25} height={30} id={offer._id} onClick={handleUnsave}/>
                                                            </div>
                                                        :    <div>
                                                                <IconHeart className='terracotaT' stroke-width="1.0" width={25} height={30} id={offer._id} onClick={handleSaveSubmit}/>
                                                            </div>
                                                    }
                                                </div>
                                            </div>

                                            <div className="vertical">
                                                <div>
                                                    <p className="txt13 capitals lineH20 noMrgB">{offer.author.company.name}</p>
                                                </div>
                                                <p className="txt16 bold lineH20 noMrgTB">{offer.title}</p>
                                                <p className="txt15 lineH15 mrgT5 noMrgB">{offer.description}</p>
                                                <div>
                                                    {offer.linkToDocs
                                                            ? <div onClick={() => openLinkToDocs(offer.linkToDocs)} className='horizontal iCenter'>
                                                                <p className="underline underlineOffset3 mrgT5 mrgB7 mrgR2">Voir documents</p> <IconChevronRight size={15} className='mrgT8 mrgB7'/>
                                                            </div>
                                                            : ""
                                                        }
                                                </div>
                                                <div className="mrgB20"></div>
                                            </div>

                                            <div className="horizontal iCenter">
                                                <div>
                                                    <Link to={`/p/matchs/p-list-reco/${offer._id}`}>
                                                        <IconShare2 className="aReset bT mrgR40"/>
                                                    </Link>
                                                </div>

                                                <div className="mrgR10"></div>
                                                
                                                {!offer.rdvSelected && !offer.quotationReceptionAllowed
                                                    ?   <>
                                                            <div className="sameWidth">
                                                            {offer.receivedQuotations.length > 0
                                                                ?   <>  
                                                                        {offer.receivedQuotations.map((quot) => quot.author).includes(userData._id)
                                                                            ?  <MatchActionButtonDisabled >
                                                                                    <div className='horizontal iCenter jCenter'>
                                                                                        <p className="noMrgTB mrgR5 txt13">Devis envoyé</p>
                                                                                        <IconCheck  className="noMrgTB" width={15}/>
                                                                                    </div>
                                                                                </MatchActionButtonDisabled>  
                                                                            :  <Link to={`/p/matchs/send-quo/${offer._id}`}>
                                                                                    <MatchActionButton>
                                                                                        <p className='txt13 noMrgTB'>Envoyer devis</p>
                                                                                    </MatchActionButton>
                                                                                </Link>
                                                                        }
                                                                    </>
                                                                :   <Link to={`/p/matchs/send-quo/${offer._id}`}>
                                                                        <MatchActionButton>
                                                                        <p className="noMrgTB mrgR5 txt13">Envoyer devis</p>
                                                                        </MatchActionButton>
                                                                    </Link>
                                                            }
                                                            </div>
                                                            </>
                                                    :   <>
                                                        <div className="sameWidth">
                                                            {offer.rdvSelected 
                                                                ?   <>
                                                                        {offer.rdvDemands.length > 0
                                                                            ?   <>  
                                                                                {offer.rdvDemands.map((rdv)=> rdv.applicant).includes(userData._id) 
                                                                                    ?   
                                                                                        <> 
                                                                                        <MatchActionButtonDisabled >
                                                                                            <div className='horizontal iCenter jCenter'>
                                                                                                <p className="noMrgTB mrgR5 txt13">Demande RDV</p>
                                                                                                <IconCheck  className="noMrgTB" width={15}/>
                                                                                            </div>
                                                                                        </MatchActionButtonDisabled>  
                                                                                        </>
                                                                                    :   <form id={offer._id} onSubmit={handleNewRdvDemandSubmit}>
                                                                                            <div>
                                                                                                <MatchActionButton type='submit'>
                                                                                                    <p className='txt13 noMrgTB'>Demander RDV</p>
                                                                                                </MatchActionButton>
                                                                                            </div>
                                                                                        </form>
                                                                                }
                                                                                </>
                                                                            :   <form id={offer._id} onSubmit={handleNewRdvDemandSubmit}>
                                                                                    <div>
                                                                                        <MatchActionButton type='submit'>
                                                                                            <p className='txt13 noMrgTB'>Demander RDV</p>
                                                                                        </MatchActionButton>
                                                                                    </div>
                                                                                </form>
                                                                            }
                                                                    </>
                                                                :   <>
                                                                        <MatchActionButtonNull>
                                                                            <div className='horizontal iCenter jCenter'>
                                                                                <p className="noMrgTB mrgR5 txt13">RDV non requis</p>
                                                                            </div>
                                                                        </MatchActionButtonNull>
                                                                    </>
                                                            }
                                                        </div>

                                                        <div className="mrgR10"></div>
                                                        
                                                        <div className="sameWidth">
                                                            {offer.quotationReceptionAllowed
                                                                ?   <>
                                                                    {offer.receivedQuotations.length > 0
                                                                        ?   <>  
                                                                                {offer.receivedQuotations.map((quot) => quot.author).includes(userData._id)
                                                                                            ?   <Link to={`/p/quo/list`}> 
                                                                                                    <MatchActionButtonDisabled >
                                                                                                        <div className='horizontal iCenter jCenter'>
                                                                                                            <p className="noMrgTB mrgR5 txt13">Devis envoyé</p>
                                                                                                            <IconCheck  className="noMrgTB" width={15}/>
                                                                                                        </div>
                                                                                                    </MatchActionButtonDisabled>  
                                                                                                </Link>
                                                                                    
                                                                                            :   <>
                                                                                                        <Link to={`/p/matchs/send-quo/${offer._id}`}>
                                                                                                            <MatchActionButton>
                                                                                                                <p className='txt13 noMrgTB'>Envoyer devis</p>
                                                                                                            </MatchActionButton>
                                                                                                        </Link>
                                                                                                </>
                                                                                }  
                                                                            </>
                                                                        :   <Link to={`/p/matchs/send-quo/${offer._id}`}>
                                                                                <MatchActionButton>
                                                                                <p className="noMrgTB mrgR5 txt13">Envoyer devis</p>
                                                                                </MatchActionButton>
                                                                            </Link>
                                                                    }
                                                                    </>
                                                                :   <MatchActionButtonNull>
                                                                        <div className='horizontal iCenter jCenter'>
                                                                            <p className="noMrgTB mrgR5 txt13">Envoyer devis</p>
                                                                        </div>
                                                                    </MatchActionButtonNull>
                                                            }
                                                        </div>
                                                        </>
                                                }                                                
                                            </div>
                                        </div>
                                    </>
                                :   ""
                            }
                        </div>
                    ))}

                    <div id="mrgB50"></div>           
                        
                </>
            ) : (
                <>
                    <AppNavbarPresta/>                    
                    <MatchHd>Mes matchs</MatchHd>  
                    {listOffer.length === 0 
                        ?   <>
                            <div className="width50pCenter vertical">
                                <div className='emptyListNotification'>
                                    <div className= 'horizontal iCenter mrgT10'>
                                        <IconMessage width={100} height={45}/>
                                        <p className='txt20 bold'>
                                            Paramétrez vos offres Matchs
                                        </p>
                                    </div>
                                    
                                <div className='horizontal iCenter'>
                                    <IconMessage width={100} height={45} color='F8F8F8'/>
                                    <Link to="/p/profile/intervention-places" className='aReset'><p className='txt18 terracotaT mrgT10 mrgB30 mrgR50'>Mettre à jour mes lieux d'intervention</p></Link>
                                    <Link to="/p/profile/account" className='aReset'><p className='txt18 terracotaT mrgT10 mrgB30'>Mettre à jour mon ou mes métier(s)</p></Link>
                                </div> 
                                </div>                                                        
                            </div>
                            </>
                        : ""
                    }
                    <div className='width50pCenter mrgB20'>
                        {listOffer.map((offer, index) => (
                            <div key = {index}>  
                                {offer.deadlineToReceiveQuotationDate >= today
                                    ?   <>
                                            <Carousel
                                                style={{
                                                    border:'1px solid #ebebeb',
                                                }}
                                                height={390}
                                                slideSize="100%"
                                                slideGap="xs"
                                                breakpoints={[
                                                    { maxWidth: 'md', slideSize: '90%' },
                                                    { maxWidth: 'sm', slideSize: '100%', slideGap: 0 },
                                                ]}
                                                loop
                                                align="start">
                                                {offer.pictures.length > 0
                                                    ?   <>
                                                            {offer.pictures.map((url) => (
                                                                <Carousel.Slide key={url}>
                                                                <img    
                                                                    className="imgCarroussel"
                                                                    src={url}
                                                                    alt='photo_chantier'/>
                                                                </Carousel.Slide>
                                                            ))}
                                                        </>
                                                    :   <>
                                                            {offer.author.company.logo
                                                                ?   <Carousel.Slide className='horizontal iCenter jCenter'>
                                                                        <img    
                                                                            className="bLiteGrey carrouselLogoImg "
                                                                            src={offer.author.company.logo}
                                                                            alt='logo'/>
                                                                    </Carousel.Slide> 
                                                                    :   <div className='horizontal iCenter jCenter w100 dGreyT'><IconPhoto width={48} height={48}/></div>
                                                            }
                                                        </>     
                                                        
                                                }
                                            </Carousel>
                                               
                                            <div id="syndic-logo-OffreDeTravaux" >
                                                {offer.author.company.logo
                                                    ?   <>
                                                            <Link className="aReset" to={`/p/matchs/s-info/${offer._id}`}>
                                                                <img className='img68x68' 
                                                                        src={offer.author.company.logo}
                                                                        alt='logo'/>
                                                            </Link>
                                                        </>
                                                    :   <>
                                                            <Link className="aReset" to={`/p/matchs/s-info/${offer._id}`}>
                                                                <div className="img68x68 horizontal iCenter jCenter bLiteGrey txt30 dGreyT medium txtCenter">{offer.author.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                            </Link>
                                                        </>
                                                }
                                            </div>
                                            <div className='followPosition'>    
                                                <div className="offer-first-lines-info">
                                                    <div className="horizontal spaceBtw">
                                                        <div className="vertical">
                                                            <div className='horizontal iCenter wraped'>
                                                                {/* <div className='mrgR5'> */}
                                                                    {offer.recommendTo.find(presta => presta._id === userData._id)
                                                                        ?   <Badge size='lg' color="yellow" className='mrgB5'>{offer.author.company.name} VOUS PROPOSE</Badge>
                                                                        :   ""
                                                                    }
                                                                    
                                                                {/* </div> */}
                                                                <div className='horizontal wraped'>
                                                                    {offer.recommendFor.map((presta, index) => (
                                                                        <div key={index}>
                                                                        {presta._id !== userData._id && (
                                                                            <Badge color="indigo" className='mrgB5 mrgR5'>
                                                                                RECO {presta.company.name}
                                                                            </Badge>
                                                                        )}
                                                                    </div>
                                                                    ))}

                                                                {offer.assigned.assigned ?
                                                                    <Badge color="orange" size="lg" className='mrgB5 mrgR5'>
                                                                        EXCLUSIVITÉ {offer.author.company.name}
                                                                    </Badge>
                                                                : ""
                                                                } 
                                                                </div>
                                                            </div>
                                                            <div className="horizontal iCenter">
                                                                <div><IconHammer width={20} className="mrgR5"/></div>
                                                                <div><p className="txt16 noMrgTB mrgR10 mrgL10">{offer.occupation}</p></div>
                                                                <div>
                                                                    {offer.urgent 
                                                                        ?   <Badge size='lg' color="red">Urgent</Badge> 
                                                                        : ""}
                                                                </div>
                                                            </div>
                                                            <div className="horizontal iCenter">
                                                                <IconCalendarEvent width={20} className="mrgR5"/>
                                                                <p className="txt16 noMrgTB mrgL10">Du {offer.startDate} au {offer.endDate}</p>
                                                            </div>
                                                            <div className="horizontal iCenter">
                                                                <IconLocation width={20}className="mrgR5"/>
                                                                <p className="txt16 noMrgTB mrgL10" >{offer.cityOfIntervention}</p>
                                                            </div>
                                                            {offer.nbOfLots > 1 
                                                                ?        
                                                                <div className="horizontal iCenter">
                                                                    <IconBuilding width={20}className="mrgR5"/>
                                                                    <p className="txt16 noMrgTB mrgL10" >{offer.nbOfLots} lots</p>
                                                                </div>
                                                                : ""
                                                            }
                                                        </div>

                                                        <div className="vertical iCenter mrgR20">
                                                            <div className="horizontal iBtm">
                                                                <h2 className="txt20 noMrgTB mrgR2">{offer.deadline}</h2>
                                                                <span>J</span>
                                                            </div>

                                                            {offer.savedBy.includes(userData._id)        
                                                                ?   <div>
                                                                        <IconHeartFilled className='terracotaT' width={25} height={30} id={offer._id} onClick={handleUnsave}/>
                                                                    </div>
                                                                :    <div>
                                                                        <IconHeart className='terracotaT' stroke-width="1.0" width={25} height={30} id={offer._id} onClick={handleSaveSubmit}/>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="vertical">
                                                        <div>
                                                            <p className="txt15 capitals lineH20 mrgB5">{offer.author.company.name}</p>
                                                        </div>
                                                        <p className="txt18 bold lineH20 noMrgTB">{offer.title}</p>
                                                        <p className="txt16 lineH18 mrgT5 noMrgB mrgR20 txtJustify">{offer.description}</p>
                                                        <div>
                                                            {offer.linkToDocs
                                                                    ? <div onClick={() => openLinkToDocs(offer.linkToDocs)} className='horizontal iCenter'>
                                                                        <p className="underline underlineOffset3 mrgT5 mrgB7 mrgR2">Voir documents</p> <IconChevronRight size={15} className='mrgT8 mrgB7'/>
                                                                    </div>
                                                                    : ""
                                                                }
                                                        </div>
                                                        <div className="mrgB20"></div>
                                                    </div>

                                                    <div className="horizontal iCenter">
                                                        <div>
                                                            <Link to={`/p/matchs/p-list-reco/${offer._id}`}>
                                                                <IconShare2 width={25} className="aReset bT mrgR40"/>
                                                            </Link>
                                                        </div>

                                                        <div className="mrgR10"></div>
                                                        
                                                        {!offer.rdvSelected && !offer.quotationReceptionAllowed
                                                            ?   <>
                                                                    <div className="sameWidth">
                                                                    {offer.receivedQuotations.length > 0
                                                                        ?   <>  
                                                                                {offer.receivedQuotations.map((quot) => quot.author).includes(userData._id)
                                                                                    ?  <MatchActionButtonDisabled >
                                                                                            <div className='horizontal iCenter jCenter'>
                                                                                                <p className="noMrgTB mrgR5 txt16">Devis envoyé</p>
                                                                                                <IconCheck  className="noMrgTB" width={15}/>
                                                                                            </div>
                                                                                        </MatchActionButtonDisabled>  
                                                                                    :  <Link to={`/p/matchs/send-quo/${offer._id}`}>
                                                                                            <MatchActionButton>
                                                                                                <p className='txt16 noMrgTB'>Envoyer devis</p>
                                                                                            </MatchActionButton>
                                                                                        </Link>
                                                                                }
                                                                            </>
                                                                        :   <Link to={`/p/matchs/send-quo/${offer._id}`}>
                                                                                <MatchActionButton>
                                                                                <p className="noMrgTB mrgR5 txt16">Envoyer devis</p>
                                                                                </MatchActionButton>
                                                                            </Link>
                                                                    }
                                                                    </div>
                                                                    </>
                                                            :   <>
                                                                <div className="sameWidth">
                                                                    {offer.rdvSelected 
                                                                        ?   <>
                                                                                {offer.rdvDemands.length > 0
                                                                                    ?   <>  
                                                                                        {offer.rdvDemands.map((rdv)=> rdv.applicant).includes(userData._id) 
                                                                                            ?   
                                                                                                <> 
                                                                                                <MatchActionButtonDisabled >
                                                                                                    <div className='horizontal iCenter jCenter'>
                                                                                                        <p className="noMrgTB mrgR5 txt16">Demande RDV</p>
                                                                                                        <IconCheck  className="noMrgTB" width={15}/>
                                                                                                    </div>
                                                                                                </MatchActionButtonDisabled>  
                                                                                                </>
                                                                                            :   <form id={offer._id} onSubmit={handleNewRdvDemandSubmit}>
                                                                                                    <div>
                                                                                                        <MatchActionButton type='submit'>
                                                                                                            <p className='txt16 noMrgTB'>Demander RDV</p>
                                                                                                        </MatchActionButton>
                                                                                                    </div>
                                                                                                </form>
                                                                                        }
                                                                                        </>
                                                                                    :   <form id={offer._id} onSubmit={handleNewRdvDemandSubmit}>
                                                                                            <div>
                                                                                                <MatchActionButton type='submit'>
                                                                                                    <p className='txt16 noMrgTB'>Demander RDV</p>
                                                                                                </MatchActionButton>
                                                                                            </div>
                                                                                        </form>
                                                                                    }
                                                                            </>
                                                                        :   
                                                                            <MatchActionButtonNull>
                                                                                <div className='horizontal iCenter jCenter'>
                                                                                    <p className="noMrgTB mrgR5 txt16">RDV non requis</p>
                                                                                </div>
                                                                            </MatchActionButtonNull>
                                                                    }
                                                                </div>

                                                                <div className="mrgR10"></div>
                                                                
                                                                <div className="sameWidth">
                                                                    {offer.quotationReceptionAllowed
                                                                        ?   <>
                                                                            {offer.receivedQuotations.length > 0
                                                                                ?   <>  
                                                                                        {offer.receivedQuotations.map((quot) => quot.author).includes(userData._id)
                                                                                                    ?   <Link to={`/p/quo/list`}> 
                                                                                                            <MatchActionButtonDisabled >
                                                                                                                <div className='horizontal iCenter jCenter'>
                                                                                                                    <p className="noMrgTB mrgR5 txt16">Devis envoyé</p>
                                                                                                                    <IconCheck  className="noMrgTB" width={15}/>
                                                                                                                </div>
                                                                                                            </MatchActionButtonDisabled>  
                                                                                                        </Link>
                                                                                            
                                                                                                    :   <>
                                                                                                                <Link to={`/p/matchs/send-quo/${offer._id}`}>
                                                                                                                    <MatchActionButton>
                                                                                                                        <p className='txt16 noMrgTB'>Envoyer devis</p>
                                                                                                                    </MatchActionButton>
                                                                                                                </Link>
                                                                                                        </>
                                                                                                }  
                                                                                    </>
                                                                                :   <Link to={`/p/matchs/send-quo/${offer._id}`}>
                                                                                        <MatchActionButton>
                                                                                        <p className="noMrgTB mrgR5 txt16">Envoyer devis</p>
                                                                                        </MatchActionButton>
                                                                                    </Link>
                                                                            }
                                                                            </>
                                                                        :   <div className='w100'>
                                                                                <MatchActionButtonNull >
                                                                                    <div className='horizontal iCenter jCenter'>
                                                                                        <p className="noMrgTB mrgR5 txt16">Envoyer devis</p>
                                                                                    </div>
                                                                                </MatchActionButtonNull>
                                                                            </div>
                                                                    }
                                                                </div>
                                                                </>
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>                                                
                                        </>
                                    :   ""
                                }
                            </div>
                        ))}
                        <div id="mrgB50"></div>
                    </div>   
                </>
            )}
        </>
    )
}


export default Matchlist;