import { React, useEffect, useContext, useState } from 'react';
import { AuthContext } from '../../../../contexts/authContext';
import { Link, useNavigate } from 'react-router-dom';

import { UnstyledButton, Group, Loader } from '@mantine/core';
import { IconUser, IconLock, IconFileCertificate, IconBrandHipchat, IconChevronRight } from '@tabler/icons-react';


import AppNavbarSyndic from '../../../Navbar/NavbarSyndic';
import AppHdSingleTitle from '../../../../composents/Headers/AppHeaders/AppHdSingleTitle/AppHdSingleTitle'
import Box from '../../../../composents/Boxes/Standard/Box';

const ProfileMenu = () => {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [ menuProfile , setMenuProfile ] = useState(null)

    const navigate = useNavigate();

    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/s/login");
            return;
        }
        getUser()

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
      
          window.addEventListener('resize', handleResize);
      
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const getUser = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch('https://boldee-97d8d36af3bd.herokuapp.com/s/getUser', {
                method: "GET",    
                headers: {
                    Authorization: `Bearer ${token}`
                    }
        });
            const data = await response.json()
            setMenuProfile(data)
        }   catch (error) {
            return null;
        }
    }

    return(
        menuProfile ?
            <>
                {isMobile ? (
                    <>
                    <AppNavbarSyndic></AppNavbarSyndic>
                        <>
                            <AppHdSingleTitle>Profil</AppHdSingleTitle>
                            <div className="mrgB20"></div>
                            <div className="txtCenter">
                                <UnstyledButton>
                                    <Group>
                                    <div className="horizontal jCenter">
                                                <div>
                                                    {menuProfile.company.logo     
                                                        ?   <img src={menuProfile.company.logo} alt="logo" className='roundImg70' size={100} />
                                                        :   <div className="noAvatarProfile">{menuProfile.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                    }
                                                </div>
                                                <div className="vertical mrgL20">
                                                    <p className='txt18 medium noMrgB'>{menuProfile.firstname} {menuProfile.lastname}</p>
                                                    <p className='txt15 capitals dGreyT noMrgT noMrgB'>{menuProfile.company.name}</p>
                                                </div>
                                            </div>
                                    </Group>
                                </UnstyledButton>
                            
                                <div className="mrgB20"></div>

                                <Box>
                                    <div  className="profil-menu vertical mrgL10 mrgR20">
                                        <Link to={`/s/profile/account`} className="aReset bT">
                                                <div className="horizontal iCenter spaceBtw">
                                                    <div className="horizontal iCenter paddingMenuProfil">
                                                        <IconUser width={25} className="mrgR10"/>
                                                        <p>Mon compte</p>
                                                    </div>
                                                    <IconChevronRight width={16}/>                                </div>
                                                <hr></hr>
                                        </Link>

                                        <Link to={`/s/profile/param/psw-edit`} className="aReset bT">
                                                <div className="horizontal iCenter spaceBtw">
                                                    <div className="horizontal iCenter paddingMenuProfil">
                                                        <IconLock width={25} className="mrgR10"/>
                                                        <p>Modifier mon mot de passe</p>
                                                    </div>
                                                    <IconChevronRight width={16}/>                                </div>
                                                <hr></hr>
                                        </Link>

                                        <Link to="/s/profile/disclaimer" className="aReset bT">
                                                <div className="horizontal iCenter spaceBtw">
                                                    <div className="horizontal iCenter paddingMenuProfil">
                                                        <IconFileCertificate width={25} className="mrgR10"/>
                                                        <p>Mentions légales</p>
                                                    </div>
                                                    <IconChevronRight width={16}/>                                </div>
                                                <hr></hr>
                                        </Link>

                                        <Link to="/s/profile/contact" className="aReset bT">
                                                <div className="horizontal iCenter spaceBtw">
                                                    <div className="horizontal iCenter paddingMenuProfil">
                                                        <IconBrandHipchat width={25} className="mrgR10"/>
                                                        <p>Nous contacter</p>
                                                    </div>
                                                    <IconChevronRight width={16}/>                                </div>   
                                        </Link>
                                    </div>
                                </Box>
                                
                                <div className='mrgB10'></div>

                                <div>
                                    <p id="versionApp">Boldee V.beta 1</p>
                                </div>
                            
                                <div className='mrgB150'></div>

                            </div>
                        </>   
                    </>
                ) : (
                    <>
                    <AppNavbarSyndic/>
                        <div className='width50pCenter'>
                            <AppHdSingleTitle>Profil</AppHdSingleTitle>
                            <div className="mrgB30"></div>
                            <div className="txtCenter">
                                <UnstyledButton>
                                    <Group>
                                    <div className="horizontal iCenter">
                                                <div>
                                                    {menuProfile.company.logo     
                                                        ?   <img src={menuProfile.company.logo} alt="logo" className='roundImg100' size={100} />
                                                        :   <div className="noAvatarProfile">{menuProfile.company.name.split(" ").map((word) => word.charAt(0)).join("")}</div>
                                                    }
                                                </div>
                                                <div className="vertical mrgL20">
                                                    <p className='txt20 medium noMrgT mrgB5'>{menuProfile.firstname} {menuProfile.lastname}</p>
                                                    <p className='txt18 capitals dGreyT noMrgT noMrgB'>{menuProfile.company.name}</p>
                                                </div>
                                            </div>
                                    </Group>
                                </UnstyledButton>
                            
                                <div className="mrgB30"></div>

                                <Box>
                                    <div  className="profil-menu vertical mrgL20 mrgR20">
                                        <Link to={`/s/profile/account`} className="aReset bT">
                                                <div className="horizontal iCenter spaceBtw">
                                                    <div className="horizontal iCenter paddingMenuProfil">
                                                        <IconUser width={30} className="mrgR10"/>
                                                        <p>Mon compte</p>
                                                    </div>
                                                    <IconChevronRight width={25}/>                                </div>
                                                <hr></hr>
                                        </Link>

                                        <Link to={`/s/profile/param/psw-edit`} className="aReset bT">
                                                <div className="horizontal iCenter spaceBtw">
                                                    <div className="horizontal iCenter paddingMenuProfil">
                                                        <IconLock width={30} className="mrgR10"/>
                                                        <p>Modifier mon mot de passe</p>
                                                    </div>
                                                    <IconChevronRight width={25}/>                                </div>
                                                <hr></hr>
                                        </Link>

                                        <Link to="/s/profile/disclaimer" className="aReset bT">
                                                <div className="horizontal iCenter spaceBtw">
                                                    <div className="horizontal iCenter paddingMenuProfil">
                                                        <IconFileCertificate width={30} className="mrgR10"/>
                                                        <p>Mentions légales</p>
                                                    </div>
                                                    <IconChevronRight width={25}/>                                </div>
                                                <hr></hr>
                                        </Link>

                                        <Link to="/s/profile/contact" className="aReset bT">
                                                <div className="horizontal iCenter spaceBtw">
                                                    <div className="horizontal iCenter paddingMenuProfil">
                                                        <IconBrandHipchat width={30} className="mrgR10"/>
                                                        <p>Nous contacter</p>
                                                    </div>
                                                    <IconChevronRight width={25}/>                                </div>   
                                        </Link>
                                    </div>
                                </Box>
                                
                                <div className='mrgB10'></div>

                                <div>
                                    <p id="versionApp">Boldee V.beta 1</p>
                                </div>
                            
                                <div className='mrgB150'></div>

                            </div>
                        </div>   
                    </>
                )}
            </>
        : 
        <Loader />
    )
    
}

export default ProfileMenu;


