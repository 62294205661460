import React from "react";
import ecoartisanLogo from '../../../../assets/images/ecoartisan.png'
import classes from './LogoEcoArtisan.module.css';

const LogoEcoArtisan = (props) => {
    return(
        <div className={classes.Logo}>
            <img src={ecoartisanLogo} alt="logo"/>
        </div>
    )
}
export default LogoEcoArtisan;