import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import { Menu, Button, Burger} from '@mantine/core';
import { IconBuildingEstate, IconBarrierBlock, IconBrandHipchat, IconUserPlus } from '@tabler/icons-react';
import LogoNavbar from '../../composents/Logo/NavbarLogo/Logo';
import LogoBurgerMenu from '../../composents/Logo/BurgerMenuLogo/LogoBurgerMenu';

const Navbar = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [opened, { toggle }] = useDisclosure(false);
    const label = opened ? 'Close navigation' : 'Open navigation';

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>       
            <div className="navbar">
                   
                    
                {isMobile ? (
                    <>
                        <div className='horizontal iCenter w100 jCenter spaceBtw'>
                            <div className='mrgL10'>
                                <Link to="/">
                                    <LogoNavbar />
                                </Link>
                            </div>
            
                            <div className='horizontal mrgT10 mrgLauto'>
                                <div>
                                    <Menu color='dark' shadow="md" width={200} offset={10}>
                                        <Menu.Target>
                                            <Button>
                                                <p className='txt15 medium useRoboto'>Connexion</p>
                                            </Button>
                                        </Menu.Target>

                                        <Menu.Dropdown>
                                            <Menu.Item>
                                                <Link to ="/s/login" className="aReset bT">
                                                    <p className='txt15 noMrgTB'>Syndic de copropriétés</p>
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <Link to ="/p/login" className="aReset bT   ">
                                                <p className='txt15 noMrgTB'>Artisan - Prestataire</p>
                                                </Link>
                                            </Menu.Item>
                                        </Menu.Dropdown>
                                    </Menu>
                                </div>
                                <div className='mrgL20 mrgR20'>
                                    <Burger opened={opened} onClick={toggle} aria-label={label} />
                                </div>
                            </div>
                        </div>
                        {opened &&  (                            
                            <ul className="menu__box">
                                <Link to="/">
                                    <LogoBurgerMenu />
                                </Link>
                                <li><Link to="/s/login" className="menu__item mrgT20">
                                    <div className="horizontal">
                                        <IconBuildingEstate width={18} className="mrgR10 logo_size"/>Syndics de copropriétés
                                    </div>
                                    </Link>
                                </li>
                                <li><Link to="/p/login" className="menu__item">
                                    <div className="horizontal">
                                        <IconBarrierBlock width={18} className="mrgR10 logo_size"/>Artisans - Prestataires
                                    </div>
                                    </Link>
                                </li>
                                <li><Link to="/contact" className="menu__item">
                                    <div className="horizontal">
                                        <IconBrandHipchat width={18} className="mrgR10 logo_size"/>Contact
                                    </div>
                                    </Link>

                                </li>
                                <li><Link to="/signup" className="menu__item">
                                    <div className="horizontal terracotaT">
                                        <IconUserPlus width={18} className="mrgR10 logo_size"/>S'inscrire
                                    </div>
                                    </Link>
                                </li>
                            </ul>
                        )}
                    </>
                ) : (
                    <>
                        <div className='horizontal iCenter w100 jCenter spaceBtw'>
                            <div className='mrgL10'>
                                <Link to="/">
                                    <LogoNavbar />
                                </Link>
                            </div>
                            <div className='horizontal mrgT10 iCenter mrgLauto'>
                                <div>
                                    <Menu color='dark' shadow="md" width={200} trigger="hover" openDelay={100} closeDelay={400} position="right" offset={10}>
                                        <Menu.Target>
                                            <Button>
                                                <p className='txt18 medium useRoboto'>Connexion</p>
                                            </Button>
                                        </Menu.Target>

                                        <Menu.Dropdown>
                                            <Menu.Item>
                                                <Link to ="/s/login" className="aReset bT">
                                                    <p className='txt15 noMrgTB'>Syndic de copropriétés</p>
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <Link to ="/p/login" className="aReset bT">
                                                    <p className='txt15 noMrgTB'>Artisan - Prestataire</p>
                                                </Link>
                                            </Menu.Item>
                                        </Menu.Dropdown>
                                    </Menu>
                                </div>
                                <div className='mrgL20 mrgR20'>
                                        <Burger opened={opened} onClick={toggle} aria-label={label} size='lg' />                        
                                    </div>
                            </div>
                        </div>
                        {opened &&  (
                            
                        <ul className="menu__box">
                            <Link to="/">
                                <LogoBurgerMenu />
                            </Link>
                            <li><Link to="/s/login" className="menu__item mrgT20">
                                <div className="horizontal">
                                    <IconBuildingEstate width={18} className="mrgR10 logo_size"/>Syndics de copropriétés
                                </div>
                                </Link>
                            </li>
                            <li><Link to="/p/login" className="menu__item">
                                <div className="horizontal">
                                    <IconBarrierBlock width={18} className="mrgR10 logo_size"/>Artisans - Prestataires
                                </div>
                                </Link>
                            </li>
                            <li><Link to="/contact" className="menu__item">
                                <div className="horizontal">
                                    <IconBrandHipchat width={18} className="mrgR10 logo_size"/>Contact
                                </div>
                                </Link>

                            </li>
                            <li><Link to="/signup" className="menu__item">
                                <div className="horizontal terracotaT">
                                    <IconUserPlus width={18} className="mrgR10 logo_size"/>S'inscrire
                                </div>
                                </Link>
                            </li>
                        </ul>
                        )}
                    </>
                )}
                   
            </div>       
        </>
      )}
      
  
  export default Navbar;