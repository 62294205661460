import { React, useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../../../../contexts/authContext';
import { useNavigate } from 'react-router-dom';

import { TextInput, NumberInput, Textarea, Box, Autocomplete, Select, Checkbox, Stepper, Modal, Button, FileButton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { DatePickerInput } from '@mantine/dates';
import { subDays , addDays, addYears } from 'date-fns';
import { IconCalendar, IconX, IconPlus } from '@tabler/icons-react';
import 'dayjs/locale/fr';

import AddOfferHd from '../../../../../composents/Headers/Customized/AddOfferHd/AddOfferHd';
import SyndicRectangleButton from '../../../../../composents/Buttons/Syndic/RectangleButton/RectangleButtonMain';
import SyndicRectangleButtonSnd from '../../../../../composents/Buttons/Syndic/RectangleButton/RectangleButtonSecond';
import AppDesktopLogo from '../../../../../composents/Logo/AppDesktopLogo/AppDesktopLogo';

function AddNewOfferSyndic() {
    const { getToken } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
    const [rdv, setRdv] = useState(false);
    const [previewPictures, setPreviewPictures] = useState([]);

    const [ openedModalLinkToDocs, { open : openModalLinkToDocs, close: closeModalLinkToDocs}]= useDisclosure(false);
    const [ openedImgLimit, { open : openModalImgLimit, close : closeModalImgLimit }] = useDisclosure(false);
    const [ openedExistingImg, { open: openModalExistingImg, close: closeModalExistingImg}] = useDisclosure(false)
    const [ openedTooBigImg, { open: openModalTooBigImg, close: closeModalTooBigImg}] = useDisclosure(false)
    const [ openedModalWrongType, { open: openModalWrongType, close: closeModalWrongType}] = useDisclosure(false);

    const [query, setQuery] = useState('');
    const [options, setOptions] = useState([]);
    const [cityOfIntervention, setCityOfIntervention] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/s/login");
            return;
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const form = useForm({
      initialValues: {
        title: '',
        urgent: false,
        cityOfIntervention: '',
        occupation: '',
        workRangeDates: ["", ""],
        deadlineToReceiveQuotationDate: addDays(new Date(),1),
        rdvSelected: false,
        quotationReceptionAllowed: false,
        nbOfLots: '',
        description: '',
        linkToDocs: '',
        pictures:[]
      },
  
      validate: {
        title : (value) => (
            value.length < 1
            ? 'Renseignez le titre de votre offre'
            : value.length > 90 
            ? '90 caractères maximum' 
            : null
        ),
        occupation : (value) => (value.length < 1 ? 'Sélectionnez le métier recherché': null),
        workRangeDates : (value) => (value[0].length || value[1].length < 1 ? 'Sélectionnez les dates prévisionnelles de travaux' : null),
        description : (value) => (value.length > 300 ? '300 caractères maximum' : null),
    },
    });

    const minDeadline = subDays(form.values.workRangeDates.at(0),1);
    const HandleSetRdv = () => {
        setRdv(!rdv)
    }

    const [active, setActive] = useState(0);
    const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

    const handleQueryChange = async (value) => {
        setQuery(value);

        if (value) {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch('https://boldee-97d8d36af3bd.herokuapp.com/s/offers/search-cityOfIntervention', {
                    method: 'POST',
                    body: JSON.stringify({ query: value }),
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }          
                })

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                  }
                const data = await response.json();
                const suggestionsMap = new Map();

                data.features.forEach((feature) => {
                const city = feature.properties.city || '';
                const postcode = feature.properties.postcode || '';
                const suggestionKey = `${city}-${postcode}`;

                suggestionsMap.set(suggestionKey, {
                    value: `${postcode} ${city}`,
                    label: `${postcode} ${city}`,
                    postcode: postcode,
                    city: city
                });
                });

                const uniqueSuggestions = Array.from(suggestionsMap.values());

                setOptions(uniqueSuggestions);
            } catch (error) {
            }
        }
    
    };

    const handleQuerySubmit = (citySelected) => {
        setCityOfIntervention(citySelected.target.value) 
    };   

    const handleFormSubmit = async (values, _event) => {
        _event.preventDefault();
        try {
            const token = localStorage.getItem('token');
            const response = await fetch("https://boldee-97d8d36af3bd.herokuapp.com/s/offers/add", {
                method: "POST",
                body: JSON.stringify({
                    assigned : {
                        assigned : false,
                        assignedTo : null
                    },
                    title: values.title,
                    urgent: values.urgent,
                    cityOfIntervention: cityOfIntervention,
                    occupation: values.occupation,
                    workRangeDates: values.workRangeDates,
                    deadlineToReceiveQuotationDate: values.deadlineToReceiveQuotationDate,
                    rdvSelected: values.rdvSelected,
                    quotationReceptionAllowed: values.quotationReceptionAllowed,
                    nbOfLots: values.nbOfLots,
                    description: values.description,
                    linkToDocs: values.linkToDocs,
                    pictures: previewPictures
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            const newOffer = await response.json();
            sessionStorage.setItem("offer", JSON.stringify(newOffer));
            navigate(`/s/offers/add-preview/${newOffer._id}`)
        } catch (error) {
        }
    }

    const fileBrowseHandler = (event) => {
        let readers = [];
        const limitImages = 3
        const maxSizeInBytes = 10*1024*1024
        for(let i = 0;i < event.length;i++){
           
            if(event[i].size > maxSizeInBytes) {
                openModalTooBigImg()
            } else if(event[i].type !== 'image/png' && event[i].type !== 'image/jpeg' && event[i].type !== 'image/jpg'){
                openModalWrongType()
            } else if (event[i].size <= maxSizeInBytes) {
            let reader = new FileReader();
            reader.readAsDataURL(event[i])
            reader.onload = () => {
                if(!previewPictures.concat([...readers]).includes(reader.result)) {
                    readers.push(reader.result);
                    if(previewPictures.concat([...readers]).length <= limitImages ) {
                        setPreviewPictures(previewPictures.concat([...readers]))
                    }   else if (previewPictures.concat([...readers]).length > limitImages){
                        setPreviewPictures(previewPictures.concat([...readers]).slice(0,3))
                        openModalImgLimit()
                    }
                } else if (previewPictures.concat([...readers]).includes(reader.result)) {
                    openModalExistingImg()
                }
            };
           
              
            reader.onerror = function (error) {
            }
        }
        }      
    }

    const handleVerifyLinkToDocs = (event) => {
        event.preventDefault();
        const linkToDocForVerification = form.values.linkToDocs.trim();
        if(!linkToDocForVerification) {
            openModalLinkToDocs();
        } else {
            window.open(linkToDocForVerification, '_blank');
        }        
    }

    const deleteImg = (event) => {
        const pictureToDelete = previewPictures.filter(picture => picture !== event.currentTarget.value);
        setPreviewPictures(pictureToDelete);
    }
    
    return(
        <>  
            {isMobile ? (
                <>
                    <AddOfferHd>Publier une offre</AddOfferHd>
                    <form onSubmit={form.onSubmit(handleFormSubmit)}>
                        <div className="width90pCenter roboto">
                            <Stepper color="gray" size="xs" active={active} onStepClick={setActive} breakpoint={60}>
                                {/* 1 sur 3 */}
                                <Stepper.Step label="Votre besoin" >
                                    <Box id="stepForm-mrg-t">
                                        <TextInput
                                            placeholder="Titre"
                                            label="Titre de l'offre"
                                            withAsterisk
                                            {...form.getInputProps('title')}
                                        />
                                        <div className="mrgB10"></div>

                                        <Select
                                            label="Métier recherché"
                                            placeholder="Sélectionnez"
                                            data={[
                                                { value: 'Entreprise de travaux', label: 'Entreprise de travaux' },
                                                { value: 'Electricien', label: 'Electricien' },
                                                { value: 'Menuisier', label: 'Menuisier' },
                                                { value: 'Plombier', label: 'Plombier' },
                                                { value: 'Entreprise de nettoyage, Gardien, Concierge', label: 'Entreprise de nettoyage, Gardien, Concierge' },
                                                { value: 'Jardinier- Paysagiste', label: 'Jardinier- Paysagiste' },
                                                { value: 'Entreprise de maintenance et de dépannage', label: 'Entreprise de maintenance et de dépannage' },
                                                { value: 'Chauffagiste', label: 'Chauffagiste' },
                                                { value: 'Désinfection, Désinsectisation, Dératisation', label: 'Désinfection, Désinsectisation, Dératisation' },
                                                { value: 'Architecte, Bureaux d\'études', label: 'Architecte, Bureaux d\'études' },
                                                { value: 'Assureur', label: 'Assureur' },
                                                { value: 'Avocat spécialisé en droit immobilier', label: 'Avocat spécialisé en droit immobilier' },
                                                { value: 'Autre', label: 'Autre' }
                                            ]}
                                            withAsterisk
                                            {...form.getInputProps('occupation')}
                                        />
                                        <div className="mrgB10"></div>
                                            
                                        <Autocomplete
                                            label="Code postal du lieu d'intervention"
                                            placeholder="Code postal"
                                            withAsterisk
                                            value={query}
                                            onChange={handleQueryChange}
                                            onSelect={handleQuerySubmit}
                                            data={options}  
                                            textFieldProps={{ required: true }}
                                        />

                                        <div className="mrgB20"></div>
                                        
                                        <Checkbox 
                                            labelPosition="left"
                                            label="Est-ce une urgence ?"
                                            size="md"
                                            color="dark"
                                            // urgent={urgent}
                                            // onClick={HandleSetUrgent}
                                            {...form.getInputProps('urgent', { type: 'checkbox' })} 
                                            />
                                        <div className="mrgB10"></div>

                                        <DatePickerInput
                                            icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                                            type="range"
                                            valueFormat="DD MMM YYYY"                          
                                            locale="fr"
                                            label="Dates prévisionnelles de travaux"
                                            placeholder="Sélectionnez vos dates"
                                            minDate= {new Date()}
                                            maxDate = {addYears(new Date(),3)}
                                            allowSingleDateInRange
                                            clearable
                                            withAsterisk
                                            required
                                            {...form.getInputProps('workRangeDates')}
                                        />
                                        
                                        <div className="mrgB30"></div>
                                    
                                        <SyndicRectangleButton onClick={nextStep}>
                                            Continuer
                                        </SyndicRectangleButton>
                                    </Box>
                                </Stepper.Step>

                                {/* 2 sur 3 */}
                                <Stepper.Step label="Vos modalités">
                                    <Box id="stepForm-mrg-t">
                                        <DatePickerInput
                                            icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                                            locale="fr"
                                            defaultValue={addDays(new Date(),1)}
                                            valueFormat="DD MMM YYYY"    
                                            minDate={addDays(new Date(),1)}
                                            maxDate={minDeadline}
                                            placeholder="Sélectionnez une date" 
                                            label="Délai de réception des devis" 
                                            description="Renseignez la date d'échéance jusqu'à laquelle vous souhaitez recevoir les devis. Votre offre reste publiée tant que cette date n'est pas échue."
                                            withAsterisk
                                            required
                                            {...form.getInputProps('deadlineToReceiveQuotationDate')}
                                            />
                                        <div className="mrgB20"></div>

                                        <Checkbox
                                            labelPosition="left"
                                            label="Nécessité d'un RDV sur site pour établir un devis"
                                            color="dark"
                                            size="md"
                                            rdv={rdv}
                                            onClick={HandleSetRdv}
                                            {...form.getInputProps('rdvSelected', { type: 'checkbox' })}
                                            />
                                        <div className="mrgB20"></div>

                                        { rdv ?
                                        <> 
                                            <Checkbox
                                                labelPosition="left"
                                                label="Autoriser les prestataires à envoyer des devis sans RDV de chiffrage"
                                                size="md"
                                                color="dark"
                                                // quotationAllowed={quotationAllowed}
                                                // onClick={HandlesetQuotationAllowed}
                                                {...form.getInputProps('quotationReceptionAllowed', { type: 'checkbox' })}
                                                />
                                            <div className="mrgB10"></div>
                                        </>
                                        : ''
                                        } 

                                        <div className="mrgB30"></div>

                                        <SyndicRectangleButtonSnd onClick={prevStep}>
                                            Retour
                                        </SyndicRectangleButtonSnd>
                                        
                                        <div className="mrgB10"></div>

                                        <SyndicRectangleButton onClick={nextStep}>
                                            Continuer
                                        </SyndicRectangleButton>
                                        
                                        <div id="mrgB50"></div>

                                    </Box>
                                </Stepper.Step>

                                {/* 3 sur 3 */}
                                <Stepper.Step  label="Boostez & Publiez">
                                    <Box id="stepForm-mrg-t">
                                        <NumberInput
                                            placeholder="Nombre"
                                            label="Nombre de lot(s)"
                                            max={99999}
                                            min={1}
                                            {...form.getInputProps('nbOfLots')}
                                        />
                                        <div className="mrgB10"></div>

                                        <Textarea
                                            label="Description du projet"
                                            {...form.getInputProps('description')}
                                        />
                                        <div className="mrgB10"></div>

                                        <div className='horizontal iEnd'>
                                            <div className='linkToDocsInput'>
                                                <TextInput
                                                    label="Lien vers document(s) de l'offre"
                                                    placeholder='https://'                   
                                                    {...form.getInputProps('linkToDocs')}
                                                />
                                            </div>
                                            <button onClick={handleVerifyLinkToDocs} className='btnReset mrgLauto useRoboto linkToDocsBtn'>
                                                Vérifier lien
                                            </button>
                                        </div>
                                        <div className="mrgB10"></div>

                                        {openModalLinkToDocs  
                                            ?   <Modal 
                                                    centered
                                                    size="50%"
                                                    opened={openedModalLinkToDocs}
                                                    onClose={closeModalLinkToDocs}
                                                    withCloseButton={false}>
                                                    <p className="useRoboto txt15 txtCenter noMrgTB">
                                                        Aucun lien à vérifier
                                                    </p>
                                                </Modal> 
                                            :   ""
                                        }

                                        <p className="useRoboto bold txt15 mrgB5">Photos (3 maximum)</p>
                                        
                                        <div className='horizontal'>
                                            {previewPictures.length > 0 
                                                ?   <div className='horizontal mrgT5 mrgB5'>
                                                        {previewPictures.map( (picture, index) => (
                                                            <div key = {picture} style = {{marginRight : '5px'}} className='vertical imgRelative'>
                                                                <img className='img65x65' src={picture} alt='photo_chantier'/>
                                                                <button value={picture} onClick={deleteImg} className='btnReset imgDeleteImgBtnAbsolute mrgR5 mrgT5 bold bLiteTransparent'><IconX width={13} height={13}/></button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                :   ""
                                            }

                                            <FileButton
                                                className='img68x68T mrgT5' 
                                                multiple
                                                onChange={fileBrowseHandler}
                                                accept="image/png,image/jpeg,image/jpg">
                                                {(props) => <Button color="gray" {...props}>
                                                    <div className='vertical iCenter'>
                                                        <IconPlus width={20}/> 
                                                        <p className='noMrgTB'>Ajouter photo</p>
                                                    </div> 
                                                </Button>}
                                            </FileButton>

                                            {openModalImgLimit  
                                                ?   <Modal 
                                                        centered
                                                        size="50%"
                                                        opened={openedImgLimit}
                                                        onClose={closeModalImgLimit}
                                                        withCloseButton={false}>
                                                        <p className="useRoboto txt15 txtCenter noMrgTB">
                                                            Chargement de 3 photos maximum
                                                        </p>
                                                    </Modal> 
                                                :   ""
                                            }

                                            {openModalExistingImg  
                                                ?   <Modal 
                                                        centered
                                                        size="50%"
                                                        opened={openedExistingImg}
                                                        onClose={closeModalExistingImg}
                                                        withCloseButton={false}>
                                                        <p className="useRoboto txt15 txtCenter noMrgTB">
                                                            Photo déjà téléchargée
                                                        </p>
                                                    </Modal> 
                                                :   ""
                                            }
                                            
                                            {openModalTooBigImg  
                                                ?   <Modal 
                                                        centered
                                                        size="50%"
                                                        opened={openedTooBigImg}
                                                        onClose={closeModalTooBigImg}
                                                        withCloseButton={false}>
                                                        <p className="useRoboto txt15 txtCenter noMrgTB">
                                                            10 Mo Maximum par photo
                                                        </p>
                                                    </Modal> 
                                                :   ""
                                            }
                                            {openModalWrongType 
                                                ?   <Modal 
                                                        centered
                                                        size="50%"
                                                        opened={openedModalWrongType}
                                                        onClose={closeModalWrongType}
                                                        withCloseButton={false}>
                                                        <p className="useRoboto txt15 txtCenter noMrgTB">
                                                            Fichiers autorisés : .png, .jpeg, .jpg
                                                        </p>
                                                    </Modal> 
                                                :   ""
                                            }
                                        </div>

                                        <div className="mrgB30"></div>

                                        <SyndicRectangleButtonSnd onClick={prevStep}>
                                                Retour
                                        </SyndicRectangleButtonSnd>
                                            
                                        <div className="mrgB10"></div>

               
                                        <SyndicRectangleButton type="submit">
                                            Visualiser mon offre
                                        </SyndicRectangleButton>
                                    </Box>
                                </Stepper.Step>
                            </Stepper>
                        </div>
                    </form>
                </>
            ) : (
                <>
                    <AppDesktopLogo />
                    <AddOfferHd>Publier une offre</AddOfferHd>
                    <form onSubmit={form.onSubmit(handleFormSubmit)}>
                        <div className="width50pCenter roboto">
                            <Stepper color="gray" size="md" active={active} onStepClick={setActive} breakpoint={60}>
                                {/* 1 sur 3 */}
                                <Stepper.Step label="Votre besoin" >
                                    <Box id="stepForm-mrg-t">
                                        <TextInput
                                            placeholder="Titre"
                                            label="Titre de l'offre"
                                            withAsterisk
                                            size="md"
                                            {...form.getInputProps('title')}
                                        />
                                        <div className="mrgB20"></div>

                                        <Select
                                            label="Métier recherché"
                                            placeholder="Sélectionnez"
                                            data={[
                                                { value: 'Entreprise de travaux', label: 'Entreprise de travaux' },
                                                { value: 'Electricien', label: 'Electricien' },
                                                { value: 'Menuisier', label: 'Menuisier' },
                                                { value: 'Plombier', label: 'Plombier' },
                                                { value: 'Entreprise de nettoyage, Gardien, Concierge', label: 'Entreprise de nettoyage, Gardien, Concierge' },
                                                { value: 'Jardinier- Paysagiste', label: 'Jardinier- Paysagiste' },
                                                { value: 'Entreprise de maintenance et de dépannage', label: 'Entreprise de maintenance et de dépannage' },
                                                { value: 'Chauffagiste', label: 'Chauffagiste' },
                                                { value: 'Désinfection, Désinsectisation, Dératisation', label: 'Désinfection, Désinsectisation, Dératisation' },
                                                { value: 'Architecte, Bureaux d\'études', label: 'Architecte, Bureaux d\'études' },
                                                { value: 'Assureur', label: 'Assureur' },
                                                { value: 'Avocat spécialisé en droit immobilier', label: 'Avocat spécialisé en droit immobilier' },
                                                { value: 'Autre', label: 'Autre' }
                                            ]}
                                            withAsterisk
                                            size="md"
                                            {...form.getInputProps('occupation')}
                                        />
                                        <div className="mrgB20"></div>
                                            
                                        <Autocomplete
                                            label="Code postal du lieu d'intervention"
                                            placeholder="Code postal"
                                            withAsterisk
                                            value={query}
                                            onChange={handleQueryChange}
                                            onSelect={handleQuerySubmit}
                                            data={options}  
                                            textFieldProps={{ required: true }}
                                            size="md"
                                        />

                                        <div className="mrgB30"></div>
                                        
                                        <Checkbox 
                                            labelPosition="left"
                                            label={
                                                <p className='txt16 noMrgT'>
                                                    Est-ce une urgence ?
                                                </p>}
                                            size="md"
                                            color="dark"
                                            {...form.getInputProps('urgent', { type: 'checkbox' })} 
                                            />
                                        <div className="mrgB10"></div>

                                        <DatePickerInput
                                            icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                                            type="range"
                                            valueFormat="DD MMM YYYY"                          
                                            locale="fr"
                                            label="Dates prévisionnelles de travaux"
                                            placeholder="Sélectionnez vos dates"
                                            minDate= {new Date()}
                                            maxDate = {addYears(new Date(),3)}
                                            allowSingleDateInRange
                                            clearable
                                            withAsterisk
                                            required
                                            size="md"
                                            {...form.getInputProps('workRangeDates')}
                                        />
                                        
                                        <div className="mrgB30"></div>
                                    
                                        <SyndicRectangleButton onClick={nextStep}>
                                            <p className='txt16'>Continuer</p>
                                        </SyndicRectangleButton>
                                    </Box>
                                    
                                    <div className='mrgB100'></div>
                                </Stepper.Step>

                                {/* 2 sur 3 */}
                                <Stepper.Step label="Vos modalités">
                                    <Box id="stepForm-mrg-t">
                                        <DatePickerInput
                                            icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                                            locale="fr"
                                            defaultValue={addDays(new Date(),1)}
                                            valueFormat="DD MMM YYYY"    
                                            minDate={addDays(new Date(),1)}
                                            maxDate={minDeadline}
                                            placeholder="Sélectionnez une date" 
                                            label="Délai de réception des devis" 
                                            description= {
                                                <p className='txt16 noMrgT'>
                                                    Renseignez la date d'échéance jusqu'à laquelle vous souhaitez recevoir les devis. Votre offre reste publiée tant que cette date n'est pas échue.
                                                </p>
                                            }
                                            withAsterisk
                                            required
                                            size="md"
                                            {...form.getInputProps('deadlineToReceiveQuotationDate')}
                                            />
                                        <div className="mrgB30"></div>

                                        <Checkbox
                                            labelPosition="left"
                                            label= {
                                                <p className='txt16 noMrgT'>
                                                    Nécessité d'un RDV sur site pour établir un devis
                                                </p>
                                            }
                                            color="dark"
                                            size="md"
                                            rdv={rdv}
                                            onClick={HandleSetRdv}
                                            {...form.getInputProps('rdvSelected', { type: 'checkbox' })}
                                            />
                                        <div className="mrgB20"></div>

                                        { rdv ?
                                        <> 
                                            <Checkbox
                                                labelPosition="left"
                                                label={
                                                    <p className='txt16 noMrgT'>
                                                        Autoriser les prestataires à envoyer des devis sans RDV de chiffrage
                                                    </p>
                                                }
                                                size="md"
                                                color="dark"
                                                {...form.getInputProps('quotationReceptionAllowed', { type: 'checkbox' })}
                                                />
                                            <div className="mrgB10"></div>
                                        </>
                                        : ''
                                        } 

                                        <div className="mrgB30"></div>

                                        <SyndicRectangleButtonSnd onClick={prevStep}>
                                            <p className='txt16'>Retour</p>
                                        </SyndicRectangleButtonSnd>
                                        
                                        <div className="mrgB10"></div>

                                        <SyndicRectangleButton onClick={nextStep}>
                                            <p className='txt16'>Continuer</p>
                                        </SyndicRectangleButton>
                                        
                                        <div id="mrgB50"></div>

                                    </Box>
                                    <div className='mrgB100'></div>

                                </Stepper.Step>

                                {/* 3 sur 3 */}
                                <Stepper.Step  label="Boostez & Publiez">
                                    <Box id="stepForm-mrg-t">
                                        <NumberInput
                                            placeholder="Nombre"
                                            label="Nombre de lot(s)"
                                            max={99999}
                                            min={1}
                                            size="md"
                                            {...form.getInputProps('nbOfLots')}
                                        />
                                        <div className="mrgB20"></div>

                                        <Textarea
                                            label="Description du projet"
                                            size="md"
                                            {...form.getInputProps('description')}
                                            />
                                        <div className="mrgB20"></div>

                                        <div className='horizontal iEnd'>
                                            <div className='linkToDocsInput'>
                                                <TextInput
                                                    label="Lien vers document(s) de l'offre"
                                                    placeholder='https://'
                                                    size="md"                   
                                                    {...form.getInputProps('linkToDocs')}
                                                />
                                            </div>
                                            <button onClick={handleVerifyLinkToDocs} className='btnReset mrgLauto useRoboto linkToDocsBtn'>
                                                Vérifier lien
                                            </button>
                                        </div>
                                        <div className="mrgB20"></div>

                                        {openModalLinkToDocs  
                                            ?   <Modal 
                                                    centered
                                                    size="auto"
                                                    opened={openedModalLinkToDocs}
                                                    onClose={closeModalLinkToDocs}
                                                    withCloseButton={false}>
                                                    <p className="useRoboto txt15 txtCenter noMrgTB">
                                                        Aucun lien à vérifier
                                                    </p>
                                                </Modal> 
                                            :   ""
                                        }

                                        <p className="medium txt16 mrgB5">Photos (3 maximum)</p>
                                        
                                        <div className='horizontal'>
                                            {previewPictures.length > 0 
                                                ?   <div className='horizontal mrgT5 mrgB5'>
                                                        {previewPictures.map( (picture, index) => (
                                                            <div key = {picture} style = {{marginRight : '5px'}} className='vertical imgRelative'>
                                                                <img className='img100x100' src={picture} alt='photo_chantier'/>
                                                                <button value={picture} onClick={deleteImg} className='btnReset imgDeleteImgBtnAbsolute mrgR5 mrgT5 bold bLiteTransparent'><IconX width={20} height={20}/></button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                :   ""
                                            }

                                            <FileButton
                                                className='img100x100T mrgT5' 
                                                multiple
                                                onChange={fileBrowseHandler}
                                                accept="image/png,image/jpeg,image/jpg">
                                                {(props) => <Button color="gray" {...props}>
                                                    <div className='vertical iCenter'>
                                                        <IconPlus width={20}/> 
                                                        <p className='noMrgTB txt16'>Ajouter photo</p>
                                                    </div> 
                                                </Button>}
                                            </FileButton>

                                            {openModalImgLimit  
                                                ?   <Modal 
                                                        centered
                                                        size="auto"
                                                        opened={openedImgLimit}
                                                        onClose={closeModalImgLimit}
                                                        withCloseButton={false}>
                                                        <p className="useRoboto txt16 txtCenter noMrgTB">
                                                            Chargement de 3 photos maximum
                                                        </p>
                                                    </Modal> 
                                                :   ""
                                            }

                                            {openModalExistingImg  
                                                ?   <Modal 
                                                        centered
                                                        size="auto"
                                                        opened={openedExistingImg}
                                                        onClose={closeModalExistingImg}
                                                        withCloseButton={false}>
                                                        <p className="useRoboto txt16 txtCenter noMrgTB">
                                                            Photo déjà téléchargée
                                                        </p>
                                                    </Modal> 
                                                :   ""
                                            }
                                            
                                            {openModalTooBigImg  
                                                ?   <Modal 
                                                        centered
                                                        size="auto"
                                                        opened={openedTooBigImg}
                                                        onClose={closeModalTooBigImg}
                                                        withCloseButton={false}>
                                                        <p className="useRoboto txt16 txtCenter noMrgTB">
                                                            10 Mo Maximum par photo
                                                        </p>
                                                    </Modal> 
                                                :   ""
                                            }
                                            {openModalWrongType 
                                                ?   <Modal 
                                                        centered
                                                        size="auto"
                                                        opened={openedModalWrongType}
                                                        onClose={closeModalWrongType}
                                                        withCloseButton={false}>
                                                        <p className="useRoboto txt16 txtCenter noMrgTB">
                                                            Fichiers autorisés : .png, .jpeg, .jpg
                                                        </p>
                                                    </Modal> 
                                                :   ""
                                            }
                                        </div>

                                        <div className="mrgB30"></div>

                                        <SyndicRectangleButtonSnd onClick={prevStep}>
                                            <p className='txt16'>Retour</p>
                                        </SyndicRectangleButtonSnd>
                                            
                                        <div className="mrgB10"></div>

                                        <SyndicRectangleButton type="submit">
                                        <p className='txt16'>Visualiser mon offre</p>
                                        </SyndicRectangleButton>

                                    </Box>
                                    <div className='mrgB100'></div>

                                </Stepper.Step>
                            </Stepper>
                        </div>
                    </form>
                </>
            )}
        </>

    );
}

  export default AddNewOfferSyndic;

