import React from "react";
import artisanDeConfianceLogo from '../../../../assets/images/artisandeconfiance.jpg'
import classes from './LogoArtisansDeConfiance.module.css';

const LogoArtisansDeConfiance = (props) => {
    return(
        <div className={classes.Logo}>
            <img src={artisanDeConfianceLogo} alt="logo"/>
        </div>
    )
}
export default LogoArtisansDeConfiance;