import React from "react";
import qualienrLogo from '../../../../assets/images/qualienr.jpg'
import classes from './LogoQualienr.module.css';

const LogoQualienr = (props) => {
    return(
        <div className={classes.Logo}>
            <img src={qualienrLogo} alt="logo"/>
        </div>
    )
}
export default LogoQualienr;