import React, { createContext, useState } from "react";
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const setToken = token => {
    localStorage.setItem("token", token);
  };


  // Fonction pour récupérer le token JWT depuis localStorage
  const getToken = () => {
    return localStorage.getItem("token");
  };

  const getUserType = () => {
    return localStorage.getItem("userType");

  }

  const getSession = () => {
    return sessionStorage.getItem("user");
  }

  const setUserType = type => {
    localStorage.setItem("userType", type);
  };

  // Fonction pour supprimer le token JWT de localStorage
  const removeToken = () => {
    localStorage.removeItem("token");
  };

   // Fonction pour supprimer le user de sessionStorage
   const removeUser = () => {
    sessionStorage.removeItem("user");
   };
  
   // Fonction pour supprimer le user type de sessionStorage
   const removeUserType = () => {
    sessionStorage.removeItem("userType");
  };

  const [userData, setUserData] = useState();
  const [error, setError] = useState();


  const handleLoginPresta = async (values, _event) => {
    _event.preventDefault();
    try {
      const response = await fetch("https://boldee-97d8d36af3bd.herokuapp.com/p/login", {
        method: "POST",
        body: JSON.stringify({ 
          email: values.email, 
          password: values.password 
        }),
        headers: { "Content-Type": "application/json" }
      });      
      if (response.ok) {
        const data = await response.json();
        await setUserData(data.user);
        await setToken(data.token);
        await setUserType(data.user.company.type);
        sessionStorage.setItem("user", JSON.stringify(data.user));
      } else if (response.status === 401){
        setError('Identifiant ou mot de passe invalide.');
      } else if (response.status === 403){
        setError('Nombre maximum de connexion atteinte')
      }
    } catch (error) {
    }
  };

  const handleLoginSyndic = async (values, _event) => {
    _event.preventDefault();
    try {
      const response = await fetch("https://boldee-97d8d36af3bd.herokuapp.com/s/login", {
        method: "POST",
        body: JSON.stringify({ 
          email: values.email, 
          password: values.password 
        }),
        headers: { "Content-Type": "application/json" }
      });

      if (response.ok) {
        const data = await response.json();
        await setUserData(data.user);
        await setToken(data.token);
        await setUserType(data.user.company.type);
        sessionStorage.setItem("user", JSON.stringify(data.user));
      } else if (response.status === 401){
        setError('Identifiant ou mot de passe invalide.');
      } 
    } catch (error) {
      console.error(error);
    }
  };

  const handleLoginAdmin = async (values, _event) => {
    _event.preventDefault();
    try {
      const response = await fetch("https://boldee-97d8d36af3bd.herokuapp.com/adm/login", {
        // const response = await fetch("http://localhost:5030/adm/login", {
        method: "POST",
        body: JSON.stringify({ 
          email: values.email, 
          password: values.password 
        }),
        headers: { "Content-Type": "application/json" }
      });

      if (response.ok) {
        const data = await response.json();
        await setUserData(data.user);
        await setToken(data.token);
        await setUserType(data.type);
        sessionStorage.setItem("user", JSON.stringify(data.user));
      } else if (response.status === 401){
        setError('Identifiant ou mot de passe invalide.');
      }
    } catch (error) {
    }
  };

  // Suppression du token JWT de localStorage lors de la déconnexion
  const handleLogoutPresta = async () => {
    await removeToken(); // Suppression du token JWT de localStorage
    await removeUser();
    await removeUserType();
    try {
      await fetch(`https://boldee-97d8d36af3bd.herokuapp.com/p/logout/${userData._id}`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${getToken()}` },
      });      
    } catch (error) {
    }
  };

    // Suppression du token JWT de localStorage lors de la déconnexion
  const handleLogoutSyndic = async () => {
    removeToken();
    removeUser();
    removeUserType();

    try {
      await fetch('https://boldee-97d8d36af3bd.herokuapp.com/s/logout', {
        method: 'POST',
        headers: { Authorization: `Bearer ${getToken()}` },
      });
    } catch (error) {
    }
  };

  // Suppression du token JWT de localStorage lors de la déconnexion
  const handleLogoutAdmin = async () => {
    removeToken();
    removeUser();
    removeUserType();
  
    try {
      await fetch('https://boldee-97d8d36af3bd.herokuapp.com/adm/logout', {
        method: 'POST',
        headers: { Authorization: `Bearer ${getToken()}` },
      });
    } catch (error) {
    }
  };

  return (
    <AuthContext.Provider
      value={{ 
        userData, 
        handleLoginPresta, handleLogoutPresta, error,
        handleLoginSyndic, handleLogoutSyndic, 
        handleLoginAdmin, handleLogoutAdmin,
         getToken, getSession, getUserType }}
    >
      {children}
    </AuthContext.Provider>
  );
};

