import { React, useState, useEffect } from 'react';
import classes from './FavoritePrestaHd.module.css';
import { Link } from 'react-router-dom';
import { IconPlus } from '@tabler/icons-react';

const AppHdWithCloseRight = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
          };
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return(
        <>
            {isMobile ? (
                <div className={classes.appHeading}>
                    <div className={classes.navbackBlank}></div>
                    <div className={classes.navtitle}>
                        <h2>{props.children}</h2>
                    </div>
            
                    <Link to="/s/favPresta/add-list" className="aReset bT">
                        <div>
                            <IconPlus/>
                        </div>
                    </Link>
                </div>
            ) : (
                <div className={classes.appHeading}>
                <div className={classes.navbackBlank}></div>
                <div className={classes.navtitle}>
                    <h2>{props.children}</h2>
                </div>
        
                <Link to="/s/favPresta/add-list" className="aReset bT">
                    <div className='mrgR20'>
                        <IconPlus width={30} height={30}/>
                    </div>
                </Link>
            </div>
            )}
        </>
    )
}

export default AppHdWithCloseRight;