import { React, useState, useEffect, useContext } from 'react';
import { AuthContext } from "../../../contexts/authContext";
import { TextInput, Box, PasswordInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Link, useNavigate } from 'react-router-dom';

import SyndicRectangleButton from '../../../composents/Buttons/Syndic/RectangleButton/RectangleButtonMain';
import Navbar from '../../Navbar/NavbarPublic';
import Footer from '../../Footer/Footer';
import LogoLogin from '../../../composents/Logo/LoginLogo/LogoLogin';


const LoginSyndic = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const { handleLoginSyndic, userData, getToken, error } = useContext(AuthContext);
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      email: '',
      password: ''
    },

    validate: {
      email : (value) => (
        value.length < 1
        ? 'Renseignez votre E-mail'
        : /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) 
        ? null 
        : 'E-mail non valide'
    ),      
    password: (value) => (
      value.length < 1
        ? 'Renseignez votre mot de passe.'
        : null
    ),
    }
  });

  useEffect(
    () => {
      window.scrollTo(0, 0);
      const token = getToken();
      if (token) {
        // Redirection vers la page de connexion si le token n'est pas présent dans localStorage
        navigate("/s/offers/list");
        return;
      };
      const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userData]
  );

  return (
    <div>
      <Navbar />

      {isMobile ? (
          <>
              <div className="width90pCenter txtCenter">
                  <div className="mrgT150"></div>
                  <div><LogoLogin /></div>
                  <p id="signUpTitle">Se connecter</p>
                  <p className="txt18 bold mrgT5 terracotaT">Accès syndic</p>
                  <p id="loginSubtitle">Vous n'avez toujours pas de compte ?
                    <Link to="/signup" className="aReset bold bT underline mrgL10">
                      S'inscrire
                    </Link></p>
              </div>
              <Box className="width90pCenter" sx={{ maxWidth: 300 }} mx="auto">
                <form onSubmit={form.onSubmit(handleLoginSyndic)}>

                  <TextInput
                    withAsterisk
                    placeholder="Email"
                    {...form.getInputProps('email')}
                  />
                  <div className="mrgB10"></div>

                  <PasswordInput
                    placeholder='Mot de passe'
                    withAsterisk
                    {...form.getInputProps('password')}
                  />
                  {error && <p className='txt13 rT mrgT5 mrgB5'> {error}</p>}
                  <div className="mrgB10"></div>
                  
                  <div className="horizontal jEnd">
                    <Link to="/s/forgot-password" className="aReset bT">
                      <p className="noMrg txt13 thin mrgB20">Mot de passe oublié</p>
                    </Link>
                  </div>


                  <SyndicRectangleButton
                    type={"submit"}
                    onClick={() => { }}>
                    Se connecter</SyndicRectangleButton>

                </form>

              </Box>
              <div className="mrgT150"></div>
          </>
      ) : (
        <>
            <div className="width90pCenter txtCenter">
                  <div className="mrgT200"></div>
                  <p id="signUpTitle">Se connecter</p>
                  <p className="txt30 bold mrgT10 terracotaT">Accès syndic</p>
                  <p id="loginSubtitle">Vous n'avez toujours pas de compte ?
                    <Link to="/signup" className="aReset bold bT underline mrgL10">
                      S'inscrire
                    </Link></p>
              </div>
              <Box className="width90pCenter" sx={{ maxWidth: 300 }} mx="auto">
                <form onSubmit={form.onSubmit(handleLoginSyndic)}>

                  <TextInput
                    withAsterisk
                    placeholder="Email"
                    size='lg'
                    {...form.getInputProps('email')}
                  />
                  <div className="mrgB10"></div>

                  <PasswordInput
                    placeholder='Mot de passe'
                    withAsterisk
                    size='lg'
                    {...form.getInputProps('password')}
                  />
                  {error && <p className='txt15 rT mrgT5 mrgB5'> {error}</p>}
                  <div className="mrgB10"></div>
                  
                  <div className="horizontal jEnd">
                    <Link to="/s/forgot-password" className="aReset bT">
                      <p className="noMrg txt16 thin mrgB20">Mot de passe oublié</p>
                    </Link>
                  </div>


                  <SyndicRectangleButton
                    type={"submit"}
                    onClick={() => { }}>
                    Se connecter</SyndicRectangleButton>

                </form>

              </Box>
              <div className="mrgT150"></div>
        </>
      )}  

      <Footer />
    </div>
  )

}

export default LoginSyndic;